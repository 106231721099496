import React, { Component } from "react";
import {
  NavigationHeaderContainer,
  NavigationContainer
} from "static/styled/NavigationBar.styled";
import { connectStore } from "redux-box";
import { module as activity } from "@st/activity";
import { module as level } from "@st/level";
import { Link } from "react-router-dom";

// export default connectStore({
//   activity,
//   level
// })
// export default 
class ActivityPackLevelHeader extends Component {
  constructor(props) {
    super();
    this.state = {
      levelSelected: "Level 1"
    };
  }

  handleLevelSelector = data => {
    let { activity } = this.props;
    activity.setActiveGradeLevel(data);
    activity.setActiveLevel("Easy");
    activity.requestActivityPacks();
  };

  render() {
    const data = this.props.level.levels;
    let levelList = data.map((level, i) => (
      <Link
        to={"/activities/packs/" + level + "/Easy"}
        key={i}
        className={
          this.props.activity.active_grade_level === level ? "active" : ""
        }
        onClick={() => this.handleLevelSelector(level)}
      >
        {level}
      </Link>
    ));
    return (
      <div style={{ marginTop: "10px" }}>
        <NavigationHeaderContainer>
          <NavigationContainer>{levelList}</NavigationContainer>
        </NavigationHeaderContainer>
      </div>
    );
  }
}
export default connectStore({
  activity,
 level
 })(ActivityPackLevelHeader)