import React, {Component} from 'react';
import {ThemeButton} from "@sc/custom.styled";

import {
    ActivityModalContainer,
    ActivityPopUpContainer
} from "@sc/ActivityModal.styled";
import {
    EnterTagsInputContainer, SelectionLabels, SelectTagsListContainer,
    TagLabelContainer
} from "@c/activity-view/activity-creator/tag-editor/TagEditor.styled";
import {connectStore} from "redux-box";
import {module as activity} from "@st/activity";
import {module as readingChallenge} from "@st/readingChallenge";
import {module as ui} from "@st/ui";
import {PanelHeader} from "@sc/custom.styled";



class ActivityAssignTags extends Component {
    handleTagChange = (e) => {
        this.setState({
            tag: e.target.value,
        })
    };
    handleEnterClick = (e) => {
        if (e.key === 'Enter' && e.target.value.trim().length) {
            this.handleAddClick(e);
        }
    };
    handleAddClick = (e) => {
        let {readingChallenge} = this.props;
        if (this.state.tag.trim().length)
        {
            readingChallenge.requestCreateReadingTag(this.state.tag);
        }
        this.setState({
            tag: ''
        })
    };

    handleTagClick(tag){
        let {readingChallenge} = this.props;
            readingChallenge.requestCreateReadingTag(tag)
    };

    onDoneClick=(e)=>{
       // console.log("hello");
        this.props.activity.closeEditor();
    };

    constructor(props) {
        super(props);
        this.state = {
            tag: ''
        }
    }

    render() {
        let selectTagArray = ['Teacher stuff', 'Teacher ', 'Student', 'Mathematics'];
        const selectTags = selectTagArray.map((tag, i) =>
            <TagLabelContainer key={i} onClick={() => this.handleTagClick(tag)}>
                <div>
                    {tag}
                </div>
            </TagLabelContainer>
        );
        return (

            <ActivityPopUpContainer>
                <PanelHeader primary>
                    <span>
                        Assign Tags
                </span>
                    <ThemeButton primary onClick={this.props.activity.closeEditor}>Done</ThemeButton>
                    {/*<ThemeButton primary onClick={this.onDoneClick}>Done</ThemeButton>*/}
                </PanelHeader>
                <ActivityModalContainer>

                    <EnterTagsInputContainer>
                        <input type="text" placeholder="Enter a tag name" value={this.state.tag}
                               onChange={this.handleTagChange} onKeyPress={this.handleEnterClick}>
                        </input>
                        <button onClick={this.handleAddClick}> +

                        </button>

                    </EnterTagsInputContainer>
                    <SelectionLabels> OR </SelectionLabels>

                    <SelectionLabels primary>
                        Select Tags from below
                    </SelectionLabels>
                    <SelectTagsListContainer>
                        {selectTags}
                    </SelectTagsListContainer>

                </ActivityModalContainer>

            </ActivityPopUpContainer>
        )
    }
}
export default connectStore({
    activity,ui,readingChallenge
})(ActivityAssignTags)