import React, { Component } from "react";
import { MainBodyContainer, MainBodyContentContainer } from "@sc/custom.styled";
import ExperienceViewHeader from "./experience-view-header/ExperienceViewHeader";
import AllExperiencesList from "./all-experiences-list/AllExperiencesList";
import { connectStore } from "redux-box";
import ExperiencesRouter from "@r/experienceRouter";
import { module as experience } from "store/experience";
// export default connectStore({
//   experience
// })

class ExperiencesView extends Component {
  componentDidMount() {
    let { experience } = this.props;
    experience.setExperienceType(this.props.match.params.category);
    experience.requestGetAllExperiencesList(0);
  }
  render() {
    return (
      <MainBodyContainer>
        <ExperiencesRouter />
        <ExperienceViewHeader />
        <MainBodyContentContainer>
          <AllExperiencesList />
        </MainBodyContentContainer>
      </MainBodyContainer>
    );
  }
}
export default connectStore({
  experience
 })(ExperiencesView)