import React, { Component } from "react";
import {
  NavigationContainer,
  NavigationHeaderContainer,
  NavigationToolbar
} from "@sc/NavigationBar.styled";
import { SearchBarContainer } from "@c/users-view/MainContent.styled";
import { connectStore } from "redux-box";
import { DateSelectionField } from "@sc/custom.styled";
import { module as payment } from "@st/payment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { Link } from "react-router-dom";

class PaymentsHeader extends Component {
  constructor(props) {
    super();
    this.state = {
      date: '',
      // date: moment(),
      searchBarEnable: false
    };
  }
  componentDidMount = () => {
    if (this.props.payment.payment_date) {
      // let newDate = moment.unix(this.props.experience.experience_date).format('YYYY-MM-DD');
      this.setState({
        date: this.props.payment.payment_date
      })
    }
  }
  enableSearchBar = actionType => {
    if (actionType === "focus" && this.state.searchBarEnable === false)
      this.setState({
        searchBarEnable: true
      });
    else if (actionType === "blur" && this.state.searchBarEnable === true)
      this.setState({
        searchBarEnable: false
      });
  };
  onDatepickerRef(el) {
    if (el && el.input) {
      el.input.readOnly = true;
    }
  }

  handlePaymentSearch = evt => {
    let { payment } = this.props;
    payment.setSearchPayment(evt.target.value);
    setTimeout(() => {
      let { payment } = this.props;
      payment.requestPaymentList(0);
    }, 2500);
  };
  handleClearSearch = () => {
    let { payment } = this.props;
    payment.setSearchPayment("");
    payment.requestPaymentList(0);
  };

  setPaymentType = value => {
    let { payment } = this.props;
    payment.setPaymentType(value.toLowerCase());
    payment.requestPaymentList(0);
  };
  handleDateChange = date => {
    let { payment } = this.props;
    this.setState({
      date: date
    });
    if (date) {
      let selectedDate = new Date(date);
      let month =
        selectedDate.getMonth() >= 9
          ? selectedDate.getMonth() + 1
          : "0" + (selectedDate.getMonth() + 1);
      let day =
        selectedDate.getDate() < 10
          ? "0" + selectedDate.getDate()
          : selectedDate.getDate();
      let dateData = selectedDate.getFullYear() + "-" + month + "-" + day;
      payment.setActivePaymentDate(dateData);
      payment.requestPaymentList(0);
    }
  };
  handleClearBtn = () => {
    let { payment } = this.props;
    this.setState({
      date: ""
    });
    payment.setActivePaymentDate("");
    payment.requestPaymentList(0);
  };

  render() {
    let { payment } = this.props;
    return (
      <NavigationHeaderContainer>
        <NavigationContainer>
          <Link
            to="/payments/success"
            className={payment.payment_type === "success" ? "active" : ""}
            onClick={() => this.setPaymentType("success")}
          >
            Success{" "}
          </Link>

          <Link
            to="/payments/failed"
            className={payment.payment_type === "failed" ? "active" : ""}
            onClick={() => this.setPaymentType("failed")}
          >
            Failed
          </Link>
          <Link
            to="/payments/others"
            className={payment.payment_type === "others" ? "active" : ""}
            onClick={() => this.setPaymentType("others")}
          >
            Others
          </Link>
          <Link
            to="/payments/payment-link"
            className={payment.payment_type === "payment-link" ? "active" : ""}
            onClick={() => this.props.setPaymentType("payment-link")}
          >
            Generate Payment Link{" "}
          </Link>
        </NavigationContainer>
        <NavigationToolbar>
          <SearchBarContainer
            width={this.state.searchBarEnable ? "300px" : "200px"}
          >
            <i className="fa fa-search" />
            <input
              type="text"
              placeholder="Search"
              onChange={this.handlePaymentSearch}
              value={this.props.payment.search_payment}
              onFocus={() => this.enableSearchBar("focus")}
              onBlur={() => this.enableSearchBar("blur")}
            />
            {this.props.payment.search_payment.length > 0 && (
              <i
                className="fa fa-times cross"
                onClick={() => this.handleClearSearch()}
              />
            )}
          </SearchBarContainer>
          <div id="payments-date">
            <DateSelectionField width="220px" style={{ overflow: "hidden" }}>
              <img src={require("static/img/date-icon.png")} alt="startDate" />
              <DatePicker
                // selected={this.state.date}
                selected={this.state.date}
                selectsStart
                startDate={this.state.date}
                onChange={this.handleDateChange}
                placeholderText='Select date'
                ref={el => this.onDatepickerRef(el)}
              />

              {this.state.date ? (
                <i
                  className="fa fa-times"
                  onClick={() => this.handleClearBtn()}
                  style={{ padding: "8px", cursor: "pointer" }}
                />
              ) : null}
            </DateSelectionField>
          </div>
        </NavigationToolbar>
      </NavigationHeaderContainer>
    );
  }
}
export default connectStore({
  payment
})(PaymentsHeader)