import React, {Component} from 'react';
import store from 'store'
import action from '@st/activity/actions'
import {McqItemContainer, McqDescription, McqToolbar} from "static/styled/custom.styled";
import { connectStore } from "redux-box";
import { module as activity } from "@st/activity";
import { module as assessment } from "@st/assessment";
import swal from 'sweetalert2'
import { module as onboard } from "@st/onboard";
import Permission from "@s/permission";
// export default connectStore({
//     assessment,activity
// })
// export default 
class McqCard extends Component
{

    handleDeleteClick=(question,evt)=>{
        const {permission} = this.props.onboard;
        if(Permission.getPermission(permission, "change_activity")){
            this.props.activity.closeEditor();
            swal({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Delete'
            }).then((result) => {
                if (result.value) {
                    this.props.assessment.requestDeleteAssessmentQuestion(question);
                }
            })

            evt.stopPropagation();
        }
    }

    handleEditClick(data){
        const {permission} = this.props.onboard;
        if(Permission.getPermission(permission, "change_activity")){
            this.props.assessment.setActiveAssessmentMcq(data);
            store.dispatch(action.setEditorType('createQA'))
        }
       
    }
    render(){
        let data=this.props.question_data;
        return(
            <McqItemContainer width="95%" onClick={() => this.handleEditClick(data)}  style={{cursor:'pointer'}}>

                <McqDescription>
                    {data.name}
                </McqDescription>
                <McqToolbar>
                    <img src={require("static/img/edit-button-blue.png")} onClick={() => this.handleEditClick(data)}
                                className="image-fluid"
                                 alt="Edit icon"/>
                    <img src={require("static/img/trash.png")} onClick={(evt) => this.handleDeleteClick(data,evt)}
                         className="image-fluid" alt="Delete icon"/>
                </McqToolbar>
            </McqItemContainer>
        )
    }
}

export default connectStore({
    activity,
   assessment,
   onboard
   })(McqCard)