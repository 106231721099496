import React, { Component } from "react";
import {
  ImageLoader,
  CheckBall,
  CheckBallInner,
  DropdownItem,
  FlexStart,
  UploadImagesParentContainer,
} from "@sc/custom.styled";
import {
  ActivityContinueButtonContainer,
  ActivityMainFormContainer,
  ActivityUploadImageButton,
  CreateActivityContainer,
  SelectFileIn,
  ImageContainer,
  ChangeImageButton,
  UploadActivityImageContainer,
} from "@c/activity-view/activity-creation-form/ActivityCreationForm.styled";
import { connectStore } from "redux-box";
import { uploadFile } from "@s/api/file";
import swal from "sweetalert2";
import { ActivityCreatorNavContainer } from "@sc/ActivityModal.styled";
import {
  RCHeaderDropDown,
  RCHeaderDropDownMenu,
  RCNameInput,
  CreateRCButton,
} from "./QuizCreationForm.styled";
import { module as ui } from "@st/ui";
import { module as activity } from "@st/activity";
import { module as quiz2 } from "@st/quiz2";
import { module as category } from "@st/category";

import { Chevron } from "../../../static/styled/custom.styled";

class QuizCreationForm extends Component {
  constructor(props) {
    super();
    this.state = {
      gradeSelected: null,
      gradeSelector: false,
      categorySelector: false,
      gradeData: [],
      selectedGrades: [],
      displayMenu: false,
      gradePackSelected: null,
      showActivityCreator: false,
      enableLoad: false,
      imageChange: false,
      imageBackgroundChange: false,
      enableBackgroundChange: false,

      file: "",
      quizbody: {
        name: "",
        description: "",
        grade: "",
        status: true,
        points: null,
        image: "https://stones2milestones.com/img/external/placeholder.png",
        quiz_type: "",
        theme: "",
        quiz_category: "",
        quiz_category_name: "",
      },
    };
  }

  componentDidMount() {
    this.props.activity.requestGrades();
    const res = this.props.category.getCategoryData();
    console.log("res>>>", this.props);
    console.log("res123>>>", res);

    const grade =
      this.props.activity.active_grade || this.props.activity.grades[0];

    this.setState({
      gradeSelected: grade,
      quizbody: { ...this.state.quizbody, grade: grade.id },
    });

    const { activity } = this.props;

    let gradeList = activity.grades
      // .filter((g) => g.name !== "Nursery")
      .map((grade) => {
        let gradeData = {
          ...grade,
          selected: false,
          clicked: false,
        };
        return gradeData;
      });

    this.setState({
      gradeData: [
        {
          name: "Select All",
          selected: false,
          clicked: false,
        },
        ...gradeList,
      ],
    });
  }

  _handleImageChange = (e) => {
    // e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];
    let fileSize = e.target.files[0].size / 1024 / 1024;
    if (fileSize > 2) {
      swal("Oops!", "File size greater than 2MB ", "warning");
    } else {
      reader.onloadend = () => {
        this.setState({
          enableLoad: true,
          imageChange: false,
          quizbody: {
            ...this.state.quizbody,
            image: reader.result,
          },
        });
      };
      reader.readAsDataURL(file);
      uploadFile(file).then((res) => {
        this.setState({
          quizbody: { ...this.state.quizbody, image: res.file },
          enableLoad: false,
        });
      });
    }
  };

  handleImageFocus = (actionType) => {
    if (actionType === "over" && this.state.imageChange === false)
      this.setState({
        imageChange: true,
      });
    else if (actionType === "out" && this.state.imageChange === true)
      this.setState({
        imageChange: false,
      });
  };

  handleGradeSelector = (val) => {
    let newGradeData = this.state.gradeData.map((grade) => {
      return { ...grade, clicked: false };
    });
    this.setState({
      gradeData: newGradeData,
      gradeSelector: val,
    });
  };

  handleGradeChange = (data) => {
    this.handleGradeSelector(true);
    let selectedGrades = this.state.selectedGrades;
    if (data.name === "Select All" || data.name === "Unselect All") {
      // change the grade data with new grade data
      let newGradeData = this.state.gradeData.map((grade) => {
        if (grade.name === "Select All" || grade.name === "Unselect All") {
          let name =
            grade.name === "Select All" ? "Unselect All" : "Select All";
          return { ...grade, name: name, selected: !grade.selected };
        }
        return {
          ...grade,
          selected: !this.state.gradeData[0].selected,
        };
      });

      // change selected grades
      if (data.name === "Unselect All") selectedGrades = [];
      else if (data.name === "Select All") {
        // storing the id's and filtering for undefined
        selectedGrades = this.state.gradeData
          .map((g) => g.id)
          .filter((g) => g !== undefined);
      }

      this.setState({
        gradeData: newGradeData,
        selectedGrades: selectedGrades,
      });
    } else {
      let newGradeData = this.state.gradeData.map((grade) => {
        if (grade.name === data.name)
          return { ...grade, selected: !grade.selected };
        return { ...grade };
      });

      const data_idx = selectedGrades.indexOf(data.id);

      // removing grade if it already exists
      data_idx > -1
        ? selectedGrades.splice(data_idx, 1)
        : selectedGrades.push(data.id);

      this.setState({
        gradeData: newGradeData,
        selectedGrades: selectedGrades,
      });
    }
  };

  handleGradeSelected(grades) {
    this.setState({
      displayMenu: !this.state.displayMenu,
      gradeSelected: grades,
      quizbody: { ...this.state.quizbody, grade: grades.id },
    });
  }

  handleContinueBtn = (e) => {
    this.setState({
      displayMenu: false,
    });

    this.handleAddActivity();
  };

  handleAddActivity = () => {
    let { quizbody } = this.state;
    if (
      quizbody.name &&
      this.state.selectedGrades.length &&
      quizbody.description &&
      quizbody.quiz_type &&
      quizbody.theme &&
      quizbody.quiz_category
    ) {
      let points = this.state.quizbody.points;
      points === "" && (points = 0);

      // let grade_arr = this.state.selectedGrades.map((grade) => {
      //   let tempGrade =
      //     this.props.activity.grades.find((ele) => ele.id === grade).name || "";

      //   if (!["Nursery", "Junior KG", "Senior KG"].includes(tempGrade)) {
      //     return tempGrade.charAt(tempGrade.length - 1);
      //   } else {
      //     if (tempGrade === "Nursery") {
      //       return "9";
      //     }

      //     if (tempGrade === "Junior KG") {
      //       return "10";
      //     }

      //     if (tempGrade === "Senior KG") {
      //       return "11";
      //     }
      //   }
      //   return 0;
      // });
      // let grade = grade_arr.join(", ");
      this.props.quiz2.requestCreateQuiz({
        ...this.state.quizbody,
        grade: this.state.selectedGrades,
      });
      this.props.ui.hideModal();
      swal("Done!", "Quiz created successfully!", "success");
    } else {
      swal(
        "Oops!",
        "You must enter name, grade, description, type ,theme and category to create quiz",
        "error"
      );
    }
  };

  handleChallengeName = (evt) => {
    this.setState({
      quizbody: { ...this.state.quizbody, name: evt.target.value },
    });
  };
  handleQuizType = (evt) => {
    this.setState({
      quizbody: { ...this.state.quizbody, quiz_type: evt.target.value },
    });
  };
  handleQuizTheme = (evt) => {
    this.setState({
      quizbody: { ...this.state.quizbody, theme: evt.target.value },
    });
  };

  handleCategorySelector = (val) => {
    let newGradeData = this.state.gradeData.map((grade) => {
      return { ...grade, clicked: false };
    });
    this.setState({
      gradeData: newGradeData,
      categorySelector: val,
    });
  };

  handleQuizCategory = (value) => {
    console.log("Value>>", value);
    this.setState({
      quizbody: {
        ...this.state.quizbody,
        quiz_category: value.id,
        quiz_category_name: value.name,
      },
    });
    this.handleCategorySelector(false);
  };
  handleChallengeDescription = (evt) => {
    this.setState({
      quizbody: { ...this.state.quizbody, description: evt.target.value },
    });
  };

  handleFreadomPoints = (evt) => {
    if (evt.target.value.length === 0 && this.state.quizbody.points !== "") {
      this.setState({
        quizbody: { ...this.state.quizbody, points: "" },
      });
    } else
      this.setState({
        quizbody: { ...this.state.quizbody, points: evt.target.value },
      });
  };

  render() {
    console.log("gradeData", this.state.gradeData);
    console.log("info", this.state.quizbody);
    return (
      <CreateActivityContainer>
        <UploadImagesParentContainer>
          {!this.state.quizbody.image ? (
            <UploadActivityImageContainer height="120px" marginBottom="14px">
              <ActivityUploadImageButton type="button">
                <img
                  src={require("static/img/cloud-upload-icon.png")}
                  alt="cloud-upload"
                />
                <span>Upload Image </span>
              </ActivityUploadImageButton>

              <SelectFileIn
                type="file"
                onChange={(e) => this._handleImageChange(e)}
              />
            </UploadActivityImageContainer>
          ) : this.state.enableLoad ? (
            <UploadActivityImageContainer height="120px" marginBottom="14px">
              <ImageLoader>
                {" "}
                <img
                  alt="loading"
                  width="30"
                  height="30"
                  src="http://photokonkurs.russia.travel/images/icons/preloader.gif"
                />
              </ImageLoader>
            </UploadActivityImageContainer>
          ) : (
            <UploadActivityImageContainer
              marginBottom="14px"
              height="120px"
              onMouseOver={() => this.handleImageFocus("over")}
              onMouseOut={() => this.handleImageFocus("out")}
            >
              <ImageContainer src={this.state.quizbody.image} />
              <ChangeImageButton
                top={this.state.imageChange ? "83%" : "100%"}
                opacity={this.state.imageChange ? 1 : 0}
              >
                Change Image
              </ChangeImageButton>
              <SelectFileIn
                type="file"
                onChange={(e) => this._handleImageChange(e)}
              />
            </UploadActivityImageContainer>
          )}
          {/* {!this.state.quizbody.background_image ? (
                        <UploadActivityImageContainer height="190px" >
                            <ActivityUploadImageButton type="button">
                                <div> <img src={require("static/img/cloud-upload-icon.png")} alt="cloud-upload" />
                                </div>
                                <span> Upload Full screen Image </span>
                            </ActivityUploadImageButton>

                            <SelectFileIn
                                type="file"
                                onChange={e => this._handleBackgroundImageChange(e)}
                            />

                        </UploadActivityImageContainer>
                    ) : this.state.enableBackgroundLoad ? <UploadActivityImageContainer height="190px">
                        <ImageLoader> <img alt="loading" width="30" height="30"
                            src="http://photokonkurs.russia.travel/images/icons/preloader.gif" />
                        </ImageLoader>
                    </UploadActivityImageContainer> :
                            <UploadActivityImageContainer
                                height="190px"
                                onMouseOver={() => this.handleBackgroundImageFocus("over")}
                                onMouseOut={() => this.handleBackgroundImageFocus("out")}
                            >
                                <ImageContainer src={this.state.readingChallenge.background_image} />
                                <ChangeImageButton
                                    top={this.state.imageBackgroundChange ? "83%" : "100%"}
                                    opacity={this.state.imageBackgroundChange ? 1 : 0}
                                >
                                    Change Image
                        </ChangeImageButton>
                                <SelectFileIn
                                    type="file"
                                    onChange={e => this._handleBackgroundImageChange(e)}
                                />
                            </UploadActivityImageContainer>
          }*/}
        </UploadImagesParentContainer>
        <ActivityMainFormContainer>
          <form>
            <ActivityCreatorNavContainer>
              <RCHeaderDropDown
                active={this.state.gradeSelector}
                onBlur={() => this.handleGradeSelector(false)}
                tabIndex="1"
              >
                <div
                  style={{ fontSize: "1rem" }}
                  // onMouseDown={(e) => e.preventDefault()}
                  onClick={() =>
                    this.handleGradeSelector(!this.state.gradeSelector)
                  }
                >
                  {this.state.selectedGrades.length >= 9
                    ? "All "
                    : this.state.selectedGrades.length + " "}{" "}
                  Grades Selected
                  <Chevron>
                    <img
                      src={require("static/img/downarrowicon.png")}
                      alt="down-arrow"
                    />
                  </Chevron>
                </div>
                <RCHeaderDropDownMenu
                  maxHeight="150px"
                  active={this.state.gradeSelector}
                  color="#fff"
                  style={{ padding: "0px" }}
                >
                  {this.state.gradeData.map((gradeData, index) => (
                    <DropdownItem key={index}>
                      <FlexStart
                        style={{
                          width: "100%",
                          padding: "6px 8px",
                          position: "relative",
                          borderBottom: "solid 1px #e6e6e6",
                        }}
                        onMouseDown={(e) => e.preventDefault()}
                        onClick={() => this.handleGradeChange(gradeData)}
                      >
                        <CheckBall
                          style={{ marginRight: "6px" }}
                          active={gradeData.selected || gradeData.clicked}
                        >
                          <CheckBallInner
                            active={gradeData.selected || gradeData.clicked}
                          />
                        </CheckBall>
                        {gradeData.name}
                      </FlexStart>
                    </DropdownItem>
                  ))}
                </RCHeaderDropDownMenu>
              </RCHeaderDropDown>

              <RCHeaderDropDown
                active={this.state.categorySelector}
                onBlur={() => this.handleCategorySelector(false)}
                tabIndex="1"
              >
                <div
                  style={{ fontSize: "1rem" }}
                  onClick={() =>
                    this.handleCategorySelector(!this.state.categorySelector)
                  }
                >
                  {this.state.quizbody.quiz_category_name === ""
                    ? "Category"
                    : this.state.quizbody.quiz_category_name}

                  <Chevron>
                    <img
                      src={require("static/img/downarrowicon.png")}
                      alt="down-arrow"
                    />
                  </Chevron>
                </div>
                <RCHeaderDropDownMenu
                  maxHeight="150px"
                  active={this.state.categorySelector}
                  color="#fff"
                  style={{ padding: "0px" }}
                >
                  {this.props.category.category_list.map(
                    (categoryData, index) => (
                      <DropdownItem key={index}>
                        <FlexStart
                          style={{
                            width: "100%",
                            padding: "6px 8px",
                            position: "relative",
                            borderBottom: "solid 1px #e6e6e6",
                          }}
                          onMouseDown={(e) => e.preventDefault()}
                          onClick={() => this.handleQuizCategory(categoryData)}
                        >
                          {categoryData.name}
                        </FlexStart>
                      </DropdownItem>
                    )
                  )}
                </RCHeaderDropDownMenu>
              </RCHeaderDropDown>

              {/* <FreadomPointsInputField>
                <input
                  type="number"
                  placeholder="Freadom Points"
                  className="form-control"
                  value={this.state.quizbody.points || ""}
                  onChange={this.handleFreadomPoints}
                />
              </FreadomPointsInputField> */}
            </ActivityCreatorNavContainer>

            <RCNameInput
              type="text"
              className="form-control"
              autoFocus
              placeholder="Add Quiz Title"
              value={this.state.quizbody.name || ""}
              onChange={this.handleChallengeName}
            />

            <div>
              <textarea
                className="form-control"
                placeholder="Add a short description"
                style={{ height: "100px", marginBottom: "8px" }}
                value={this.state.quizbody.description || ""}
                onChange={this.handleChallengeDescription}
              />
            </div>
            {/* <RCNameInput
              type="text"
              className="form-control"
              autoFocus
              placeholder="Add Quiz Type. Example : 0"
              value={this.state.quizbody.quiz_type || ""}
              onChange={this.handleQuizType}
            /> */}
            <select onChange={this.handleQuizType}>
              <option>Choose type of quiz</option>
              <option value="0">Sorting Drag and Drop</option>
              <option value="1">Fill in the Blanks</option>
              <option value="2">Reorder</option>
            </select>
            {/* <RCNameInput
              type="text"
              className="form-control"
              autoFocus
              placeholder="Add Quiz Theme . Example : Theme1"
              value={this.state.quizbody.theme || ""}
              onChange={this.handleQuizTheme}
            /> */}
            <select onChange={this.handleQuizTheme}>
              <option>Choose Theme </option>
              <option value="Theme1">Space</option>
              <option value="Theme2">Cloud</option>
              <option value="Theme3">Log-Word</option>
              <option value="Theme4">Log-Letter</option>
            </select>

            <ActivityContinueButtonContainer>
              <CreateRCButton
                primary
                className="btn btn-warning"
                type="button"
                onClick={() => this.handleContinueBtn()}
              >
                Create Quiz
              </CreateRCButton>
            </ActivityContinueButtonContainer>
          </form>
        </ActivityMainFormContainer>
      </CreateActivityContainer>
    );
  }
}

export default connectStore({ ui, activity, quiz2, category })(
  QuizCreationForm
);
