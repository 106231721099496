import React, { Component } from "react";
import { connectStore } from "redux-box";
import { module as notification } from "store/notification";
import { module as ui } from "store/ui";
import {
  CheckBall,
  CheckBallInner,
  Chevron,
  DropDown,
  DropdownItem,
  DropDownMenu,
  FlexBetween,
  SubHeading,
  ThemeButton,
  InfiniteScrollLoader
} from "static/styled/custom.styled";
import { Container } from "./WOWDropdownContainer.styled";
import {
  DropdownError,
  DropdownFlexStart,
  NotificationDropdownParentContainer,
  DoneButtonContainer,
  NotificationParentContainer,
  NotificationSearchInputContainer,
  ChildDataDescription,
  NotificationScrollParentContainer
} from "../NotificationSelectorModal.styled";
import ReactDOM from "react-dom";
import InfiniteScroll from "react-infinite-scroller";
import swal from "sweetalert2";
const errorHandler = err => {
  swal("Oops!", err, "warning");
};


class WOWDropdownContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sectionTypeSelector: false,
      selectedSchool: null,
      schoolErr: "",
      enableFilter: false,
      search_school: "",
      section_list: [],
      selectedSections: [],
      schoolTypeSelector: false,
      sectionErr: "",
      search_section: "",
      filter_count: 0,
      allSelected: false,
      selectedChildren: [],
      enableAllSelected: false,
      childTypeSelector: false,
      childErr: "",
      childSelected: "",
      scrollEnabled: false,
      isMultiple: false,
      multipleSchoolList: []
    };
  }

  componentDidMount() {
    let { notification } = this.props;
    this.setState({
      isMultiple: notification.multiple_school_list.length > 0 ? true : false,
      multipleSchoolList: notification.multiple_school_list
    });
    document.addEventListener("click", this.handleSchoolClick);
    document.addEventListener("click", this.handleSectionClick);
    document.addEventListener("click", this.handleChildClick);
    if (
      notification.wow_school_list &&
      notification.wow_school_list.length === 0
    ) {
      notification.requestAllWowSchools();
    }
    // TO SET THE SELECTED SCHOOL DATA
    if (notification.selected_schools !== null) {
      this.handleSchoolSelection(notification.selected_schools);
    }

    // TO SET THE LIST IN CASE OF WOW_SECTION_LIST LENGTH>0
    if (notification.wow_section_list) {
      if (
        notification.wow_section_list.length > 0 &&
        (notification.wow_section_list.length ===
          notification.selected_sections.length ||
          notification.selected_sections[0] === "All")
      ) {
        this.setState({
          allSelected: true
        });
      }
    }

    // TO SET THE SELECTED WOW SECTIONS  AND ALSO ACTIVE NOTIFICATION SELECTION CASE
    this.handleSectionSelection();
    if (notification.selected_users && notification.selected_users.length > 0) {
      this.setChildrenList();
    } else {
      this.setState({
        selectedChildren: []
      });
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.notification) {
      let children_list = nextProps.notification.children_list;
      let selected_users = nextProps.notification.selected_users;
      if (children_list && nextProps.notification.children_list.length === 0) {
        if(selected_users.length === 0){
          this.setState({
            selectedChildren: [],
            enableAllSelected: false,
            childSelected: ""
          });
        }else{
          this.setState({
            selectedChildren: selected_users
          });
        }
      } 
      // else {
      //   this.setState({
      //     selectedChildren: selected_users
      //   });
      // }
    }
  }

  componentWillUnmount() {
    document.removeEventListener("click", this.handleSchoolClick);
    document.removeEventListener("click", this.handleSectionClick);
    document.removeEventListener("click", this.handleChildClick);
    let { notification } = this.props;
    notification.unsetChildrenList();
    notification.unsetChildrenListResult();
  }

  handleSchoolSelection = selectedSchool => {
    this.setState({
      selectedSchool: selectedSchool
    });
    this.props.notification.requestAllSectionsByWowId(selectedSchool.id);
  };

  handleSectionSelection = () => {
    let { notification } = this.props;
    this.setState({
      selectedSections: notification.selected_sections
    });
    if (
      notification.active_notification &&
      notification.selected_sections.length === 1 &&
      notification.selected_sections[0] === "All"
    )
      this.setState({
        allSelected: true
      });
  };

  setChildrenList = () => {
    let { notification } = this.props;
    let selectedSections = notification.selected_sections;
    let filter = "wow-school";
    if (
      selectedSections &&
      selectedSections.length > 0 &&
      selectedSections[0] !== "All"
    ) {
      filter = "wow-grade";
    }
    let active_notification = notification.active_notification;
    let filterIds =
      filter === "wow-school" && notification.selected_schools !== null
        ? [notification.selected_schools.id]
        : selectedSections;
    if (
      (active_notification && active_notification.filter_type === filter) ||
      active_notification === null
    ) {
      notification.requestAllChildrenByLevel({
        filter: filter,
        levels: filterIds,
        offset: 0,
        concat: false
      });
    }

    // CASE 1 WE HAVE ALL CHILDREN SELECTED
    if (
      notification.selected_users.length > 0 &&
      notification.selected_users[0] === "All"
    ) {
      if (
        (notification.active_notification &&
          notification.active_notification.filter_type !== filter) ||
        notification.active_notification === null
      ) {
        this.setState({
          enableAllSelected: true,
          childSelected: "All",
          selectedChildren: ["All"]
        });
      } else {
        this.setState({
          enableAllSelected: false,
          childSelected: "",
          selectedChildren: []
        });
      }
    } else {
      if (
        notification.active_notification &&
        notification.active_notification.filter_type !== filter
      ) {
        this.setState({
          selectedChildren: []
        });
      } else {
        this.setState({
          selectedChildren: notification.selected_users
        });
      }
    }
  };

  //    School outside click listener function
  handleSchoolClick = evt => {
    const area = ReactDOM.findDOMNode(this.refs.areaSchool);
    if (!area.contains(evt.target) && this.state.schoolTypeSelector) {
      this.setState({
        schoolTypeSelector: false
      });
    }
  };

  //    Section  outside click listener function
  handleSectionClick = evt => {
    const area = ReactDOM.findDOMNode(this.refs.areaSection);
    if (!area.contains(evt.target) && this.state.sectionTypeSelector) {
      this.setState({
        sectionTypeSelector: false
      });
      this.fetchChildrenListBySection();
    }
  };

  // Child click outside teh target area for closing dropdown opened up
  handleChildClick = evt => {
    const area = ReactDOM.findDOMNode(this.refs.areaChild);
    if (!area.contains(evt.target) && this.state.childTypeSelector) {
      this.setState({
        childTypeSelector: false
      });
    }
  };

  setSelectedWowData = () => {
    let { notification } = this.props;
    let { state } = this;
    let wowdata = state.selectedSchool.name;
    let children =
      state.selectedChildren &&
      state.selectedChildren.length > 0 &&
      state.selectedChildren[0] === "All"
        ? "All"
        : state.selectedChildren.length;
    if (state.selectedSections[0] === "All") {
      notification.setSelectedWowData(
        wowdata + ", all sections, " + children + " Children"
      );
    } else {
      notification.setSelectedWowData(
        wowdata +
          ", " +
          state.selectedSections.length.toString() +
          " sections, " +
          children +
          " Children"
      );
    }
  };

  handleDoneButton = () => {
    let { notification } = this.props;
    let { state } = this;
    if (!this.state.isMultiple) {
      if (this.state.selectedSchool === null)
        errorHandler("Please select school name");
      else if (state.selectedSections.length === 0)
        errorHandler("Please select sections!");
      else if (state.selectedChildren && state.selectedChildren.length === 0)
        errorHandler("Please select children!");
      else if (notification.active_notification === null) {
        this.setState({
          multipleSchoolList: []
        });
        this.setSelectedWowData();
        notification.setSelectedSectionsList(this.state.selectedSections);
        notification.setSelectedSchoolsList(this.state.selectedSchool);
        notification.setSelectedUsersList(this.state.selectedChildren);
        notification.setMultipleSchoolList([]);
        this.props.ui.hideNotificationModal();
      } else {
        swal({
          title: "Are you sure?",
          text: "You want to make changes in the notification criteria!",
          type: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, delete it!"
        }).then(result => {
          if (result.value) {
            this.handleUpdateNotification();
          }
          this.props.ui.hideNotificationModal();
        });
      }
    } else {
      if (this.state.multipleSchoolList.length === 0) {
        errorHandler("Please select school name");
      } else {
        this.setState({
          selectedSections: [],
          selectedChildren: []
        });
        notification.setMultipleSchoolList(this.state.multipleSchoolList);
        notification.setSelectedWowData("");
        this.props.ui.hideNotificationModal();
      }
    }
  };

  handleUpdateNotification = () => {
    let { notification } = this.props;
    let custom_user_ids = [];
    let selectedChildren = this.state.selectedChildren;
    if (selectedChildren && selectedChildren.length > 0)
      custom_user_ids = selectedChildren[0] !== "All" ? selectedChildren : [];
    let notification_data = {
      filter_type: "wow-school",
      wow_school_ids: [this.state.selectedSchool.id],
      filter_type_ids: [this.state.selectedSchool.id],
      custom_user_ids: custom_user_ids
    };

    let selectedSections = this.state.selectedSections;
    if (selectedSections.length > 0 && selectedSections[0] === "All") {
      notification.requestUpdateNotification(notification_data);
    } else {
      notification.requestUpdateNotification({
        ...notification_data,
        filter_type: "wow-grade",
        filter_type_ids: selectedSections
      });
    }
    notification.unsetDropdownData();
  };

  // WOW SCHOOL DROPDOWN FUNCTIONS

  // SCHOOL TYPE SELECTOR FUNCTION
  handleSchoolTypeSelector = value => {
    this.setState({
      schoolTypeSelector: value
    });
  };

  handleMultipleChange = () => {
    this.setState({
      isMultiple: !this.state.isMultiple,
      selectedSchool: null
    });
  };

  // SCHOOL; CHANGE DROPDOWN HANDLE FUNCTION
  handleSchoolChange = school => {
    let selectedSchool = this.state.selectedSchool;
    let { notification } = this.props;
    if (
      (!this.state.isMultiple && selectedSchool === null) ||
      (selectedSchool !== null && selectedSchool.id !== school.id)
    ) {
      notification.requestAllSectionsByWowId(school.id);
      notification.requestAllChildrenBySchool({
        filter: "wow-school",
        filter_ids: [school.id],
        concat: false,
        offset: 0
      });
      this.setState({
        selectedSchool: school,
        selectedSections: ["All"],
        enableAllSelected: true,
        childSelected: "All",
        selectedChildren: ["All"],
        allSelected: true
      });
    } else {
      selectedSchool = null;
      if (
        this.state.multipleSchoolList.length > 0 &&
        !this.state.multipleSchoolList.some(data => {
          return data === school.id;
        })
      ) {
        this.setState({
          multipleSchoolList: [...this.state.multipleSchoolList, school.id]
        });
      } else if (this.state.multipleSchoolList.length === 0) {
        this.setState({
          multipleSchoolList: [...this.state.multipleSchoolList, school.id]
        });
      } else {
        this.setState({
          multipleSchoolList: this.state.multipleSchoolList.filter(
            data => data !== school.id
          )
        });
      }
    }

    if (this.state.sectionErr !== "")
      this.setState({
        sectionErr: ""
      });

    if (this.state.childErr !== "")
      this.setState({
        childErr: ""
      });
  };

  handleSchoolSearch = evt => {
    this.setState({
      search_school: evt.target.value
    });
  };
  // FUNCTION FOR THE API CALL TO GET FILTERED CHILDREN LIST ON DROPDOWN CLICK IN SCHOOL AND SECTIONS
  fetchChildrenListBySection = () => {
    let { notification } = this.props;
    let selectedSections = this.state.selectedSections;
    if (selectedSections[0] !== "All")
      notification.requestAllChildrenBySchool({
        filter: "wow-grade",
        filter_ids: selectedSections,
        offset: 0,
        concat: false
      });
    else if (this.state.selectedSchool !== null) {
      notification.requestAllChildrenBySchool({
        filter: "wow-school",
        filter_ids: [this.state.selectedSchool.id],
        offset: 0,
        concat: false
      });
    }
  };

  // SECTION DROPDOWN FUNCTIONS

  handleSectionTypeSelector = value => {
    if (this.state.selectedSchool !== null) {
      this.setState({
        sectionTypeSelector: value
      });
      if (!value) this.fetchChildrenListBySection();
    } else {
      this.setState({
        sectionErr: "Please select school!"
      });
    }
  };

  handleSectionSearch = evt => {
    if (evt.target.value === "" && this.state.allSelected) {
      let selectedSections = this.filterSections();
      let sectionList = [];
      selectedSections.forEach(section => {
        sectionList = sectionList.concat(section.id);
      });
      this.setState({
        allSelected: false,
        selectedSections: sectionList
      });
    }
    this.setState({
      search_section: evt.target.value
    });
  };
  //   function for index 0 i.e. all sections selected
  handleAllSections = section => {
    let { notification } = this.props;
    let wow_section_list = notification.wow_section_list;

    if (
      this.state.selectedSections.length === 1 &&
      this.state.selectedSections[0] === "All"
    ) {
      this.setState({
        selectedSections: [],
        allSelected: false,
        childSelected: "",
        childErr: "Please select sections first!",
        enableAllSelected: false,
        selectedChildren: []
      });
    } else if (wow_section_list && wow_section_list.length > 0) {
      if (this.state.search_section !== "") {
        let selectedSections = this.filterSections();
        let sectionList = [];
        selectedSections.forEach(section => {
          sectionList = sectionList.concat(section.id);
        });
        this.setState({
          selectedSections: selectedSections,
          allSelected: true,
          selectedChildren: ["All"],
          childSelected: "All",
          enableAllSelected: true
        });
      } else {
        this.setState({
          selectedSections: ["All"],
          selectedChildren: ["All"],
          allSelected: true,
          childErr: "",
          childSelected: "All",
          enableAllSelected: true
        });
      }
    }
  };

  // FUNCTION FOR HANDLING TH SECTION SELECTION IN THE DROPDOWN
  handleSectionChange = (section, index) => {
    let selectedSections = this.state.selectedSections;
    if (index === 0) {
      this.handleAllSections(section);
    } else {
      this.setState({
        enableAllSelected: false,
        childSelected: "",
        childErr: "",
        selectedChildren: []
      });
      if (selectedSections.length === 1 && selectedSections[0] === "All") {
        let selectedSections = [];
        this.setState({
          selectedSections: selectedSections.concat(section.id),
          allSelected: false
        });
      } else {
        if (selectedSections.some(sect => sect === section.id)) {
          let selectedSections = this.state.selectedSections.filter(sect => {
            return sect.id !== section.id;
          });
          this.setState({
            selectedSections: selectedSections,
            allSelected: false,
            childSelected: "",
            enableAllSelected: false
          });
        } else {
          this.setState({
            selectedSections: this.state.selectedSections.concat(section.id)
          });
        }
      }
    }
  };

  // //CHILD DROPDOWN FUNCTIONS

  fetchMoreChildren = () => {
    if (
      this.props.notification.children_list_result !== null &&
      this.props.notification.children_list_result.has_next &&
      this.state.childTypeSelector &&
      !this.props.notification.enableScroll
    ) {
      if (!this.state.scrollEnabled)
        this.setState({
          scrollEnabled: true
        });
      setTimeout(() => {
        if (
          this.props.notification.children_list_result !== null &&
          this.props.notification.children_list_result.next_page > 0
        ) {
          let selectedSections = this.state.selectedSections;
          let filter =
            selectedSections[0] !== "All" ? "wow-grade" : "wow-school";
          let filter_ids =
            selectedSections[0] !== "All"
              ? selectedSections
              : [this.state.selectedSchool.id];
          this.props.notification.requestAllChildrenBySchool({
            filter: filter,
            filter_ids: filter_ids,
            offset: this.props.notification.children_list_result.next_page,
            concat: true
          });
        }
      }, 300);
    }
  };

  // HANDLE CHILD TYPE SELECTOR FOR DROPDOWN MENU
  handleChildTypeSelector = value => {
    let err = "";
    this.setState({
      childTypeSelector: value
    });
    if (
      this.state.selectedSchool === null ||
      this.state.selectedSections.length === 0
    ) {
      err = this.state.selectedSchool === null ? "school" : "sections";
      this.setState({
        childTypeSelector: false,
        childErr: "Please select " + err + " first!"
      });
    }
  };

  handleChildListSearch = evt => {
    let { notification } = this.props;
    notification.setChildrenListSearch(evt.target.value);
    if (evt.target.value !== "") {
      this.setState({
        enableAllSelected: false,
        childSelected: "",
        childErr: ""
      });
      this.fetchChildrenListBySection();
    }
  };

  handleChildChange = (index, data) => {
    if (index === 0) {
      if (this.state.childSelected === "") {
        let { notification } = this.props;
        let selectedChildren = [];
        if (
          notification.children_list.length > 0 &&
          notification.search_child !== ""
        ) {
          notification.children_list.forEach((child, index) => {
            selectedChildren = selectedChildren.concat(child.id);
          });
          this.setState({
            selectedChildren: selectedChildren
          });
        } else {
          this.setState({ selectedChildren: ["All"] });
        }
        this.setState({
          childSelected: "All",
          enableAllSelected: true
        });
      } else {
        this.setState({
          childSelected: "",
          enableAllSelected: false,
          selectedFirstChild: "",
          selectedChildren: []
        });
      }
    } else {
      if (
        this.state.selectedChildren.length === 0 ||
        this.state.selectedChildren[0] === "All"
      ) {
        let selectedChild = [data.id];
        this.setState({
          selectedChildren: selectedChild,
          enableAllSelected: false,
          childSelected: ""
        });
      } else if (!this.state.selectedChildren.some(child => child === data.id))
        this.setState({
          selectedChildren: this.state.selectedChildren.concat(data.id)
        });
      else if (this.state.selectedChildren.length > 0) {
        let selectedChildren = this.state.selectedChildren.filter(child => {
          return child !== data.id;
        });
        this.setState({
          selectedChildren: selectedChildren,
          enableAllSelected: false,
          childErr: ""
        });
      }
    }
  };

  // FUNCTION USED IN THE SERACH BAR FOR THE SCHOOL DROPDOWN
  filterSchools = () => {
    let { notification } = this.props;
    if (
      notification.wow_school_list &&
      notification.wow_school_list.length > 0
    ) {
      let schoolList = [];
      schoolList = notification.wow_school_list.filter(school => {
        school["isSelected"] = false;
        return school.name.includes(this.state.search_school);
      });
      if (schoolList.length > 0) return schoolList;
    }
    return [];
  };

  // FUNCTION USED FOR THE FILTER OF SECTIONS USING SEARCH BAR
  filterSections = () => {
    let { notification } = this.props;
    if (
      notification.wow_section_list &&
      notification.wow_section_list.length > 0
    ) {
      let sectionList = [];
      sectionList = notification.wow_section_list.filter((section, index) => {
        return section.name
          .toLowerCase()
          .includes(this.state.search_section.toLowerCase());
      });
      return sectionList;
    }

    return [];
  };

  render() {
    let { notification } = this.props;
    let schoolList = this.filterSchools();
    let sectionList = this.filterSections();
    // school list dropdown items
    let school_list_menu =
      notification.wow_school_list &&
      notification.wow_school_list.length > 0 &&
      schoolList.length > 0 ? (
        schoolList.map((school, key) => (
          <DropdownItem key={`wow${key}`}>
            <DropdownFlexStart
              second
              style={{ fontSize: "12px" }}
              onClick={() => this.handleSchoolChange(school)}
            >
              <CheckBall
                second
                style={{ marginRight: "6px" }}
                active={
                  (this.state.selectedSchool !== null &&
                    this.state.selectedSchool.id === school.id) ||
                  (this.state.isMultiple &&
                    this.state.multipleSchoolList.some(
                      data => data === school.id
                    ))
                }
              >
                <CheckBallInner
                  second
                  active={
                    (this.state.selectedSchool !== null &&
                      this.state.selectedSchool.id === school.id) ||
                    (this.state.isMultiple &&
                      this.state.multipleSchoolList.some(
                        data => data === school.id
                      ))
                  }
                />
              </CheckBall>
              {school.name}
            </DropdownFlexStart>
          </DropdownItem>
        ))
      ) : (
        <DropdownItem>
          <DropdownFlexStart second style={{ fontSize: "12px" }}>
            No school present
          </DropdownFlexStart>
        </DropdownItem>
      );

    let section_list_menu =
      notification.wow_section_list &&
      notification.wow_section_list.length > 0 &&
      sectionList.length > 0
        ? sectionList.map((section, index) => (
            <DropdownItem key={index + 1}>
              <DropdownFlexStart
                second
                style={{ fontSize: "12px" }}
                onClick={() => this.handleSectionChange(section, index + 1)}
              >
                <CheckBall
                  second
                  style={{ marginRight: "6px" }}
                  active={
                    this.state.selectedSections.includes(section.id) ||
                    this.state.allSelected
                  }
                >
                  <CheckBallInner
                    second
                    active={
                      this.state.selectedSections.includes(section.id) ||
                      this.state.allSelected
                    }
                  />
                </CheckBall>
                {section.class_
                  ? "Class " + section.class_.name + " (" + section.name + " )"
                  : ""}
              </DropdownFlexStart>
            </DropdownItem>
          ))
        : [];

    return (
      // SCHOOL DROPDOWN SINGLE SELECT MENU
      <NotificationParentContainer>
        <DropdownFlexStart
          style={{ fontSize: "12px" }}
          onClick={() => this.handleMultipleChange()}
        >
          <CheckBall
            second
            style={{ marginRight: "6px" }}
            active={this.state.isMultiple}
          >
            <CheckBallInner second active={this.state.isMultiple} />
          </CheckBall>
          Multiple School
        </DropdownFlexStart>
        {this.state.isMultiple ? (
          <NotificationDropdownParentContainer>
            <SubHeading style={{ margin: 0 }}> School </SubHeading>
            <FlexBetween style={{ width: "80%", justifyContent: "flex-start" }}>
              <DropDown
                minWidth="180px"
                style={{ width: "180px" }}
                active={this.state.schoolTypeSelector}
                ref="areaSchool"
                tabIndex="1"
              >
                <div
                  style={{
                    fontSize: "14px",
                    maxHeight: "24px",
                    overflow: "hidden"
                  }}
                  onClick={() =>
                    this.handleSchoolTypeSelector(
                      !this.state.schoolTypeSelector
                    )
                  }
                >
                  {this.state.multipleSchoolList.length > 0
                    ? `${this.state.multipleSchoolList.length} Selected `
                    : "None selected"}
                  <Chevron>
                    <img
                      src={require("static/img/downarrowicon.png")}
                      alt="down-arrow"
                    />
                  </Chevron>
                </div>
                <DropDownMenu
                  maxHeight="160px"
                  active={this.state.schoolTypeSelector}
                  color="#fff"
                  style={{ padding: "0px", minHeight: "40px" }}
                >
                  <NotificationSearchInputContainer
                    onClick={() => this.handleSchoolTypeSelector(true)}
                  >
                    <input
                      type="text"
                      placeholder="Type to Search"
                      value={this.state.search_school}
                      onChange={this.handleSchoolSearch}
                    />
                    <i className="fa fa-search" />
                  </NotificationSearchInputContainer>
                  {school_list_menu.length > 0 ? (
                    <div style={{ marginTop: "30px" }}>{school_list_menu}</div>
                  ) : (
                    <DropdownItem> No Schools Available </DropdownItem>
                  )}
                </DropDownMenu>
              </DropDown>
            </FlexBetween>
          </NotificationDropdownParentContainer>
        ) : (
          <NotificationDropdownParentContainer>
            <SubHeading style={{ margin: 0 }}> School </SubHeading>
            <FlexBetween style={{ width: "80%", justifyContent: "flex-start" }}>
              <DropDown
                minWidth="180px"
                style={{ width: "180px" }}
                active={this.state.schoolTypeSelector}
                ref="areaSchool"
                tabIndex="1"
              >
                <div
                  style={{
                    fontSize: "14px",
                    maxHeight: "24px",
                    overflow: "hidden"
                  }}
                  onClick={() =>
                    this.handleSchoolTypeSelector(
                      !this.state.schoolTypeSelector
                    )
                  }
                >
                  {this.state.selectedSchool !== null
                    ? this.state.selectedSchool.name
                    : "None selected"}
                  <Chevron>
                    <img
                      src={require("static/img/downarrowicon.png")}
                      alt="down-arrow"
                    />
                  </Chevron>
                </div>
                <DropDownMenu
                  maxHeight="160px"
                  active={this.state.schoolTypeSelector}
                  color="#fff"
                  style={{ padding: "0px", minHeight: "40px" }}
                >
                  <NotificationSearchInputContainer
                    onClick={() => this.handleSchoolTypeSelector(true)}
                  >
                    <input
                      type="text"
                      placeholder="Type to Search"
                      value={this.state.search_school}
                      onChange={this.handleSchoolSearch}
                    />
                    <i className="fa fa-search" />
                  </NotificationSearchInputContainer>
                  {school_list_menu.length > 0 ? (
                    <div style={{ marginTop: "30px" }}>{school_list_menu}</div>
                  ) : (
                    <DropdownItem> No Schools available </DropdownItem>
                  )}
                </DropDownMenu>
              </DropDown>
            </FlexBetween>
          </NotificationDropdownParentContainer>
        )}
        <Container active={this.state.isMultiple}>
          {/* SECTION DROPDOWN LIST MENU */}
          <NotificationDropdownParentContainer>
            <SubHeading style={{ margin: 0 }}> Section </SubHeading>
            <FlexBetween style={{ width: "80%", justifyContent: "flex-start" }}>
              <DropDown
                minWidth="180px"
                active={this.state.sectionTypeSelector}
                ref="areaSection"
                tabIndex="1"
              >
                <div
                  style={{ fontSize: "14px", height: "24px" }}
                  onClick={() =>
                    this.handleSectionTypeSelector(
                      !this.state.sectionTypeSelector
                    )
                  }
                >
                  {this.state.selectedSections.length === 0
                    ? " None selected"
                    : this.state.selectedSections.length === 1 &&
                      this.state.selectedSections[0] === "All"
                      ? "All Selected"
                      : this.state.selectedSections.length + " Selected"}
                  <Chevron>
                    <img
                      src={require("static/img/downarrowicon.png")}
                      alt="down-arrow"
                    />
                  </Chevron>
                </div>
                <DropDownMenu
                  maxHeight="160px"
                  active={this.state.sectionTypeSelector}
                  color="#fff"
                  style={{ padding: "0px", minHeight: "40px" }}
                >
                  <NotificationSearchInputContainer
                    onClick={() => this.handleSectionTypeSelector(true)}
                  >
                    <input
                      type="text"
                      placeholder="Type to Search"
                      value={this.state.search_section}
                      onChange={this.handleSectionSearch}
                    />
                    <i className="fa fa-search" />
                  </NotificationSearchInputContainer>
                  <DropdownItem style={{ marginTop: "32px" }}>
                    <DropdownFlexStart
                      second
                      onClick={() => this.handleSectionChange("All", 0)}
                    >
                      <CheckBall
                        second
                        style={{ marginRight: "6px" }}
                        active={this.state.allSelected}
                      >
                        <CheckBallInner
                          second
                          active={this.state.allSelected}
                        />
                      </CheckBall>
                      {this.state.allSelected ? "Unselect All" : "Select All"}
                    </DropdownFlexStart>
                  </DropdownItem>
                  {section_list_menu.length > 0 ? section_list_menu : null}
                </DropDownMenu>
              </DropDown>
            </FlexBetween>
          </NotificationDropdownParentContainer>
          {this.state.sectionErr !== "" && (
            <DropdownError active={this.state.sectionErr !== ""}>
              {this.state.sectionErr}
            </DropdownError>
          )}

          {/* CHILD SELECTION DROPDOWN MENU */}

          <NotificationDropdownParentContainer>
            <SubHeading style={{ margin: 0 }}> Child </SubHeading>
            <FlexBetween style={{ width: "80%", justifyContent: "flex-start" }}>
              <DropDown
                minWidth="180px"
                active={this.state.childTypeSelector}
                ref="areaChild"
                tabIndex="1"
              >
                <div
                  style={{ fontSize: "14px", height: "24px" }}
                  onClick={() =>
                    this.handleChildTypeSelector(!this.state.childTypeSelector)
                  }
                >
                  {this.state.selectedChildren &&
                  this.state.selectedChildren.length === 0 &&
                  !this.state.enableAllSelected
                    ? "None Selected "
                    : this.state.enableAllSelected
                      ? "All Selected"
                      : this.state.selectedChildren.length + " Child Selected"}
                  <Chevron>
                    <img
                      src={require("static/img/downarrowicon.png")}
                      alt="down-arrow"
                    />
                  </Chevron>
                </div>

                <NotificationScrollParentContainer
                  maxHeight="250px"
                  active={this.state.childTypeSelector}
                  color="#fff"
                  style={{ padding: "0px" }}
                >
                  <NotificationSearchInputContainer
                    onClick={() => this.handleChildTypeSelector(true)}
                  >
                    <input
                      type="text"
                      placeholder="Type to Search"
                      value={this.props.notification.search_child}
                      onChange={this.handleChildListSearch}
                    />
                    <i className="fa fa-search" />
                  </NotificationSearchInputContainer>
                  <InfiniteScroll
                    pageStart={0}
                    loadMore={this.fetchMoreChildren}
                    hasMore={
                      this.props.notification.children_list_result &&
                      this.props.notification.children_list_result.has_next
                    }
                    style={{ position: "relative", marginTop: "30px" }}
                    loader={
                      <InfiniteScrollLoader className="loader" key={0}>
                        <img
                          alt="loading"
                          width="20"
                          height="20"
                          src={require("static/img/Theme-loader.gif")}
                        />
                      </InfiniteScrollLoader>
                    }
                    useWindow={false}
                  >
                    <DropdownItem>
                      <DropdownFlexStart
                        style={{ fontSize: "12px" }}
                        onClick={() => this.handleChildChange(0, "All")}
                      >
                        <CheckBall
                          second
                          style={{ marginRight: "6px" }}
                          active={
                            this.state.childSelected === "All" &&
                            this.state.enableAllSelected
                          }
                        >
                          <CheckBallInner
                            second
                            active={
                              this.state.childSelected === "All" &&
                              this.state.enableAllSelected
                            }
                          />
                        </CheckBall>
                        {this.state.enableAllSelected &&
                        this.state.childSelected === "All"
                          ? "Unselect All"
                          : "Select All"}
                      </DropdownFlexStart>
                    </DropdownItem>

                    {notification.children_list.map((child, index) => (
                      <DropdownItem key={index + 1}>
                        <DropdownFlexStart
                          onClick={() =>
                            this.handleChildChange(index + 1, child)
                          }
                          style={{ fontSize: "12px" }}
                        >
                          <CheckBall
                            second
                            style={{ marginRight: "6px" }}
                            active={
                              this.state.selectedChildren.includes(child.id) ||
                              this.state.enableAllSelected
                            }
                          >
                            <CheckBallInner
                              second
                              active={
                                this.state.selectedChildren.includes(
                                  child.id
                                ) ||
                                (this.state.childSelected === "All" &&
                                  this.state.enableAllSelected)
                              }
                            />
                          </CheckBall>
                          <ChildDataDescription>
                            {child.name}
                            <div> {`( ${child.parent.name} )`} </div>
                            <div>
                              <i className="fa fa-phone" aria-hidden="true" />
                              {` ${child.parent.contact_no}`}{" "}
                            </div>
                          </ChildDataDescription>
                        </DropdownFlexStart>
                      </DropdownItem>
                    ))}
                  </InfiniteScroll>
                </NotificationScrollParentContainer>
              </DropDown>
            </FlexBetween>
          </NotificationDropdownParentContainer>
          {this.state.childErr !== "" && (
            <DropdownError active={this.state.childErr !== ""}>
              {this.state.childErr}
            </DropdownError>
          )}
        </Container>
        <DoneButtonContainer>
          <ThemeButton primary onClick={this.handleDoneButton}>
            Done
          </ThemeButton>
        </DoneButtonContainer>
      </NotificationParentContainer>
    );
  }
}
export default connectStore({
  notification,
  ui
})(WOWDropdownContainer)