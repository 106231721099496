import React, { Component } from "react";
import {
  CaseStudyItemRight,
  CaseStudyItemLeft,
  CaseStudyItem,
  CaseStudyDetails,
  CaseStudyDescription,
  CaseStudyLabels,
  ImageContainer
} from "./CaseStudyCard.styled";
import { ToolbarItem } from "@sc/Section.styled";
import { module as ui } from "@st/ui";
import { module as caseStudy } from "@st/caseStudy";
import { connectStore } from "redux-box";
import swal from "sweetalert2";
import { module as onboard } from "@st/onboard";
import Permission from "@s/permission";
// export default connectStore({
//   ui,
//   caseStudy
// })
class CaseStudyCard extends Component {
  handleEditClick = evt => {
    const {permission} = this.props.onboard;
    if(Permission.getPermission(permission, "change_testimonial")){
      evt.preventDefault();
      this.props.ui.showModal("CASESTUDYFORM");
      this.props.caseStudy.enableCaseStudyEdit(true);
      this.props.caseStudy.setCaseStudyId(this.props.caseStudyInfo.id);
    }
    
  };

  handleDeleteClick = evt => {
    swal({
      text: "Are you sure that you want to delete this case study?",
      type: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No"
    }).then(result => {
      if (result.value) {
        this.props.caseStudy.deleteCaseStudy(this.props.caseStudyInfo.id);
      } else if (result.dismiss === swal.DismissReason.cancel) {
      }
    });
    evt.stopPropagation();
  };

  handleCardBtn = () => {
    this.props.ui.showModal("CASESTUDYFORM");
    this.props.caseStudy.setCaseStudyId(this.props.caseStudyInfo.id);
  };

  render() {
    let caseStudyInfo = this.props.caseStudyInfo;
    const {permission} = this.props.onboard;
    return (
      <CaseStudyItem onClick={this.handleCardBtn}>
        <CaseStudyItemLeft>
          <ImageContainer
            src={
              this.props.caseStudyInfo && this.props.caseStudyInfo.author_image
                ? caseStudyInfo.author_image
                : require("static/img/default-image.png")
            }
            className="image-fluid"
            alt="caseStudy Icon"
          />
          <CaseStudyDetails>
            <CaseStudyLabels primary> {caseStudyInfo.name} </CaseStudyLabels>
            <CaseStudyDescription>
              {caseStudyInfo.description.length < 200
                ? caseStudyInfo.description
                : caseStudyInfo.description.slice(0, 200)}{" "}
            </CaseStudyDescription>

            <CaseStudyLabels> - {caseStudyInfo.author_name}</CaseStudyLabels>
          </CaseStudyDetails>
        </CaseStudyItemLeft>
        <CaseStudyItemRight>
        { Permission.getPermission(permission, "change_testimonial")?
          <ToolbarItem primary onClick={this.handleEditClick}>
            <div className="icon-container">
              <i className="fa fa-pencil" />
            </div>
          </ToolbarItem>:null}
        { Permission.getPermission(permission, "delete_testimonial")?
        <ToolbarItem onClick={this.handleDeleteClick}>
          <div className="icon-container">
            <i className="fa fa-trash" />
          </div>
        </ToolbarItem>:null}
        </CaseStudyItemRight>
      </CaseStudyItem>
    );
  }
}

export default connectStore({
  ui,
  caseStudy,
  onboard
})(CaseStudyCard);
