export default {
  //......................MISC................
  SET_STORY_EDITOR_TYPE: (state, { editorType }) =>
    (state.current_editor_type = editorType),
  SET_STORY_TYPE: (state, { storyType }) =>
    (state.current_story_type = storyType),
  SET_STORY_MAIN_TYPE: (state, { storyMainType }) =>
    (state.current_story_main_type = storyMainType),
  SET_FETCH_STORY_LIST: (state, { value }) => (state.fetch_story_list = value),
  SET_STORIES_RESULT: (state, { storiesResult }) =>
    (state.stories_result = storiesResult),
  SET_FETCH_MEDIA_LOADING: (state, { loading }) =>
    (state.fetch_media_loading = loading),
  SET_FETCH_IMAGES_LOADING: (state, { loading }) =>
    (state.fetch_images_loading = loading),
  SET_SEARCH_STORY: (state, { search }) => (state.search_story = search),
  SET_SELECTED_COLLECTIONS: (state, { collection }) =>
    (state.selected_collections = collection),
  SET_SELECTED_SOURCES: (state, { source }) =>
    (state.selected_sources = source),

  //......................Collection...........
  SET_ACTIVE_COLLECTION: (state, { collection }) =>
    (state.active_collection = collection),
  SET_COLLECTION_LIST: (state, { collectionList }) =>
    (state.collection_list = collectionList),
  SET_FETCH_COLLECTIONS: (state, { val }) => (state.fetch_collections = val),

  //......................Story................
  SET_ACTIVE_STORY_GRADE_LEVEL: (state, { gradeLevel }) =>
    (state.active_story_grade_level = gradeLevel),
  SET_FETCH_ACTIVE_STORY: (state, { value }) => {
    state.fetch_active_story = value;
  },
  SET_ACTIVE_STORY_LEVEL: (state, { level }) =>
    (state.active_story_level = level),
  SET_STORY_LIST: (state, { storyList }) => (state.story_list = storyList),
  SET_ACTIVE_STORY: (state, { story }) => (state.active_story = story),
  UNSET_ACTIVE_STORY: (state, { activeStory }) =>
    (state.active_story = activeStory),
  SET_CONCAT_STORY_LIST: (state, { storyList }) =>
    (state.story_list = state.story_list.concat(storyList)),
  DELETE_STORY: (state, { storyIndex }) =>
    (state.story_list = [
      ...state.story_list.slice(0, storyIndex),
      ...state.story_list.slice(storyIndex + 1),
    ])((state.stories_result.count -= 1)),
  UPDATE_STORY_LIST: (state, { payload }) => {
    state.story_list[payload.storyIndex] = payload;
  },
  SET_ACTIVE_FILTER: (state, { value }) => {
    state.activeFilter.push(value);
  },
  UNSET_ACTIVE_FILTER: (state, { value }) => {
    state.activeFilter = state.activeFilter.filter((currFilter) => {
      return currFilter.type !== value.type;
    });
  },

  //......................Question................
  UPDATE_NEW_STORY_QUESTION: (state, { question }) =>
    (state.new_question = question),
  ADD_NEW_STORY_OPTION: (state, { option }) =>
    state.new_options.push({ name: option, __id: Math.random() }),
  REMOVE_NEW_STORY_OPTION: (state, { optionId }) => {
    state.new_options = state.new_options.filter(
      (option) => option.__id !== optionId
    );
  },
  // SET_STORY_QUESTION: (state, { question }) => (state.question_set = question),
  // NEW_STORY_QUESTION: (state, { question }) => (state.new_question = question),
  SET_ACTIVE_STORY_QUESTION: (state, { question }) =>
    (state.active_mcq = question),
  UNSET_ACTIVE_STORY_QUESTION: (state) => (state.active_mcq = null),
  SET_ALL_STORY_QUESTION: (state, { question }) => (state.questions = question),
  UNSET_ALL_ACTIVE_STORY_QUESTION: (state, { question }) =>
    (state.questions = question),

  //.....................................Section.............................
  SET_STORY_SECTIONS: (state, { sections }) => (state.sections = sections),
  REMOVE_STORY_SECTION: (state, action) => (state.new_section = null),
  SET_ACTIVE_STORY_SECTION: (state, { section }) =>
    (state.active_section = section),
  UPDATE_ACTIVE_STORY_SECTION: (state, { section }) =>
    (state.active_section = { ...state.active_section, ...section }),
  START_CREATING_STORY_SECTION: (state, { sectionType, data }) => {
    state.current_news_editor_type = sectionType;
    state.current_section_action = "create";
    state.active_section = data;
  },
  START_UPDATING_STORY_SECTION: (state, { sectionType, data }) => {
    state.current_editor_type = sectionType;
    state.current_section_action = "update";
    state.active_section = data;
  },
  ADD_NEW_STORY_SECTION: (state, { section }) =>
    state.new_sections.push({ __id: Math.random(), ...section }),
  REMOVE_NEW_STORY_SECTION: (state, { section }) => {
    state.new_sections = state.new_sections.filter(
      (sec) => sec.__id !== section.__id
    );
  },
  RESET_NEW_STORY_SECTIONS: (state) => (state.new_sections = []),
  UPDATE_NEW_STORY_SECTION: (state, { section }) => {
    state.new_sections = state.new_sections.map((sec) => {
      if (sec.__id === section.__id) return section;
      return sec;
    });
  },
  UNSET_ACTIVE_STORY_SECTION: (state) => (state.active_section = null),
  SET_STORY_ACTION_TYPE: (state, { action }) =>
    (state.current_section_action = action),
  SET_STORY_COLLECTION_LIST: (state, { storyCollections }) =>
    (state.story_collections = storyCollections),
  FETCH_LOAD_SET_TOP_STORY: (state, { val }) =>
    (state.load_set_top_story = val),

  // EXPERIENCE MUTATIONS
  SET_STORY_EXPERIENCES_LIST: (state, { result }) =>
    (state.story_experience_list = result),
  SET_ACTIVE_STORY_EXPERIENCE: (state, { experience }) =>
    (state.active_experience = experience),
  SET_FETCH_ACTIVE_STORY_EXPERIENCE: (state, { value }) =>
    (state.fetch_active_story_experience = value),
  SET_FETCH_STORY_EXPERIENCE_LIST: (state, { value }) =>
    (state.fetch_story_experience_list = value),
  SET_GRADE_LEVEL_SORT: (state, { value }) => (state.filterGradeSort = value),
  SET_ACTIVE_STORY_TYPE: (state, { value }) => (state.story_type = value),
  SET_ACTIVE_STORIES_DATE: (state, { date }) => (state.story_date = date),
};
