import React, { Component } from 'react';
import { module as payment } from '@st/payment';
import { connectStore } from "redux-box";
import {SectionCard} from "../PaymentsLink.styled"



class ChildCards extends Component {
    constructor(props) {
      super();
      this.state = {
      };
    }

    componentDidMount() {
    }

    selectChild= (e, child) => {
        e.stopPropagation();
        let { payment } = this.props;
        payment.setSelectedChild(child);
        
        this.props.fetchPlans(true);

    }
    unSelectChild= (e) => {
        e.stopPropagation();
        this.props.payment.setSelectedChild({});
        this.props.fetchPlans(true);
    }

    render() {
        let { payment } = this.props;
        let selectedChild= payment.selectedChild? payment.selectedChild: {id:-999};

        let childCard= (
            payment.childList.length>0 ?
            payment.childList.map((child)=> 
            (
                <div key={child.id} className="col-sm-4">
                    <div className={"card m-2 "+ (selectedChild.id===child.id && "bg-warning text-light")} >
                        <div className="card-body">
                            <h5 className="card-title">{child.name}</h5>
                            <h6 className="card-subtitle mb-2 text-danger">{child.days_left} days left</h6>
                            <p className="card-text">{child.grade_level}</p>
                            {  selectedChild.id === child.id?
                                (<button className="btn btn-sm btn-light" onClick={(e)=>this.unSelectChild(e)}>
                                    Unselect Child<i className="fa fa-minus ml-2"/>
                                </button>):
                                (<button className="btn btn-sm btn-warning" onClick={(e)=>this.selectChild(e, child)}>
                                    Select Child<i className="fa fa-plus ml-2"/>
                                </button>)
                            }
                            
                        </div>
                    </div>
                </div>
            )):
            (
                <div className="col-sm-8">
                    <div className="alert alert-warning p-3" role="alert">
                        <h6 className="alert-heading">Please wait.</h6>
                        <hr/>
                        <h6>Reasons for taking too much time.</h6>
                        <p className="mb-0"><b>1: Child list is empty.</b></p>
                        <p className="mb-0"><b>2: Child with invite code will not appear.</b></p>
                    </div>
                </div>
            )
        );

        return (
            <SectionCard className=" ">
                <h6 className="text-muted"><b>STEP 2: Select Child</b> </h6>                    
                <div className="row">
                    {childCard}
                </div>
            </SectionCard>
        )
    }
}
export default connectStore({
    payment
})(ChildCards)