import React, {Component} from 'react';
import {CaseStudyAuthorName,LoadingCaseStudyContainer, CaseStudyDescription,CaseStudyInputField} from "./CaseStudyCreatorForm.styled";
import {ActivityContinueButtonContainer,ActivityMainFormContainer, ActivityUploadImageButton, CreateActivityContainer, SelectFileIn, UploadActivityImageContainer,ImageContainer,ChangeImageButton} from "@c/activity-view/activity-creation-form/ActivityCreationForm.styled";
import {ThemeButton, LoaderImage,ImageLoader } from "@sc/custom.styled";
import { module as ui } from "@st/ui";
import { uploadFile } from "@s/api/file";
import { module as caseStudy } from "@st/caseStudy";
import { connectStore } from "redux-box";
import swal from "sweetalert2";
// export default connectStore({
//     ui,caseStudy,
// })

class CaseStudyCreatorForm extends Component
{
    constructor(props)
    {
        super();
        this.state={

            enableLoad:false,
            imageChange:false,
            new_case_study:{
                author_image:null,
                author_name:'',
                name:'',
                description:'',
            }
        }
        
    }

    componentWillReceiveProps(nextProps){
        if(nextProps.caseStudy && nextProps.caseStudy.active_case_study){
            this.normalizeForState(nextProps.caseStudy.active_case_study)
        }
        else{

            this.setState({
                new_case_study:{
                    ...this.state.new_case_study,
                    author_image:null,
                    author_name:'',
                    name:'',
                    description:'',
                }
            })
        }
    }

    componentDidMount()
    {
        let {caseStudy}=this.props;

        if(caseStudy.caseStudyId )
        {
            caseStudy.getCaseStudyById(caseStudy.caseStudyId);
            let activeCaseStudy=caseStudy.active_case_study;
            if(caseStudy.fetching_case_study===false && caseStudy.active_case_study) {
               // console.log("hello");
                this.setState({
                    new_case_study: {
                        ...this.state.new_case_study,
                        author_image: activeCaseStudy.author_image,
                        author_name: activeCaseStudy.author_name,
                        description: activeCaseStudy.description,
                        name: activeCaseStudy.name,
                    }
                })
            }
        }
    }
    

    componentWillUnmount()
    {
        let {caseStudy}=this.props;
        if(caseStudy.active_case_study)
        {
            caseStudy.setActiveCaseStudy(null);
        }
        caseStudy.setCaseStudyId("");
        caseStudy.setActiveCaseStudy(null);
        caseStudy.enableCaseStudyEdit(false);
    }



    normalizeForState = (caseStudy) => {
        this.setState({
            new_case_study: {
                ...this.state.new_case_study,
                author_image: caseStudy.author_image,
                author_name: caseStudy.author_name,
                description: caseStudy.description,
                name: caseStudy.name,
            }
        });
    };

    handleUpdateBtn=()=>
    {

                this.props.caseStudy.updateCaseStudy(this.state.new_case_study);


    };

    handleCaseStudyName = evt => {
        this.setState({
            new_case_study: { ...this.state.new_case_study, name: evt.target.value }
        });
    };

    handleDescriptionChange=evt=>{
        this.setState({
            new_case_study: { ...this.state.new_case_study, description: evt.target.value }
        });
    };
    handleAuthorNameChange=evt=>{
        this.setState({
            new_case_study: { ...this.state.new_case_study, author_name: evt.target.value }
        });
    };

    handleImageFocus = actionType => {
        if (actionType === "over" && this.state.imageChange ===false)
            this.setState({
                imageChange: true
            });
        else if (actionType === "out" && this.state.imageChange === true)
            this.setState({
                imageChange: false
            });
    };

    _handleImageChange = e => {
        e.preventDefault();

        let reader = new FileReader();
        let file = e.target.files[0];
        let fileSize = e.target.files[0].size / 1024 / 1024;
        if (fileSize > 2) {
          swal('Oops!', 'File size greater than 2MB ', 'warning')
        }
        else {
        reader.onloadend = () => {

            this.setState({
                imageChange:false,
                enableLoad:true,
                    new_case_study: { ...this.state.new_case_study, author_image: reader.result }
            });
        };
        reader.readAsDataURL(file);
        uploadFile(file).then(res => {
            this.setState({
                new_case_study: { ...this.state.new_case_study, author_image: res.file },
                enableLoad:false,
            });
        });
    }
    };

    handleContinueBtn=()=>{
        this.setState({
            displayMenu: false
        });
        if (this.state.new_case_study.name && this.state.new_case_study.author_name && this.state.new_case_study.description) {
            this.props.caseStudy.createCaseStudy(this.state.new_case_study);
            this.props.ui.hideModal();
        } else {
            swal(
                "Oops!",
                "You must enter case Study name , description and author name to create Case Study",
                "error"
            );
        }
    };

    handleEditBtn=()=>{

        this.props.caseStudy.enableCaseStudyEdit(true);
        this.props.caseStudy.setCaseStudyId(this.props.caseStudy.active_case_study.id);

    };

    render(){
        let state=this.state;
        return(
            <div>
            {this.props.caseStudy.fetching_case_study ?
                <CreateActivityContainer primary>
                    <LoadingCaseStudyContainer>
                        <LoaderImage src={require("static/img/Theme-loader.gif")} />{" "}

                    </LoadingCaseStudyContainer>
                </CreateActivityContainer>:
            <CreateActivityContainer>
                {!state.new_case_study.author_image ? (
                    <UploadActivityImageContainer>
                        <ActivityUploadImageButton type="button">
                            <img src={require("static/img/cloud-upload-icon.png")} alt="cloud-upload-full-screen"/>
                            <span>Upload Image </span>
                        </ActivityUploadImageButton>
                        <SelectFileIn
                            type="file"
                            onChange={e => this._handleImageChange(e)}
                        />
                    </UploadActivityImageContainer>
                ) : this.state.enableLoad?
                    <UploadActivityImageContainer>
                        <ImageLoader > <img alt="loading" width="30" height="30" src="http://photokonkurs.russia.travel/images/icons/preloader.gif" />
                        </ImageLoader>
                    </UploadActivityImageContainer>:
                    <UploadActivityImageContainer
                        onMouseOver={() => this.handleImageFocus("over")}
                        onMouseOut={() => this.handleImageFocus("out")}
                    >
                        <ImageContainer
                            src={
                                state.new_case_study.author_image
                                    ? state.new_case_study.author_image
                                    : require("static/img/activity-bg.png")
                            }
                        />
                        {this.props.caseStudy.editCaseStudy || this.props.caseStudy.active_case_study===null? <ChangeImageButton
                            top={this.state.imageChange ? "83%" : "100%"}
                            opacity={this.state.imageChange ? 1 : 0}>
                            Change Image
                        </ChangeImageButton>:null}
                        {this.props.caseStudy.editCaseStudy || this.props.caseStudy.active_case_study===null ?
                            <SelectFileIn
                            type="file"
                            onChange={e => this._handleImageChange(e)}/>
                            :null}
                    </UploadActivityImageContainer>}

                <ActivityMainFormContainer>
                    <form>
                        <CaseStudyInputField
                            type="text"
                            placeholder="Add case study quote here"
                            border={this.props.caseStudy.editCaseStudy?'1px solid rgba(0,0,0,0.3)':'none'}
                            radius={this.props.caseStudy.editCaseStudy?'10px':'0px'}
                            fontSize={this.props.caseStudy.editCaseStudy?'14px':'18px'}
                            margin={this.props.caseStudy.editCaseStudy?'3px':'0px'}
                            value={state.new_case_study.name}
                            disabled={this.props.caseStudy.active_case_study?!this.props.caseStudy.editCaseStudy:false}
                            onChange={this.handleCaseStudyName}/>
                        <CaseStudyAuthorName
                            type="text" placeholder="Author Name"
                            value={state.new_case_study.author_name}
                            padding={this.props.caseStudy.editCaseStudy?'3px 6px':'6px'}
                            fontSize={this.props.caseStudy.editCaseStudy?'14px':'16px'}
                            border={this.props.caseStudy.editCaseStudy?'1px solid rgba(0,0,0,0.3)':'none'}
                            radius={this.props.caseStudy.editCaseStudy?'10px':'0px'}
                            disabled={this.props.caseStudy.active_case_study?!this.props.caseStudy.editCaseStudy:false}
                            onChange={this.handleAuthorNameChange}/>
                        <div>
                            <CaseStudyDescription border={this.props.caseStudy.editCaseStudy?'1px solid rgba(0,0,0,0.3)':'none'}
                                                  radius={this.props.caseStudy.editCaseStudy?'10px':'0px'}
                                                  height={this.props.caseStudy.editCaseStudy?'100px':'80px'}
                                                  fontSize={this.props.caseStudy.editCaseStudy?'12px':'14px'}
                                                  placeholder="Add description"
                                                  value={state.new_case_study.description}
                                                  disabled={this.props.caseStudy.active_case_study?!this.props.caseStudy.editCaseStudy:false}
                                                  onChange={this.handleDescriptionChange}/>
                        </div>
                        <ActivityContinueButtonContainer>
                            {this.props.caseStudy.active_case_study?
                                (this.props.caseStudy.editCaseStudy?
                                    <ThemeButton primary type="button" onClick={this.handleUpdateBtn}> Update Case Study </ThemeButton>:
                                    <ThemeButton primary type="button" onClick={this.handleEditBtn}>  Edit Case Study </ThemeButton>)
                            :<ThemeButton primary type="button" onClick={this.handleContinueBtn}> Create Case Study </ThemeButton>}
                        </ActivityContinueButtonContainer>
                    </form>
                </ActivityMainFormContainer>

            </CreateActivityContainer>}
            </div>

        )
    }
}

export default connectStore({
    ui,caseStudy,
   })(CaseStudyCreatorForm)
// export default  CaseStudyCreatorForm;
