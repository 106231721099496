import React, { Component } from "react";
import { module as activity } from "@st/activity";
import { connectStore } from "redux-box";
import ActivityPackInfo from "./activity-pack-info/ActivityPackInfo";
import PackActivitiesList from "./pack-activities-list/PackActivitiesList";
import {
    ActivityComponentInfoContainer,
} from "./ActivityPackCreator.styled";
import { LoadingContainer} from "@sc/custom.styled";
import TagEditor from "@c/activity-view/activity-pack-creator/tag-editor/TagEditor";
import {ComponentContainer, CreatorComponent, SubContainer} from "@sc/Creator.styled";


// export default connectStore({
//   activity
// })

class ActivityPackCreator extends Component {
  constructor(props) {
    super();
    this.state = {
      activityPackEdit: false,
      showSubContainer: false,
      activityPackName: "Activity Pack Name",
      gradeSelected: "Grade 1",
      displayMenu: false,
      activityCount: [1, 2, 3],
        translate: '100%',
      activityDescriptionValue:
        "Activity description to tell you what this is and how it works to help the parents know stuff"
    };
  }


  componentDidMount() {
    this.props.activity.requestActivityPackById(this.props.match.params.id);
      let history=this.props.history;
      //let location=this.props.location;
      let {activity}=this.props;
      document.addEventListener('keyup', function(e) {

          if (e.keyCode === 27 && window.location.pathname.includes("/activities/packs")) {
              history.push("/activities/packs/"+activity.active_grade_level+"/"+activity.active_level);
          }
      });

  }
    componentWillMount(){

        setTimeout(()=>{
            this.setState({
                opacity: 1,
                translate: '0'
            })
        },30)
    }

  componentWillUnmount(){
    this.props.activity.unsetPackNewTag();
      this.props.activity.closeEditor();
  }

  handleShowSubContainer = (type, title = null) => {
    this.setState({ showSubContainer: type });
  };

  hideSubContainer = () => {
    this.setState({ showSubContainer: false });
  };



  render() {
    let { activity } = this.props;

    return (
      <CreatorComponent>
          <ComponentContainer style={{transform: `translateX(${this.state.translate})`}}>
              {this.props.activity.fetchingActivityPack ? (
                  <LoadingContainer primary>
                      <div>
                          <img alt="loading" width="60" height="60" src={require("static/img/Theme-loader.gif")}  />{" "}

                      </div>
                  </LoadingContainer>
              ):(
              <ActivityComponentInfoContainer>
            {activity.active_activity_pack ? (
              <ActivityPackInfo
                activityPack={activity.active_activity_pack}
                handleShowSubContainer={this.handleShowSubContainer}
              />
            ) : null}
            {activity.active_activity_pack ? (
              <PackActivitiesList
                activitiesList={activity.active_activity_pack.activity}
              />
            ) : null}
            </ActivityComponentInfoContainer>)}
          </ComponentContainer>

          {activity.current_editor_type && <SubContainer active={activity.current_editor_type}>
            {activity.current_editor_type === "pack_tag" && (
                <TagEditor
                    title={this.state.sectionTitle}
                    onDone={this.hideSubContainer}
                />
            )}

        </SubContainer>}
      </CreatorComponent>

    );
  }
}
// export default ActivityPackCreator
export default connectStore({
  activity,
 })(ActivityPackCreator)