import { createSagas } from "redux-box";
import NotificationApi from "@s/api/notification";
import { call, put } from "redux-saga/effects";
import swal from "sweetalert2";

const errorHandler = err => {
  //console.log(err);
  swal("Oops!", err.error_message, "error");
};

export default createSagas({
  // REQUEST ALL USERS (CHILDREN)
  REQUEST_ALL_CHILDREN_LIST_BY_OFFSET: function*({ offset }) {
    try {
      yield put({
        type: "SET_FETCH_USER_CHILD_LIST",
        value: true
      });
      const globalState = require("store").default.getState();
      let search =
        globalState.notification.search_child !== ""
          ? globalState.notification.search_child
          : "";
      let activeNotification = globalState.notification.active_notification;
      let activeId = activeNotification !== null ? activeNotification.id : "";

      const res = yield call(
        NotificationApi.getAllChildren,
        offset,
        search,
        activeId
      );

      yield put({
        type: "SET_USER_CHILDREN_LIST_RESULT",
        result: res
      });
      if (res && res.result) {
        yield put({
          type: "SET_USER_CHILDREN_LIST",
          result: res.result.children
        });
      }
    } catch (err) {
      errorHandler(err);
    } finally {
      yield put({
        type: "SET_FETCH_USER_CHILD_LIST",
        value: false
      });
    }
  },
  REQUEST_CONCAT_CHILDREN_LIST_BY_OFFSET: function*({ offset }) {
    try {
      yield put({
        type: "SET_FETCH_USER_CHILD_LIST",
        value: true
      });
      yield put({
        type: "SET_ENABLE_SCROLL",
        value: true
      });
      const globalState = require("store").default.getState();
      let search =
        globalState.notification.search_child !== ""
          ? globalState.notification.search_child
          : "";
      let activeNotification = globalState.notification.active_notification;
      let activeId = activeNotification !== null ? activeNotification.id : "";
      const res = yield call(
        NotificationApi.getAllChildren,
        offset,
        search,
        activeId
      );
      if (res && res.result) {
        yield put({
          type: "SET_ENABLE_SCROLL",
          value: false
        });
      }
      yield put({
        type: "SET_USER_CHILDREN_LIST_RESULT",
        result: res
      });

      if (res && res.result) {
        yield put({
          type: "SET_USER_CONCAT_CHILDREN_LIST",
          result: res.result.children
        });
      }
    } catch (err) {
      errorHandler(err);
    } finally {
      yield put({
        type: "SET_FETCH_USER_CHILD_LIST",
        value: false
      });
    }
  },

  REQUEST_ALL_CHILDREN_BY_LEVEL: function*({ selectedLevels }) {
    try {
      yield put({
        type: "SET_FETCH_USER_CHILD_LIST",
        value: true
      });
      if (selectedLevels.concat) {
        yield put({
          type: "SET_ENABLE_SCROLL",
          value: true
        });
      }

      let levels = "";
      const globalState = require("store").default.getState();
      let search =
        globalState.notification.search_child !== ""
          ? globalState.notification.search_child
          : "";
      let activeNotification = globalState.notification.active_notification;
      let activeId = activeNotification !== null ? activeNotification.id : "";
      for (let i = 0; i < selectedLevels.levels.length; i++) {
        levels += selectedLevels.levels[i];
        if (i !== selectedLevels.levels.length - 1) levels += "::::";
      }

      const res = yield call(
        NotificationApi.getAllChildrenByLevel,
        selectedLevels.filter,
        levels,
        selectedLevels.offset,
        search,
        activeId
      );
      yield put({
        type: "SET_USER_CHILDREN_LIST_RESULT",
        result: res
      });
      if (res && res.result) {
        if (selectedLevels.concat)
          yield put({
            type: "SET_ENABLE_SCROLL",
            value: false
          });

        if (!selectedLevels.concat)
          yield put({
            type: "SET_USER_CHILDREN_LIST",
            result: res.result.children
          });
        else {
          yield put({
            type: "SET_USER_CONCAT_CHILDREN_LIST",
            result: res.result.children
          });
        }
      }
    } catch (err) {
      errorHandler(err);
    } finally {
      yield put({
        type: "SET_FETCH_USER_CHILD_LIST",
        value: false
      });
    }
  },

  // WOW DROPDOWN CHILDREN SELECTION DROPDOWN FUNCTIONS
  REQUEST_ALL_CHILDREN_BY_SCHOOL: function*({ school }) {
    try {
      yield put({
        type: "SET_FETCH_USER_CHILD_LIST",
        value: true
      });
      if (school.concat) {
        yield put({
          type: "SET_ENABLE_SCROLL",
          value: true
        });
      }
      let filter_ids = "";
      const globalState = require("store").default.getState();
      let search =
        globalState.notification.search_child !== ""
          ? globalState.notification.search_child
          : "";
      let activeNotification = globalState.notification.active_notification;
      let activeId = activeNotification !== null ? activeNotification.id : "";
      for (let i = 0; i < school.filter_ids.length; i++) {
        filter_ids += school.filter_ids[i];
        if (i !== school.filter_ids.length - 1) filter_ids += "::::";
      }
      const res = yield call(
        NotificationApi.getAllChildrenByLevel,
        school.filter,
        filter_ids,
        school.offset,
        search,
        activeId
      );
      yield put({
        type: "SET_USER_CHILDREN_LIST_RESULT",
        result: res
      });
      if (res && res.result) {
        if (school.concat)
          yield put({
            type: "SET_ENABLE_SCROLL",
            value: false
          });

        if (!school.concat)
          yield put({
            type: "SET_USER_CHILDREN_LIST",
            result: res.result.children
          });
        else {
          yield put({
            type: "SET_USER_CONCAT_CHILDREN_LIST",
            result: res.result.children
          });
        }
      }
    } catch (err) {
      errorHandler(err);
    } finally {
      yield put({
        type: "SET_FETCH_USER_CHILD_LIST",
        value: false
      });
    }
  },
  REQUEST_GRADES_LIST: function*() {
    try {
      const res = yield call(NotificationApi.getAllGrades);
      yield put({
        type: "SET_GRADE_LIST",
        result: res
      });
    } catch (err) {
      errorHandler(err);
    }
  },

  // FUNCTION FOR WOW DROPDOWNS
  REQUEST_ALL_WOW_SCHOOLS: function*() {
    try {
      const globalState = require("store").default.getState();
      let activeNotification = globalState.notification.active_notification;
      let activeId = activeNotification !== null ? activeNotification.id : "";
      const res = yield call(NotificationApi.getWOWSchools, activeId);

      yield put({
        type: "SET_WOW_SCHOOL_LIST",
        result: res.result.school
      });
    } catch (err) {
      errorHandler(err);
    }
  },

  REQUEST_ALL_SECTIONS_BY_WOW_ID: function*({ schoolId }) {
    try {
      const globalState = require("store").default.getState();
      let activeNotification = globalState.notification.active_notification;
      let activeId = activeNotification !== null ? activeNotification.id : "";
      const res = yield call(
        NotificationApi.getSectionsByWowId,
        schoolId,
        activeId
      );

      yield put({
        type: "SET_WOW_SECTIONS_LIST",
        data: res.section
      });
    } catch (err) {
      errorHandler(err);
    }
  },

  // FUNCTIONS FOR NOTIFICATION LIST CRUD

  REQUEST_NOTIFICATION_LIST: function*({ notification }) {
    try {
      yield put({
        type: "SET_FETCH_NOTIFICATION_LIST",
        value: true
      });
    } catch (err) {
      errorHandler(err);
    } finally {
      yield put({
        type: "SET_FETCH_NOTIFICATION_LIST",
        value: false
      });
    }
  },

  REQUEST_ALL_NOTIFICATIONS: function*({ notification }) {
    try {
      yield put({
        type: "SET_FETCH_NOTIFICATION_LIST",
        value: true
      });
      const globalState = require("store").default.getState();
      let search =
        globalState.notification.search_notification !== ""
          ? globalState.notification.search_notification
          : "";
      let message_type = globalState.notification.filter_msg_type;
      let user_type = globalState.notification.filter_user_type;
      let date = globalState.notification.filter_date;

      let filter_fields = "";
      if (user_type === "" && message_type !== "")
        filter_fields = "notification_type=" + message_type;
      else if (user_type !== "" && message_type === "")
        filter_fields = "filter_type=" + user_type;
      else if (user_type !== "" || message_type !== "")
        filter_fields = `notification_type=${message_type}&filter_type=${user_type}`;
      if (date !== "") {
        if (filter_fields === "" && date !== "") filter_fields = `date=${date}`;
        else {
          filter_fields += `&date=${date}`;
        }
      }
      const res = yield call(
        NotificationApi.getAllNotifications,
        notification.offset,
        notification.type,
        search,
        filter_fields
      );
      yield put({
        type: "SET_NOTIFICATION_RESULT",
        result: res
      });
      if (res && res.result) {
        yield put({
          type: "SET_NOTIFICATION_LIST",
          data: res.result
        });
      }
    } catch (err) {
      errorHandler(err);
    } finally {
      yield put({
        type: "SET_FETCH_NOTIFICATION_LIST",
        value: false
      });
    }
  },
  REQUEST_CONCAT_ALL_NOTIFICATIONS: function*({ notification }) {
    try {
      const globalState = require("store").default.getState();
      const search =
        globalState.notification.search_notification !== ""
          ? globalState.notification.search_notification
          : "";
      const message_type = globalState.notification.filter_msg_type;
      const user_type = globalState.notification.filter_user_type;
      let date = globalState.notification.filter_date;

      let filter_fields = "";
      if (user_type === "" && message_type !== "")
        filter_fields = "notification_type=" + message_type;
      else if (user_type !== "" && message_type === "")
        filter_fields = "filter_type=" + user_type;
      else if (user_type !== "" || message_type !== "")
        filter_fields = `notification_type=${message_type}&filter_type=${user_type}`;
      if (date !== "") {
        if (filter_fields === "" && date !== "") filter_fields = `date=${date}`;
        else {
          filter_fields += `&date=${date}`;
        }
      }

      const res = yield call(
        NotificationApi.getAllNotifications,
        notification.offset,
        notification.type,
        search,
        filter_fields
      );
      yield put({
        type: "SET_NOTIFICATION_RESULT",
        result: res
      });
      if (res && res.result) {
        yield put({
          type: "SET_CONCAT_NOTIFICATION_LIST",
          data: res.result
        });
      }
    } catch (err) {
      errorHandler(err);
    }
  },

  REQUEST_CREATE_NOTIFICATION: function*({ notification }) {
    try {
      const globalState = require("store").default.getState();
      let email_description = globalState.notification.email_description;
      let notification_data = notification;
      if (notification_data.notification_type === "email")
        notification_data = { ...notification_data, body: email_description };
      const res = yield call(
        NotificationApi.createNotification,
        notification_data
      );
      let notificationType = globalState.notification.selected_not_type;
      if (res) {
        yield put({
          type: "UNSET_DROPDOWN_DATA"
        });

        swal("Done", "Notification created successfully !", "success");
        yield put({
          type:"SET_MULTIPLE_SCHOOL_LIST",
          data:[]
        })

        yield put({
          type: "REQUEST_ALL_NOTIFICATIONS",
          notification: {
            offset: 0,
            type: notificationType
          }
        });
      }
    } catch (err) {
      errorHandler(err);
    }
  },

  REQUEST_NOTIFICATION_BY_ID: function*({ notificationId }) {
    try {
      yield put({
        type: "SET_FETCH_ACTIVE_NOTIFICATION",
        value: true
      });
      const res = yield call(
        NotificationApi.getNotificationById,
        notificationId
      );
      if (res && res.notification_type === "email")
        yield put({
          type: "SET_NOTIFICATION_DESCRIPTION",
          value: res.body
        });
      yield put({
        type: "SET_DROPDOWN_DATA",
        data: res
      });
      yield put({
        type: "SET_ACTIVE_NOTIFICATION",
        result: res
      });
    } catch (err) {
      errorHandler(err);
    } finally {
      yield put({
        type: "SET_FETCH_ACTIVE_NOTIFICATION",
        value: false
      });
    }
  },
  REQUEST_DELETE_NOTIFICATION: function*({ notification }) {
    try {
      yield call(NotificationApi.deleteNotificationById, notification);
      yield put({
        type: "REQUEST_ALL_NOTIFICATIONS",
        notification: {
          type: "scheduled",
          offset: 0
        }
      });
    } catch (err) {
      errorHandler(err);
    }
  },
  REQUEST_UPDATE_NOTIFICATION: function*({ data }) {
    try {
      const globalState = require("store").default.getState();
      let email_description = globalState.notification.email_description;
      let notification_data = data;
      if (notification_data.notification_type === "email")
        notification_data = { ...notification_data, body: email_description };
      let activeNotification = globalState.notification.active_notification;
      let notificationId = activeNotification.id;

      const res = yield call(
        NotificationApi.updateNotification,
        notificationId,
        notification_data
      );

      if (res && res.notification_type === "email")
        yield put({
          type: "SET_NOTIFICATION_DESCRIPTION",
          value: res.body
        });
      yield put({
        type: "SET_DROPDOWN_DATA",
        data: res
      });
      yield put({
        type: "SET_ACTIVE_NOTIFICATION",
        result: res
      });
      yield put({
        type: "REQUEST_ALL_NOTIFICATIONS",
        notification: {
          type: "scheduled",
          offset: 0
        }
      });
    } catch (err) {
      errorHandler(err);
    }
  }
});
