import React, { Component } from "react";
import {
  FilterContainer,
  FilterDataContainer,
  FilterItemContainer
} from "./FilterNotificationContainer.styled";
import {
  Heading,
  PanelHeader,
  DateSelectionField,
  SelectBox,
  SelectorButton,
  FlexBetween
} from "static/styled/custom.styled";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { connectStore } from "redux-box";
import { module as notification } from "store/notification";
import Toggle from "react-toggle";


class FilterNotificationContainer extends Component {
  constructor(props) {
    super();
    this.state = {
      userType: "all",
      messageType: "all",
      dateChange: false,
      // date: moment(),
      date: new Date(),
      selectedDate: "",
    };
  }

  setDefaultState() {
    let { notification } = this.props;
    let date = notification.filter_date;
    if (date === "") {
      this.setState({
        dataChange: false
      });
    } else {
      let dateData = date.split("-");
      let newDate = moment();
      newDate.set({
        year: dateData[0],
        month: dateData[1] - 1,
        date: dateData[2]
      });
      this.setState({
        dateChange: true,
        date: newDate
      });
    };
    this.setState({
      userType:
        notification.filter_user_type === ""
          ? "all"
          : notification.filter_user_type,
      messageType:
        notification.filter_msg_type === ""
          ? "all"
          : notification.filter_msg_type
    });
  }


  componentDidMount() {
    this.setDefaultState();
  }

  handleDateChange = date => {
    this.setState({
      date: date,
      selectedDate: moment(date).format("YYYY-MM-DD")
    }, () => this.handleDateChangeNotification(this.state.selectedDate));
  };
  handleDateChangeNotification = (date) => {
    let { notification } = this.props;
    if (this.state.dateChange) {
      notification.setFilterDate(
        date
      );
      notification.requestAllNotifications({
        offset: 0,
        type: notification.selected_not_type
      });
    }
  }
  // handleDateChange = date => {
  //   let { inviteCode } = this.props;
  //   this.setState({
  //     date: moment(date).format("YYYY-MM-DD")
  //   })
  // };

  handleUserType = data => {
    let { notification } = this.props;
    if (data !== this.state.userType) {
      this.setState({
        userType: data
      });
      if (data === "all") {
        notification.setFilterUserType("");
      } else {
        notification.setFilterUserType(data);
      }

      notification.requestAllNotifications({
        offset: 0,
        type: notification.selected_not_type
      });
    }
  };

  handleMessageType = data => {
    let { notification } = this.props;
    if (data !== this.state.messageType) {
      this.setState({
        messageType: data
      });
      if (data === "all") {
        notification.setFilterMessageType("");
      } else {
        notification.setFilterMessageType(data);
      }

      notification.requestAllNotifications({
        offset: 0,
        type: notification.selected_not_type
      });
    }
  };

  enableDateFilter = evt => {
    let { notification } = this.props;
    this.setState({
      dateChange: evt.target.checked
    });
    if (evt.target.checked) {
      let selectedDate = moment
        .utc(new Date(this.state.date._d))
        .format("YYYY-MM-DD");
      notification.setFilterDate(
        moment.utc(new Date(this.state.date._d)).format("YYYY-MM-DD")
      );
      if (selectedDate !== notification.filter_date)
        notification.requestAllNotifications({
          offset: 0,
          type: notification.selected_not_type
        });
    } else {
      notification.setFilterDate("");
      notification.requestAllNotifications({
        offset: 0,
        type: notification.selected_not_type
      });
    }
  };
  onDatepickerRef(el) {
    if (el && el.input) {
      el.input.readOnly = true;
    }
  }

  render() {
    let userType = ["All", "WOW", "Non-WOW"];
    let messageType = ["All", "SMS", "Push", "Email"];
    return (
      <FilterContainer>
        <PanelHeader primary style={{ position: "absolute", zIndex: 99 }}>
          <Heading style={{ fontSize: "18px", marginBottom: 0 }}>
            Select Filters
          </Heading>
        </PanelHeader>
        <FilterDataContainer>
          <FilterItemContainer>
            <Heading>Select Date</Heading>
            <FlexBetween>
              <div id="payments-date">
                <DateSelectionField
                  width="220px"
                  style={{ overflow: "hidden", marginLeft: 0 }}
                >
                  <img
                    src={require("static/img/date-icon.png")}
                    alt="startDate"
                  />
                  <DatePicker
                    selected={this.state.date}
                    disabled={!this.state.dateChange}
                    onChange={this.handleDateChange}
                    ref={el => this.onDatepickerRef(el)}
                  />
                </DateSelectionField>
              </div>
              <Toggle
                defaultChecked={this.state.dataChange}
                checked={this.state.dateChange}
                onChange={this.enableDateFilter}
                ref={el => this.onDatepickerRef(el)}
              />
            </FlexBetween>
          </FilterItemContainer>

          <FilterItemContainer>
            <Heading>Select Message Type</Heading>

            <FlexBetween
              style={{ marginTop: "10px", justifyContent: "flex-start" }}
            >
              {messageType.map((data, index) => (
                <SelectBox
                  key={index}
                  style={{ marginRight: "14px" }}
                  active={this.state.messageType === data.toLowerCase()}
                  onClick={() => this.handleMessageType(data.toLowerCase())}
                >
                  <SelectorButton
                    active={this.state.messageType === data.toLowerCase()}
                  >
                    <div />
                  </SelectorButton>
                  <span>{data}</span>
                </SelectBox>
              ))}
            </FlexBetween>
          </FilterItemContainer>

          <FilterItemContainer>
            <Heading>Select User Type</Heading>
            <FlexBetween
              style={{ marginTop: "10px", justifyContent: "flex-start" }}
            >
              {userType.map((data, index) => (
                <SelectBox
                  key={index}
                  style={{ marginRight: "18px" }}
                  active={this.state.userType === data.toLowerCase()}
                  onClick={() => this.handleUserType(data.toLowerCase())}
                >
                  <SelectorButton
                    active={this.state.userType === data.toLowerCase()}
                  >
                    <div />
                  </SelectorButton>
                  <span>{data}</span>
                </SelectBox>
              ))}
            </FlexBetween>
          </FilterItemContainer>
        </FilterDataContainer>
      </FilterContainer>
    );
  }
}
export default connectStore({
  notification
})(FilterNotificationContainer)