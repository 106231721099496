import styled from "styled-components";

export const FilterContainer=styled.div`
   position:relative;
`;

export const FilterDataContainer=styled.div`
    height:100%;
    padding:15px;
    padding-top:50px;
    width:100%;
    overflow-y:auto;
    `;

export const FilterItemContainer=styled.div`
 padding:14px 0;
 border-bottom:1px solid rgba(0,0,0,0.1);
 `;
