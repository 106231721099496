import styled from "styled-components";
import {LoadingContainer} from "static/styled/custom.styled";
import {ActivityDescription,ActivityInputField,} from "@c/activity-view/activity-creation-form/ActivityCreationForm.styled";
export const SchoolName=styled.input`
    font-size:var(--md-font-size);
    color:#000;
    outline:none;
   background:white;
    border:${props=>props.border};
    border-radius:${props=>props.radius};
     font-size:${props=>props.fontSize};
    padding:${props => props.padding};
    width:100%;
    ::placeholder
    {
        color:rgba(0,0,0,0.4);
        font-size:(--sm-font-size);
        
    }`;

export const LoadingSchoolContainer=LoadingContainer.extend`
position:relative;`;

export const SchoolNameDesc=ActivityDescription.extend`
    background:white;
    border:${props=>props.border};
    border-radius:${props=>props.radius};
    height:${props=>props.height};
    font-size:${props=>props.fontSize};
    padding:3px 6px;`;

export const SchoolInputField=ActivityInputField.extend`
   
    border:${props=>props.border};
     font-size:${props=>props.fontSize};
    border-radius:${props=>props.radius};
   margin-bottom:${props=>props.margin};
   
    `;
    export const EnterClassInputContainer = styled.div`
    border-radius: 30.5px;
    border: 1px solid rgba(0, 0, 0, 0.15);
    position: relative;
    height: 40px;
    padding: 6px 0px 3px 0px;
    margin-top: 20px;
  
    input {
      border: none;
      outline: none;
      font-size: var(--md-font-size);
      width: 94%;
      margin-left: 5px;
      margin-right: 5px;
      padding-left: 8px;
      padding-right: 28px;
    }
  
    button {
      position: absolute;
      top: 3px;
      border: none;
      border-radius: 100%;
      right: 8px;
      width: 30px;
      height: 30px;
      text-align: center;
      font-size: var(--md-font-size);
      color: white;
      outline: none;
      font-weight: bold;
      background-color: #ff8a23;
      cursor: pointer;
    }
  `;