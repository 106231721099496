import React, { Component } from "react";
import {
  ChildLabels,
  ParentDetailsContainer,
  ChildProfileContainer,
  ParentInfo,
  ChildSubHeading,
  ChildFieldLabel,
  ChildInputField,
  InterestsContainer,
  ChildUploadContainer,
  AddButton,
} from "./ChildProfileInfo.styled";
import { SectionHeading } from "@c/activity-view/activity-creator/activity-info/ActivityInfo.styled";
import {
  AlignRow,
  EditIcon,
  ParentBodyContentContainer,
  ParentFormField,
} from "@c/users-view/parent-profile/ParentProfile.styled";
import { FlexBetween, ThemeButton,FlexStart } from "@sc/custom.styled";
import { connectStore } from "redux-box";
import {
  NavigationHeaderContainer,
  NavigationContainer,
} from "@sc/NavigationBar.styled";
import { module as user } from "@st/user";
import { ToggleButtonMainContainer } from "@c/users-view/parent-profile/ParentProfile.styled";
import {
  ToggleStatus,
  ToggleButton,
  ToggleHandler,
} from "@c/users-view/MainContent.styled";
import ChildSubscription from "@c/users-view/child-profile/child-subscription/ChildSubscription";
import Permission from "@s/permission";
import { module as onboard } from "@st/onboard";
import { module as ui } from "@st/ui";

class ChildProfileInfo extends Component {
  constructor() {
    super();
    this.state = {
      freados: 0,
      streak: 0,
      freadoAdd: "",
      streakAdd: "",
      enableEdit: false,
      enableToggle: false,
      editFreados: false,
      editStreak: false,
      selected_nav: "Profile",
      child_info: {
        name: "",
        school: "",
        age: "",
      },
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.user && nextProps.user.active_child) {
      this.normalizeForState(nextProps.user.active_child);
    } else {
      this.setState({
        freados: 0,
        streak: 0,
        child_info: {
          ...this.state.child_info,
          id: "",
          name: "",
          age: "",
          school: "",
        },
      });
    }
  }

  normalizeForState = (childInfo) => {
    this.setState({
      freados: childInfo.freadom_point,
      streak: childInfo.streak,
      child_info: {
        ...this.state.child_info,

        name: childInfo.name,
        id: childInfo.id,
        school: childInfo.school,
        age: childInfo.age,
      },
    });
  };

  handleNameChange = (evt) => {
    this.setState({
      child_info: { ...this.state.child_info, name: evt.target.value || "" },
    });
  };

  handleSchoolChange = (evt) => {
    this.setState({
      child_info: { ...this.state.child_info, school: evt.target.value || "" },
    });
  };
  handleAgeChange = (evt) => {
    this.setState({
      child_info: { ...this.state.child_info, age: evt.target.value || "" },
    });
  };

  enableEdit = () => {
    this.setState({
      enableEdit: !this.state.enableEdit,
    });
  };

  handleFreadosChange = (evt) => {
    this.setState({
      freadoAdd: evt.target.value,
    });
  };

  handleStreakChange = (evt) => {
    this.setState({
      streakAdd: evt.target.value,
    });
  };

  handleStreakAddClick = () => {
    const { user } = this.props;
    if (
      user.active_child &&
      this.state.streakAdd > 0 &&
      this.state.streakAdd !== ""
    ) {
      user.requestUpdateStreakByChildId({
        child_id: user.active_child.id,
        streak: parseInt(this.state.streakAdd, 10),
      });
      this.setState({
        streakAdd: "",
      });
    }
  };

  handleAddClick = () => {
    const { user } = this.props;
    if (
      user.active_child &&
      this.state.freadoAdd >= 0 &&
      this.state.freadoAdd !== ""
    ) {
      user.requestUpdateFreadosByChildId({
        child_id: user.active_child.id,
        freadom_point: parseInt(this.state.freadoAdd, 10),
      });
      this.setState({
        freadoAdd: "",
      });
    }
  };

  handleStreakKeyDown = (evt) => {
    const { user } = this.props;
    if (
      evt.keyCode === 13 &&
      user.active_child &&
      this.state.streakAdd > 0 &&
      this.state.streakAdd !== ""
    ) {
      // this.setState({ editFreados: false });
      user.requestUpdateStreakByChildId({
        child_id: user.active_child.id,
        streak: parseInt(this.state.streakAdd, 10),
      });
    } else if (evt.keyCode === 13) {
      this.setState({ editStreak: false });
    }
  };

  handleFreadosKeyDown = (evt) => {
    const { user } = this.props;
    if (
      evt.keyCode === 13 &&
      user.active_child &&
      this.state.freadoAdd >= 0 &&
      this.state.freadoAdd !== ""
    ) {
      // this.setState({ editFreados: false });
      user.requestUpdateFreadosByChildId({
        child_id: user.active_child.id,
        freadom_point: parseInt(this.state.freadoAdd, 10),
      });
    } else if (evt.keyCode === 13) {
      this.setState({ editFreados: false });
    }
  };

  saveChangesBtn = () => {
    this.setState({
      enableEdit: false,
    });
    this.props.user.requestUpdateChildProfile(this.state.child_info);
  };

  handleToggle = () => {
    const { permission } = this.props.onboard;
    if (Permission.getPermission(permission, "change_user")) {
      if (this.props.user.active_child)
        this.props.user.requestUpdateChildSubLevel({
          id: this.state.child_info.id,
          level: "Hard",
        });
    }
  };

  handleNavigationSelector = (choice) => {
    if (this.state.selected_nav !== choice)
      this.setState({
        selected_nav: choice,
      });
  };

  handleLinkSchoolCode = () => {
    this.props.ui.showModal("schoolLinkform");
  };

  handleUnlinkSchoolCode = () => {
    this.props.ui.showModal("schoolUnlinkWarning");
  };

  render() {
    let state = this.state;
    let { user } = this.props;
    let navList = ["Profile", "Subscriptions"];
    const { permission } = this.props.onboard;
    return (
      <ParentBodyContentContainer style={{ padding: 0 }}>
        {this.props.user.active_child ? (
          <NavigationHeaderContainer shadow style={{ paddingLeft: "30px" }}>
            <NavigationContainer>
              {navList.map((choice, i) => (
                <a
                  key={i}
                  className={choice === this.state.selected_nav ? "active" : ""}
                  onClick={() => this.handleNavigationSelector(choice)}
                >
                  <span> {choice}</span>
                </a>
              ))}
            </NavigationContainer>
            {this.props.user.active_child.level === "Easy" ? (
              <ToggleButtonMainContainer style={{ width: "280px" }}>
                <ToggleStatus> Click to switch to Hard </ToggleStatus>
                <ToggleButton
                  color={state.enableToggle ? "#ff8f26" : "#d5d5d5"}
                >
                  {this.props.user.active_child.level === "Easy" ? (
                    <ToggleHandler primary onClick={this.handleToggle} />
                  ) : (
                    <ToggleHandler onClick={this.handleToggle} />
                  )}
                </ToggleButton>
                {this.props.user.active_child.level === "Easy" ? (
                  <ToggleStatus primary onClick={this.handleToggle}>
                    {" "}
                    Easy{" "}
                  </ToggleStatus>
                ) : (
                  <ToggleStatus onClick={this.handleToggle}>
                    {" "}
                    Hard{" "}
                  </ToggleStatus>
                )}
              </ToggleButtonMainContainer>
            ) : null}
          </NavigationHeaderContainer>
        ) : null}

        {this.props.user.active_child &&
          (this.state.selected_nav === "Profile" ? (
            <ChildProfileContainer>
              <ChildUploadContainer>
                <FlexBetween>
                  <ChildLabels>
                    <ChildSubHeading style={{ fontSize: "18px" }}>
                      Personal Details{" "}
                    </ChildSubHeading>
                    {!state.enableEdit &&
                    Permission.getPermission(permission, "change_user") ? (
                      <span>
                        <EditIcon onClick={this.enableEdit}>
                          <img
                            src={require("static/img/editicon.png")}
                            alt="edit-icon"
                          />
                        </EditIcon>
                      </span>
                    ) : null}
                  </ChildLabels>
                  {state.enableEdit ? (
                    <ThemeButton primary onClick={this.saveChangesBtn}>
                      SaveChanges
                    </ThemeButton>
                  ) : null}
                </FlexBetween>
                <AlignRow
                  margin={"0px 0px 12px 0px"}
                  style={{ paddingTop: "8px" }}
                >
                  <ParentFormField
                    className="col-sm-8 col-md-3 col-lg-3"
                    padding="3px 6px"
                  >
                    <input
                      type="text"
                      autoFocus
                      placeholder="Full Name"
                      value={state.child_info.name}
                      onChange={this.handleNameChange}
                      disabled={!state.enableEdit}
                    />
                  </ParentFormField>
                  <div className="col-sm-4 col-md-1 col-lg-1" />
                  <ParentFormField
                    className="col-sm-8 col-md-3 col-lg-3"
                    padding="3px 6px"
                  >
                    <input
                      type="text"
                      autoFocus
                      placeholder="School Name"
                      value={state.child_info.school}
                      onChange={this.handleSchoolChange}
                      disabled={!state.enableEdit}
                    />
                  </ParentFormField>
                  <div className="col-sm-4 col-md-1 col-lg-1" />
                  <ParentFormField
                    className="col-sm-8 col-md-3 col-lg-3"
                    padding="3px 6px"
                  >
                    <input
                      type="text"
                      autoFocus
                      placeholder="Age"
                      value={state.child_info.age}
                      onChange={this.handleAgeChange}
                      disabled={!state.enableEdit}
                    />
                  </ParentFormField>
                  <div className="col-sm-4 col-md-1 col-lg-1" />
                </AlignRow>
              </ChildUploadContainer>
              <ChildUploadContainer>
                <ChildSubHeading style={{ fontSize: "18px" }}>
                  <FlexBetween>
                    <div>Other Details </div>
                  </FlexBetween>
                </ChildSubHeading>
                <FlexBetween style={{ paddingTop: "14px" }}>
                  <ChildFieldLabel>
                    <ChildInputField>
                      <FlexBetween start>
                        <span style={{ color: "#432666" }}>
                          <b>Freadom Points </b>
                          <br></br>( {user.active_child.freadom_point} ):
                        </span>
                        <SectionHeading
                          style={{ marginTop: "10px" }}
                          width={"40%"}
                          placeholder="Add Freadom Points"
                          type="number"
                          autoFocus
                          onKeyDown={this.handleFreadosKeyDown}
                          onChange={this.handleFreadosChange}
                          value={state.freadoAdd}
                        />
                        <AddButton
                          onClick={this.handleAddClick}
                          color="#432666"
                        >
                          +
                        </AddButton>
                      </FlexBetween>
                    </ChildInputField>
                  </ChildFieldLabel>

                  <ChildFieldLabel>
                    <ChildInputField>
                      <FlexBetween start>
                        <span style={{ color: "#432666" }}>
                          <b>Streak</b>
                          <br></br> ( {user.active_child.streak} ):
                        </span>
                        <SectionHeading
                          style={{ marginTop: "10px" }}
                          width={"35%"}
                          placeholder="Add Streaks"
                          type="number"
                          autoFocus
                          onKeyDown={this.handleStreakKeyDown}
                          onChange={this.handleStreakChange}
                          value={state.streakAdd}
                        />
                        <AddButton
                          onClick={this.handleStreakAddClick}
                          color="#432666"
                        >
                          +
                        </AddButton>
                      </FlexBetween>
                    </ChildInputField>
                  </ChildFieldLabel>

                  <ChildFieldLabel>
                    <ParentInfo align="16px" color="#432666">
                      <b>Assessment Taken: </b>{" "}
                      {user.active_child.level_test_status}
                    </ParentInfo>
                  </ChildFieldLabel>
                </FlexBetween>
                <FlexStart>
                  <ChildFieldLabel>
                    <ParentInfo align="16px" color="#432666">
                      <b>Invite Code:</b>
                      {user.active_child.invite_code
                        ? user.active_child.invite_code
                        : "No Invite code"}
                    </ParentInfo>
                  </ChildFieldLabel>
                  <ChildFieldLabel>
                    <ParentInfo align="16px" color="#432666">
                      <b>School Code:</b>
                      {user.active_child.school_code ? (
                        <React.Fragment>
                          {user.active_child.school_code}
                          <i
                            style={{ marginLeft: "5px", cursor: "pointer" }}
                            onClick={this.handleUnlinkSchoolCode}
                            className="link_icon fa fa-chain-broken"
                          />
                          {
                            this.props.user.active_child.is_teacher &&
                          <i
                          style={{ marginLeft: "5px", cursor: "pointer" }}
                          onClick={this.handleLinkSchoolCode}
                          className="link_icon fa fa-link"
                          />
                        }
                        </React.Fragment>
                      ) : (
                        <React.Fragment>
                          No School code
                          <i
                            style={{ marginLeft: "5px", cursor: "pointer" }}
                            onClick={this.handleLinkSchoolCode}
                            className="link_icon fa fa-link"
                          />
                        </React.Fragment>
                      )}
                    </ParentInfo>
                  </ChildFieldLabel>
                </FlexStart>
                <div style={{ display: "inline-block" }}>
                  <InterestsContainer>
                    <span> Interests : </span>
                    {this.props.user.active_child.interest &&
                    this.props.user.active_child.interest.length ? (
                      <span>
                        {this.props.user.active_child.interest.toString()}
                      </span>
                    ) : (
                      "No Interests added"
                    )}
                  </InterestsContainer>
                </div>
              </ChildUploadContainer>
              <ChildUploadContainer>
                <ChildSubHeading style={{ fontSize: "18px" }}>
                  Parent Details
                </ChildSubHeading>
                <ParentDetailsContainer>
                  <ParentInfo align="16px" color="#432666">
                    {user.active_child.parent.name}
                  </ParentInfo>
                  <ParentInfo align="16px" color={"rgba(67,38,102,0.6)"}>
                    {user.active_child.parent.email}
                  </ParentInfo>
                  <ParentInfo align="14px" color={"rgba(67,38,102,0.6)"}>
                    Contact Number: {user.active_child.parent.contact_no}
                  </ParentInfo>
                </ParentDetailsContainer>
              </ChildUploadContainer>
            </ChildProfileContainer>
          ) : (
            <ChildSubscription />
          ))}
      </ParentBodyContentContainer>
    );
  }
}

export default connectStore({
  user,onboard,ui
})(ChildProfileInfo);
