export default {
assessmentList:[],
    active_assessment:null,
    questions: [],
    fetch_assessment_list:false,
    active_question: {},
    active_options: [],
    new_question: {
        name: "",
        answer: "",
        level: "easy"
    },
    new_options: [],
    active_mcq: null,
}
