import React, { Component } from "react";
import Select from "react-select";
import { uploadCsvFile } from "@s/api/file";
import "./InviteCodeView.css";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import InviteCodeSearch from "./invite-code-search";
import swal from "sweetalert2";
import "react-tabs/style/react-tabs.css";
import moment from "moment";
import {
  ModalView,
  ModalViewParentContainer,
  ModalViewContainer,
  SelectContainer,
  FormContainer,
  FlexCenter,
} from "./InviteCodeView.styled";
import {
  Heading,
  MainBodyContentContainer,
  InputBox,
  ThemeButton,
} from "@/static/styled/custom.styled";
import { connectStore } from "redux-box";
import { module as inviteCode } from "store/inviteCode";
import { module as activity } from "store/activity";
import DatePicker from "react-datepicker";
import { DateSelectionField } from "@sc/custom.styled";
import "react-datepicker/dist/react-datepicker.css";
// import BulkAdoption from "./bulk-adoption";
// 


class InviteCodeView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedSchool: null,
      selectedSection: null,
      selectedClass: null,
      selectedGrade: null,
      count: 0,
      date: null,
      dateFormat: null,
    };
  }
  handleClearBtn = () => {
    let { inviteCode } = this.props;
    this.setState({
      date: "",
      dateFormat:""
    });
    // inviteCode.setActiveNewsDate("");
    // inviteCode.requestNewsFreeds(0);
  };

  handleSchoolChange = (selectedSchool) => {
    let { inviteCode } = this.props;
    this.setState({
      selectedSchool,
      selectedSection: null,
      selectedClass: null,
      selectedGrade: null,
    });
    inviteCode.requestClassList(selectedSchool.id);
  };

  handleClassChange = (selectedClass) => {
    let { inviteCode } = this.props;
    this.setState({
      selectedClass,
      selectedSection: null,
      selectedGrade: null,
    });
    inviteCode.requestSectionList({
      schoolId: selectedClass.school.id,
      classId: selectedClass.id,
    });
  };
  // //function for date selector (function starts)
  handleDateChange = (date) => {
    let { inviteCode } = this.props;
    this.setState({
      date: date,
      dateFormat: moment(date).format("YYYY-MM-DD"),
    });
  };
  //function for date selector in stories(function ends)

  handleSectionChange = (selectedSection) => {
    this.setState({ selectedSection });
  };

  handleGradeChange = (selectedGrade) => {
    this.setState({ selectedGrade });
  };
  onDatepickerRef(el) {
    if (el && el.input) {
      el.input.readOnly = true;
    }
  }

  handleCountChange = (e) => {
    this.setState({ count: e.target.value });
  };
  handleButtonClick = () => {
    let { inviteCode } = this.props;
    if (
      this.state.selectedSchool === null ||
      this.state.selectedClass === null ||
      this.state.selectedGrade === null ||
      this.state.selectedGrade === null ||
      this.state.date === null ||
      this.state.selectedSection === null
    ) {
      swal.fire({
        title: "Error!",
        text: "Fields cannot be empty",
        timer: 1500,
      });
    } else {
      let payload = {
        school: this.state.selectedSchool.id,
        class: this.state.selectedClass.id,
        section: this.state.selectedSection.id,
        grade: this.state.selectedGrade.id,
        gradeName: this.state.selectedGrade.name,
        countToGenerate: parseInt(this.state.count, 10),
        expiry_date: this.state.dateFormat,
      };
      inviteCode.createRandomInviteCode(payload);
      this.setState({
        selectedSection: null,
      });
    }
  };

  handleFileChange = (file) => {
    let fileData = file;
    uploadCsvFile(fileData).then((res) => {
      if (res.success) {
        fileData = "";
        swal("Done", "Verified successfully", "success");
      } else {
        fileData = "";
        swal("Oops!", res.error.error_message);
      }
    });
  };

  componentDidMount() {
    let { inviteCode } = this.props;
    inviteCode.requestSchoolList();
  }
  handleUploadClick = (e) => {
    this.inputElement.click();
  };
  render() {
    const { selectedSchool } = this.state;
    const { selectedClass } = this.state;
    const { selectedSection } = this.state;
    const { count } = this.state;
    const { selectedGrade } = this.state;

    return (
      <MainBodyContentContainer primary style={{ position: "relative" }}>
        <Tabs>
          <TabList>
            <Tab>Generate Codes</Tab>
            <Tab>Verify Invite Codes</Tab>
            <Tab>Search Invite Codes</Tab>
            {/* <Tab>School Codes</Tab> */}
            {/* <Tab>Bulk Adoption</Tab> */}
            {/* <Tab>Renewal Old School</Tab> */}
          </TabList>

          <TabPanel>
            <ModalView id="modalDisplay">
              <ModalViewParentContainer>
                <Heading
                  style={{
                    fontSize: "20px",
                    textAlign: "center",
                    padding: "10px",
                    fontWeight: "500",
                  }}
                >
                  Generate Invite Codes
                </Heading>
                <ModalViewContainer>
                  <FormContainer>
                    <SelectContainer>Select School</SelectContainer>
                    <Select
                      className="dropdown-container"
                      value={selectedSchool}
                      onChange={this.handleSchoolChange}
                      options={
                        this.props.inviteCode
                          ? this.props.inviteCode.schoolList
                          : []
                      }
                    />
                  </FormContainer>
                  <FormContainer>
                    <SelectContainer>Select Class</SelectContainer>
                    <Select
                      className="dropdown-container"
                      value={selectedClass}
                      onChange={this.handleClassChange}
                      options={
                        this.props.inviteCode
                          ? this.props.inviteCode.classList
                          : []
                      }
                    />
                  </FormContainer>
                  <FormContainer>
                    <SelectContainer>Select Section</SelectContainer>
                    <Select
                      className="dropdown-container"
                      value={selectedSection}
                      onChange={this.handleSectionChange}
                      options={
                        this.props.inviteCode
                          ? this.props.inviteCode.sectionList
                          : []
                      }
                    />
                  </FormContainer>
                  <FormContainer>
                    <SelectContainer>Freadom Grade</SelectContainer>
                    <Select
                      className="dropdown-container"
                      value={selectedGrade}
                      onChange={this.handleGradeChange}
                      options={this.props.activity.gradeList}
                    />
                  </FormContainer>
                  <FormContainer>
                    <SelectContainer>Invite Count</SelectContainer>
                    <InputBox
                      type="number"
                      placeholder="Input invite count"
                      value={count}
                      onChange={(e) => this.handleCountChange(e)}
                    />
                  </FormContainer>

                  <div
                    className="datepicker-wrappper"
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                      marginBottom: "20px",
                    }}
                  >
                    <div id="payments-date">
                      <DateSelectionField
                        width="220px"
                        style={{ overflow: "hidden" }}
                      >
                        <img
                          src={require("static/img/date-icon.png")}
                          alt="startDate"
                        />
                        <DatePicker
                          selected={this.state.date}
                          selectsStart
                          startDate={this.state.date}
                          onChange={this.handleDateChange}
                          minDate={moment().toDate()}
                          placeholderText="Select Expiry date"
                          ref={(el) => this.onDatepickerRef(el)}
                        />
                        {this.state.date ? (
                          <i
                            className="fa fa-times"
                            onClick={() => this.handleClearBtn()}
                            style={{ padding: "8px", cursor: "pointer" }}
                          />
                        ) : null}
                      </DateSelectionField>
                    </div>
                  </div>
                  <ThemeButton primary onClick={this.handleButtonClick}>
                    Create/Generate Codes
                  </ThemeButton>
                </ModalViewContainer>
              </ModalViewParentContainer>
            </ModalView>
          </TabPanel>
          <TabPanel>
            <FlexCenter>
              <input
                style={{ display: "none" }}
                ref={(input) => (this.inputElement = input)}
                type="file"
                accept=".csv"
                onChange={(e) => this.handleFileChange(e.target.files[0])}
              />
              <ThemeButton primary onClick={this.handleUploadClick}>
                Upload CSV file
              </ThemeButton>
            </FlexCenter>
          </TabPanel>
          <TabPanel>
            <InviteCodeSearch />
          </TabPanel>
          
          {/* <TabPanel>
            <SchoolCode />
          </TabPanel> */}
          {/* <TabPanel>
            <BulkAdoption />
          </TabPanel> */}
          {/* <TabPanel>
            <RenewalOldSchool />
          </TabPanel> */}
        </Tabs>
      </MainBodyContentContainer>
    );
  }
}
export default connectStore({
  inviteCode,
  activity,
})(InviteCodeView)