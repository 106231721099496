export default {
  tagResult: null,
  tagList: {},
  childTagList: {},
  currentEditorType: "tags",
  activeChildTag: {},
  activeTag: {},
  selectedTagList: [],
  selectedTagIdList: [],
  fetch_activity_packs_by_level:false
};
