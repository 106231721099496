import React from 'react';
import { connectStore } from 'redux-box';
import { module as schoolGuidedPath } from "@st/schoolGuidedPath";
import store from 'store';
import action from '@st/activity/actions';
import { CardIcon } from "static/styled/custom.styled";



import { TaskListCardContainer, TaskListLeftContent, TaskListCardImageContainer,TaskListCardImage, TaskListCardContent, TaskListCardTitle, TaskListCardDescription, TaskListCardType, TaskListCardTypeContent, TaskListRightContent , CardDeleteBlock} from './TaskListCard.styled'

const TaskListCardCard = (props) => {
    const handleTaskDelete = (evt) =>{
        evt.stopPropagation();
        props.schoolGuidedPath.deleteTask(props.taskData.id)
    }

    return (
        <TaskListCardContainer
            onClick={() => {
                props.schoolGuidedPath.setActiveTask(props.taskData);
                store.dispatch(action.setEditorType('task'))
            }}
        >
            <TaskListLeftContent>
                <TaskListCardImageContainer>
                    <TaskListCardImage url={props.taskData.thumbnail_url} >
                    </TaskListCardImage>
                </TaskListCardImageContainer>
                <TaskListCardContent>
                    <TaskListCardTitle>
                        {props.taskData.name.substring(0, 50)}
                    </TaskListCardTitle>
                    <TaskListCardDescription>
                        {props.taskData.description.substring(0, 50)}
                    </TaskListCardDescription>
                </TaskListCardContent>
            </TaskListLeftContent>
            <CardDeleteBlock>
                <CardIcon onClick={handleTaskDelete}>
                            <i className="fa fa-trash" />
                </CardIcon>
            </CardDeleteBlock>
            <TaskListRightContent>
                <TaskListCardType>
                    <TaskListCardTypeContent>
                        {props.taskData.content_type}
                    </TaskListCardTypeContent>
                </TaskListCardType>
            </TaskListRightContent>
        </TaskListCardContainer>
    )
}
export default connectStore({
    schoolGuidedPath
})(TaskListCardCard)