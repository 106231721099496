import styled from "styled-components"

export const ModalContainer = styled.div`
    position:fixed;
    left:0;
    height:100%;
    width:100%;
    display:flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    transition:all 0.3s;
    top:${props => props.active ? '0' : '100%'};
    opacity:${props => props.active ? '1' : '0'};
    visibility: ${props => props.active ? 'visible' : 'hidden'};
    transition:all 0.3s;
`;