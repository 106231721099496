import {createActions,using} from 'redux-box'

export default createActions({
    //....................UNIT CRUD..................
    getLevelList:using(),
    setActiveFreadomFutureLevel:using("level"),
    setFetchFutureFreadomList:using("value"),
    setFreadomFutureList:using("freadomFutureList"),
    requestFreadomFutureByGradeId:using("gradeId"),
    requestConcatFreadomFuture:using("offset"),
    // setActiveFreadomFutureGrade:using("grade"),
    requestFreadomFuture:using(),
    // requestCreateReadingChallenge:using("reading"),
    // requestUpdateReadingChallenge:using("reading"),
    // setActiveReadingChallenge:using("activeReading"),
    //CREATE UNIT 
    createFreadomFutureUnit : using("unitData"),
    // //.................Section............................
    requestFreadomFutureActiveGrade:using(),

    //................................TASK CRUD............
    requestFreadomFutureTaskList : using("unitId"),
    createFreadomFutureTask : using("taskData"),
    setActiveFreadomFutureTask:using("task"),

    // //...............MISC.................
    setSearchFreadomFuture:using("search"),


})