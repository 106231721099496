import React, { Component } from "react";
import { MainBodyContainer } from "@sc/custom.styled";
import { connectStore } from "redux-box";
import { module as payment } from "@st/payment";
import PaymentsHeader from "./payments-header/PaymentsHeader";
import PaymentsList from "./payments-list/PaymentsList";
import PaymentsLink from "./payments-link/PaymentsLink";

 class PaymentsView extends Component {
  componentDidMount() {
    let { payment } = this.props;
    payment.setPaymentType(this.props.match.params.type);
    payment.requestPaymentList(0);
  }
  render() {
    return (
      <MainBodyContainer>
        <PaymentsHeader />
        {
          this.props.payment.payment_type==="payment-link"? 
          <PaymentsLink />
          :
          <PaymentsList />
        }
      </MainBodyContainer>
    );
  }
}
export default connectStore({
  payment
})(PaymentsView)