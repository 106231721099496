import styled from "styled-components"
import stars from "static/img/stars.png"


export const ModalBackDrop = styled.div`
    position:fixed;
    background-color:var(--primary-color);
    background-image:url(${stars});
    top:0;
    opacity: ${props => props.second ? 0.8 : (props.active ? 0.3 : 0)};
    visibility:${props => props.active ? "visible" : "hidden"};
    left:0;
    width:100%;
    height:100%;
    z-index: 900;
    transition:0.3s;
    background-size: cover;
    max-width:100%;
`;