import React, { Component } from 'react';
import {
    UserDetailValues, UserField2,
    UserFieldName
} from "@c/users-view/MainContent.styled";
import { module as payment } from '@st/user';
import { connectStore } from "redux-box";


 class PaymentsCard extends Component {

    getTransDate = () => {
        let { paymentInfo } = this.props;
        let months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        let date = new Date(paymentInfo.created_at);
        let startDate = date.getDate() + " " + months[date.getMonth()] + " " + date.getFullYear();
        return startDate;
    }
    render() {

        let { paymentInfo } = this.props;
        let date = this.getTransDate();

        return (
            <UserDetailValues>
                <UserFieldName style={{ width: '14%' }} >{paymentInfo.child.id}</UserFieldName>
                <UserFieldName>{paymentInfo.child.name} </UserFieldName>
                <UserField2> {paymentInfo.child.parent.contact_no}</UserField2>
                <UserField2> {paymentInfo.pg_type.toLowerCase() === 'razorpay' ? paymentInfo.razorpay_payment_id : paymentInfo.txn_id}</UserField2>
                <UserField2> {date}</UserField2>
                <UserField2> {`${paymentInfo.amount}-${paymentInfo.currency.toUpperCase()} `} </UserField2>
                <UserField2 >  {paymentInfo.pg_type.slice(0, 1).toUpperCase().concat(paymentInfo.pg_type.slice(1))}
                </UserField2>
                <UserField2> {paymentInfo.status.slice(0, 1).toUpperCase().concat(paymentInfo.status.slice(1))} </UserField2>

            </UserDetailValues>
        )
    }
}
export default connectStore({
    payment
})(PaymentsCard)