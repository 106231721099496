import { Api } from "./base";

const _headers = () => {
  return {
    Authorization:
      "JWT " + require("store").default.getState().onboard.user.auth_token,
    "Content-Type": "application/json"
  };
};

export default {
  getLeaderBoardList(search, offset = 0) {
    if (search === "")
      return Api.getRequest(
        `/analytics/v1/admin-leaderboard/?offset=${offset}`,
        _headers()
      );

    return Api.getRequest(
      `/analytics/v1/admin-leaderboard/?offset=${offset}&query=${search}`,
      _headers()
    );
  },
  getFreadomPointLeaderBoardList(search, offset = 0) {
    if (search === "")
      return Api.getRequest(
        `/analytics/v1/admin-leaderboard/?name=fp-leaderboard&offset=${offset}`,
        _headers()
      );

    return Api.getRequest(
      `/analytics/v1/admin-leaderboard/?name=fp-leaderboard&offset=${offset}&query=${search}`,
      _headers()
    );
  },
  // for searching in leaderboard
  // getLeaderBoards(search, offset, status, date) { 
  //   if (search === "")
  //     return Api.getCsv(
  //       `/analytics/v1/admin-leaderboard/?status=${status}&offset=${offset}&date=${date}`,
  //       _headers()
  //     );

  //   return Api.getCsv(
  //     `/analytics/v1/admin-leaderboard/?status=${status}&offset=${offset}&key=${search}&date=${date}`,
  //     _headers()
  //   );
  // },
  // for searching in leaderboard
};
