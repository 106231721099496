import styled from "styled-components";

export const Container = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const InfoBox = styled.div`
  width: 400px;
  // flex: 400px 1;
  font-size: 14px;
  line-height: 2;
  flex-basis: 400px;
  background: #e7e7e7;
  border-radius: 10px;
  color: #000;
  padding: 10px;
  margin-top: 30px;
  i {
    color: #999;
    width: 40px;
  }
`;

export const Pill = styled.button`
  background: #fff;
  border: 1px solid #f5f5f5;
  border-radius: 100px;
  outline: 0;
  font-size: 12px;
  padding: 5px 20px;
  margin-right: 10px;
  &:focus {
    outline: 0;
  }
`;

export const CardBody = styled.div`
  color: var(--primary-color);
  font-size: var(--xxsm);
  padding: 10px;
`;
export const CardTitle = styled.div`
  color: var(--primary-color);
  font-size: var(--md-font-size);
  padding: 10px;
`;

export const CardContainer = styled.div`
    border-radius:10px;
    overflow:hidden;
    background:white;
    min-height:120px;
    position:relative;
    margin-bottom:20px;
    border:1px solid #e7e7e7;
        transition:all 0.3s;
      &:hover{
            box-shadow: 0px 3px 10px -2px rgba(0,0,0,0.5);
            }
`;

export const ModalContainer = styled.div`
  width: 550px;
    padding: 30px;  
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    overflow: auto;
`
export const Item = styled.div`
  padding: 10px;
  color: #555;
  border-radius: 3px;
  margin: 3px;
  cursor: pointer;

  :hover {
    background: #f2f2f2;
  }
`;