import React, { Component } from "react";
import { CreatorComponent, SubContainer } from "@sc/Creator.styled";
import {
  LoadingContainer,
  PanelHeader,
  ThemeButton,
  Heading
} from "static/styled/custom.styled";
import "video-react/dist/video-react.css";
import {
  Player,
  BigPlayButton,
  ControlBar,
  VolumeMenuButton,
  TimeDivider,
  CurrentTimeDisplay
} from "video-react";
import "../../../global.css";
import {
  ExperienceDescription,
  ExperienceDetailsMainContainer,
  ExperienceHeaderContainer,
  ExperienceImageContainer,
  ExperienceMediaContainer,
  ExperienceToolbarContainer,
  UserDetailsContainer
} from "@/components/activity-view/activity-creator/experiences-editor/experience-card/ExperienceCard.styled";
import "./videoExpEditor.css";
// import swal from "sweetalert2";
import { connectStore } from "redux-box";
import { module as experience } from "store/experience";
import { module as onboard } from "@st/onboard";
import Permission from "@s/permission";
// export default connectStore({
//   experience
// })

class AllExperiencesEditor extends Component {
  constructor(props) {
    super();
    this.state = {
      translate: "100%"
    };
  }

  handleCloseBtn = () => {
    let { experience, history } = this.props;
    experience.setActiveAllExperience(null);
    let category = experience.experience_type;
    history.push(`/experiences/${category}`);
  };
  // REACT LIFECYCLE METHODS

  componentWillMount() {
    let { history } = this.props;
    let { experience } = this.props;
    experience.requestGetAllActiveExperienceById(this.props.match.params.id);
    document.addEventListener("keyup", function(e) {
      let category = experience.experience_type;
      if (
        e.keyCode === 27 &&
        window.location.pathname.includes(`experiences/${category}/edit`)
      ) {
        history.push(`/experiences/${category}`);
      }
    });
  }
  componentDidMount() {
    setTimeout(() => {
      this.setState({
        translate: "0"
      });
    }, 30);
  }

  componentWillUnmount() {
    let { experience } = this.props;
    experience.setActiveAllExperience(null);
  }

  render() {
    let { experience } = this.props;
    let experience_info = experience && experience.active_all_experience;
    const {permission} = this.props.onboard;
    return (
      <CreatorComponent>
        <SubContainer
          active
          style={
            experience.fetch_all_active_experience
              ? {}
              : { transform: `translateX(${this.state.translate})` }
          }
        >
          {experience.fetch_all_active_experience ? (
            <LoadingContainer
              style={
                experience.active_all_experience
                  ? { height: "50px" }
                  : { top: 0 }
              }
            >
              <div>
                <img
                  alt="loading"
                  width="60"
                  height="60"
                  src={require("static/img/Theme-loader.gif")}
                />
              </div>
            </LoadingContainer>
          ) : null}
          {!experience.fetch_all_active_experience ? (
            <PanelHeader primary>
              <Heading style={{ margin: "0px" }}> Experience details </Heading>
              <ThemeButton primary onClick={this.handleCloseBtn}>
                Close
              </ThemeButton>
            </PanelHeader>
          ) : null}
          {experience_info ? (
            <ExperienceDetailsMainContainer>
              <ExperienceHeaderContainer>
                <UserDetailsContainer>
                  <div>
                    <img
                      alt="child"
                      src={
                        experience_info && experience_info.child_image
                          ? experience_info.child_image
                          : require("static/img/default-image.png")
                      }
                    />
                  </div>
                  <span> {experience_info && experience_info.child_name} </span>
                </UserDetailsContainer>
                <ExperienceToolbarContainer>
                  {experience_info &&
                  experience_info.media &&
                  experience_info.media.length > 0 ? (
                    <a
                      href={experience_info.media[0].media_url}
                      download
                      target="_blank"
                    >
                      <i
                        className="fa fa-cloud-download"
                        style={{ color: "#ff7373" }}
                      />
                    </a>
                  ) : (
                    <i
                      className="fa fa-cloud-download"
                      onClick={this.handleDownLoadError}
                      style={{ color: "#ff7373" }}
                    />
                  )}
                  {Permission.getPermissionMulti(permission, ["delete_readingexperience","delete_activityexperience","delete_storyexperience"])?
                  <i
                    className="fa fa-check-circle"
                    onClick={e => this.handlePublish(e, experience_info)}
                    style={
                      experience_info.is_published
                        ? { color: "#61bd4f" }
                        : { color: "#ff7373" }
                    }
                  />:null}
                  {Permission.getPermissionMulti(permission, ["change_readingexperience","change_activityexperience","change_storyexperience"])?
                  <i
                    className="fa fa-trash"
                    onClick={e => this.handleDelete(e, experience_info.id)}
                    style={{ color: "#ff7373" }}
                  />:null}
                </ExperienceToolbarContainer>
              </ExperienceHeaderContainer>
              {experience_info &&
              experience_info.content_type.length > 0 &&
              experience_info.media.length > 0 ? (
                <ExperienceMediaContainer
                  innerRef={input => {
                    this.myInput = input;
                  }}
                  height={
                    experience_info.content_type === "audio" ? "50px" : "150px"
                  }
                >
                  {experience_info &&
                    experience_info.content_type === "audio" &&
                    experience_info.media.map((audio, i) => (
                      <audio controls key={i}>
                        <source
                          src={audio.media_url}
                          type="audio/ogg; codecs=opus"
                        />
                        <source
                          src={audio.media_url}
                          type="audio/ogg; codecs=vorbis"
                        />
                        <source src={audio.media_url} type="audio/mpeg" />
                      </audio>
                    ))}
                  {experience_info &&
                    experience_info.content_type === "image" && (
                      <ExperienceImageContainer
                        src={
                          experience_info && experience_info.media.length >= 1
                            ? experience_info.media[0].media_url
                            : require("static/img/default-image.png")
                        }
                      />
                    )}
                  {experience_info &&
                    experience_info.content_type === "video" && (
                      <Player
                        height={140}
                        width={300}
                        style={{ borderRadius: "10px", width: "100%" }}
                        fluid={false}
                        playsInline
                        src={experience_info.media[0].media_url}
                      >
                        <BigPlayButton
                          position="center"
                          style={{ left: "55%" }}
                        />
                        <ControlBar>
                          <TimeDivider disabled />
                          <CurrentTimeDisplay disabled />
                          <VolumeMenuButton disabled />
                        </ControlBar>
                      </Player>
                    )}
                </ExperienceMediaContainer>
              ) : null}

              <ExperienceDescription>
                {experience_info && experience_info.text}
              </ExperienceDescription>
            </ExperienceDetailsMainContainer>
          ) : (
            ""
          )}
        </SubContainer>
      </CreatorComponent>
    );
  }
}
export default connectStore({
  experience,onboard
})(AllExperiencesEditor)