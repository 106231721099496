import React, {Component} from 'react';
import {EditorMainContainer, Heading, PanelHeader, ThemeButton} from "static/styled/custom.styled";
import {module as story} from "@st/story";
import {connectStore} from "redux-box";
import ExperiencesList from "./story-experience-list/StoryExperienceList";
import ExperienceCard from "./story-experience-card/StoryExperienceCard";


 class StoryExperienceEditor extends Component
{

    componentWillUnmount()
    {
        let {story}=this.props;
        story.setStoryExperiencesList([]);
        story.setActiveStoryExperience(null);
        

    }


    handleDoneBtn=()=>
    {
        let {story}=this.props;
        story.setStoryExperiencesList([]);
        story.setActiveStoryExperience(null);
        story.setStoryEditorType('')

    }
    render(){
        return(
            <EditorMainContainer style={{overflow:'hidden',padding:'0px'}}>
            <PanelHeader primary>
                {!this.props.story.active_experience? <Heading style={{margin:'0px'}} >  All experiences </Heading>:
                    <div style={{display:"flex",alignItems:"center"}}>
                        <i className="fa fa-chevron-left" style={{fontSize:'16px',marginRight:'8px',cursor:"pointer"}} 
                        onClick={()=>this.props.story.setActiveStoryExperience(null)}/>
                        <Heading style={{margin:'0px'}} >  Experience details </Heading>
                    </div>}
                <ThemeButton primary onClick={this.handleDoneBtn}>Done</ThemeButton>
            </PanelHeader>
            {!this.props.story.active_experience?
                <ExperiencesList/>:
                <ExperienceCard/>}
        </EditorMainContainer>
        )
    }
}

export default connectStore({
    story
})(StoryExperienceEditor)