import React, { Component } from "react";
import { connectStore } from "redux-box";
import InfiniteScroll from "react-infinite-scroller";
import { module as notification } from "store/notification";
import { module as ui } from "store/ui";
import swal from "sweetalert2";
import {
  CheckBall,
  CheckBallInner,
  Chevron,
  DropDown, ThemeButton,
  DropdownItem,
  FlexBetween,
  InfiniteScrollLoader,
  SubHeading,
} from "static/styled/custom.styled";
import {
  DropdownFlexStart,
  NotificationDropdownParentContainer,
  NotificationParentContainer,
  NotificationScrollParentContainer,
  NotificationSearchInputContainer, DoneButtonContainer,ChildDataDescription
} from "../NotificationSelectorModal.styled";
import ReactDOM from "react-dom";


class UsersDropdownContainer extends Component {
  constructor(props) {
    super();
    this.state = {
      selectedType: "Child",
      selectedChildren: [],
      selectedFirstChildName: '',
      childTypeSelector: false,
      childSelected: "",
      scrollEnabled: false
    };
  }

  componentWillReceiveProps(nextProps) {
    let notification = nextProps.notification;
    let children_list = nextProps.notification.children_list;
    if (
      notification &&
      children_list.length > 0 &&
      notification.selected_users.length > 0
    ) {
      //let selectedChildren = [];
      if (!this.state.scrollEnabled) {
        if (notification.selected_users && notification.selected_users.length === 1 && notification.selected_users[0] === 'All') {
          this.setState({
            selectedChildren: [],
            childSelected: 'All',
            enableAllSelected: true
          });
        }
      }
    }
    else if (notification && notification.children_list.length > 0 && this.state.childSelected === 'All') {
      let selectedChildren = [];
      if (!this.state.scrollEnabled) {
        notification.children_list &&
          notification.children_list.length > 0 &&
          notification.children_list.forEach(child => {
            selectedChildren = selectedChildren.concat(child.id);
          });
        this.setState({
          selectedChildren: selectedChildren
        });
      }
    }
  }



  componentDidMount() {
    let { notification } = this.props;
    document.addEventListener("click", this.handleFilterClick);
    notification.requestAllChildrenListByOffset(0);
    if ((notification.selected_users.length > 0 && notification.active_notification === null)
      || (notification.active_notification !== null && notification.active_notification.filter_type === 'all')) {
      let users = notification.selected_users;
      if (users.length === 1 && users[0] === 'All')
        this.setState({
          enableAllSelected: true,
          childSelected: 'All',
          selectedChildren: [],
        });
      else {
        console.log(notification.selected_users);
        this.setState({
          selectedChildren: notification.selected_users,
        })
      }
    }
  }

  componentWillUnmount = () => {
    document.removeEventListener("click", this.handleFilterClick);
    this.props.notification.unsetChildrenListResult();
    this.props.notification.unsetChildrenList();
    this.props.notification.setEnableScroll(false);
  };

  handleFilterClick = evt => {
    const area = ReactDOM.findDOMNode(this.refs.area);

    if (!area.contains(evt.target) && this.state.childTypeSelector) {
      //console.log(area);
      this.setState({
        childTypeSelector: false
      });
    }
  };

  fetchMoreChildren = () => {
    if (
      this.props.notification.children_list_result !== null &&
      this.props.notification.children_list_result.has_next &&
      this.state.childTypeSelector && !this.props.notification.enableScroll
    ) {
      setTimeout(() => {
        if (
          this.props.notification.children_list_result !== null &&
          this.props.notification.children_list_result.next_page > 0
        )
          if (!this.state.scrollEnabled)
            this.setState({
              scrollEnabled: true
            });
        this.props.notification.requestConcatChildrenListByOffset(this.props.notification.children_list_result.next_page)
      }, 300);
    }
  };

  handleChildTypeSelector = value => {
    this.setState({
      childTypeSelector: value
    });
  };

  handleChildChange = (index, data) => {
    if (index === 0) {
      if (this.state.childSelected === "") {
        let { notification } = this.props;
        let selectedChildren = [];

        if (notification.children_list.length > 0) {
          notification.children_list.forEach((child, index) => {
            if (index === 0) { this.setState({ selectedFirstChildName: child.name }) }
            selectedChildren = selectedChildren.concat(child.id);
          });
          this.setState({
            selectedChildren: selectedChildren
          });
        }
        this.setState({
          childSelected: "All",
          enableAllSelected: true
        });
      } else {
        this.setState({
          childSelected: "",
          enableAllSelected: false,
          selectedChildren: []
        });
      }
    } else {
      if (!this.state.selectedChildren.some(child => child === data.id)) {
        // console.log("hello");
        if (this.state.selectedFirstChildName === '') {
          this.setState({selectedFirstChildName: data.name});
        }
        this.setState({
          selectedChildren: this.state.selectedChildren.concat(data.id)
        });
      } else {
        let count = 0;
        let newSelect = '';
        let selectedChildren = this.state.selectedChildren.filter(child => {
          if (child !== data.id && count === 0) {
            newSelect = child.name;
            count++;
          }
          return child !== data.id;
        });


        this.setState({
          selectedChildren: selectedChildren,
          selectedFirstChildName: this.state.selectedFirstChildName === data.name ?
            newSelect : this.state.selectedFirstChildName,
          enableAllSelected: false,
          childSelected: '',
        });
      }
    }
  };

  handleChildListSearch = evt => {
    let { notification } = this.props;

    notification.setChildrenListSearch(evt.target.value);
    setTimeout(() => {
      notification.requestAllChildrenListByOffset(0);
    }, 1000);


  };


  handleDoneButton = () => {
    let { notification, ui } = this.props;
    if (this.state.selectedChildren.length === 0)
      swal('Oops!', 'Please select children !', 'warning');
    else {
      let notification_data = {
        filter_type: 'all',
        filter_type_ids: [],
        custom_user_ids: this.state.selectedChildren,
        wow_school_ids: [],
      }
      if (this.state.enableAllSelected && notification.search_child === '') {

        if (notification.active_notification === null) {
          notification.setSelectedUsersList(['All']);
          notification.setSelectedAllData(' All Children Selected');
        }
        else {

          notification.requestUpdateNotification({
            ...notification_data, custom_user_ids: []
          })
          notification.unsetDropdownData();
        }
      }
      else {
        if (notification.active_notification === null) {
          notification.setSelectedUsersList(this.state.selectedChildren);
          //let selectedChild = '';
          if (this.state.selectedChildren.length === 1) {
            notification.setSelectedAllData(this.state.selectedChildren.length + ' Child Selected');
          }
          if (this.state.selectedChildren.length > 1) {
           // selectedChild = this.state.selectedFirstChildName + " + " + this.state.selectedChildren.length - 1;
            notification.setSelectedAllData(this.state.selectedChildren.length + ' Children Selected');
          }
        }
        else {

          notification.requestUpdateNotification(notification_data);
          notification.unsetDropdownData();
        }


      }
      ui.hideNotificationModal();
    }
  }

  render() {
    let { notification } = this.props;
    return (
      <NotificationParentContainer>
        <NotificationDropdownParentContainer>
          <SubHeading style={{ margin: 0 }}> Child </SubHeading>
          <FlexBetween style={{ width: "80%", justifyContent: "flex-start" }}>
            <DropDown
              minWidth="180px"
              active={this.state.childTypeSelector}
              ref="area"
              tabIndex="1"
            >
              <div
                style={{ fontSize: "14px", height: "24px" }}
                onClick={() =>
                  this.handleChildTypeSelector(!this.state.childTypeSelector)
                }
              >
                {this.state.selectedChildren.length === 0 &&
                  !this.state.enableAllSelected
                  ? "None Selected "
                  : this.state.enableAllSelected ? 'All Selected' : this.state.selectedChildren.length + " Child Selected"}
                <Chevron>
                  <img
                    src={require("static/img/downarrowicon.png")}
                    alt="down-arrow"
                  />
                </Chevron>
              </div>

              <NotificationScrollParentContainer
                maxHeight="260px"
                active={this.state.childTypeSelector}
                color="#fff"
                style={{ padding: "0px" }}
              >
                <NotificationSearchInputContainer
                  onClick={() => this.handleChildTypeSelector(true)}
                >
                  <input
                    type="text"
                    placeholder="Type to Search"
                    value={this.props.notification.search_child}
                    onChange={this.handleChildListSearch}
                  />
                  <i className="fa fa-search" />
                </NotificationSearchInputContainer>
                <InfiniteScroll
                  pageStart={0}
                  loadMore={this.fetchMoreChildren}
                  hasMore={
                    this.props.notification.children_list_result &&
                    this.props.notification.children_list_result.has_next
                  }
                  style={{ position: "relative", marginTop: "30px" }}
                  loader={
                    <InfiniteScrollLoader className="loader" key={0}>
                      <img
                        alt="loading"
                        width="20"
                        height="20"
                        src={require("static/img/Theme-loader.gif")}
                      />
                    </InfiniteScrollLoader>
                  }
                  useWindow={false}
                >
                  <DropdownItem>
                    <DropdownFlexStart
                      style={{ fontSize: '12px' }}
                      onClick={() => this.handleChildChange(0, "All")}
                    >
                      <CheckBall
                        second
                        style={{ marginRight: "6px" }}
                        active={
                          this.state.childSelected === "All" &&
                          this.state.enableAllSelected
                        }
                      >
                        <CheckBallInner
                          second
                          active={
                            this.state.childSelected === "All" &&
                            this.state.enableAllSelected
                          }
                        />
                      </CheckBall>
                      {this.state.enableAllSelected && this.state.childSelected === 'All'
                        ? "Unselect All"
                        : "Select All"}
                    </DropdownFlexStart>
                  </DropdownItem>

                  {notification.children_list.map((child, index) => (
                    <DropdownItem key={index + 1}>
                      <DropdownFlexStart
                        onClick={() => this.handleChildChange(index + 1, child)}
                        style={{ fontSize: '12px' }}
                      >
                        <CheckBall
                          second
                          style={{ marginRight: "6px" }}
                          active={
                            this.state.selectedChildren.includes(child.id) ||
                            this.state.enableAllSelected
                          }
                        >
                          <CheckBallInner
                            second
                            active={
                              this.state.selectedChildren.includes(child.id) ||
                              (this.state.childSelected === "All" &&
                                this.state.enableAllSelected)
                            }
                          />
                        </CheckBall>
                        <ChildDataDescription>
                          {child.name}
                          <div> {`( ${child.parent.name} )`} </div>
                          <div> <i class="fa fa-phone" aria-hidden="true" />
                          {` ${child.parent.contact_no}`} </div>
                        </ChildDataDescription>
                      </DropdownFlexStart>
                    </DropdownItem>
                  ))}
                </InfiniteScroll>
              </NotificationScrollParentContainer>
            </DropDown>
          </FlexBetween>
        </NotificationDropdownParentContainer>
        <DoneButtonContainer>
          <ThemeButton primary onClick={this.handleDoneButton}>  Done </ThemeButton>
        </DoneButtonContainer>
      </NotificationParentContainer>
    );
  }
}
export default connectStore({
  notification,
  ui
})(UsersDropdownContainer)