import React, { Component } from "react";
import {
  FillFieldLabel,
  InputFieldLeftIcon,
  InputFieldText,
  LoginBackDrop,
  LoginButton,
  LoginForm,
  LoginFreadomLogo,
  LoginHeading,
  LoginImage,
  ModalLoginView,
  SuperAdminText
} from "./Login.styled";
import swal from "sweetalert2";
import {ModalContainer} from "@sc/ModalContainer.styled";
import {InputField} from "@sc/InputField.styled";
// import {Link} from "react-router-dom";
import {connectStore} from "redux-box";
import {module as onboard} from "@st/onboard";


class Login extends Component {
    constructor(props){
        super();
        this.state = {
            login_detail: {
                user_type: 'admin',
                username: '',
                password: '',
                device_id: "1234",
                device_token: "124",
                device_type: "android",
            }
        }
    }

    handleUsernameChange = e => {
        this.setState({
            login_detail:{...this.state.login_detail,username : e.target.value}
        })
    }
    handlePasswordChange = e =>{
        this.setState({
            login_detail:{...this.state.login_detail,password : e.target.value}
        })
    };
    handleKeyPress=event=> {
        if (event.key === 'Enter')
        {this.handleLoginClick()}
    };

    handleLoginClick = () => {
        //let {state} = this.state.login_detail;
        if(this.state.login_detail.username!=='' && this.state.login_detail.password!=='')
            this.props.onboard.requestLogin(this.state.login_detail);
        else {
            swal(
                "Oops!",
                "You must enter login credentials to login ",
                "error"
            );
        }
    };


    render() {

        return (
            <ModalContainer active>
                <LoginBackDrop primary>
                </LoginBackDrop>
                <ModalLoginView>
                    <LoginImage>
                        <LoginFreadomLogo/>
                    </LoginImage>
                    <LoginForm>
                        <LoginHeading> You’re logging in as a
                            <SuperAdminText> Super Admin</SuperAdminText> </LoginHeading>
                        <FillFieldLabel className="fill-fields-label"> Fill in your details to login </FillFieldLabel>
                        <InputField>
                            <InputFieldLeftIcon primary/>
                            <InputFieldText type="text" placeholder="Username" className="input-field" onChange={this.handleUsernameChange} onKeyPress={this.handleKeyPress}>

                            </InputFieldText>
                        </InputField>
                        <InputField>
                            <InputFieldLeftIcon/>
                            <InputFieldText type="password" placeholder="Password" className="input-field" onChange={this.handlePasswordChange} onKeyPress={this.handleKeyPress}>
                            </InputFieldText>
                        </InputField>
                        <LoginButton primary onClick={this.handleLoginClick}> Login </LoginButton>
                    </LoginForm>
                </ModalLoginView>
            </ModalContainer>

        )

    }
}
export default connectStore({
    onboard
})(Login)