import React, { Component } from "react";
import {
  ActivityAddHeaderContainer,
  ActivityPopUpContainer
} from "static/styled/ActivityModal.styled";
import { ThemeButton } from "static/styled/custom.styled";
import Editor from "react-rte";
import {connectStore} from 'redux-box'
import {module as story} from 'store/story'

const toolbarConfig = {
  // Optionally specify the groups to display (displayed in the order listed).
  display: ["INLINE_STYLE_BUTTONS", "BLOCK_TYPE_BUTTONS", "HISTORY_BUTTONS"],
  INLINE_STYLE_BUTTONS: [
    { label: "Bold", style: "BOLD", className: "custom-css-class" },
    { label: "Italic", style: "ITALIC", className: "custom-css-class" },
    { label: "Underline", style: "UNDERLINE", className: "custom-css-class" }
  ],
  BLOCK_TYPE_BUTTONS: [
    {
      label: "UL",
      style: "unordered-list-item",
      className: "custom-css-class"
    },
    { label: "OL", style: "ordered-list-item", className: "custom-css-class" }
  ]
};


class SectionedTextEditor extends Component {
  constructor(props) {
    super();
    this.state = {
      value: Editor.createEmptyValue(),
      activeTitle: ""
    };
  }

  onChange = (value) => {

    this.setState({value});
  };

  onDone=()=>{
     const {story} = this.props;
    let value=this.state.value.toString('markdown').length>2?this.state.value.toString('html'):'';
    console.log(this.state.value.toString('markdown').length);
      const data ={
        description: value,
        content_type: "text",
          story:this.props.story.active_story.id,
      };


      if(story.current_section_action==='create')
      {story.requestCreateStorySection(data);
          this.props.story.resetNewStorySections()}
      else
          story.requestUpdateStorySection(data);
      
      story.setStoryEditorType('');
      // const data = {
      //      description: this.state.value.toString('html'),
      //      content_type: "text"
      //   }
      // this.props.activity.requestUpdateSection(data)
  }

  componentWillMount=()=>{
      const section = this.props.story.active_section
      section && section.description && this.setState({
          value: Editor.createValueFromString(section.description, 'html')
      })
  }

  render() {
    return (
      <ActivityPopUpContainer>
        <ActivityAddHeaderContainer>
          <span>Text Editor</span>
          <ThemeButton primary onClick={this.onDone}>
            Done
          </ThemeButton>
        </ActivityAddHeaderContainer>
        <p> </p>
        <Editor
          className="editor"
          value={this.state.value}
          placeholder="Enter Text Here..."
          onChange={this.onChange}
          toolbarConfig={toolbarConfig}
          toolbarClassName="top-toolbar"
          editorClassName="editor-text-area"
        />
     
      </ActivityPopUpContainer>
    );
  }
}
export default connectStore({
  story
})(SectionedTextEditor)