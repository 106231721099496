import { createSagas } from "redux-box";
import GradeApi from "@s/api/grade";
import FreadomFutureApi from "@s/api/freadomFuture";
import { call, put } from "redux-saga/effects";
//import { push } from "react-router-redux";
import swal from "sweetalert2";
import { Api } from "../../services/api/base";

// import store from 'store'
const defaultSections = [
  { name: "Challenge Rules" },
  { name: "Challenge Steps" },
  { name: "Resources" },
];

const errorHandler = (err) => {
  console.log("freadomFuture store err",err);
  swal("Oops!", err.error_message, "error");
};

export default createSagas({

  REQUEST_FREADOM_FUTURE_ACTIVE_GRADE: function*() {
    try {
      const res = yield call(GradeApi.getAllGrades);

      yield put({
        type: "REQUEST_FREADOM_FUTURE_BY_GRADE_ID",
        gradeId: res[0].id,
      });
      yield put({
        type: "SET_ACTIVE_FREADOM_FUTURE_GRADE",
        grade: res[0].id,
      });
    } catch (err) {}
  },

  GET_LEVEL_LIST : function*() {
    try {
      //need to fetch level list
      yield put({
        type: "SET_FREADOM_FUTURE_LEVEL_LIST",
        level_list: [{ value: '8e41872c-ff47-413c-916d-c52a1731f7c3', label: 'easy' }]
      });
      yield put({
        type: "SET_ACTIVE_FREADOM_FUTURE_LEVEL",
        level: { value: '8e41872c-ff47-413c-916d-c52a1731f7c3', label: 'easy' }
      });
    } catch (err) {}
  },

  REQUEST_FREADOM_FUTURE_BY_GRADE_ID: function*({ gradeId }) {
    try {
      yield put({
        type: "SET_FETCH_FREADOM_FUTURE_LIST",
        value: true,
      });
      yield put({
        type: "SET_ACTIVE_FREADOM_FUTURE_GRADE",
        grade: gradeId,
      });
      const gState = require("store").default.getState();
      const search = gState.freadomFuture.search_reading;
      const level = gState.freadomFuture.active_freadom_future_level.length > 0 ? gState.freadomFuture.active_freadom_future_level.value : "";
      const res = yield call(
        FreadomFutureApi.getFreadomFutureUnit,
        gradeId,
        level,
        0,
        search
      );
      yield put({
        type: "SET_FREADOM_FUTURE_RESULT",
        result: res,
      });
      if (res && res.result && res.result.length > 0)
        yield put({
          type: "SET_FREADOM_FUTURE_LIST",
          freadomFutureList: res.result,
        });
      yield put({
        type: "SET_FETCH_FREADOM_FUTURE_LIST",
        value: false,
      });
    } catch (err) {
      errorHandler(err);
    }
  },
  
  REQUEST_CONCAT_FREADOM_FUTURE: function*({ offset }) {
    try {
      const gState = require("store").default.getState();
      const gradeId = gState.freadomFuture.active_freadom_future_grade;
      const search = gState.freadomFuture.search_reading;
      const level = gState.freadomFuture.active_freadom_future_level.value;
      const res = yield call(
        FreadomFutureApi.getFreadomFutureUnit,
        gradeId,
        level,
        offset,
        search
      );

      yield put({
        type: "SET_FREADOM_FUTURE_RESULT",
        result: res,
      });
      if (res && res.result && res.result.length > 0)
        yield put({
          type: "SET_CONCAT_READING_CHALLENGE_LIST",
          freadomFutureList: res.result,
        });
    } catch (err) {
      errorHandler(err);
    }
  },

  REQUEST_FREADOM_FUTURE: function*() {
    try {
      yield put({
        type: "SET_FETCH_FREADOM_FUTURE_LIST",
        value: true,
      });

      const gState = require("store").default.getState();
      const gradeId = gState.freadomFuture.active_freadom_future_grade;
      const search = gState.freadomFuture.search_reading;
      const level = gState.freadomFuture.active_freadom_future_level.value;
      const res = yield call(
        FreadomFutureApi.getFreadomFutureUnit,
        gradeId,
        level,
        0,
        search
      );
      yield put({
        type: "SET_FREADOM_FUTURE_RESULT",
        result: res,
      });
      if (res && res.result && res.result.length > 0)
        yield put({
          type: "SET_FREADOM_FUTURE_LIST",
          freadomFutureList: res.result,
        });
    } catch (err) {
      errorHandler(err);
    } finally {
      yield put({
        type: "SET_FETCH_FREADOM_FUTURE_LIST",
        value: false,
      });
    }
  },

  CREATE_FREADOM_FUTURE_UNIT: function*({ unitData }) {
    try {
      yield call(FreadomFutureApi.createFreadomFutureUnit, unitData);
    } catch (err) {
      errorHandler(err);
    }
  },

  //.......................TASK CRUD ..............................

  REQUEST_FREADOM_FUTURE_TASK_LIST : function*({ unitId }) {
    try {
      const res = yield call(FreadomFutureApi.requestFreadomFutureTaskList, unitId);
      yield put({
        type: "SET_FREADOM_FUTURE_TASK_LIST",
        taskList: res,
      });

    } catch (err) {
      errorHandler(err);
    }
  },

  CREATE_FREADOM_FUTURE_TASK: function*({ taskData }) {
    try {
      yield call(FreadomFutureApi.createFreadomFutureTask, taskData);
    } catch (err) {
      errorHandler(err);
    }
  },

});
