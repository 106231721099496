import { Api } from "./base";

const _headers = () => {
  return {
    Authorization:
      "JWT " + require("store").default.getState().onboard.user.auth_token,
    "Content-Type": "application/json"
  };
};

export default {
  
  //..................................UNIT CRUD.................

  getFreadomFutureUnit(gradeId, difficultId,offset,search) {
    if (search !== "")
     // add search param 
      return Api.getCsv(
        `/freadom-future/v1/admin-ff-unit/?&grade_id=${gradeId}&difficulty_level_id=${difficultId}`,
        _headers()
      );

    return Api.getCsv(
      `/freadom-future/v1/admin-ff-unit/?&grade_id=${gradeId}&difficulty_level_id=${difficultId}&offset=${offset}`,
      _headers()
    );
  },

  createFreadomFutureUnit(unitData){
    return Api.post(`/freadom-future/v1/admin-ff-unit/`, unitData, _headers());
  },


  //.........................TASK CRUD......................
  requestFreadomFutureTaskList(unitId) {
    return Api.get(`/freadom-future/v1/admin-ff-task/?unit_id=${unitId}`, _headers());
  },

  createFreadomFutureTask(taskData){
    return Api.post(`/freadom-future/v1/admin-ff-task/`, taskData, _headers());
  },

  getContentSearchList(searchValue,searchIndex) {
    return Api.get(`/global-search/v1/?query=${searchValue}&index_type=${searchIndex}`, _headers());
  },

};
