export default {
    //SET FUNCTION OF PAYMENT POP UP
    SET_PLAN_INFO: (state, { plan }) => (state.plan_info = plan),

    // CRUD FOR PAYMENTS LIST
    SET_PAYMENT_LIST: (state, result) => (state.payemnt_list = result),
    SET_PAYMENT_LIST_RESULT: (state, { result }) => (state.payment_list_result = result),
    SET_CONCAT_PARENT_LIST: (state, result) => (state.payemnt_list = result),
    SET_SEARCH_PAYMENT: (state, { search }) => (state.search_payment = search),
    SET_PAYMENT_TYPE: (state, { paymentType }) => (state.payment_type = paymentType),
    SET_PAYMENT_MODE: (state, { mode }) => (state.payment_mode = mode),
    SET_ACTIVE_PAYMENT_DATE: (state, { date }) => (state.payment_date = date),

    //MISC FOR THE PAYMENTS
    SET_FETCH_PAYMENT_LIST: (state, { value }) => (state.fetch_payment_list = value),
    SET_PAYMENT_OFFSET: (state, { offset }) => (state.payment_offset = offset),

    //CRUD FOR CHILD SUBSCRIPTION LIST

    SET_FETCH_SUBSCRIPTION_LIST: (state, { value }) => (state.fetch_subscription_list = value),
    SET_SUBSCRIPTION_LIST: (state, { result }) => (state.subscription_list = result),
    SET_SUBSCRIPTION_LIST_RESULT: (state, { result }) => (state.subscription_list_result = result),
    SET_CONCAT_SUBSCRIPTION_LIST: (state, { result }) => (state.subscription_list = state.subscription_list.concat(result)),
    SET_ACTIVE_SUBSCRIPTION: (state, { subscription }) => (state.active_subscription = subscription),

    //CRUD FOR CHILD PAYMENT LIST 
    SET_FETCH_SUB_PAYMENT_LIST: (state, { value }) => (state.fetch_sub_payment_list = value),
    SET_SUB_PAYMENT_LIST_RESULT: (state, { result }) => (state.sub_payment_list_result = result),
    SET_SUB_PAYMENT_LIST: (state, { result }) => (state.sub_payment_list = result),
    SET_CONCAT_SUB_PAYMENT_LIST: (state, { result }) => (state.sub_payment_list = state.sub_payment_list.concat(result)),

    // FOR GENERATING PAYMENT LINK
    SET_PHONE_NUMBER: (state, { phoneNumber }) => (state.phoneNumber = phoneNumber),
    SET_WEB_PLANS_LIST: (state, { plans }) => (state.webPlans = plans),
    SET_CHILD_LIST_FOR_PAYMENT_LINK: (state, { childList }) => (state.childList = childList),
    SET_SELECTED_CHILD_FOR_PAYMENT_LINK: (state, { child }) => (state.selectedChild = child),
    SET_SELECTED_PLAN_FOR_PAYMENT_LINK: (state, { plan }) => (state.selectedPlan = plan),
    SET_VALIDATED_PROMO_CODE: (state, { promoCode }) => (state.validatedPromoCode = promoCode),
    SET_PAYMENT_LINK: (state, { paymentLink }) => (state.paymentLink = paymentLink)

}

