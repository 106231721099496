export default {
    fetch_freadom_future_list:false,
    active_freadom_future_grade:{},
    freadom_future_level_list : [],
    active_freadom_future_level: [],
    // active_reading_challenge:null,
    // start_date:'',
    // end_date:'',
    // reading_offset:0,
    freadom_future_result:null,
    // fetchingReadingChallenge:false,
    freadomFutureList:[],
    // new_sections: [],
    // current_editor_type :'',

    //..........TASK STATE................
    freadom_future_task_list : [],
    active_task: null,

    // //MISC
    search_reading:''
}
