import React, { Component } from "react";
import { CaseStudiesListContainer } from "./CaseStudyView.styled";
import CaseStudyCard from "./case-study-card/CaseStudyCard";
import {
  MainBodyContentContainer,
  PlusButton,
  PlusIcon,
    LoadingContainer
} from "@sc/custom.styled";
import { module as caseStudy } from "@st/caseStudy";
import { module as ui } from "@st/ui";
import { connectStore } from "redux-box";
import { module as onboard } from "@st/onboard";
import Permission from "@s/permission";
import swal from "sweetalert2";
// export default connectStore({
//   ui,
//   caseStudy
// })

// export default 
class CaseStudyView extends Component {
  constructor(props) {
    super();
    this.state = {
      caseStudiesList: []
    };
  }

  componentDidMount() {
    const { caseStudy } = this.props;
    caseStudy.requestCaseStudies();
  }

  handleAddClickButton = e => {
    const {permission} = this.props.onboard;
    if(Permission.getPermission(permission, "add_testimonial")){
      this.props.ui.showModal("CASESTUDYFORM");
    }else{
      swal({
        text: "You do not have permission.",
        type: "warning"
      });
    }
  };

  render() {
    const isCreatorOpen = this.props.location.pathname === "/case-studies/new";
    let { caseStudy } = this.props;
    return (
      <MainBodyContentContainer primary style={{padding:'5px 0'}}>
          {this.props.caseStudy.fetch_case_studies_list?
              <LoadingContainer>
                  <img alt="loading" width="60" height="60"  src={require("static/img/Theme-loader.gif")}/>
              </LoadingContainer>:
              <CaseStudiesListContainer>
                  {caseStudy.caseStudies && caseStudy.caseStudies.length !== 0 &&
                   caseStudy.caseStudies.map((caseStudyInfo, id) => (
                   <CaseStudyCard key={caseStudyInfo.id} caseStudyInfo={caseStudyInfo} />
                   ))}
            </CaseStudiesListContainer>}
            
            <PlusButton
              hide={this.props.ui.show_modal}
              onClick={this.handleAddClickButton}>
              <PlusIcon
                primary
                alt="Plus Icon"
                cross={isCreatorOpen}
                src={require("static/img/plusicon.png")}
              />
            </PlusButton>
      </MainBodyContentContainer>
    );
  }
}

export default connectStore({
  ui,onboard,caseStudy
 })(CaseStudyView)
