export default {
    //............MISC........
    current_editor_type: '',
    current_story_type: 'Audio',
    current_story_main_type: '',
    fetch_story_list: false,
    fetch_media_loading: false,
    fetch_images_loading: false,
    load_set_top_story: false,
    story_offset: 0,
    stories_result: null,
    selected_collections: '',
    selected_sources: '',
    //........collections....
    active_collection: null,
    collection_list: [],
    fetch_collections: false,

    //..........story........
    story_list: [],
    active_story: null,
    search_story: '',
    active_story_grade_level: 'Level 1',
    active_story_level: 'Easy',
    questions: [],
    story_collections: [],
    active_question: {},
    active_options: [],
    new_question: {
        name: "",
        answer: "",
        level: "easy"
    },
    new_options: [],
    active_mcq: null,
    fetch_active_story: false,
    filterGradeSort: '',
    activeFilter: [],
    story_date: '',
    story_type: '',
    storyStatus: '',

    //.....section
    new_sections: [],
    current_section_action: "create",
    active_section: {},
    new_section: null,


    //...Experineces
    active_experience: null,
    fetch_active_story_experience: false,
    fetch_story_experience_list: false,
    story_experience_list: [],


}
