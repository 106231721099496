import styled from "styled-components";
import { ActivityPopUpContainer } from "static/styled/ActivityModal.styled";

export const AddMediaPopUpContainer = ActivityPopUpContainer.extend`
  padding: 14px 20px;
  padding-top: 0px;
`;
export const ActivityName = styled.p`
  font-size: var(--sm-font-size);
  color: rgba(38, 54, 81, 0.5);
  margin: 0px;
  padding-bottom: 0px;
`;

export const MediaSelectorMenu = styled.div`
  display: flex;
  justify-content: space-between;
  flex-flow: row nowrap;
  width: 90%;
  padding: 10px 0px;
  align-items: center;
`;
export const MediaSelectionItem = styled.div`
  position: relative;
  width: 12%;
  display: flex;
  color: ${props => props.textColor};
  cursor: pointer;
  align-items: center;
  i {
    font-size: 10px;
  }
  span {
    margin-left: 4px;
    font-size: var(--xsm-font-size);

    font-weight: 500;
  }
`;
export const UploadFileContainer = styled.div`
    position:relative;
    display:flex;
    justify-content-center;
    width:100%;
        justify-content:center;
         height:130px;
    align-items:center;
    border: dashed 1px rgba(179, 185, 194, 1);
    border-radius: 6px;
    `;

export const UploadFileLabelContainer = styled.div`
  display: flex;

  align-items: center;
  justify-content: center;

  flex-flow: column nowrap;
  i {
    margin-bottom: 5px;
    color: #b3b9c2;
    font-size: 60px;
  }
`;

export const UploadLabel = styled.div`
  display: inline-block;

  font-size: var(--xsm-font-size);
  font-weight: 500;
  color: #929aa8;
`;

export const BrowseContainer = styled.div`
  display: inline;
  color: var(--secondary-color);
  font-weight: 500;
  cursor: pointer;
  input {
    position: absolute;
    top: 0;
    opacity: 0;
    cursor: pointer;
    width: 200px;
    height: 130px;
    left: 71px;
  }
`;



export const PhotoAlbumContainer = styled.div`
  padding: 8px 0px;
 
  span {
    color: #000;
    font-weight: bold;
    font-size: var(--sm-font-size);
  }
`;

export const PhotoAlbumListContainer = styled.div`
  display: flex;
  flex-flow: row wrap;
  margin-top: 10px;
`;

export const PhotoAlbumItem = styled.div`
  width: 40px;
  height: 40px;
  position: relative;
  border: 0px;
  outline: none;
  border-radius: 4px;
  margin-right:14px;
   border:1px solid rgba(0,0,0,0.1);
   background-image: url("http://photokonkurs.russia.travel/images/icons/preloader.gif");
   background-size:18px;
   background-position:center;
   background-repeat:no-repeat;
`;

export const PhotoImage = styled.img`
  max-width: 100%;
  height: 40px;
  border-radius: 4px;
  background-repeat: no-repeat;
  background-position: center;
  width: 40px;
`;

export const DeleteItemContainer = styled.img`
    cursor:pointer;
    position:absolute;
    top:-5px;
    background-color:white;
    border:1px solid white;
    border-radius:100%;
    width:16px;
    height:16px;
    left:28px;
    cursor: pointer;
    background-repeat:no-repeat;
    `;










