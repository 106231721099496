import { createSagas } from "redux-box";
import swal from "sweetalert2";
import InviteCodeApi from "@s/api/inviteCode";
import { call, put } from "redux-saga/effects";
const errorHandler = (err) => {
  console.log(err);
  swal("Oops!", err.error_message, "error");
};

const getData = (res) => {
  let result = res.map((el) => {
    el.value = el.name;
    el.label = el.name;
    return el;
  });
  return result;
};

export default createSagas({
  REQUEST_SCHOOL_LIST: function*() {
    try {
      const res = yield call(InviteCodeApi.getSchoolList);
      let result = getData(res);
      yield put({
        type: "SET_SCHOOL_LIST",
        schoolList: result,
      });
    } catch (err) {
      errorHandler(err);
    } finally {
      // yield put({
      //   type: "SET_FETCH_ALL_EXPERIENCE_LIST",
      //   val: false
      // });
    }
  },
  REQUEST_PROJECT_LIST: function*() {
    try {
      // debugger;
      const res = yield call(InviteCodeApi.getProjectList);
      console.log("Res form data::::", res);
      let result = getData(res.data.data);
      console.log("result", result);
      yield put({
        type: "SET_PROJECT_LIST",
        projectList: result,
      });
    } catch (err) {
      errorHandler(err);
    } finally {
      // yield put({
      //   type: "SET_FETCH_ALL_EXPERIENCE_LIST",
      //   val: false
      // });
    }
  },
  REQUEST_SLOT_LIST: function*() {
    try {
      console.log("In sagas req", res);

      const res = yield call(InviteCodeApi.getSlotList);
      let result = getData(res.data.data);
      // console.log("result", result);

      yield put({
        type: "SET_SLOT_LIST",
        slotList: result,
      });
    } catch (err) {
      errorHandler(err);
    } finally {
      // yield put({
      //   type: "SET_FETCH_ALL_EXPERIENCE_LIST",
      //   val: false
      // });
    }
  },
  REQUEST_CLASS_LIST: function*({ schoolId }) {
    try {
      const res = yield call(InviteCodeApi.getClassList, schoolId);
      let result = getData(res);
      yield put({
        type: "SET_CLASS_LIST",
        classList: result,
      });
    } catch (err) {
      errorHandler(err);
    } finally {
      // yield put({
      //   type: "SET_FETCH_ALL_EXPERIENCE_LIST",
      //   val: false
      // });
    }
  },
  REQUEST_SECTION_LIST: function*({ data }) {
    try {
      const res = yield call(
        InviteCodeApi.getSectionList,
        data.schoolId,
        data.classId
      );
      let result = getData(res);
      yield put({
        type: "SET_SECTION_LIST",
        sectionList: result,
      });
    } catch (err) {
      errorHandler(err);
    } finally {
      // yield put({
      //   type: "SET_FETCH_ALL_EXPERIENCE_LIST",
      //   val: false
      // });
    }
  },
  CREATE_RANDOM_INVITE_CODE: function*({ data }) {
    try {
      const res = yield call(InviteCodeApi.createRandomInviteCode, data);
      if (res.success === true) {
        swal("Done", "Invite code generated successfully", "success");
      } else {
        swal("Oops!", res.error.error_message);
      }
    } catch (err) {
      errorHandler(err);
    }
  },
  REQUEST_VERIFY_INVITE_CODE_CSV: function*({ data }) {
    try {
      const res = yield call(InviteCodeApi.verifyInviteCodeCsv, data);
      if (res) {
        const globalState = require("store").default.getState();
        let grade = globalState.activity.grades.filter(
          (data) => data.id === res.grade
        );
        res.grade_name = grade[0].name;
        yield put({
          type: "SET_INVITE_CODE_CSV_DETAIL",
          data: res,
        });
      } else {
        swal("Oops!", "Invite code doesn't exist !");
      }
    } catch (err) {
      errorHandler(err);
    }
  },
  REQUEST_SCHOOL_CODES_BY_OFFSET: function*({ offset }) {
    try {
      yield put({
        type: "SET_SCHOOL_CODES_OFFSET",
        offset: offset,
      });
      yield put({
        type: "REQUEST_SCHOOL_CODE_LIST",
      });
    } catch (err) {
      errorHandler(err);
    }
  },
  REQUEST_SCHOOL_CODE_LIST: function*() {
    try {
      const globalState = require("store").default.getState();
      let offset = globalState.inviteCode.schoolCodesOffset;
      let queryParam = "";

      if (offset > 0) {
        queryParam = "offset=" + offset;
      }

      const res = yield call(InviteCodeApi.getSchoolCodeList, queryParam);

      yield put({
        type: "SET_SCHOOL_CODE_LIST_LENGTH",
        count: res.count,
      });

      yield put({
        type: "SET_SCHOOL_CODE_LIST",
        data: res.result,
      });
    } catch (err) {
      errorHandler(err);
    }
  },
  REQUEST_SCHOOL_CODE_LIST_AFTER_UPDATE: function*() {
    try {
      const globalState = require("store").default.getState();
      let offset = globalState.inviteCode.schoolCodesOffset;
      let queryParam = "";

      if (offset > 0) {
        queryParam = "offset=" + offset;
      }

      const res = yield call(InviteCodeApi.getSchoolCodeList, queryParam);

      yield put({
        type: "SET_SCHOOL_CODE_LIST_LENGTH",
        count: res.count,
      });

      yield put({
        type: "SET_SCHOOL_CODE_LIST_AFTER_UPDATE",
        data: res.result,
      });
    } catch (err) {
      errorHandler(err);
    }
  },
  CREATE_SCHOOL_CODE: function*({ data }) {
    try {
      const res = yield call(InviteCodeApi.createSchoolCode, data);
      if (res.school_code.length > 0) {
        swal("Done", "School code generated successfully", "success");
      } else {
        swal("Oops!", res.error.error_message);
      }
      yield put({
        type: "UPDATE_SCHOOL_CODE_LIST",
        data: res,
      });
    } catch (err) {
      errorHandler(err);
    }
  },
  REQUEST_SCHOOL_SEARCH: function*({ data }) {
    try {
      const gState = require("store").default.getState();
      console.log("string", data);
      let queryParam = "";
      if (Object.keys(data).length > 0) {
        queryParam = data["name"]
          ? "name=" + data["name"]
          : "code=" + data["code"];
      }

      const res = yield call(
        InviteCodeApi.getSchoolCodeListOnSearch,
        queryParam
      );
      //if(res!==null)
      // yield put({
      //   type: "SET_SEARCH_RESULT",
      //   result: res
      // });
      if (res && res.result)
        yield put({
          type: "SET_SEARCH_RESULT",
          result: res.result,
        });
    } catch (err) {
      errorHandler(err);
    }
    // finally {
    //   yield put({
    //     type: "SET_FETCH_ACTIVITY_BY_GRADE",
    //     val: false
    //   });
    // }
  },
  SET_SELECTED_SCHOOL: function*({ data }) {
    try {
      yield put({
        type: "SET_SCHOOL_TO_EDIT",
        school: data,
      });
    } catch (err) {
      errorHandler(err);
    } finally {
    }
  },
  REQUEST_UPDATE_SCHOOL: function*({ data }) {
    try {
      const gState = require("store").default.getState();
      let school = gState.inviteCode.selectedSchool;
      console.log(school);
      const res = yield call(InviteCodeApi.updateSchoolCode, school.id, data);
      yield put({
        type: "REQUEST_SCHOOL_CODE_LIST_AFTER_UPDATE",
      });
    } catch (err) {
      errorHandler(err);
    } finally {
    }
  },
  ENABLE_SCHOOL_EDIT: function*({ value }) {
    try {
      yield put({
        type: "SET_SCHOOL_EDIT",
        value: value,
      });
    } catch (err) {
      errorHandler(err);
    } finally {
    }
  },
  SET_EDIT_SCHOOL: function*({ school }) {
    try {
      yield put({
        type: "SET_SCHOOL_EDIT_INFO",
        value: school,
      });
    } catch (err) {
      errorHandler(err);
    } finally {
    }
  },
  GET_SCHOOL_LIST: function*({ offset }) {
    try {
      const gState = require("store").default.getState();
      let searchString = gState.inviteCode.schoolSearch;
      let selectedSearchOption = gState.inviteCode.selectedSearchOption;
      yield put({
        type: "FETCH_SCHOOL_LIST",
        value: true,
      });
      let q = "";
      if (offset) {
        q = `offset=${offset}`;
      }
      if (searchString) {
        if (offset) {
          q = q + `&`;
        }
        q = q + `${selectedSearchOption}=${searchString}`;
      }
      const res = yield call(InviteCodeApi.getAdminSchoolList, q);
      yield put({
        type: "SAVE_SCHOOL_LIST_RESULT",
        school: res,
      });
      yield put({
        type: "SAVE_SCHOOL_LIST",
        school: res.result,
      });
    } catch (err) {
      errorHandler(err);
    } finally {
      yield put({
        type: "FETCH_SCHOOL_LIST",
        value: false,
      });
    }
  },
  CREATE_SCHOOL: function*({ schoolData }) {
    try {
      const res = yield call(InviteCodeApi.createSchool, schoolData);
      if (res.id) {
        yield put({
          type: "SET_SCHOOL_INFO",
          value: res,
        });
        yield put({
          type: "SHOW_MODAL",
          componentId: "ADDCLASSFORM",
        });
        // swal("Done", "School created successfully", "success");
      } else {
        swal("Oops!", res.error.error_message);
      }
      yield put({
        type: "GET_SCHOOL_LIST",
      });
    } catch (err) {
      errorHandler(err);
    }
  },
  UPDATE_SCHOOL: function*({ schoolId, schoolData }) {
    try {
      const res = yield call(InviteCodeApi.updateSchool, schoolId, schoolData);
      yield put({
        type: "SET_SCHOOL_INFO",
        value: null,
      });
      if (res.id) {
        yield put({
          type: "SET_SCHOOL_INFO",
          value: res,
        });
        swal("Done", "School updated successfully", "success");
      } else {
        swal("Oops!", res.error.error_message);
      }
      yield put({
        type: "GET_SCHOOL_LIST",
      });
    } catch (err) {
      errorHandler(err);
    }
  },
  CREATE_CLASS: function*({ classData }) {
    try {
      console.log(classData);
      const res = yield call(InviteCodeApi.createClass, classData);
      if (res) {
        swal({
          title: "Done",
          titleText: "Class added successfully",
          type: "success",
          toast: true,
          position: "top-right",
          timer: 2000,
        });
      } else {
        swal({
          title: "Error",
          titleText: "Class not added successfully",
          type: "error",
          toast: true,
          position: "top-right",
          timer: 2000,
        });
      }
    } catch (err) {
      swal({
        title: "Error",
        titleText: "Class not added successfully",
        type: "error",
        toast: true,
        position: "top-right",
        timer: 2000,
      });
      errorHandler(err);
    }
  },
  GET_CLASS: function*({ classData }) {
    try {
      if (Object.keys(classData).length > 0) {
        let url = Object.keys(classData)
          .map(function(k) {
            return (
              encodeURIComponent(k) + "=" + encodeURIComponent(classData[k])
            );
          })
          .join("&");
        console.log(url);
        const res = yield call(InviteCodeApi.getClass, url);
        yield put({
          type: "GET_CLASS_RESULT",
          result: res,
        });
      }
    } catch (err) {
      errorHandler(err);
    }
  },
  SET_SEARCH_STRING_FUN: function*({ value }) {
    try {
      if (Object.keys(value).length > 0) {
        yield put({
          type: "SET_SEARCH_STRING",
          value: value,
        });
        // setTimeout(() => {
        yield put({
          type: "GET_SCHOOL_LIST",
        });
        // }, 10);
      }
    } catch (err) {
      errorHandler(err);
    }
  },

  GET_REPORT_DATA: function*(value) {
    console.log("value>>>>>", value);
    try {
      const res = yield call(InviteCodeApi.getReport, value.schoolInfo);
      console.log("result>>>>>>>", res);
      yield put({
        type: "SET_REPORT_DATA",
        value: res,
      });
    } catch (err) {
      errorHandler(err);
    }
  },

  CLEAR_REPORT_DATA: function*(){
    console.log("clear value will unmount>>>>>");
    try {
      yield put({
        type: "SET_REPORT_DATA",
        value: [],
      });
    } catch (err) {
      errorHandler(err);
    }
  },

  GET_SCHOOL_GRADE: function*({schoolId}){
    try {
      const res = yield call(InviteCodeApi.getSchoolGrade, schoolId);
      console.log("res======",res);
      yield put({
        type: "SET_SCHOOL_GRADE",
        value: res,
      });
    } catch (err) {
      errorHandler(err);
    }
  },

  UPDATE_SCHOOL_GUIDED_PATH: function*({schoolGuidedPathObj}){
    const {schoolInfo,...schoolGuidedPathObject} = schoolGuidedPathObj
    try {
      const res = yield call(InviteCodeApi.updateSchoolGuidedPath, schoolGuidedPathObject);
      if(res.id){
        // if(schoolGuidedPathFormData.guided_path !== schoolGuidedPathObj.guided_path || schoolGuidedPathFormData.grade_ids)
        yield put({
          type:"UPDATE_SCHOOL",
          schoolId:schoolInfo.id,
          schoolData:schoolInfo
        })
        return true;
      }else
      {
        return false;
      }
    } catch (err) {
      errorHandler(err);
      return false;
    }
  },

  GET_ALL_GRADES : function*(){
    try {
      const res = yield call(InviteCodeApi.getAllGrades);
      yield put({
        type: "SET_ALL_GRADES",
        value: res,
      });
    } catch (err) {
      errorHandler(err);
    }
  },

  GET_SCHOOL_IRO : function*({school}){
    try {
      const res = yield call(InviteCodeApi.getSchoolIro, school);
      yield put({
        type: "SET_SCHOOL_IRO",
        data: res,
      });
    } catch (err) {
      errorHandler(err);
    }
  },

  UPDATE_SCHOOL_IRO : function*({data}){
    try {
      const res = yield call(InviteCodeApi.updateSchoolIro, data);
      yield put({
        type: "SET_SCHOOL_IRO",
        data: res,
      });
    } catch (err) {
      errorHandler(err);
    }
  },

});
