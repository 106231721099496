import styled from "styled-components";
import { FlexBetween } from "static/styled/custom.styled";

export const TopNav = FlexBetween.extend`
  position: absolute;
  background-color: white;
  left: 0;
  top: 0;
  z-index: 100;
  width: 100%;
  padding: 0px;
  padding-top: 5px;
`;


export const ActivityComponentInfoContainer = styled.div`
  overflow-y: auto;
  height: 100%;
  padding: 70px 20px 0 20px;
  overflow-x: hidden;
`;
