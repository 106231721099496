import styled from "styled-components";

export const CollectionEditorMainContainer=styled.div`
width:100%;
  border-radius: 10px;
  background: white;

 position:relative;
 height:100%;
    overflow-y: auto; 
    padding:14px;
   
  
`;
