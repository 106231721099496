import { createSagas } from "redux-box";
import swal from "sweetalert2";
import { call, put } from "redux-saga/effects";
import MiscApi from "@s/api/misc";
const errorHandler = err => {
  console.log(err);
  swal("Oops!", err.error_message, "error");
};
const getData = (res) => {
  let result = res.map((el) => {
    el.value = el.name;
    el.label = el.name;
    return el;
  });
  return result;
};
export default createSagas({
  REQUEST_ANALYTICS_STORY: function*() {
    try {
      const res = yield call(MiscApi.getAnalyticsStory);
      if (res.success) {
        swal("Success", res.result.message);
      }
    } catch (err) {
      errorHandler(err);
    }
  },
  REQUEST_PROJECTS_LIST: function*() {
    try {
      const res = yield call(MiscApi.getProjects);
     
      let result = getData(res.result);
      
      yield put({
        type: "SET_TIMEKIT_PROJECT_LIST",
        projectList: result,
      });
    } catch (err) {
      errorHandler(err);
    }
  }
});
