import React from "react";
import { Route, Switch, withRouter } from "react-router-dom";
import CreateNotificationContainer from "@c/notifications-view/create-notification-container/CreateNotificationContainer";
import FilterNotificationContainer from "@c/notifications-view/filter-notification-container/FilterNotificationContainer";
export const Routes = function({ location }) {
  return (
    <Switch location={location}>
      <Route
        path={"/notifications/scheduled/edit/:id"}
        render={props => <CreateNotificationContainer {...props} />}
      />
      <Route
        path={"/notifications/:type/create"}
        render={props => <CreateNotificationContainer {...props} />}
      />
      <Route
        path={"/notifications/:type"}
        render={props => <FilterNotificationContainer {...props} />}
      />
    </Switch>
  );
};

export default withRouter(Routes);
