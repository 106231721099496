import React, { Component } from "react";
import {
  CollectionListContainer,
  CollectionPopUpContainer,
  CollectionCreatorContainer,
  CollectionItem,
  CollectionListMainContainer,
  CollectionToolbar,
  TitleInputField,
  UploadCollectionImageButton,
} from "./StoryCollectionCreator.styled";
import {
  ThemeButton,
  SubHeading,
  AlignContentHorizontal,
  ImageLoader,
  FlatBackdrop,
  LoadingContainer,
} from "static/styled/custom.styled";
import { module as ui } from "@st/ui";
import { module as story } from "@st/story";
import { uploadFile } from "@s/api/file";
import { connectStore } from "redux-box";
import {
  ChangeImageButton,
  ImageContainer,
  SelectFileIn,
  UploadActivityImageContainer,
} from "@c/activity-view/activity-creation-form/ActivityCreationForm.styled";
import swal from "sweetalert2";
import { NavigationHeaderContainer } from "static/styled/NavigationBar.styled";


class StoryCollectionCreator extends Component {
  constructor(props) {
    super();
    this.state = {
      imageChange: false,
      enableLoad: false,
      collectionEdit: false,
      collection_info: {
        name: "",
        image: "",
        thumbnail: "",
      },
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.story && nextProps.story.active_collection) {
      this.normalizeForState(nextProps.story.active_collection);
    } else {
      this.setState({
        collection_info: {
          name: "",
          image: "",
          thumbnail: "",
        },
      });
    }
  }

  normalizeForState = (active_collection) => {
    this.setState({
      collection_info: {
        name: active_collection.name,
        image: active_collection.image || null,
        thumbnail: active_collection.thumbnail,
      },
    });
  };

  handleCollectionNameChange = (evt) => {
    this.setState({
      collection_info: {
        ...this.state.collection_info,
        name: evt.target.value,
      },
    });
  };

  handleImageChange = (e) => {
    // e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];
    let fileSize = e.target.files[0].size / 1024 / 1024;
    if (fileSize > 2) {
      swal("Oops!", "File size greater than 2MB ", "warning");
    } else {
      reader.onloadend = () => {
        this.setState({
          imageChange: false,
          enableLoad: true,
          collection_info: {
            ...this.state.collection_info,
            image: reader.result,
            
          },
        });
      };
      reader.readAsDataURL(file);
      uploadFile(file).then((res) => {
        this.setState({
          collection_info: { ...this.state.collection_info, image: res.file },
          enableLoad: false,
        });
      });
    }
  };
  handleEmojiChange = (e) => {
    // e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];
    let fileSize = e.target.files[0].size / 1024 / 1024;
    if (fileSize > 2) {
      swal("Oops!", "File size greater than 2MB ", "warning");
    } else {
      reader.onloadend = () => {
        this.setState({
          imageChange: false,
          enableLoad: true,
          collection_info: {
            ...this.state.collection_info,
            thumbnail: reader.result,
            
          },
        });
      };
      reader.readAsDataURL(file);
      uploadFile(file).then((res) => {
        this.setState({
          collection_info: { ...this.state.collection_info, thumbnail: res.file },
          enableLoad: false,
        });
      });
    }
  };

  componentDidMount() {
    let { story } = this.props;
    story.requestCollections();
  }

  componentWillUnmount() {
    this.props.story.setActiveCollection(null);
  }

  handleImageFocus = (actionType) => {
    if (actionType === "over" && this.state.imageChange === false)
      this.setState({
        imageChange: true,
      });
    else if (actionType === "out" && this.state.imageChange === true)
      this.setState({
        imageChange: false,
      });
  };

  updateCollection = () => {
    
    if (this.state.collection_info.name !== "")
      this.props.story.requestUpdateCollection(this.state.collection_info);
    
    else {
      swal("Oops!", "You forget to enter the collection name", "warning");
    }
  };

  createCollection = () => {
    if (this.state.collection_info.name !== "") {
      this.props.story.requestCreateCollection(this.state.collection_info);
      this.setState({
        collection_info: {
          name: "",
          image: "",
          thumbnail:""
        },
      });
    } else {
      swal("Oops!", "You forget to enter the collection name", "warning");
    }
  };

  render() {
    //let a=[1,2,3,4,5,6,7,8,9,10,11,12,13];
    let collectionList = this.props.story.collection_list && (
      <AlignContentHorizontal>
        {this.props.story.collection_list.map((collection, index) => (
          <div className="col-sm-8 col-md-5 col-lg-6" key={index}>
            <CollectionItem
              key={index}
              onClick={() => this.props.story.setActiveCollection(collection)}
              
              bg={
                collection.image !== "" || !collection.image
                  ? collection.image
                  : ""
              }
              
            > 
              <FlatBackdrop
                style={{ backgroundColor: "rgba(67,38,102,0.5)" }}
              />
              <SubHeading
                style={{ fontWeight: "normal", color: "white", zIndex: "10" }}
              >
                {" "}
                {collection.name}
              </SubHeading>
              <CollectionToolbar></CollectionToolbar>
            </CollectionItem>
          </div>
        ))}
      </AlignContentHorizontal>
    );
    return (
      <CollectionPopUpContainer style={{ overflowY: "hidden" }}>
        <CollectionListMainContainer>
          <NavigationHeaderContainer shadow>
            Collections{" "}
          </NavigationHeaderContainer>
          <CollectionListContainer>
            {this.props.story.fetch_collections ? (
              <LoadingContainer>
                <div>
                  <img
                    alt="loading"
                    width="60"
                    height="60"
                    src={require("static/img/Theme-loader.gif")}
                  />
                </div>
              </LoadingContainer>
            ) : (
              collectionList
            )}
          </CollectionListContainer>
        </CollectionListMainContainer>
        <CollectionCreatorContainer>
          <NavigationHeaderContainer shadow>
            {this.props.story.active_collection
              ? "Edit Collection"
              : "Add Collections"}
          </NavigationHeaderContainer>
          <CollectionListContainer>
            <div style={{ padding: "10px 15px" }}>
              {this.state.collection_info.image === "" ||
              !this.state.collection_info.image ? (
                <div
                  class="flex-container "
                  style={{
                    display: "flex",
                    // justifyContent: "space-between",
                    // alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      marginRight: "5px",
                    }}
                  >
                    <UploadActivityImageContainer
                      style={{
                        backgroundColor: "rgba(67, 38, 102,0.1)",
                        borderRadius: "4px",
                        height: "140px",
                      }}
                    >
                      <UploadCollectionImageButton
                        type="button"
                        style={{ backgroundColor: "transparent" }}
                      >
                        <i className="fa fa-cloud-upload" />
                        <div>Upload Image </div>
                      </UploadCollectionImageButton>

                      <SelectFileIn
                        style={{ height: "140px" }}
                        type="file"
                        onChange={(e) => this.handleImageChange(e)}
                      />
                    </UploadActivityImageContainer>
                  </div>
                  <div>
                    <UploadActivityImageContainer
                      style={{
                        backgroundColor: "rgba(67, 38, 102,0.1)",
                        borderRadius: "4px",
                        height: "140px",
                      }}
                    >
                      <UploadCollectionImageButton
                        type="button"
                        style={{ backgroundColor: "transparent" }}
                      >
                        <i className="fa fa-cloud-upload" />
                        <div>Upload Emoji </div>
                      </UploadCollectionImageButton>

                      <SelectFileIn
                        style={{ height: "140px" }}
                        type="file"
                        onChange={(e) => this.handleImageChange(e)}
                      />
                    </UploadActivityImageContainer>
                  </div>
                </div>
              ) : this.state.enableLoad ? (
                <UploadActivityImageContainer style={{ height: "140px" }}>
                  <ImageLoader>
                    {" "}
                    <img
                      alt="loading"
                      width="30"
                      height="30"
                      src="http://photokonkurs.russia.travel/images/icons/preloader.gif"
                    />
                  </ImageLoader>
                </UploadActivityImageContainer>
              ) : (
                <div
                  class="flex-container "
                  style={{
                    display: "flex",
                    // justifyContent: "space-between",
                    // alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      marginRight: "5px"
                    }}
                  >
                    <UploadActivityImageContainer
                      style={{ height: "140px", display: "flex-box" }}
                      onMouseOver={() => this.handleImageFocus("over")}
                      onMouseOut={() => this.handleImageFocus("out")}
                    >
                      <ImageContainer src={this.state.collection_info.image} />
                      <ChangeImageButton
                        top={this.state.imageChange ? "83%" : "100%"}
                        opacity={this.state.imageChange ? 1 : 0}
                      >
                        Change Image
                      </ChangeImageButton>
                      <SelectFileIn
                        style={{ height: "140px" }}
                        type="file"
                        onChange={(e) => this.handleImageChange(e)}
                      />
                    </UploadActivityImageContainer>
                  </div>
                  <div>
                    <UploadActivityImageContainer
                      style={{ height: "140px", display: "flex-box" }}
                      onMouseOver={() => this.handleImageFocus("over")}
                      onMouseOut={() => this.handleImageFocus("out")}
                    >
                      <ImageContainer src={this.state.collection_info.thumbnail} />
                      <ChangeImageButton
                        top={this.state.imageChange ? "83%" : "100%"}
                        opacity={this.state.imageChange ? 1 : 0}
                      >
                        Change Emoji
                      </ChangeImageButton>
                      <SelectFileIn
                        style={{ height: "140px" }}
                        type="file"
                        onChange={(e) => this.handleEmojiChange(e)}
                      />
                    </UploadActivityImageContainer>
                  </div>
                </div>
              )}
              <TitleInputField
                type="text"
                placeholder="Give it a title"
                onChange={this.handleCollectionNameChange}
                value={this.state.collection_info.name}
              />
              {this.props.story.active_collection ? (
                <ThemeButton primary onClick={this.updateCollection}>
                  {" "}
                  Save Collection
                </ThemeButton>
              ) : (
                <ThemeButton primary onClick={this.createCollection}>
                  Add Collection
                </ThemeButton>
              )}
            </div>
          </CollectionListContainer>
        </CollectionCreatorContainer>
      </CollectionPopUpContainer>
    );
  }
}
export default connectStore({
  ui,
  story,
})(StoryCollectionCreator)