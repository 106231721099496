import React, { Component } from "react";
import {
  ActivitiesListContainer,
  ActivitiesListHeader,
  ActivitiesListMainContainer
} from "./PackActivitiesList.styled";
import ActivityPackCard from "@c/tag-view/activity-pack-list/ActivityPackCard";
import { module as tags } from "@st/tags";
import { module as ui } from "@st/ui";
import { connectStore } from "redux-box";
// import swal from "sweetalert2";

 class PackActivitiesList extends Component {
  constructor(props) {
    super();
  }

  handleTagClick = tags => {
    this.props.tags.setActiveChildTag(tags);
  };

  render() {
    let childList = this.props.tags.childTagList;
    return (
      <ActivitiesListContainer>
        <ActivitiesListHeader>
          <span>Child Tags</span>
        </ActivitiesListHeader>
        {childList.result &&
          childList.result.length !== 0 && (
            <ActivitiesListMainContainer>
              {childList.result.map((tag, i) => {
                return (
                  <div
                    className="col-sm-6 col-md-4 col-lg-4"
                    key={i}
                    onClick={() => this.handleTagClick(tag)}
                  >
                    <ActivityPackCard tag={tag} />
                  </div>
                );
              })}
            </ActivitiesListMainContainer>
          )}
      </ActivitiesListContainer>
    );
  }
}

export default connectStore({
  tags,
  ui
})(PackActivitiesList)