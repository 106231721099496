import React, { Component } from "react";
import { Container, Pill, InfoBox } from "./InviteCodeSearch.styled";
import { InputBox } from "@sc/InputField.styled";
import { ThemeButton } from "@/static/styled/custom.styled";
import { connectStore } from "redux-box";
import { module as inviteCode } from "store/inviteCode";
import { module as activity } from "store/activity";
import { Link } from "react-router-dom";



class InviteCodeSearch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchText: ""
    };
  }

  handleSearchChange = e => {
    this.setState({
      searchText: e.target.value
    });
  };
  handleSearchClick = () => {
    if (this.state.searchText.trim().length > 0) {
      this.props.inviteCode.requestVerifyInviteCodeCsv(this.state.searchText);
    }
  };

  componentWillUnmount() {
    this.props.inviteCode.unsetInviteCodeCsvDetail({});
  }

  render() {
    let { inviteCode } = this.props;
    const Button = () => (
      <Link
        target={"_blank"}
        to={`/users/children/${inviteCode.inviteCodeDetail.child_id}`}
      >
        View Child Profile
      </Link>
    );

    return (
      <Container>
        <InfoBox>
          <div style={{ width: "100%", marginTop: "20px" }}>
            <InputBox
              style={{ textAlign: "center" }}
              type="text"
              placeholder="Enter Code"
              onfocus={true}
              value={this.state.searchText}
              onChange={this.handleSearchChange}
            />
            <div className="text-center">
              <ThemeButton primary onClick={this.handleSearchClick}>
                Search
              </ThemeButton>
            </div>
          </div>

          {inviteCode.inviteCodeDetail.name && (
            <div
              style={{
                borderTop: "1px solid #f4f4f4",
                padding: "20px 0",
                marginTop: "20px"
              }}
            >
              <div style={{ textTransform: "uppercase" }}>
                Code - {inviteCode.inviteCodeDetail.name}
              </div>
              <div>School - {inviteCode.inviteCodeDetail.school}</div>
              <div>Grade - {inviteCode.inviteCodeDetail.grade_name}</div>
              {inviteCode.inviteCodeDetail.wow_class_name !== "" && (
                <div>Class - {inviteCode.inviteCodeDetail.wow_class_name}</div>
              )}
              {inviteCode.inviteCodeDetail.wow_section_name !== "" && (
                <div>
                  Section - {inviteCode.inviteCodeDetail.wow_section_name}
                </div>
              )}
              <div
                className="text-center"
                style={{
                  paddingTop: "20px",
                  marginTop: "10px",
                  borderTop: "1px solid #f4f4f4"
                }}
              >
                <Pill>
                  {inviteCode.inviteCodeDetail.is_used ? "Used" : "Not Used"}
                </Pill>
                {inviteCode.inviteCodeDetail.is_used &&
                  inviteCode.inviteCodeDetail.child_id !== null && (
                    <span>
                      <Pill>
                        {inviteCode.inviteCodeDetail.is_valid
                          ? "Not Expired"
                          : "Expired"}
                      </Pill>
                      <div style={{ marginTop: "10px" }}>
                        <Button />
                      </div>
                    </span>
                  )}
              </div>
            </div>
          )}
        </InfoBox>
      </Container>
    );
  }
}
export default connectStore({
  inviteCode,
  activity
})(InviteCodeSearch)