import React, { Component } from "react";
import {
  AlignContentHorizontal,
  EmptyContainer,
  AutoContainer,
  LoadingContainer,
  InfiniteScrollParentContainer,
  InfiniteScrollLoader
} from "static/styled/custom.styled";
import {
  NavigationHeaderContainer,
  NavigationContainer
} from "static/styled/NavigationBar.styled";
import { connectStore } from "redux-box";
import { module as activity } from "@st/activity";
import { module as freadomFuture } from "@st/freadomFuture";
import { Link } from "react-router-dom";
import Select from 'react-select';
import swal from "sweetalert2";
import FreadomFutureCard from "./freadom-future-card/FreadomFutureCard";
import InfiniteScroll from "react-infinite-scroller";
import { SearchBarContainer } from "@c/users-view/MainContent.styled";


class FreadomFutureList extends Component {
  constructor(props) {
    super();
    this.state = {
      classList: [],
      searchBarEnable: false
    };
  }

  fetchMoreReadingChallenges = () => {
    if (
      this.props.freadomFuture.freadom_future_result !== null &&
      this.props.freadomFuture.freadom_future_result.has_next
    ) {
      setTimeout(() => {
        if (
          this.props.freadomFuture.freadom_future_result !== null &&
          this.props.freadomFuture.freadom_future_result.next_page > 0
        )
          this.props.freadomFuture.requestConcatFreadomFuture(
            this.props.freadomFuture.freadom_future_result.next_page
          );
      }, 100);
    }
  };
  handleGradeChange = grade => {
    this.props.freadomFuture.setFreadomFutureList([]);
    this.props.freadomFuture.requestFreadomFutureByGradeId(grade.id);
  };
  handlePublishClick = (freadomFuture, e) => {
    e.preventDefault();
    let value = null;
    if (freadomFuture.is_published) value = "unpublish";
    else value = "publish";

    swal({
      text:
        "Are you sure that you want to " + value + " this reading challenge?",
      type: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No"
    }).then(result => {
      if (result.value) {
        this.props.freadomFuture.requestUpdateReadingChallenge({
          ...freadomFuture,
          is_published: !freadomFuture.is_published
        });
      }
    });
    e.stopPropagation();
  };

  filterReadingChallenge = () => {
    return this.props.freadomFuture.freadomFutureList &&
      this.props.freadomFuture.active_freadom_future_grade
      ? this.props.freadomFuture.freadomFutureList.filter(freadomFuture => {
        return (
          freadomFuture.grade ===
          this.props.freadomFuture.active_freadom_future_grade &&
          this.props.freadomFuture.active_freadom_future_grade
        );
      })
      : [];
  };

  handleReadingSearch = evt => {
    let { freadomFuture } = this.props;
    freadomFuture.setSearchFreadomFuture(evt.target.value);
    setTimeout(() => {
      this.props.freadomFuture.requestFreadomFuture();
    }, 2000);
  };
  handleClearSearch = () => {
    let { freadomFuture } = this.props;
    freadomFuture.setSearchFreadomFuture("");
    freadomFuture.requestFreadomFuture();
  };

  enableSearchBar = actionType => {
    if (actionType === "focus" && this.state.searchBarEnable === false)
      this.setState({
        searchBarEnable: true
      });
    else if (actionType === "blur" && this.state.searchBarEnable === true)
      this.setState({
        searchBarEnable: false
      });
  };

  handleLevelChange = (selectLevel) => {
    this.props.freadomFuture.setActiveFreadomFutureLevel(selectLevel);
  };

  render() {
    let classList =
      this.props.activity.grades &&
      this.props.activity.grades.map((grade, i) => (
        <a
          key={i}
          className={
            this.props.freadomFuture.active_freadom_future_grade &&
              grade.id === this.props.freadomFuture.active_freadom_future_grade
              ? "active"
              : ""
          }
          onClick={() => this.handleGradeChange(grade)}
        >
          <span> {grade.name}</span>
        </a>
      ));

    let readingChallengeList = (
      <AlignContentHorizontal marginBottom="70px">
        { (this.props.freadomFuture.freadomFutureList && this.props.freadomFuture.freadomFutureList.length > 0) ? (
          this.props.freadomFuture.freadomFutureList.map((freadomFutureInfo, index) => {
            return (
              <div className="col-sm-6 col-md-4 col-lg-3" key={index}>
                <Link to={"/freadom-future/edit/" + freadomFutureInfo.id}>
                  <FreadomFutureCard
                    freadomFutureInfo={freadomFutureInfo}
                    challengeId={activity.id}
                    handlePublishClick={e =>
                      this.handlePublishClick(freadomFutureInfo, e)
                    }
                  />
                </Link>
              </div>
            );
          })
        ) : (
            <EmptyContainer>
              <h4>NO FREADOM FUTURE UNIT FOR THIS GRADE</h4>
            </EmptyContainer>
          )}
      </AlignContentHorizontal>
    );

    return (
      <AutoContainer>
        <NavigationHeaderContainer shadow>
          <NavigationContainer>{classList}</NavigationContainer>
            <Select
              value = {this.props.freadomFuture.active_freadom_future_level}
              onChange={this.handleLevelChange}
              options={this.props.freadomFuture.freadom_future_level_list}
            />
          <SearchBarContainer
            width={this.state.searchBarEnable ? "300px" : "200px"}
          >
            <i className="fa fa-search" />
            <input
              type="text"
              onChange={this.handleReadingSearch}
              placeholder="Search"
              value={this.props.freadomFuture.search_reading}
              onFocus={() => this.enableSearchBar("focus")}
              onBlur={() => this.enableSearchBar("blur")}
            />
            {this.props.freadomFuture.search_reading.length > 0 && (
              <i
                className="fa fa-times cross"
                onClick={() => this.handleClearSearch()}
              />
            )}
          </SearchBarContainer>
        </NavigationHeaderContainer>
        { this.props.freadomFuture.fetch_freadom_future_list ? (
          <LoadingContainer style={{ zIndex: "100" }}>
            <img
              alt="loading"
              width="60"
              height="60"
              src={require("static/img/Theme-loader.gif")}
            />
          </LoadingContainer>
        ) : this.props.freadomFuture.freadomFutureList.length > 0 ?
        (
            <InfiniteScrollParentContainer>
              <InfiniteScroll
                pageStart={0}
                loadMore={this.fetchMoreReadingChallenges}
                style={{ position: "relative" }}
                hasMore={
                  this.props.freadomFuture.freadom_future_result.has_next
                }
                loader={
                  <InfiniteScrollLoader className="loader" key={0}>
                    <img
                      alt="loader"
                      width="50"
                      height="50"
                      src={require("static/img/Theme-loader.gif")}
                    />
                  </InfiniteScrollLoader>
                }
                useWindow={false}
              >
                {readingChallengeList}{" "}
              </InfiniteScroll>
            </InfiniteScrollParentContainer>
        )
        :null
      }
      </AutoContainer>
    );
  }
}
export default connectStore({
  activity,
  freadomFuture
})(FreadomFutureList)