import { createSagas } from "redux-box";
import AssessmentApi from "@s/api/assessment";
import { call, put } from "redux-saga/effects";
//import { push } from "react-router-redux";
import swal from "sweetalert2";

const errorHandler = err => {
  //console.log(err);
  swal("Oops!", err.error_message, "error");
};

export default createSagas({
  REQUEST_CREATE_GRADE_LEVEL_TEST: function*({ grade }) {
    try {
      yield call(AssessmentApi.createLevelTest, grade);
      yield put({
        type: "REQUEST_GET_LEVEL_TESTS"
      });
    } catch (err) {
      errorHandler(err);
    }
  },
  REQUEST_GET_LEVEL_TESTS: function*() {
    try {
      yield put({
        type: "SET_FETCH_ASSESSMENT_LIST",
        value: true
      });
      const res = yield call(AssessmentApi.getLevelTests);
      yield put({
        type: "SET_ASSESSMENT_LIST",
        assessmentList: res
      });
    } catch (err) {
      errorHandler(err);
    } finally {
      yield put({
        type: "SET_FETCH_ASSESSMENT_LIST",
        value: false
      });
    }
  },
  REQUEST_GET_LEVEL_TEST_BY_ID: function*({ assessmentId }) {
    try {
      const res = yield call(AssessmentApi.getLevelTestById, assessmentId);
      yield put({
        type: "SET_ACTIVE_ASSESSMENT",
        activeAssessment: res
      });
      yield put({
        type: "REQUEST_GET_ALL_ASSESSMENT_QUESTIONS",
        levelId: assessmentId
      });
    } catch (err) {
      errorHandler(err);
    }
  },
  REQUEST_UPDATE_ASSESSMENT: function*({ data }) {
    try {
      const globalState = require("store").default.getState();
      let assessment_id;
      assessment_id = globalState.assessment.active_assessment.id;
      yield call(AssessmentApi.updateAssessment, assessment_id, data);
      yield put({
        type: "REQUEST_GET_LEVEL_TEST_BY_ID",
        assessmentId: assessment_id
      });
      yield put({
        type: "REQUEST_GET_LEVEL_TESTS"
      });
    } catch (err) {
      errorHandler(err);
    }
  },
  REQUEST_GET_ALL_ASSESSMENT_QUESTIONS: function*({ levelId }) {
    try {
      const res = yield call(AssessmentApi.getAllQuestion, levelId);
      yield put({
        type: "SET_ALL_ASSESSMENT_QUESTION",
        question: res
      });
    } catch (err) {
      errorHandler(err);
    }
  },
  REQUEST_CREATE_ASSESSMENT_QUESTION: function*({ question }) {
    try {
      const res = yield call(AssessmentApi.createQuestion, question);
      swal("Done!", "Created question!", "success");

      yield put({
        type: "REQUEST_GET_ALL_ASSESSMENT_QUESTIONS",
        levelId: res.level_test
      });
    } catch (err) {
      errorHandler(err);
    }
  },

  REQUEST_UPDATE_ASSESSMENT_QUESTION: function*({ mcq }) {
    try {
      const gState = require("store").default.getState();
      const questionId = gState.assessment.active_mcq.id;
      const res = yield call(AssessmentApi.updateQuestion, questionId, mcq);

      for (let i = 0; i < mcq.option.length; i++) {
        let option = mcq.option[i];
        yield AssessmentApi.updateOption(option.id, { name: option.name });
      }

      swal("Done!", "Updated question!", "success");
      yield put({
        type: "REQUEST_GET_ALL_ASSESSMENT_QUESTIONS",
        levelId: res.level_test
      });
    } catch (err) {
      errorHandler(err);
    }
  },
  REQUEST_DELETE_ASSESSMENT_QUESTION: function*({ question }) {
    try {
      yield call(AssessmentApi.deleteQuestion, question.id);
      swal("Done!", "The selected question was deleted!", "success");
      yield put({
        type: "REQUEST_GET_ALL_ASSESSMENT_QUESTIONS",
        levelId: question.level_test
      });
    } catch (err) {
      errorHandler(err);
    }
  }
});
