import React, { Component } from "react";
import {
  ActivityComponentInfoContainer,
  TopNav,
} from "@c/activity-view/activity-creator/ActivityCreator.styled";

import {
  ComponentContainer,
  SubContainer,
  CreatorComponent,
} from "@sc/Creator.styled";
import {
  SaveChangesButton,
  ThemeButton,
  LoadingContainer,
} from "static/styled/custom.styled";
import { connectStore } from "redux-box";
import QuizMcqEditor from "./quiz-mcq-editor/QuizMcqEditor";

import { module as activity } from "@st/activity";
import { module as quiz2 } from "@st/quiz2";
import { module as onboard } from "@st/onboard";
import Permission from "@s/permission";
import QuizInfo from "./quiz-info/QuizInfo";
import McqSection from "../../reading-challenge-view/reading-challenge-creator/mcq-section/McqSection";
import QuizMcqSection from "./mcq-section";
import QuizLinks from "./quiz-links/QuizLinks";

class QuizChallengeCreator extends Component {
  constructor(props) {
    super();
    this.state = {
      levelSelector: false,
      showSubContainer: false,
      activityDescription: "",
      sectionList: [],
      translate: "100%",
      sectionTitle: null,
    };
  }

  componentWillMount() {
    const { quiz2 } = this.props;
    quiz2.requestQuizById(this.props.match.params.id);
    quiz2.requestAllQuizQuestions(this.props.match.params.id);
  }
  componentDidMount() {
    setTimeout(() => {
      this.setState({
        opacity: 1,
        translate: "0",
      });
    }, 30);

    let history = this.props.history;
    //let location=this.props.location;
    document.addEventListener("keyup", function(e) {
      if (e.keyCode === 27 && window.location.pathname.includes("/quiz2")) {
        history.push("/quiz2");
      }
    });
  }
  componentWillUnmount() {
    //this.props.quiz2.resetNewReadingSections();
    this.props.activity.setEditorType("");
    this.props.quiz2.setActiveQuiz(null);
    this.props.quiz2.unsetActiveQuizQuestion();
  }

  handleShowSubContainer = (type, title = null) => {
    this.setState({ showSubContainer: type, sectionTitle: title });
  };
  hideSubContainer = () => {
    this.setState({ showSubContainer: false });
  };
  render() {
    let { quiz2, activity } = this.props;
    let { permission } = this.props.onboard;
    console.log("checking props in creator ", this.props);
    return (
      <CreatorComponent style={{ opacity: this.state.opacity }}>
        {" "}
        <ComponentContainer
          style={
            quiz2.active_quiz
              ? {}
              : { transform: `translateX(${this.state.translate})` }
          }
        >
          {this.props.quiz2.fetchingQuiz ? (
            <LoadingContainer
              style={quiz2.active_quiz ? { height: "50px" } : { top: 0 }}
            >
              <div>
                <img
                  alt="loading"
                  width="60"
                  height="60"
                  src={require("static/img/Theme-loader.gif")}
                />
              </div>
            </LoadingContainer>
          ) : null}
          <ActivityComponentInfoContainer>
            {quiz2.active_quiz && <QuizInfo active_quiz={quiz2.active_quiz} />}
            {quiz2.active_quiz && (
              <div>
                <QuizMcqSection
                  question_data={quiz2.questions}
                  handleShowSubContainer={this.handleShowSubContainer}
                />
                <QuizLinks
                  id={this.props.match.params.id}
                  applink={
                    quiz2.active_quiz != null &&
                    quiz2.active_quiz.deeplink != null
                      ? quiz2.active_quiz.deeplink
                      : ""
                  }
                />
              </div>
            )}
          </ActivityComponentInfoContainer>
        </ComponentContainer>
        {activity.current_editor_type && (
          <SubContainer active={activity.current_editor_type}>
            {/*when title is passed it creates a new one instead of updating active_section  */}
            {activity.current_editor_type === "text" && (
              <div></div>
              // <TextEditor
              //   new={this.state.sectionTitle}
              //   onDone={this.hideSubContainer}
              // />
            )}

            {activity.current_editor_type === "mcq" && (
              <QuizMcqEditor
                title={this.state.sectionTitle}
                onDone={this.hideSubContainer}
              />
            )}
          </SubContainer>
        )}
        {/* 
        <QuizLinks
          id={this.props.match.params.id}
          applink={
            quiz2.active_quiz != null && quiz2.active_quiz.deeplink != null
              ? quiz2.active_quiz.deeplink
              : ""
          }
        />
          */}
      </CreatorComponent>
    );
  }
}

export default connectStore({ activity, quiz2, onboard })(QuizChallengeCreator);
