import styled from "styled-components";
import { secondary, primary } from "static/styled/color.styled";
export const FileListContainer = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  margin-top: 10px;
  padding-bottom: 5px;
`;

export const FileLink = styled.div`
  cursor: pointer;
  margin: 0 15px 10px 0;
  a {
    text-decoration: none;
    color: ${primary};
    font-size: 40px;
    // width: 100%;
    // height: 100%;
    transition: all 0.3s;
    &:hover {
      color: ${secondary};
    }
  }
`;
