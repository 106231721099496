import styled from "styled-components";
import {FlexCenter, Heading} from "@sc/custom.styled"
export const PlanPopUpContainer=styled.div`
  position :relative;
  height:200px;


`;

export const PlanIconParentContainer=FlexCenter.extend`
position:absolute;
top:-30px;
width:100%;

right:0;


`;

export const PlanIconContainer=FlexCenter.extend`

background-color:#ee8a23;
justify-content:center;
border-radius:100px;
width:80px;
height:80px;
outine:none;
i {
   
    color:white;
    
    font-size:24px;
}
 `;

 export const PlanInfoContainer=styled.div`
 padding:30px;
 padding-top:50px;

  `;
 export const PlanDescription=styled.div`
 color:rgba(0,0,0,0.4);
 font-size:var(--lg-font-size);
 `;
 export const PlanName=Heading.extend`
 font-size:24px;
 color:rgba(0,0,0,0.9);
 font-weight:500;
 padding:0;
 margin:0;
 `;
 export const PlanCostContainer=styled.div`
 margin-top:10px;
 span {
   color:#ee8a23;
   font-size:24px;
   font-weight:bold;

 }
 font-size:var(--xlg-font-size);
 color:rgba(0,0,0,1);
 font-weight:500;
 `;


