export default {
  notification_result: null,
  notification_list: [],
  enableDoneBtn: false,
  create_success: false,
  active_notification: null,
  criteriaType: "People/Users",
  fetch_notification_list: false,
  fetch_active_notification: false,
  fetch_child_list: false,
  enableScroll: false,
  selected_first_child: "",
  unset_data: false,
  editor_type: "",
  selected_not_type: "scheduled",
  // for notification type email description
  email_description: "",
  notification_type: "sms",

  // DROPDOWN DATA VARIABLE
  wow_section_list: [],
  wow_school_list: [],
  grade_list: [],
  children_list: [],
  children_list_result: [],
  search_child: "",
  selected_all_data: "",
  selected_wow_data: "",
  selected_freadom_data: "",

  // LIST FOR THE DROPDOWNS SELECTED DATA INCLUDING FREADOM, WOW AND PEOPLE/USERS
  selected_sections: [],
  selected_schools: null,
  multiple_school_list: [],
  selected_levels: [],
  selected_users: [],
  selected_grades: [],
  selected_filter: "Grade",
  selected_grade_count: 0,
  selected_school_count: 0,
  selected_section_count: 0,
  selected_user_count: 0,

  // FILTER VARIABLES FOR SEARCHING
  search_notification: "",
  filter_user_type: "",
  filter_date: "",
  filter_msg_type: ""
};
