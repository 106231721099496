import React, { Component } from "react";
import { Pagination, PaginationButton } from "@c/users-view/MainContent.styled";
import {
  ScrollContainer,
  ScrollParentContainer,
  Container,
  SelectTagsListContainer,
  TagLabelContainer
} from "../TagSelector.styled";
// import {
//   ThemeButton,
//   ErrorField,
//   PanelHeader,
//   TagChipsContainer,
//   TagChips
// } from "@sc/custom.styled";
import { connectStore } from "redux-box";
import { module as tags } from "store/tags";

// export default connectStore({
//   tags
// })

class ParentTagSelector extends Component {
  // constructor(props) {
  //   super(props);
  // }
  componentWillMount() {
    this.props.tags.requestTagList();
  }

  handleNext = () => {
    // let { leaderBoard } = this.props;
    // if (leaderBoard.freadomPointLeaderBoardList.has_next) {
    //   leaderBoard.requestFreadomPointLeaderBoardList(
    //     leaderBoard.freadomPointLeaderBoardList.next_page
    //   );
    // }
  };
  handlePrevious = () => {
    // let { leaderBoard } = this.props;
    // if (
    //   leaderBoard.freadomPointLeaderBoardList.prev_page >= 0 &&
    //   leaderBoard.freadomPointLeaderBoardList.offset !== 0
    // ) {
    //   leaderBoard.requestfreadomPointLeaderBoardList(
    //     leaderBoard.freadomPointLeaderBoardList.prev_page
    //   );
    // }
  };

  handleTagClick = tag => {
    let { tags } = this.props;
    tags.requestChildTagListByParentId(tag.id);
    tags.addTagInSelectedTagList(tag);
  };
  render() {
    let { tagList,tagResult } = this.props.tags;
    return (
      <Container>
        <ScrollContainer>
          <ScrollParentContainer>
            {tagList &&
              tagList.length > 0 && (
                <SelectTagsListContainer>
                  {tagList.map((tag, i) => (
                    <TagLabelContainer
                      key={i}
                      onClick={() => this.handleTagClick(tag)}
                    >
                      <div>{tag.name}</div>
                    </TagLabelContainer>
                  ))}
                </SelectTagsListContainer>
              )}
          </ScrollParentContainer>
        </ScrollContainer>
        <Pagination>
          <PaginationButton
            color={tagResult && tagResult.offset !== 0 ? "#ff8a23" : "#d5d5d5"}
            onClick={this.handlePrevious}
          >
            <i className="fa fa-chevron-left" />
          </PaginationButton>
          <PaginationButton
            color={tagResult && tagResult.has_next ? "#ff8a23" : "#d5d5d5"}
            onClick={this.handleNext}
          >
            <i className="fa fa-chevron-right" />
          </PaginationButton>
        </Pagination>
      </Container>
    );
  }
}
export default connectStore({
  tags
 })(ParentTagSelector)