import styled from "styled-components";
import {
  AutoContainer,
  InfiniteScrollParentContainer
} from "@/static/styled/custom.styled";
export const Container = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-bottom: 1px solid #fff;
`;

export const ScrollContainer = AutoContainer.extend`
  top: 0;
  padding-bottom: 50px;
`;

export const ScrollParentContainer = InfiniteScrollParentContainer.extend`
  z-index: 0;
  padding-top: 0;
  padding-bottom: 120px;
  top: 40px;
`;
