import { createActions, using } from "redux-box";

export default createActions({
  setExperienceType: using("expType"),
  requestGetAllExperiencesList: using("offset"),
  setAllExperienceNext: using("data"),
  unsetAllExperiencesList: using(),
  setAllExperiencesResult: using("data"),
  requestDeleteAllExperienceById: using("experienceId"),
  requestPublishAllExperienceById: using("experience"),
  setActiveAllExperience: using("experience"),
  requestGetAllActiveExperienceById: using("experienceId"),
  setActiveExperienceDate: using('date'),
});
