import config from "../config";

const _headers = () => {
  return {
    Authorization:
      "JWT " + require("store").default.getState().onboard.user.auth_token,
    "file-content-type": "application/epub+zip",
  };
};

export const uploadFile = (image) => {
  let formData = new FormData();
  formData.append("file", image);
  return new Promise((resolve, reject) => {
    fetch(config.api_url + "/misc/v1/media/", {
      method: "POST",
      body: formData,
    })
      .then((res) => {
        return res.json();
      })
      .then((res) => {
        resolve(res.result);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const uploadEpubFile = (image, headers = _headers()) => {
  let formData = new FormData();
  //let headers={"Content-type":"application/epub+zip"}
  formData.append("file", image);
  return new Promise((resolve, reject) => {
    // Api.post(`/upload/v1/`, JSON.stringify(formData))
    fetch(config.api_url + "/misc/v1/media/", {
      method: "POST",
      body: formData,
      headers,
    })
      .then((res) => {
        return res.json();
      })
      .then((res) => {
        resolve(res.result);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const uploadCsvFile = (csv, headers = _headers()) => {
  let formData = new FormData();
  formData.append("csv", csv);
  return new Promise((resolve, reject) => {
    fetch(config.api_url + "/misc/v1/verify-invite-code-csv/", {
      method: "POST",
      body: formData,
      headers,
    })
      .then((res) => {
        return res.json();
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const validateAdoptionCsvFile = (csv, headers = _headers()) => {
  let formData = new FormData();
  formData.append("csv", csv);
  return new Promise((resolve, reject) => {
    fetch(config.api_url + "/misc/v1/admin-validate-register-user-csv/", {
      method: "POST",
      body: formData,
      headers,
    })
      .then((res) => {
        return res.json();
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const generateBulkAdoptionFile = (
  csv,
  reqBody,
  headers = _headers()
) => {
  let formData = new FormData();
  formData.append("csv", csv);
  Object.keys(reqBody).forEach((key) => formData.append(key, reqBody[key]));

  return new Promise((resolve, reject) => {
    fetch(config.api_url + "/misc/v1/admin-register-user-csv/", {
      method: "POST",
      body: formData,
      headers,
    })
      .then((res) => {
        return res.json();
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const validateRenewalCsvFile = (
  csv,
  schoolId,
  classId,
  headers = _headers()
) => {
  let formData = new FormData();

  formData.append("csv", csv);
  formData.append("wow_school", schoolId);
  formData.append("wow_class", classId);
  return new Promise((resolve, reject) => {
    fetch(config.api_url + "/misc/v1/admin-validate-register-user-csv/", {
      method: "POST",
      body: formData,
      headers,
    })
      .then((res) => {
        return res.json();
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
export const generateRenewalOldSchoolFile = (
  csv,
  reqBody,
  headers = _headers()
) => {
  let formData = new FormData();
  formData.append("csv", csv);
  Object.keys(reqBody).forEach((key) => formData.append(key, reqBody[key]));

  return new Promise((resolve, reject) => {
    fetch(config.api_url + "/misc/v1/admin-register-user-csv/", {
      method: "POST",
      body: formData,
      headers,
    })
      .then((res) => {
        return res.json();
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
export const validateScheduleCsvFile = (
  csv,
  project_id,
  headers = _headers()
) => {
  let formData = new FormData();

  formData.append("csv", csv);
  formData.append("projectId", project_id);

  return new Promise((resolve, reject) => {
    fetch(
      config.api_url + "/live-classes/v1/admin-validate-group-booking-csv/",
      {
        method: "POST",
        body: formData,
        headers,
      }
    )
      .then((res) => {
        return res.json();
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const validateAndUploadCsvFile = ( csv,
  project_id,
  email,
  headers = _headers()) => {
  let formData = new FormData();
  formData.append("csv", csv);
  formData.append("project_id", project_id);
  formData.append("email", email);

  return new Promise((resolve, reject) => {
    fetch(config.api_url + "/live-classes/v1/admin-group-booking-csv/", {
      method: "POST",
      body: formData,
      headers,
    })
      .then((res) => {
        return res.json();
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const generateScheduleFile = (csv, reqBody, headers = _headers()) => {
  let formData = new FormData();
  formData.append("csv", csv);
  Object.keys(reqBody).forEach((key) => formData.append(key, reqBody[key]));

  return new Promise((resolve, reject) => {
    fetch(config.api_url + "/live-classes/v1/admin-group-booking-csv/", {
      method: "POST",
      body: formData,
      headers,
    })
      .then((res) => {
        return res.json();
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export default uploadFile;
