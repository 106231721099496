import React, { Component } from "react";
import {
  SearchBarContainer,
  ToolbarContainer
} from "@c/users-view/MainContent.styled";
import {
  NavigationContainer,
  NavigationHeaderContainer,
  NavigationToolbar
} from "@sc/NavigationBar.styled";
import { Link } from "react-router-dom";
import { connectStore } from "redux-box";
import { module as activity } from "store/activity";

// export default connectStore({
//   activity
// })
// export default 
class ActivityViewHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchBarEnable: false,
      navigationSelector: false
    };
  }

  componentDidMount() {
    let currentPath = window.location.pathname;
    if (currentPath.includes("/activities/packs")) {
      this.setState({
        navigationSelector: true
      });
    } else {
      this.setState({
        navigationSelector: false
      });
    }
  }
  handleNavigationSelector = val => {
    this.setState({
      navigationSelector: val
    });
  };

  handleActivitiesSearch = e => {
    let { activity } = this.props;
    if (activity.enableOrder) activity.enableReorder(false);
    activity.setActivitySearch(e.target.value);
    setTimeout(() => {
      if (this.props.activity.active_grade !== null)
        this.props.activity.requestActivitiesByGrade(activity.active_grade);
    }, 1500);
  };

  handleActivityPacksSearch = evt => {
    //let activity=this.props;
    this.props.activity.setActivityPacksSearch(evt.target.value);
    setTimeout(() => {
      this.props.activity.requestActivityPacks();
    }, 1500);
  };

  enableSearchBar = actionType => {
    if (actionType === "focus" && this.state.searchBarEnable === false)
      this.setState({
        searchBarEnable: true
      });
    else if (actionType === "blur" && this.state.searchBarEnable === true)
      this.setState({
        searchBarEnable: false
      });
  };
  handleClearSearch = () => {
    let { activity } = this.props;

    activity.setActivitySearch("");
    activity.requestActivitiesByGrade(activity.active_grade);
  };

  handleClearPackSearch = () => {
    let { activity } = this.props;

    activity.setActivityPacksSearch("");
    activity.requestActivitiesByGrade(activity.active_grade);
  };


  render() {
    let currentPath = window.location.pathname;
    return (
      <NavigationHeaderContainer>
        <NavigationContainer>
          <Link
            to="/activities"
            className={this.state.navigationSelector === false ? "active" : ""}
            onClick={() => this.handleNavigationSelector(false)}
          >
            Activities
          </Link>
          <Link
            to={
              "/activities/packs/" +
              this.props.activity.active_grade_level +
              "/" +
              this.props.activity.active_level
            }
            className={this.state.navigationSelector === true ? "active" : ""}
            onClick={() => this.handleNavigationSelector(true)}
          >
            Activity Packs
          </Link>
        </NavigationContainer>
        <NavigationToolbar>
          <SearchBarContainer
            width={this.state.searchBarEnable ? "300px" : "200px"}
          >
            <i className="fa fa-search" />
            {currentPath.includes("/activities/packs/Level") ? (
              <div>
                <input
                  type="text"
                  onChange={this.handleActivityPacksSearch}
                  placeholder="Search"
                  value={this.props.activity.search_pack_text}
                  onFocus={() => this.enableSearchBar("focus")}
                  onBlur={() => this.enableSearchBar("blur")}
                />
                {/* {this.props.activity.search_pack_text.length > 0 && (
                  <i
                    className="fa fa-times cross"
                    onClick={() => this.handleClearPackSearch()}
                  />
                )} */}
              </div>
            ) : (
                <div>
                  <input
                    type="text"
                    onChange={this.handleActivitiesSearch}
                    placeholder="Search"
                    value={this.props.activity.search_text}
                    onFocus={() => this.enableSearchBar("focus")}
                    onBlur={() => this.enableSearchBar("blur")}
                  />
                  {/* {this.props.activity.search_text.length > 0 && (
                    <i
                      className="fa fa-times cross"
                      onClick={() => this.handleClearSearch()}
                    />
                  )} */}
                </div>
              )}
          </SearchBarContainer>
          <ToolbarContainer>
            {/* <img src={require("static/img/filter.png")} alt="Filter" /> */}
            {/* <img
              src={require("static/img/scroll_right.png")}
              alt="Scroll Right"
            /> */}
          </ToolbarContainer>
        </NavigationToolbar>
      </NavigationHeaderContainer>
    );
  }
}

export default connectStore({
  activity,
 })(ActivityViewHeader)