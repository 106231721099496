export default {
  //SATE VARIABLES COMMON FOR ALL TABS
  experience_type: "activity",
  all_experiences_list: [],
  experience_offset: 0,
  experience_next: false,
  active_all_experience: null,
  fetch_all_experience_list: false,
  fetch_all_active_experience:false,
  all_experiences_result: null,
  experience_date: '',
};
