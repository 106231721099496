import React, { Component } from "react";
import {
  ActivityContinueButtonContainer,
  ActivityDescription,
  ActivityInputField,
  ActivityMainFormContainer,
  ActivityUploadImageButton,
  CreateActivityContainer,
  SelectFileIn,
  ImageContainer,
  ChangeImageButton,
  UploadActivityImageContainer
} from "@c/activity-view/activity-creation-form/ActivityCreationForm.styled";
import {
  ThemeButton,
  ImageLoader,
  UploadImagesParentContainer,
  Chevron,
  DropDown,
  ToggleSlider,
  DropDownMenu,
  CheckBall,
  CheckBallInner,
  DropdownItem,
  FlexStart,
  SubLevelItem,
} from "@sc/custom.styled";

import {
  ActivityCreatorNavContainer,
  FreadomPointsInputField
} from "@sc/ActivityModal.styled";
import { module as ui } from "@st/ui";
import { module as activity } from "@st/activity";
import { module as level } from "@st/level";
import { uploadFile } from "@s/api/file";
import { connectStore } from "redux-box";
import swal from "sweetalert2";

// export default connectStore({
//   ui,
//   activity,
//   level
// })

class ActivityPackCreationForm extends Component {
  constructor(props) {
    super();
    this.state = {
      gradeSelected: null,
      displayMenu: false,
      levelTypeSelector: false,
      subLevelTypeSelector: false,
      grade_level: "",
      level: "",
      setTop: 60,
      showActivityCreator: false,
      imageChange: false,
      enableBackgroundLoad: false,
      imageBackgroundChange: false,
      freadom_point: "",
      enableLoad: false,
      file: "",
      activityPack: {
        name: "",
        description: "",
        grade_level: "",
        background_image: "",
        tag: [],
        image: "",
        is_published: true
      },
      levelData: [],
      selectedLevels: [],
    };
  }

  handleImageFocus = actionType => {
    if (actionType === "over" && this.state.imageChange === false)
      this.setState({
        imageChange: true
      });
    else if (actionType === "out" && this.state.imageChange === true)
      this.setState({
        imageChange: false
      });
  };
  componentDidMount() {

    let levelList = this.props.level.levels.map((level) => {
      let levelData = {
        name: level,
        selected: false,
        clicked: false,
        levels: [
          { name: "Easy", selected: false },
          { name: "Hard", selected: false },
        ],
      };
      return levelData;
    });
    this.setState({
      levelData: [
        {
          name: "Select All",
          selected: false,
          clicked: false,
          levels: [],
        },
        ...levelList,
      ],
    });
  }

  _handleImageChange = e => {
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];
    let fileSize = e.target.files[0].size / 1024 / 1024;
    if (fileSize > 2) {
      swal("Oops!", "File size greater than 2MB ", "warning");
    } else {
      reader.onloadend = () => {
        this.setState({
          enableLoad: true,
          imageChange: false,
          activityPack: { ...this.state.activityPack, image: reader.result }
        });
      };
      reader.readAsDataURL(file);
      uploadFile(file).then(res => {
        this.setState({
          activityPack: { ...this.state.activityPack, image: res.file },
          enableLoad: false
        });
      });
    }
  };

  handleBackgroundImageFocus = actionType => {
    if (actionType === "over" && this.state.imageBackgroundChange === false)
      this.setState({
        imageBackgroundChange: true
      });
    else if (actionType === "out" && this.state.imageBackgroundChange === true)
      this.setState({
        imageBackgroundChange: false
      });
  };

  _handleBackgroundImageChange = e => {
    e.preventDefault();

    let reader = new FileReader();
    let file = e.target.files[0];
    let fileSize = e.target.files[0].size / 1024 / 1024;

    if (fileSize > 2) {
      swal("Oops!", "File size greater than 2Mb ", "warning");
    } else {
      reader.onloadend = () => {
        this.setState({
          enableBackgroundLoad: true,
          imageBackgroundChange: false,
          activityPack: {
            ...this.state.activityPack,
            background_image: reader.result
          }
        });
      };
      reader.readAsDataURL(file);
      uploadFile(file).then(res => {
        this.setState({
          activityPack: {
            ...this.state.activityPack,
            background_image: res.file
          },
          enableBackgroundLoad: false
        });
      });
    }
  };

  handleContinueBtn = async () => {
    this.setState({
      displayMenu: false
    });

    if (this.state.activityPack.name && this.state.selectedLevels.length > 0) {
      for (const key in this.state.selectedLevels) {
        const activity = this.state.selectedLevels[key];
        await this.setState({activityPack: {
          ...this.state.activityPack,
          grade_level:activity
        }})

        if (this.state.freadom_point === ""){
          await this.props.activity.createActivityPack({
            ...this.state.activityPack,
            freadom_point: 0
          });
        }else{
          await this.props.activity.createActivityPack({
            ...this.state.activityPack,
            freadom_point: this.state.freadom_point
          });
        }
      }
      this.props.ui.hideModal();
    } else {
      swal(
        "Oops!",
        "You must enter name and grade level to create an activity pack",
        "error"
      );
    }
  };

  handleActivityPackName = evt => {
    this.setState({
      activityPack: { ...this.state.activityPack, name: evt.target.value }
    });
  };
  handleActivityPackDescription = evt => {
    this.setState({
      activityPack: {
        ...this.state.activityPack,
        description: evt.target.value
      }
    });
  };

  handleFreadomPoints = evt => {
    if (evt.target.value.length === 0 && this.state.freadom_point != null) {
      this.setState({
        freadom_point: ""
      });
    } else
      this.setState({
        freadom_point: evt.target.value
      });
  };

  handleLevelTypeSelector = val => {
    // this.setState({
    //   levelTypeSelector: val
    // });
    let newLevelData = this.state.levelData.map((level) => {
      return { ...level, clicked: false };
    });
    this.setState({
      levelData: newLevelData,
      levelTypeSelector: val,
    });
  };

  handleLevelChange = (data) => {
    this.handleLevelTypeSelector(true);
    let activityLevels = [];
    if (data === "Select All" || data === "Unselect All") {
      let newLevelData = this.state.levelData.map((level) => {
        let subLevels =
          level.levels.length > 0 &&
          level.levels.map((subLevel) => {
            activityLevels = activityLevels.concat(level.name + ":" + subLevel.name);

            return { ...subLevel, selected: !this.state.levelData[0].selected };
          });
        if (level.name === "Select All" || level.name === "Unselect All") {
          let name =
            level.name === "Select All" ? "Unselect All" : "Select All";
          return { ...level, name: name, selected: !level.selected };
        }
        return {
          ...level,
          selected: !this.state.levelData[0].selected,
          levels: subLevels,
        };
      });
      if (data === "Unselect All") activityLevels = [];
      this.setState({
        levelData: newLevelData,
        selectedLevels: activityLevels,
      });
    } else {
      let newLevelData = this.state.levelData.map((level) => {
        if (level.name === data) return { ...level, clicked: !level.clicked };
        return { ...level, clicked: false };
      });
      this.setState({
        levelData: newLevelData,
        activityPack: {
          ...this.state.activityPack,
          level: data,
        },
      });
    }
  };

  handleSubLevelSelector = (levelName, subLevel) => {
    let newLevelData = this.state.levelData.map((level) => {
      if (level.name === levelName) {
        let val = false;
        let subLevels = level.levels.map((subLevelOption) => {
          if (subLevelOption.name === subLevel.name) {
            //console.log(this.state.selectedLevels);
            if (subLevel.selected) {
              let storyLevels = this.state.selectedLevels.filter(
                (selectedLevel) => {
                  return selectedLevel !== levelName + ":" + subLevel.name;
                }
              );

              this.setState({
                selectedLevels: storyLevels,
              });
            } else {
              this.setState({
                selectedLevels: this.state.selectedLevels.concat(
                  levelName + ":" + subLevel.name
                ),
              });
            }

            return { ...subLevelOption, selected: !subLevelOption.selected };
          }
          //else if(subLevelOption.selected)
          return subLevelOption;
        });
        val = subLevels && (subLevels[0].selected || subLevels[1].selected);
        return { ...level, selected: val, levels: subLevels };
      }
      return { ...level };
    });
    this.setState({
      levelData: newLevelData,
    });

    //console.log(this.state.selectedLevels);
  };


  handleSpecLevelSelector = (evt, selectedLevel) => {
    evt.preventDefault();
    evt.stopPropagation();
    let activityLevels = this.state.selectedLevels;
    let newLevelData = this.state.levelData.map((level) => {
      if (level.name === selectedLevel.name) {
        let subLevels =
          level.levels.length > 0 &&
          level.levels.map((subLevel) => {
            if (level.selected) {
              if (
                activityLevels &&
                activityLevels.some(
                  (data) => data === level.name + ":" + subLevel.name
                )
              )
              activityLevels = activityLevels.filter((selectedLevel) => {
                  return selectedLevel !== level.name + ":" + subLevel.name;
                });
            } else {
              if (
                !this.state.selectedLevels.some(
                  (data) => data === level.name + ":" + subLevel.name
                )
              ) {
                activityLevels = activityLevels.concat(
                  level.name + ":" + subLevel.name
                );
              }
            }
            return { ...subLevel, selected: !level.selected };
          });

        return { ...level, selected: !level.selected, levels: subLevels };
      }

      return level;
    });

    this.setState({
      levelData: newLevelData,
      selectedLevels: activityLevels,
    });
  };

  // handleLevelSelected = level => {
  //   let levelData = this.state.levelData.map(option => {
  //     if (option.name === level.name) return { ...option, selected: true };
  //     return { ...option, selected: false };
  //   });
  //   this.setState({
  //     levelData: levelData,
  //     activityPack: {
  //       ...this.state.activityPack,
  //       grade_level: level.name + ":" + level.subLevel
  //     }
  //   });
  // };

  // handleSubLevelSelected = (evt, level) => {
  //   let subLevel = level.subLevel;
  //   let levelData = this.state.levelData.map(option => {
  //     if (option.name === level.name) {
  //       subLevel = option.subLevel === "Easy" ? "Hard" : "Easy";
  //       return { ...option, selected: true, subLevel: subLevel };
  //     }

  //     return { ...option, selected: false, subLevel: "Easy" };
  //   });
  //   //console.log(subLevel);
  //   this.setState({
  //     levelData: levelData,
  //     activityPack: {
  //       ...this.state.activityPack,
  //       grade_level: level.name + ":" + subLevel
  //     }
  //   });
  //   evt.stopPropagation();
  // };

  render() {
    return (
      <CreateActivityContainer>
        <UploadImagesParentContainer>
          {!this.state.activityPack.image ? (
            <UploadActivityImageContainer height="120px" marginBottom="14px">
              <ActivityUploadImageButton type="button">
                <img
                  src={require("static/img/cloud-upload-icon.png")}
                  alt="Upload"
                />
                <span>Upload Image </span>
              </ActivityUploadImageButton>

              <SelectFileIn
                type="file"
                onChange={(e) => this._handleImageChange(e)}
              />
            </UploadActivityImageContainer>
          ) : this.state.enableLoad ? (
            <UploadActivityImageContainer height="120px" marginBottom="14px">
              <ImageLoader>
                <img
                  alt="loading"
                  width="30"
                  height="30"
                  src="http://photokonkurs.russia.travel/images/icons/preloader.gif"
                />
              </ImageLoader>
            </UploadActivityImageContainer>
          ) : (
            <UploadActivityImageContainer
              height="120px"
              marginBottom="14px"
              onMouseOver={() => this.handleImageFocus("over")}
              onMouseOut={() => this.handleImageFocus("out")}
            >
              <ImageContainer
                src={
                  this.state.activityPack.image
                    ? this.state.activityPack.image
                    : require("static/img/activity-bg.png")
                }
              />
              <ChangeImageButton
                top={this.state.imageChange ? "83%" : "100%"}
                opacity={this.state.imageChange ? 1 : 0}
              >
                Change Image
              </ChangeImageButton>
              <SelectFileIn
                type="file"
                onChange={(e) => this._handleImageChange(e)}
              />
            </UploadActivityImageContainer>
          )}

          {this.state.activityPack.background_image === "" ? (
            <UploadActivityImageContainer height="180px">
              <ActivityUploadImageButton type="button">
                <img
                  src={require("static/img/cloud-upload-icon.png")}
                  alt="Upload"
                />
                <span>Upload Full screen Image </span>
              </ActivityUploadImageButton>
              <SelectFileIn
                type="file"
                onChange={(e) => this._handleBackgroundImageChange(e)}
              />
            </UploadActivityImageContainer>
          ) : this.state.enableBackgroundLoad ? (
            <UploadActivityImageContainer height="180px">
              <ImageLoader>
                {" "}
                <img
                  alt="loading"
                  width="30"
                  height="30"
                  src="http://photokonkurs.russia.travel/images/icons/preloader.gif"
                />
              </ImageLoader>
            </UploadActivityImageContainer>
          ) : (
            <UploadActivityImageContainer
              height="180px"
              onMouseOver={() => this.handleBackgroundImageFocus("over")}
              onMouseOut={() => this.handleBackgroundImageFocus("out")}
            >
              <ImageContainer
                src={
                  this.state.activityPack.background_image !== ""
                    ? this.state.activityPack.background_image
                    : require("static/img/activity-bg.png")
                }
              />
              <ChangeImageButton
                top={this.state.imageBackgroundChange ? "83%" : "100%"}
                opacity={this.state.imageBackgroundChange ? 1 : 0}
              >
                Change Image
              </ChangeImageButton>
              <SelectFileIn
                type="file"
                onChange={(e) => this._handleBackgroundImageChange(e)}
              />
            </UploadActivityImageContainer>
          )}
        </UploadImagesParentContainer>

        <ActivityMainFormContainer>
          <form>
            <ActivityCreatorNavContainer>
              {/* <DropDown
                minWidth="120px"
                style={{ width: "150px" }}
                active={this.state.levelTypeSelector}
                onBlur={() => this.handleLevelTypeSelector(false)}
                tabIndex="1"
              >
                <div
                  onClick={() =>
                    this.handleLevelTypeSelector(!this.state.levelTypeSelector)
                  }
                >
                  {this.state.activityPack.grade_level.length > 0
                    ? this.state.activityPack.grade_level
                    : "Select Level"}
                  <Chevron>
                    <img
                      src={require("static/img/downarrowicon.png")}
                      alt="down-arrow"
                    />
                  </Chevron>
                </div>
                <DropDownMenu
                  maxHeight="100px"
                  active={this.state.levelTypeSelector}
                  color="#fff"
                >
                  {this.state.levelData.map((level, index) => (
                    <a
                      key={level.name}
                      onClick={() => this.handleLevelSelected(level)}
                    >
                      {level.name}
                      {level.selected ? (
                        <ToggleSlider
                          onClick={evt => {
                            this.handleSubLevelSelected(evt, level);
                          }}
                          active={level.subLevel === "Hard"}
                        >
                          <div>{level.subLevel}</div>
                        </ToggleSlider>
                      ) : null}
                    </a>
                  ))}
                </DropDownMenu>
              </DropDown> */}
              {/* level */}
              <DropDown
                minWidth="160px"
                active={this.state.levelTypeSelector}
                onBlur={() => this.handleLevelTypeSelector(false)}
                tabIndex="1"
              >
                <div
                  style={{ fontSize: "14px", height: "24px" }}
                  onClick={() =>
                    this.handleLevelTypeSelector(!this.state.levelTypeSelector)
                  }
                >
                  {this.state.selectedLevels.length >= 18
                    ? "All "
                    : this.state.selectedLevels.length + " "}{" "}
                  Levels Selected
                  <Chevron>
                    <img
                      src={require("static/img/downarrowicon.png")}
                      alt="down-arrow"
                    />
                  </Chevron>
                </div>
                <DropDownMenu
                  maxHeight="150px"
                  active={this.state.levelTypeSelector}
                  color="#fff"
                  style={{ padding: "0px" }}
                >
                  {this.state.levelData.map((levelData, index) => (
                    <DropdownItem>
                      <FlexStart
                        style={{
                          width: "100%",
                          padding: "6px 8px",
                          position: "relative",
                          borderBottom: "solid 1px #e6e6e6",
                        }}
                        onMouseDown={(e) => e.preventDefault()}
                        onClick={() => this.handleLevelChange(levelData.name)}
                      >
                        <CheckBall
                          onClick={(evt) =>
                            //FREAD-1182
                            levelData.name === "Select All" ||
                            levelData.name === "Unselect All"
                              ? this.handleLevelChange(levelData.name)
                              : this.handleSpecLevelSelector(evt, levelData)
                          }
                          style={{ marginRight: "6px" }}
                          active={levelData.selected || levelData.clicked}
                        >
                          <CheckBallInner
                            active={levelData.selected || levelData.clicked}
                          />
                        </CheckBall>
                        {levelData.levels.length > 0 && (
                          <Chevron
                            cross={
                              levelData.clicked
                                ? "rotate(0deg)"
                                : "rotate(-90deg)"
                            }
                          >
                            <img
                              src={require("static/img/downarrowicon.png")}
                              alt="down-arrow"
                            />
                          </Chevron>
                        )}

                        {levelData.name}
                      </FlexStart>
                      {levelData.clicked &&
                        levelData.levels.length > 0 &&
                        levelData.levels.map((subLevel, i) => (
                          <SubLevelItem
                            key={i}
                            style={{
                              backgroundColor: "#faf7ff",
                              width: "100%",
                              padding: "6px 8px 6px 32px",
                              justifyContent: "flex-start",
                              borderBottom: "solid 1px #e6e6e6",
                            }}
                            onMouseDown={(e) => e.preventDefault()}
                            onClick={() => {
                              this.handleSubLevelSelector(
                                levelData.name,
                                subLevel
                              );
                            }}
                          >
                            <CheckBall
                              style={{ marginRight: "6px" }}
                              active={subLevel.selected}
                            >
                              <CheckBallInner active={subLevel.selected} />
                            </CheckBall>{" "}
                            {subLevel.name}
                          </SubLevelItem>
                        ))}
                    </DropdownItem>
                  ))}
                </DropDownMenu>
              </DropDown>

              {/* level */}
              <FreadomPointsInputField>
                <input
                  type="number"
                  placeholder="Freadom Points"
                  value={this.state.freadom_point}
                  onChange={this.handleFreadomPoints}
                />
              </FreadomPointsInputField>
            </ActivityCreatorNavContainer>

            <ActivityInputField
              type="text"
              placeholder="Add Activity Pack Title"
              value={this.state.activityPack.name}
              onChange={this.handleActivityPackName}
            />
            <div>
              <ActivityDescription
                placeholder="Add a short description"
                id="activityDescription"
                style={{ height: "200px" }}
                value={this.state.activityPack.description}
                onChange={this.handleActivityPackDescription}
              />
            </div>
            <ActivityContinueButtonContainer>
              <ThemeButton
                primary
                type="button"
                onClick={this.handleContinueBtn}
              >
                Continue
              </ThemeButton>
            </ActivityContinueButtonContainer>
          </form>
        </ActivityMainFormContainer>
      </CreateActivityContainer>
    );
  }
}
export default connectStore({
   ui,
  activity,
  level
 })(ActivityPackCreationForm)
