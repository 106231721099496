import styled from "styled-components";

export const ModalViewParentContainer = styled.div`
  width: 100%;
  background-color: #eee;
  height: 100%;
`;
export const ModalView = styled.div`
  position: absolute;
  right: 0px;
  height: 100%;
  min-width: 100%;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  max-width: 700px;
  background: white;
  border-radius: 10px;
`;

export const ModalViewContainer = styled.div`
  height: 100%;
  align-items: stretch;
  border-radius: 10px;
  padding: 20px 35% 0 35%;
  display: flex;
  flex-direction: column;
`;

export const SelectContainer = styled.div`
  margin-right: 30px;
  font-size: 12px;
  color: #000;
  font-size: var(--lg-font-size);
  font-weight: 500;
  margin-top: 6px;
`;

export const FormContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  align-items:center;
`;

export const FlexCenter = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 500px;
`;
