import { Api } from "./base";

const _headers = () => {
  return {
    Authorization:
      "JWT " + require("store").default.getState().onboard.user.auth_token,
    "Content-Type": "application/json"
  };
};

export default {
  getAllCaseStudies() {
    return Api.get("/misc/v1/admin-testimonial/", _headers());
  },
  createCaseStudy(data) {
    return Api.post("/misc/v1/admin-testimonial/", data, _headers());
  },
  getCaseStudyById(caseStudyId) {
    return Api.get(`/misc/v1/admin-testimonial/${caseStudyId}/`, _headers());
  },
  deleteCaseStudy(caseStudyId) {
    return Api.deleteCall(
      `/misc/v1/admin-testimonial/${caseStudyId}/`,
      _headers()
    );
  },
  updateCaseStudy(caseStudyId, data) {
    return Api.put(
      `/misc/v1/admin-testimonial/${caseStudyId}/`,
      data,
      _headers()
    );
  }
};
