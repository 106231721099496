import React, { Component } from "react";
import {
  ActivityContinueButtonContainer,
  ActivityMainFormContainer,
  CreateActivityContainer,
  ActivityUploadImageButton,
  SelectFileIn,
  ImageContainer,
  ChangeImageButton,
  UploadActivityImageContainer,
} from "@c/activity-view/activity-creation-form/ActivityCreationForm.styled";
import {
  RCNameInput,
  PRTextArea,
} from "../../school-guided-path-view/school-guided-path-creation-form/SchoolGuidedPathCreationForm.styled";
import {
  ThemeButton,
  UploadImagesParentContainer,
  ImageLoader,
} from "@sc/custom.styled";
import { uploadFile } from "@s/api/file";
import { module as ui } from "@st/ui";
import { module as product } from "@st/product";
import { connectStore } from "redux-box";
import swal from "sweetalert2";
import Select from "react-select"

const productTypeList = [
  // { value: "regular", label: "Regular" },
  { value: "custom", label: "Custom" },
];


class ProductEditForm extends Component {
  constructor(props) {
    super();
    this.state = {
      updatedProduct: {
        name: "",
        description: "",
        thumbnail_url: "",
        type:""
      },
      imageChange: false,
    };
  }

  componentDidMount() {
    const { product } = this.props;

    if (!this.props.isCreateForm) {
      this.setState({
        updatedProduct: product.edit_product,
      });
    }
  }

  componentWillUnmount() {
    this.props.product.setEditProduct(null);
  }

  handleName = (evt) => {
    this.setState({
      updatedProduct: {
        ...this.state.updatedProduct,
        name: evt.target.value,
      },
    });
  };

  handleDescription = (evt) => {
    this.setState({
      updatedProduct: {
        ...this.state.updatedProduct,
        description: evt.target.value,
      },
    });
  };

  handleType = (type)=>{
this.setState({
  updatedProduct:{
    ...this.state.updatedProduct,
    type:type.value
  }
})
  }

  handleImageFocus = (actionType) => {
    if (actionType === "over" && this.state.imageChange === false)
      this.setState({
        imageChange: true,
      });
    else if (actionType === "out" && this.state.imageChange === true)
      this.setState({
        imageChange: false,
      });
  };

  _handleImageChange = (e) => {
    // e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];

    let fileSize = e.target.files[0].size / 1024 / 1024;

    if (fileSize > 2) {
      swal("Oops!", "File size greater than 2Mb ", "warning");
    } else {
      reader.onloadend = () => {
        this.setState({
          imageChange: false,
          enableLoad: true,
          updatedProduct: {
            ...this.state.updatedProduct,
            thumbnail_url: reader.result,
          },
        });
      };
      reader.readAsDataURL(file);
      uploadFile(file).then((res) => {
        this.setState({
          updatedProduct: {
            ...this.state.updatedProduct,
            thumbnail_url: res.file,
          },
          enableLoad: false,
        });
      });
    }
  };

  handleSubmit = async () => {
    const { updatedProduct } = this.state;

    if (updatedProduct.name === "") {
      return swal("Oops", "Name cannot be Blank", "error");
    }
    if (updatedProduct.description === "") {
      return swal("Oops", "Description cannot be Blank", "error");
    }
    if (updatedProduct.type === "") {
      return swal("Oops", "type cannot be Blank", "error");
    }
    if (
      this.state.updatedProduct.thumbnail_url === "undefined" ||
      this.state.updatedProduct.thumbnail_url === "" ||
      this.state.updatedProduct.thumbnail_url === null
    ) {
      return swal("Oops", "Thumbnail is required.", "error");
    }
    if (this.props.isCreateForm) {
      await this.props.product.createProduct({ ...updatedProduct });
    } else {
      await this.props.product.updateProductList(updatedProduct);
    }
    // if(updateProductApi){
    this.props.ui.hideModal();
    // this.props.product.fetchProductList();
    // swal("Done", "Product updated successfully", "success");
    // }
  };

  render() {
    const { updatedProduct } = this.state;
    return (
      <CreateActivityContainer>
        <UploadImagesParentContainer>
          {!this.state.updatedProduct.thumbnail_url ? (
            <UploadActivityImageContainer height="120px" marginBottom="14px">
              <ActivityUploadImageButton type="button">
                <div>
                  <img
                    src={require("static/img/cloud-upload-icon.png")}
                    alt="cloud-upload"
                  />
                </div>
                <span>Upload Image </span>
              </ActivityUploadImageButton>

              <SelectFileIn
                type="file"
                onChange={(e) => this._handleImageChange(e)}
              />
            </UploadActivityImageContainer>
          ) : this.state.enableLoad ? (
            <UploadActivityImageContainer height="120px" marginBottom="14px">
              <ImageLoader>
                {" "}
                <img
                  alt="loading"
                  width="30"
                  height="30"
                  src="http://photokonkurs.russia.travel/images/icons/preloader.gif"
                />
              </ImageLoader>
            </UploadActivityImageContainer>
          ) : (
            <UploadActivityImageContainer
              height="120px"
              marginBottom="14px"
              onMouseOver={() => this.handleImageFocus("over")}
              onMouseOut={() => this.handleImageFocus("out")}
            >
              <ImageContainer src={this.state.updatedProduct.thumbnail_url} />
              <ChangeImageButton
                top={this.state.imageChange ? "83%" : "100%"}
                opacity={this.state.imageChange ? 1 : 0}
              >
                Change Image
              </ChangeImageButton>
              <SelectFileIn
                type="file"
                onChange={(e) => this._handleImageChange(e)}
              />
            </UploadActivityImageContainer>
          )}
        </UploadImagesParentContainer>
        <ActivityMainFormContainer>
          <form>
            <RCNameInput
              type="text"
              className="form-control"
              autoFocus
              placeholder="Edit Product Title"
              value={updatedProduct.name}
              onChange={this.handleName}
            />
            <div>
              <PRTextArea
                className="form-control"
                placeholder="Edit Product Description"
                value={updatedProduct.description}
                onChange={this.handleDescription}
              />
            </div>

            <Select
              className="dropdown-container"
              onChange={this.handleType}
              options={productTypeList}
              placeholder="Select Type"
            />
            <ActivityContinueButtonContainer>
              {this.props.isCreateForm ? (
                <ThemeButton onClick={this.handleSubmit} primary type="button">
                  {" "}
                  Add Product{" "}
                </ThemeButton>
              ) : (
                <ThemeButton onClick={this.handleSubmit} primary type="button">
                  {" "}
                  Update Product{" "}
                </ThemeButton>
              )}
            </ActivityContinueButtonContainer>
          </form>
        </ActivityMainFormContainer>
      </CreateActivityContainer>
    );
  }
}
export default connectStore({
  ui,
  product,
})(ProductEditForm);
