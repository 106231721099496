import React, {Component} from 'react';
import {PlanIconContainer,PlanIconParentContainer,PlanPopUpContainer,PlanCostContainer,PlanDescription,PlanInfoContainer,PlanName} from './PlanPopUp.styled';
import {connectStore} from "redux-box";
import {module as payment} from "@st/payment";

// export default connectStore({
//     payment,
// })

// export default 
class PlanPopUp extends Component
{
    
    render(){
        let {payment}=this.props;
        let amount=payment.plan_info.amount/100;
        let plan_info=this.props.payment.plan_info;
        return(
          <PlanPopUpContainer>
              <PlanIconParentContainer>
              <PlanIconContainer>
                  <i className="fa fa-clipboard"/>
                  </PlanIconContainer>
                  </PlanIconParentContainer>
                  {this.props.payment.plan_info && <PlanInfoContainer>
                       <PlanName> {plan_info.name}</PlanName>
                      <PlanDescription>
                         {payment.plan_info && payment.plan_info.description!==''?payment.plan_info.description:'No description added'}
                      </PlanDescription>
                      <PlanCostContainer>
                           <span> {`${plan_info.currency} ${amount}`} </span> Annually Per Child
                        </PlanCostContainer>
                      
                      </PlanInfoContainer>}
          </PlanPopUpContainer>
        )
    }
}
export default connectStore({
    payment
   })(PlanPopUp)