import { createActions, using } from "redux-box";

export default createActions({
    requestGrades: using(),
    //...........activity CRUD...........
    createActivity: using("activity"),
    requestActivities: using(),
    deleteActivity: using("activity"),
    requestActivitiesConcat:using("offset"),
    updateActivity: using("activity"),
    requestActivityById: using("activityId"),
    setActiveGrade: using("grade"),
    deleteTag:using("tag"),
    setActiveActivity:using("activity"),
    requestCreateTag:using("tag"),
    unsetActiveActivity:using(),
    setActivities:using("activities"),
    reorderActivity:using("activity"),
    requestPublishActivity:using("activity"),
    requestActivitiesByGrade:using("grade"),
    //............section CRUD.........
    addNewSection: using(),
    setActiveSection: using("section"),
    requestCreateSection: using("data"),
    requestUpdateSection: using("data"),
    removeSection: using(),
    startCreatingSection: using("sectionType, data"),
    startUpdatingSection: using("sectionType, data"),
    removeNewSection: using("section"),
    updateNewSection: using("section"),
    resetNewSections: using(),

    //..........activity pack CRUd............
    requestActivityPacks: using(),
    requestConcatActivityPacks:using("offset"),
    setActivePackGrade: using("grade"),
    setActivitySearch: using("search"),
    setActivityPacksSearch:using("packSearch"),
    deleteActivitySection: using("section"),
    createActivityPack: using("activityPack"),
    requestActivityPackById: using("activityPackId"),
    updateActivityPack: using("data"),
    deletePackTag:using("tag"),
    requestCreatePackTag:using("tag"),
    unsetActiveActivityPack:using(),
    unsetPackNewTag:using(),
    publishActivityPack:using("data"),

    //............questions CRUD..........
    requestAllQuestions: using("activityId"),
    requestCreateQuestion: using("question"),
    requestUpdateQuestion: using('question'),
    requestDeleteQuestion: using('question'),
    updateNewQuestion: using("question"),
    createQuestion: using("activityId", "question"),
    newQuestion: using("question"),
    setActiveMcq:using("question"),
    unsetActiveQuestion:using(),

    //..............options CRUD.........
    addNewOption:using("option"),
    removeNewOption:using("optionId"),

    //..........misc .........
    closeEditor: using(),
    setEditorType: using("editorType"),
    enableReorder:using("value"),
    setActiveLevel:using("activeLevel"),
    setActiveGradeLevel:using("activeGradeLevel"),
    setFetchActivityByLevel:using("value"),
    setFetchActivityByGrade:using("val"),

    //.................Experience.................
    requestPublishActivityExperience:using("experience"),
    requestDeleteActivityExperience:using("experienceId"),
    setActiveExperience:using("experience"),
});


