import React, { Component } from "react";
import {
  ActivityPackCardContainer,
  ActivityPackContainer,
  ActivityPackTitle
} from "./ActivityPackCard.styled";

export default class ActivityPackCard extends Component {
  render() {
    let activityPack = this.props.tag;
    return (
      <ActivityPackCardContainer before={activityPack.ideal_value}>
        <ActivityPackContainer>
          <ActivityPackTitle primary>{activityPack.name}</ActivityPackTitle>
          <ActivityPackTitle>{activityPack.description}</ActivityPackTitle>
        </ActivityPackContainer>
      </ActivityPackCardContainer>
    );
  }
}
