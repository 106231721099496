import React, { Fragment } from "react";
import { Route, Switch, withRouter } from "react-router-dom";
import { ModalBackDrop } from "static/styled/ModalBackDrop.styled";
import FreadomFutureTaskCreator from "@c/freadom-future-view/fradom-future-task-creator/FreadomFutureTaskCreator";

export const Routes = function({ location }) {
  return (
    <Switch location={location}>
      <Route
        path="/freadom-future/edit/:id"
        render={props => (
          <Fragment>
            <FreadomFutureTaskCreator {...props} />
            <ModalBackDrop active second />
          </Fragment>
        )}
      />
    </Switch>
  );
};

export default withRouter(Routes);
