import React, { Component } from 'react';
import {
    MediaThemedButton, FlexStart, SubHeading
} from "static/styled/custom.styled";
import { FileListContainer } from "@c/activity-view/activity-creator/file-section/FileSection.styled";
import { StorySectionContainer } from "../StoryCreator.styled";
import {connectStore} from "redux-box";
import {module as story} from 'store/story';
import {uploadEpubFile} from "services/api/file";
import swal from "sweetalert2";
import { module as onboard } from "@st/onboard";
import Permission from "@s/permission";

class EpubStory extends Component {

    constructor(props) {
        super();
        this.state = ({
            epubFileName: '',
            media: [],
            enableLoad: false,
        })
    }

    componentDidMount()
    {
        let {section}=this.props;
        if(section!==null && section.content_type==='epub')
        {
            this.setState({
                epubFileName:section.name!=='epub'?section.name:'',
                media:section.media,
            })
        }

    }

    handleUpload = e => {
       
        if (e.target.files !== null) {
            let { story } = this.props;
            story.setFetchMediaLoading(true);
            let epubFileName = e.target.files.length > 0 ? e.target.files[0].name : 'epub';
            this.setState({
                enableLoad: true,

            });

            let fileSize = e.target.files[0].size / 1024 / 1024;
            if (fileSize > 10) {
                swal('Oops!', 'File size greater than 10MB ', 'warning')
            }
            else {
            uploadEpubFile(e.target.files[0]).then(res => {
                this.setState({
                    // pdfName:e.target.files && e.target.files[0].name,
                    media: [{ thumbnail_url: '', media_url: res.file }],
                    //videoFileName:e.target.files[0].name
                });
                if (story.active_story) {
                    let newSection = {
                        content_type: 'epub',
                        media: this.state.media,
                        name: epubFileName,
                        description: '',
                        story: story.active_story.id
                    };
                    if (this.props.section === null) {
                        story.requestCreateStorySection(newSection);
                    }
                    else {
                        story.requestUpdateStorySection(newSection);
                    }
                }
                this.setState({
                    enableLoad: false,
                    epubFileName: epubFileName,
                });
                story.setFetchMediaLoading(false);

            });
        }
        }
    };
    render() {
        const {permission} = this.props.onboard
        return (
            <StorySectionContainer>
                <FlexStart>
                    {Permission.getPermission(permission, "change_story") ?
                        <MediaThemedButton primary> {this.state.media.length === 0 ? 'Upload Epub File' : 'Change Epub File'}
                            <input
                                type="file"
                                accept=".epub"
                                onChange={this.handleUpload}
                            />
                        </MediaThemedButton>:null}
                    {this.state.enableLoad ? <SubHeading style={{ margin: '0px', marginLeft: '12px' }}>
                        Uploading Epub <img alt="loader" src={require("static/img/Theme-loader.gif")} width="32" height="32" /></SubHeading> :
                        <SubHeading style={{ margin: '0px', marginLeft: '12px' }}>
                            {this.state.epubFileName} </SubHeading>}
                </FlexStart>

                {!this.state.enableLoad && <FileListContainer style={{ marginTop: '20px' }}>
                    {this.state.media.map((mediaFile, i) => (
                        <a target="_blank" href={mediaFile.media_url} key={i}>
                            <i className="fa fa-file-o" style={{ fontSize: '48px', color: 'red', width: '100%', height: '100%' }} />
                        </a>
                    ))}
                </FileListContainer>}
            </StorySectionContainer>
        )
    }
}
export default connectStore({
    story,onboard
})(EpubStory)