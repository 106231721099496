import { createContainer } from "redux-box";
import state from "./state";
import actions from "./actions";
import mutations from "./mutations";

export const module = {
  name: "ui",
  actions,
  state,
  mutations
};

export const container = createContainer(module);
export default container;
