import React, {Component} from 'react';
import {MainBodyContainer} from "@sc/custom.styled";
import ChildProfileHeader from "./child-profile-header/ChildProfileHeader";
import ChildProfileInfo from "./child-profile-info/ChildProfileInfo";
import {connectStore} from "redux-box";
import {module as user} from "@st/user";


 class ChildProfile extends Component
{
    componentWillMount()
    {
        this.props.user.requestChildById(this.props.match.params.id);
    }

    compoentWillUnmount()
    {
        this.props.user.setActiveChild(null);
    }
    render(){
        return(
            <MainBodyContainer>
                <ChildProfileHeader/>
                
                <ChildProfileInfo/>
            </MainBodyContainer>
        )
    }
}

export default connectStore ({
    user
})(ChildProfile)