import styled from "styled-components";
import { ActivityDescriptionInfoContainer } from "@sc/ActivityModal.styled";
import {GradeDescriptionLabel} from "@c/activity-view/activity-pack-creator/activity-pack-info/ActivityPackInfo.styled";

export const NewsFreedsDescriptionInfoContainer = ActivityDescriptionInfoContainer.extend`
  width: 100%;
  z-index: 100;
  padding:18px;
  padding-bottom:0px;
  max-height:none;
  background-color: white;
`;
export const NewsFreedsInfoContainer=styled.div`
position:absolute;
z-index:100;
background-color:white;
width:100%;
min-height:205px;
box-shadow:0px 2px 2px 2px rgba(0,0,0,0.2);
top:0;
left:0;

`;


export const NewsDescriptionLabel=GradeDescriptionLabel.extend`
position:relative;
width:80%;
flex-flow:column nowrap;
align-items:flex-start;
justify-content:flex-start;
`;

