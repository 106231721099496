import React, { Component } from "react";
import {
  ActivityAddHeaderContainer,
  ActivityPopUpContainer
} from "static/styled/ActivityModal.styled";
import { ThemeButton } from "static/styled/custom.styled";
// import { ActivityName } from "components/add-media-input/AddMediaInput.styled";
import Editor from "react-rte";
// import connectStore from 'redux-box';
import {connectStore} from 'redux-box'
import {module as activity} from "@st/activity";
import {module as readingChallenge} from "@st/readingChallenge";
const toolbarConfig = {
  // Optionally specify the groups to display (displayed in the order listed).
  display: ["INLINE_STYLE_BUTTONS", "BLOCK_TYPE_BUTTONS", "HISTORY_BUTTONS"],
  INLINE_STYLE_BUTTONS: [
    { label: "Bold", style: "BOLD", className: "custom-css-class" },
    { label: "Italic", style: "ITALIC", className: "custom-css-class" },
    { label: "Underline", style: "UNDERLINE", className: "custom-css-class" }
  ],
  BLOCK_TYPE_BUTTONS: [
    {
      label: "UL",
      style: "unordered-list-item",
      className: "custom-css-class"
    },
    { label: "OL", style: "ordered-list-item", className: "custom-css-class" }
  ]
};


class TextEditor extends Component {
  constructor(props) {
    super();
    this.state = {
      value: Editor.createEmptyValue(),
      activeTitle: ""
    };
  }

  onChange = (value) => {
    this.setState({value});
  }

  onDone=()=>{
     const {readingChallenge} = this.props;
      const data ={
        description: this.state.value.toString('html'),
        content_type: "text"
      }
      if(readingChallenge.current_section_action==='create')
          readingChallenge.requestCreateReadingSection(data)
      else
          readingChallenge.requestUpdateReadingSection(data)
      this.props.activity.setEditorType('');
      this.props.activity.closeEditor();
      // const data = {
      //      description: this.state.value.toString('html'),
      //      content_type: "text"
      //   }
      // this.props.activity.requestUpdateSection(data)
  }

  componentWillMount=()=>{
      const section = this.props.readingChallenge.active_section
      section && section.description && this.setState({
          value: Editor.createValueFromString(section.description, 'html')
      })
  }

  render() {
    return (
      <ActivityPopUpContainer>
        <ActivityAddHeaderContainer>
          <span>Text Editor</span>
          <ThemeButton primary onClick={this.onDone}>
            Done
          </ThemeButton>
        </ActivityAddHeaderContainer>
        <p> </p>
        <Editor
          className="editor"
          value={this.state.value}
          placeholder="Enter Text Here..."
          onChange={this.onChange}
          toolbarConfig={toolbarConfig}
          toolbarClassName="top-toolbar"
          editorClassName="editor-text-area"
        />
     
      </ActivityPopUpContainer>
    );
  }
}
export default connectStore({
  activity,readingChallenge
})(TextEditor)