import React, { Component } from "react";
import { connectStore } from "redux-box";
import { module as activity } from "@st/activity";
import { module as quiz2 } from "@st/quiz2";
import { module as ui } from "@st/ui";
import { module as category } from "@st/category";

import { module as onboard } from "@st/onboard";

import { QuizBodyContainer } from "./Quiz2View.styled";
import {
  MainBodyContentContainer,
  PlusButton,
  PlusIcon,
} from "@sc/custom.styled";
import QuizRouter from "@r/quizRouter";
import Permission from "@s/permission";
import Quiz2List from "./quiz2-list";
import swal from "sweetalert2";

class Quiz2View extends Component {
  constructor(props) {
    super();
  }
  componentWillMount() {
    this.props.quiz2.setFetchQuizList(true);
    this.props.quiz2.requestActiveGrade();
    this.props.category.getCategoryData();
  }
  componentWillUnmount() {
    this.props.quiz2.setQuizList([]);
  }
  handleAddClickButton = (e) => {
    let loc = this.props.location;
    const { permission } = this.props.onboard;
    if (loc.pathname.includes("/quiz2/edit")) {
      this.props.history.push("/quiz2");
    } else {
      if (Permission.getPermission(permission, "add_quiz")) {
        this.props.ui.showModal("QUIZFORM");
      } else {
        swal({
          text: "You do not have permission.",
          type: "warning",
        });
      }
    }
  };
  render() {
    console.log("check props here", this.props);
    const isCreatorOpen = this.props.location.pathname.includes("/quiz2/edit");
    return (
      <QuizBodyContainer>
        <QuizRouter />
        <MainBodyContentContainer>
          <Quiz2List />
        </MainBodyContentContainer>
        <PlusButton
          hide={this.props.ui.show_modal}
          onClick={this.handleAddClickButton}
        >
          <PlusIcon
            primary
            alt="Plus Icon"
            cross={isCreatorOpen}
            src={require("static/img/plusicon.png")}
          />
        </PlusButton>
      </QuizBodyContainer>
    );
  }
}
export default connectStore({ ui, activity, quiz2, category, onboard })(
  Quiz2View
);
