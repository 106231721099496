import React, { Component } from "react";
import { ThemeButton } from "@sc/custom.styled";
import {
  ActivityModalContainer,
  ActivityPopUpContainer
} from "@sc/ActivityModal.styled";
import {
  EnterTagsInputContainer,
  SelectionLabels,
  SelectTagsListContainer,
  TagLabelContainer
} from "./TagEditor.styled";
import { connectStore } from "redux-box";
import { module as activity } from "@st/activity";
import { module as ui } from "@st/ui";
import { PanelHeader } from "@sc/custom.styled";

// export default connectStore({
//   activity,
//   ui
// })
// export default 

class ActivityAssignTags extends Component {
  handleTagChange = e => {
    this.setState({
      tag: e.target.value
    });
  };
  handleEnterClick = e => {
    if (e.key === "Enter" && e.target.value.trim().length) {
      this.handleAddClick(e);
    }
  };
  handleAddClick = e => {
    let { activity } = this.props;
    if (this.state.tag.trim().length) {
      activity.requestCreateTag(this.state.tag);
    }
    this.setState({
      tag: ""
    });
  };

  handleTagClick = tag => {
    let { activity } = this.props;
    activity.requestCreateTag(tag);
  };

  onDoneClick = e => {
    this.props.activity.closeEditor();
  };

  constructor(props) {
    super(props);
    this.state = {
      tag: ""
    };
  }

  render() {
    let selectTagArray = [
      "Teacher stuff",
      "Teacher ",
      "Student",
      "Mathematics"
    ];
    const selectTags = selectTagArray.map((tag, i) => (
      <TagLabelContainer key={i} onClick={() => this.handleTagClick(tag)}>
        <div>{tag}</div>
      </TagLabelContainer>
    ));
    return (
      <ActivityPopUpContainer>
        <PanelHeader primary>
          <span>Assign Tags</span>
          <ThemeButton primary onClick={this.props.activity.closeEditor}>
            Done
          </ThemeButton>
        </PanelHeader>
        <ActivityModalContainer>
          <EnterTagsInputContainer>
            <input
              type="text"
              placeholder="Enter a tag name"
              value={this.state.tag}
              onChange={this.handleTagChange}
              onKeyPress={this.handleEnterClick}
            />
            <button onClick={this.handleAddClick}> +</button>
          </EnterTagsInputContainer>
          <SelectionLabels> OR </SelectionLabels>
          <SelectionLabels primary>Select Tags from below</SelectionLabels>
          <SelectTagsListContainer>{selectTags}</SelectTagsListContainer>
        </ActivityModalContainer>
      </ActivityPopUpContainer>
    );
  }
}
export default connectStore({
  activity,
 ui
 })(ActivityAssignTags)