import { Api } from "./base";

const _headers = () => {
  return {
    Authorization:
      "JWT " + require("store").default.getState().onboard.user.auth_token,
    "Content-Type": "application/json"
  };
};

export default {
  getAllExperiencesList(category, offset, date) {
    return Api.getCsv(
      `/experience/v1/admin-${category}/?offset=${offset}&date=${date}`,
      _headers()
    );
  },
  deleteAllExperienceById(category, experienceId) {
    return Api.deleteCall(
      `/experience/v1/admin-${category}/${experienceId}/`,
      _headers()
    );
  },
  publishAllExperienceById(category, experienceId, data) {
    // debugger;
    return Api.put(
      `/experience/v1/admin-${category}/${experienceId}/`,
      data,
      _headers()
    );
  },
  getAllActiveExperienceById(category, experienceId) {
    return Api.get(
      `/experience/v1/admin-${category}/${experienceId}/`,
      _headers()
    );
  }
};
