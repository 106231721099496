/* eslint-disable require-yield */
import { createSagas } from "redux-box";
import { call, put } from "redux-saga/effects";
import swal from "sweetalert2";
import paymentApi from "@s/api/payment";

const errorHandler = err => {
  console.log(err);
  swal("Oops!", err.error_message, "error");
};

export default createSagas({
  // SAGA FOR PLAN INFO POP UP
  REQUEST_PLAN_INFO: function*() {
    try {
      const res = yield call(paymentApi.requestPlanInfo);

      if (res && res.length > 0)
        yield put({
          type: "SET_PLAN_INFO",
          plan: res[0]
        });
    } catch (err) {
      errorHandler(err);
    }
  },

  // SAGA FUNCTIONS FOR PAYMENT CRUD
  REQUEST_PAYMENT_LIST: function*({ offset }) {
    try {
      yield put({
        type: "SET_FETCH_PAYMENT_LIST",
        value: true
      });
      yield put({
        type: "SET_PAYMENT_OFFSET",
        offset: offset
      });

      const globalState = require("store").default.getState();
      let search = globalState.payment.search_payment;
      let type = globalState.payment.payment_type;
      let date = globalState.payment.payment_date;
      // if (date === '') {
      //     let selectedDate=new Date();
      //     let month=selectedDate.getMonth()>=9?selectedDate.getMonth()+1:'0'+(selectedDate.getMonth()+1);
      //     let day=selectedDate.getDate()<10?'0'+(selectedDate.getDate()):selectedDate.getDate();
      //     date=selectedDate.getFullYear()+"-"+month+"-"+day;
      // }
      // debugger;
      const res = yield call(
        paymentApi.requestPayments,
        search,
        offset,
        type,
        date
      );
      yield put({
        type: "SET_PAYMENT_LIST_RESULT",
        result: res
      });
    } catch (err) {
      errorHandler(err);
    } finally {
      yield put({
        type: "SET_FETCH_PAYMENT_LIST",
        value: false
      });
    }
  },
  //SAGAS FOR TEH FETCH ALL SUBSCRIPTION LIST API
  REQUEST_SUBSCRIPTION_LIST: function*({ subscription }) {
    try {
      if (!subscription.concat) {
        yield put({
          type: "SET_FETCH_SUBSCRIPTION_LIST",
          value: true
        });
      }
      const globalState = require("store").default.getState();
      let childId = globalState.user.active_child.id;

      const res = yield call(
        paymentApi.getAllSubscriptions,
        childId,
        subscription.offset
      );
      yield put({
        type: "SET_SUBSCRIPTION_LIST_RESULT",
        result: res
      });
      if (res && res.result) {
        if (!subscription.concat) {
          yield put({
            type: "SET_SUBSCRIPTION_LIST",
            result: res.result
          });
        } else {
          yield put({
            type: "SET_CONCAT_SUBSCRIPTION_LIST",
            result: res.result
          });
        }
      }
    } catch (err) {
      errorHandler(err);
    } finally {
      yield put({
        type: "SET_FETCH_SUBSCRIPTION_LIST",
        value: false
      });
    }
  },

  REQUEST_SUB_PAYMENT_LIST: function*({ payment }) {
    try {
      const globalState = require("store").default.getState();
      let subId = globalState.payment.active_subscription.id;
      if (!payment.concat) {
        yield put({
          type: "SET_FETCH_SUB_PAYMENT_LIST",
          value: true
        });
      }
      const res = yield call(
        paymentApi.getAllChildPayments,
        subId,
        payment.offset
      );
      yield put({
        type: "SET_SUB_PAYMENT_LIST_RESULT",
        result: res
      });

      if (res && res.result) {
        if (!payment.concat) {
          yield put({
            type: "SET_SUB_PAYMENT_LIST",
            result: res.result
          });
        } else {
          yield put({
            type: "SET_CONCAT_SUB_PAYMENT_LIST",
            result: res.result
          });
        }
      }
    } catch (err) {
      errorHandler(err);
    } finally {
      yield put({
        type: "SET_FETCH_SUB_PAYMENT_LIST",
        value: false
      });
    }
  },

  // SAGAS FOR GENERATING PAYMENT LINK. For fetching child list associated with a contact number
  REQUEST_WEB_PLANS_LIST: function*() {
    try {
      const res = yield call(
        paymentApi.getAllWebPlans,
      );
      if (res.length>0) {
        yield put({
          type: "SET_WEB_PLANS_LIST",
          plans: res
        });
      }
    } catch (err) {
      errorHandler(err);
    } 
  },

  REQUEST_CHILD_LIST: function*({ phoneNumber }) {
    try {
      yield put({
        type: "SET_PHONE_NUMBER",
        phoneNumber: ""
      });
      yield put({
        type: "SET_CHILD_LIST_FOR_PAYMENT_LINK",
        childList: []
      });

      const res = yield call(
        paymentApi.getChildListForPaymentLink,
        phoneNumber
      );
      if (res.length) {
        yield put({
          type: "SET_PHONE_NUMBER",
          phoneNumber: phoneNumber
        });
        yield put({
          type: "SET_CHILD_LIST_FOR_PAYMENT_LINK",
          childList: res
        });
      }
    } catch (err) {
      errorHandler(err);
    } 
  },

  SET_SELECTED_CHILD: function*({ child }) {
    try {
      yield put({
        type: "SET_SELECTED_CHILD_FOR_PAYMENT_LINK",
        child: child
      });
      yield put({
        type: "SET_SELECTED_PLAN_FOR_PAYMENT_LINK",
        plan: {}
      });
      yield put({
        type: "SET_VALIDATED_PROMO_CODE",
        promoCode: null
      });
      yield put({
        type: "SET_PAYMENT_LINK",
        paymentLink: ""
      });
    } catch (err) {
      errorHandler(err);
    } 
  },

  SET_SELECTED_PLAN: function*({ plan }) {
    try {
      yield put({
        type: "SET_SELECTED_PLAN_FOR_PAYMENT_LINK",
        plan: plan
      });
      yield put({
        type: "SET_VALIDATED_PROMO_CODE",
        promoCode: null
      });
      yield put({
        type: "SET_PAYMENT_LINK",
        paymentLink: ""
      });
    } catch (err) {
      errorHandler(err);
    } 
  },

  VALIDATE_PROMO_CODE: function*({ promoCode }) {
    try {
      yield put({
        type: "SET_PAYMENT_LINK",
        paymentLink: ""
      });

      if(promoCode==="") {
        yield put({
          type: "SET_VALIDATED_PROMO_CODE",
          promoCode: null
        });
      }
      else {
        const res = yield call(
          paymentApi.validatePromoCode,
          {promocode: promoCode}
        );
        if (res && res.id) {
          yield put({
            type: "SET_VALIDATED_PROMO_CODE",
            promoCode: res
          });
        }
      }
    } catch (err) {
      errorHandler(err);
    } 
  },

  GENERATE_PAYMENT_LINK: function*() {
    try {
      yield put({
        type: "SET_PAYMENT_LINK",
        paymentLink: ""
      });

      const globalState = require("store").default.getState();
      let body={};
      if(globalState.payment.validatedPromoCode!=null) { 
        body.promo_code = globalState.payment.validatedPromoCode.id;
      }
      
      body.child = globalState.payment.selectedChild.id;
      body.contact = globalState.payment.phoneNumber;
      body.web_plan_id = globalState.payment.selectedPlan.planId;
      body.web_plan_days = globalState.payment.selectedPlan.baseDays;
      body.web_plan_amount = globalState.payment.selectedPlan.baseAmount;
      body.plan_description= globalState.payment.selectedPlan.name;

      const res = yield call(
        paymentApi.generatePaymentLink,
        body
      );
      
      if (res && res.id) {
        yield put({
          type: "SET_PAYMENT_LINK",
          paymentLink: res.short_url
        });  
      }
    } catch (err) {
      errorHandler(err);
    } finally {
      yield put({
        type: "SET_FETCH_SUBSCRIPTION_LIST",
        value: false
      });
    }
  }
});
