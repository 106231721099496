import React, { Component } from "react";
import {
  ActivityContinueButtonContainer,
  ActivityDescription,
  ActivityInputField,
  ActivityMainFormContainer,
  ActivityUploadImageButton,
  CreateActivityContainer,
  SelectFileIn,
  ImageContainer,
  ChangeImageButton,
  UploadActivityImageContainer,
} from "./ActivityCreationForm.styled";
import {
  ThemeButton,
  ImageLoader,
  UploadImagesParentContainer,
  Chevron,
  DropDown,
  ToggleSlider,
  DropDownMenu,
  CheckBall,
  CheckBallInner,
  DropdownItem,
  FlexStart,
  SubLevelItem,
} from "@sc/custom.styled";
import {
  ActivityCreatorNavContainer,
  ActivityDropdownLabel,
  ActivityDropdownLabelContainer,
  ActivityGradeDropdown,
  ActivityGradeDropdownContainer,
  ActivityGradeDropdownMenu,
  ActivityGradeSelectOption,
  FreadomPointsInputField,
} from "@sc/ActivityModal.styled";
import { module as ui } from "@st/ui";
import { module as activity } from "@st/activity";
import { uploadFile } from "@s/api/file";
import { connectStore } from "redux-box";
import swal from "sweetalert2";

const errorHandler = (err) => {
  swal({
    text: err,
    type: "warning",
  });
};


class ActivityCreationForm extends Component {
  constructor(props) {
    super();
    this.state = {
      gradeSelected: null,
      displayMenu: false,
      gradePackSelected: null,
      showActivityCreator: false,
      enableLoad: false,
      enableBackgroundChange: false,
      imageBackgroundChange: false,
      imageChange: false,
      fraedom_point: "",
      file: "",
      activity: {
        name: "",
        description: "",
        grade: "",
        background_image: "",
        activity_pack: "",
        tag: [],
        image: null,
        is_published: true,
        source: "",
      },
     gradeSelector: false,
     gradeList:[],
     selectedGrades:[]
    };
  }
  componentDidUpdate(){

  }
  componentDidMount() {
   let levelList = this.props.activity.grades.map((grade) => {
     let gradeData = {
         ...grade,
       selected: false,
       clicked:false
     };
     return gradeData;
   });//FREAD-1182
   this.setState({
     gradeList: [
      {
        name: "Select All",
        selected: false,
        clicked: false
      },
       ...levelList,
     ],
   });
    if (window.location.pathname === "/activities")
      this.props.activity.unsetActiveActivityPack();
    // this.handleGradeSelected()
    const grade =
      this.props.activity.active_grade || this.props.activity.grades[0];
    const activity_pack = this.props.activity.active_activity_pack || null;

    let gr= [grade]
    if (activity_pack && window.location.pathname !== "/activities")
      this.setState({
        gradeSelected: grade,
        selectedGrades:gr,
        activity: {
          ...this.state.activity,
          grade: "",
          activity_pack: activity_pack.id,
        },
      });
    else {
      this.props.activity.unsetActiveActivityPack();
      this.setState({
        gradeSelected: grade,
        selectedGrades:gr,
        activity: {
          ...this.state.activity,
          grade: grade.id,
          activity_pack: null,
        },
      });
    }
    let gradename = "Select";
    let gradeName = this.props.activity.grades.filter((grade) => {
      return grade.id === this.props.activity.active_pack_grade;
    });
    if (gradeName.length > 0) {
      gradename = gradeName[0].name;

      this.setState({
        gradePackSelected: gradename,
      });
    }
  }


  componentWillReceiveProps(nextProps) {
    if (nextProps.activity && nextProps.activity.active_pack_grade) {
      this.normalizeForState(nextProps.activity.active_pack_grade);
    } else {
      this.setState({
        gradePackSelected: "Select Grade",
      });
    }
  }

  normalizeForState = (activePackGrade) => {
    let gradename = "Select";
    let gradeName = this.props.activity.grades.filter((grade) => {
      return grade.id === activePackGrade;
    });
    if (gradeName.length > 0) gradename = gradeName[0].name;
    this.setState({
      gradePackSelected: gradename,
    });
  };

  handleBackgroundImageFocus = (actionType) => {
    if (actionType === "over" && this.state.imageBackgroundChange === false)
      this.setState({
        imageBackgroundChange: true,
      });
    else if (actionType === "out" && this.state.imageBackgroundChange === true)
      this.setState({
        imageBackgroundChange: false,
      });
  };

  _handleBackgroundImageChange = (e) => {
    // e.preventDefault();
    let reader = new FileReader();
    if (e.target && e.target.files.length > 0) {
      let file = e.target.files[0];
      let fileSize = e.target.files[0].size / 1024 / 1024;
      if (fileSize > 2) {
        swal("Oops!", "File size greater than 2MB ", "warning");
      } else {
        reader.onloadend = () => {
          this.setState({
            enableBackgroundLoad: true,
            imageBackgroundChange: false,
            activity: {
              ...this.state.activity,
              background_image: reader.result,
            },
          });
        };
        reader.readAsDataURL(file);
        uploadFile(file).then((res) => {
          this.setState({
            activity: { ...this.state.activity, background_image: res.file },
            enableBackgroundLoad: false,
          });
        });
      }
    }
  };


  handleImageFocus = (actionType) => {
    if (actionType === "over" && this.state.imageChange === false)
      this.setState({
        imageChange: true,
      });
    else if (actionType === "out" && this.state.imageChange === true)
      this.setState({
        imageChange: false,
      });
  };
  handleSourceChange = (evt) => {
    this.setState({
      activity: { ...this.state.activity, source: evt.target.value },
    });
    // console.log('activity', this.state.activity.source);
  };
  _handleImageChange = (e) => {
    // e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];

    let fileSize = e.target.files[0].size / 1024 / 1024;

    if (fileSize > 2) {
      swal("Oops!", "File size greater than 2Mb ", "warning");
    } else {
      reader.onloadend = () => {
        this.setState({
          imageChange: false,
          enableLoad: true,
          activity: { ...this.state.activity, image: reader.result },
        });
      };
      reader.readAsDataURL(file);
      uploadFile(file).then((res) => {
        this.setState({
          activity: { ...this.state.activity, image: res.file },
          enableLoad: false,
        });
      });
    }
  };

  displayGradeMenu = (e) => {
    this.setState({
      displayMenu: !this.state.displayMenu,
    });
  };

  handleGradeSelected = (grades) => {
    this.setState({
      displayMenu: !this.state.displayMenu,
      gradeSelected: grades,
      activity: { ...this.state.activity, grade: grades.id },
    });
  };

  handleContinueBtn = (e) => {
    this.setState({
      displayMenu: false,
    });
    this.handleAddActivity();
  };

  handleAddActivity = async () => {
    let search = "";
    search += this.state.activity.name === "" ? "activity name" : "";
    // search +=
    //   this.state.activity.grade === "" &&
    //   !this.props.activity.active_activity_pack
    //     ? ", grade name"
    //     : "";
    search +=
      this.state.activity.image === "" || this.state.activity.image === null
        ? ", image"
        : "";

    if (search === "" && this.state.selectedGrades.length > 0) {
      for (const key of this.state.selectedGrades) {

        const gradeId = key.id;
        if (this.state.freadom_point === "")
          await this.props.activity.createActivity({
            ...this.state.activity,
            grade:gradeId,
            freadom_point: 0,
          });
        else {
          await this.props.activity.createActivity({
            ...this.state.activity,
            grade:gradeId,
            freadom_point: this.state.freadom_point,
          });
          this.props.ui.hideModal();
        }
      }
    } else {
      errorHandler("You must enter " + search + " to create activity");
    }
  };

  handleActivityName = (evt) => {
    this.setState({
      activity: { ...this.state.activity, name: evt.target.value },
    });
  };
  handleActivityDescription = (evt) => {
    this.setState({
      activity: { ...this.state.activity, description: evt.target.value },
    });
  };

  handleFreadomPoints = (evt) => {
    if (evt.target.value.length === 0 && this.state.freadom_point !== "") {
      this.setState({
        freadom_point: "",
      });
    } else
      this.setState({
        freadom_point: evt.target.value,
      });
  };


  handleGradeSelector = val => {
    let newGradeData = this.state.gradeList.map((grade) => {
      return { ...grade, clicked: false };
    });
    this.setState({
      gradeList: newGradeData,
      gradeSelector: val,
    });
  };
  handleGradeChange= val => {
    this.handleGradeSelector(true);
    //FREAD-1182
    let newGrades = [];
    if(val === 'Select All' || val === 'Unselect All')
    newGrades= this.state.gradeList.map((grade,i) => {
      if (grade.name === val){
        return { ...grade, selected: val === 'Select All',name:val === 'Select All'?'Unselect All':'Select All' };
      }else{
        return { ...grade, selected: val === 'Select All'}
      }

    })
    else
     newGrades= this.state.gradeList.map((grade,i) => {
      if (grade.name === val){
        return { ...grade, selected: !grade.selected };
      }else{
        return { ...grade}
      }

    })
    const result = newGrades.filter(gr => gr.selected == true);
   console.log(newGrades)
    this.setState({
      gradeList: newGrades,
      selectedGrades: result,
    });
  };
  render() {
    // let { imagePreviewUrl } = this.state;
    let currentPath = window.location.pathname;
    const { activity } = this.props;
    //let gradeLevel=this.props.activity.active_grade_level+"/"+this.props.activity.active_level;
    // const gradeOptions =
    //   activity.grades &&
    //   activity.grades.map((grades, i) => (
    //     <ActivityGradeSelectOption
    //       onClick={this.handleGradeSelected.bind(this, grades)}
    //       key={i}
    //     >
    //       {grades.name}
    //     </ActivityGradeSelectOption>
    //   ));

    return (
      <CreateActivityContainer>
        <UploadImagesParentContainer>
          {!this.state.activity.image ? (
            <UploadActivityImageContainer height="120px" marginBottom="14px">
              <ActivityUploadImageButton type="button">
                <div>
                  <img
                    src={require("static/img/cloud-upload-icon.png")}
                    alt="cloud-upload"
                  />
                </div>
                <span>Upload Image </span>
              </ActivityUploadImageButton>

              <SelectFileIn
                type="file"
                onChange={(e) => this._handleImageChange(e)}
              />
            </UploadActivityImageContainer>
          ) : this.state.enableLoad ? (
            <UploadActivityImageContainer height="120px" marginBottom="14px">
              <ImageLoader>
                {" "}
                <img
                  alt="loading"
                  width="30"
                  height="30"
                  src="http://photokonkurs.russia.travel/images/icons/preloader.gif"
                />
              </ImageLoader>
            </UploadActivityImageContainer>
          ) : (
            <UploadActivityImageContainer
              height="120px"
              marginBottom="14px"
              onMouseOver={() => this.handleImageFocus("over")}
              onMouseOut={() => this.handleImageFocus("out")}
            >
              <ImageContainer src={this.state.activity.image} />
              <ChangeImageButton
                top={this.state.imageChange ? "83%" : "100%"}
                opacity={this.state.imageChange ? 1 : 0}
              >
                Change Image
              </ChangeImageButton>
              <SelectFileIn
                type="file"
                onChange={(e) => this._handleImageChange(e)}
              />
            </UploadActivityImageContainer>
          )}

          {this.state.activity.background_image === "" ? (
            <UploadActivityImageContainer height="180px">
              <ActivityUploadImageButton type="button">
                <div>
                  {" "}
                  <img
                    src={require("static/img/cloud-upload-icon.png")}
                    alt="cloud-upload-full-screen"
                  />
                </div>
                <span>Upload Full screen Image </span>
              </ActivityUploadImageButton>

              <SelectFileIn
                type="file"
                onChange={(e) => this._handleBackgroundImageChange(e)}
              />
            </UploadActivityImageContainer>
          ) : this.state.enableBackgroundLoad ? (
            <UploadActivityImageContainer height="180px">
              <ImageLoader>
                {" "}
                <img
                  alt="loading"
                  width="30"
                  height="30"
                  src="http://photokonkurs.russia.travel/images/icons/preloader.gif"
                />
              </ImageLoader>
            </UploadActivityImageContainer>
          ) : (
            <UploadActivityImageContainer
              height="180px"
              marginBottom="14px"
              onMouseOver={() => this.handleBackgroundImageFocus("over")}
              onMouseOut={() => this.handleBackgroundImageFocus("out")}
            >
              <ImageContainer src={this.state.activity.background_image} />
              <ChangeImageButton
                top={this.state.imageBackgroundChange ? "83%" : "100%"}
                opacity={this.state.imageBackgroundChange ? 1 : 0}
              >
                Change Image
              </ChangeImageButton>
              <SelectFileIn
                type="file"
                onChange={(e) => this._handleBackgroundImageChange(e)}
              />
            </UploadActivityImageContainer>
          )}
        </UploadImagesParentContainer>

        <ActivityMainFormContainer>
          <form>
            <ActivityCreatorNavContainer>
              {currentPath === "/activities" ? (
                // <ActivityGradeDropdownContainer>
                //   <ActivityGradeDropdown onClick={this.displayGradeMenu}>
                //     <ActivityDropdownLabelContainer>
                //       <ActivityDropdownLabel>
                //         {this.state.gradeSelected
                //           ? this.state.gradeSelected.name
                //           : "Select Grade"}
                //       </ActivityDropdownLabel>
                //       <img
                //         src={require("static/img/downarrowicon.png")}
                //         alt="down-arrow"
                //       />
                //     </ActivityDropdownLabelContainer>
                //   </ActivityGradeDropdown>
                //   {this.state.displayMenu ? (
                //     <ActivityGradeDropdownMenu>
                //       {gradeOptions}
                //     </ActivityGradeDropdownMenu>
                //   ) : null}
                // </ActivityGradeDropdownContainer>
                <DropDown
                  minWidth="160px"
                  active={this.state.gradeSelector}
                  onBlur={() => this.handleGradeSelector(false)}
                  tabIndex="1"
                >
                  <div
                    style={{ fontSize: "14px", height: "24px" }}
                    onClick={() =>
                      this.handleGradeSelector(!this.state.gradeSelector)
                    }
                  >
                    {this.state.selectedGrades.length >= 12
                      ? "All "
                      : this.state.selectedGrades.length + " "}{" "}
                    Grade Selected
                    <Chevron>
                      <img
                        src={require("static/img/downarrowicon.png")}
                        alt="down-arrow"
                      />
                    </Chevron>
                  </div>
                  <DropDownMenu
                    maxHeight="150px"
                    active={this.state.gradeSelector}
                    color="#fff"
                    style={{ padding: "0px" }}
                  >
                    {this.state.gradeList.map((grade, index) => (
                      <DropdownItem>
                        <FlexStart
                          style={{
                            width: "100%",
                            padding: "6px 8px",
                            position: "relative",
                            borderBottom: "solid 1px #e6e6e6",
                          }}
                          onMouseDown={(e) => e.preventDefault()}
                          onClick={() => this.handleGradeChange(grade.name)}
                        >
                          <CheckBall
                            style={{ marginRight: "6px" }}
                            active={grade.selected}
                          >
                            <CheckBallInner active={grade.selected} />
                          </CheckBall>

                          {grade.selected}
                          {grade.name}
                        </FlexStart>
                      </DropdownItem>
                    ))}
                  </DropDownMenu>
                </DropDown>
              ) : null}
              <FreadomPointsInputField
                style={
                  this.state.activity.grade === ""
                    ? { marginLeft: "0px", width: "50%" }
                    : {}
                }
              >
                <input
                  type="number"
                  placeholder="Freadom Points"
                  value={this.state.freadom_point || ""}
                  onChange={this.handleFreadomPoints}
                />
              </FreadomPointsInputField>
            </ActivityCreatorNavContainer>

            <ActivityInputField
              type="text"
              placeholder="Add Activity Title"
              value={this.state.activity.name || ""}
              onChange={this.handleActivityName}
            />
            <ActivityInputField
              type="text"
              placeholder="Add activity source"
              padding="2px 10px"
              value={this.state.activity.source || ""}
              onChange={this.handleSourceChange}
            />

            <div>
              <ActivityDescription
                style={{ height: "200px" }}
                placeholder="Add a short description"
                id="activityDescription"
                value={this.state.activity.description || ""}
                onChange={this.handleActivityDescription}
              />
            </div>
            <ActivityContinueButtonContainer>
              <ThemeButton
                primary
                type="button"
                onClick={() => this.handleContinueBtn()}
              >
                Continue
              </ThemeButton>
            </ActivityContinueButtonContainer>
          </form>
        </ActivityMainFormContainer>
      </CreateActivityContainer>
    );
  }
}
export default connectStore({
  ui,
  activity,
 })(ActivityCreationForm)
