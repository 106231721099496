import React, { Component, Fragment } from "react";
import swal from "sweetalert2";
import {
  FlatCard,
  FlexEnd,
  BottomDetail,
  IconContainer,
  FlexCenter,
  FlatBackdrop
} from "static/styled/custom.styled";
import { connectStore } from "redux-box";
import { module as experience } from "store/experience";
import Pravatar from "pravatar";
import moment from "moment";
import { DateTimeContainer, NameContainer } from "../../experiences-view/ExperiencesView.styled.js";
import { FlexBetween } from "../../../static/styled/custom.styled";
import { module as onboard } from "@st/onboard";
import Permission from "@s/permission";


class AllExperienceCard extends Component {
  //HANDLES UNPUBLISH / PUBLISH EXPERIENCE FOR SELECTED CATEGORY
  handlePublishExperience = (evt, experienceInfo) => {
    let value = null;
    if (experienceInfo.is_published) value = "Unpublish";
    else value = "Publish";

    swal({
      text: "Are you sure that you want to " + value + "  this experience?",
      type: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No"
    }).then(result => {
      if (result.value) {
        this.props.experience.requestPublishAllExperienceById({
          id: experienceInfo.id,
          data: { is_published: !experienceInfo.is_published }
        });
      }
    });
    evt.preventDefault();
    evt.stopPropagation();
  };
  handleDownloadExperience = evt => {
    evt.stopPropagation();
    document.getElementById("link").click();
  };
  handleDownLoadError = evt => {
    evt.stopPropagation();
    evt.preventDefault();
    swal("Sorry!", "No media attached to this experience", "warning");
  };
  handleDeleteExperience = (evt, expId) => {
    swal({
      text: "Are you sure that you want to delete this experience?",
      type: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No"
    }).then(result => {
      if (result.value) {
        this.props.experience.requestDeleteAllExperienceById(expId);
      }
    });
    evt.stopPropagation();
    evt.preventDefault();
  };
  cardGroupByDate() {
    let { experienceInfo } = this.props;
    
    // console.log(experienceInfo.created_at)

    let newDate = new Date(experienceInfo.created_at)
    console.log(newDate.getUTCDate())

  }
  // handleConvertData(data) {
  //   return moment(data)
  //     .startOf("day")
  //     .fromNow();
  // }
  handleConvertDay(data) {
    return moment(data).format("MMM Do YY")
  }
  handleConvertTime(data) {
    return moment(data).format("h:mm:ss a")
  }
  // handleConvertModifiedData(data) {
  //   return moment(data)
  //     .startOf("day")
  //     .fromNow();
  // }
  // moment().format('MMMM Do YYYY, h:mm:ss a');



  // var datesArray = [{whole json file}]
  // var clonedArray=JSON.parse(JSON.stringify(experienceInfo.created_at);
  // function createCustomisedJson(){
  // var  parentArray={
  // clonedArray
  // }
  // for(let i=0;i<clonedArray.length;i++){
  // }
  // }
  // function searchElement(value)
  //   var similarElements=[];
  //   for(let i =0;i<clonnedArray.length;i++){
  //     if(value ==clonedArray[i]/date){
  //            similarElements.push(clonedArray[i]);
  //           clonnedArray.splice() // pass index
  //           i--;
  //     }
  //   }
  //   return similarElements;
  // }


  render() {

    let { experienceInfo } = this.props;
    const {permission} = this.props.onboard
    return (

      <FlatCard
        bg={

          experienceInfo &&
          experienceInfo.content_type === "image" &&
          experienceInfo.media &&
          experienceInfo.media.length &&
          experienceInfo.media[0].thumbnail_url
        }
      >
        <FlatBackdrop
          bg={
            experienceInfo &&
            experienceInfo.media &&
            experienceInfo.media.length &&
            experienceInfo.media[0].thumbnail_url
          }
        />
        <NameContainer>
          <div style={{ position: 'absolute', display: 'flex', top: '6px', left: '8px', zIndex: '100' }}>
            <Pravatar
              text={
                experienceInfo.child_image
                  ? ""
                  : experienceInfo.child_name.slice(0, 1)
              }
              image={experienceInfo.child_image}
              bg="#fff"
              textColor="#333"
              height="20px"
              width="20px"
              fontSize='12px'
            />
            <p style={{
              marginLeft: '3px', position: 'absolute',
              left: '24px',
              top: '1px',
              fontSize: '12px',
              width: '160px',
              maxWidth: '160px',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis'

            }}>{experienceInfo && experienceInfo.child_name} </p>
          </div>
        </NameContainer>
        <div style={{ display: 'flex', flexDirection: 'column', width: '100%', marginTop: '5px' }}>
          <DateTimeContainer style={{
            zIndex: '100', display: 'flex',
            width: '100%', paddingLeft: '8px', paddingRight: '8px',
            justifyContent: 'space-between'
          }}>
            <div style={{ color: 'white', fontSize: '12px', }} className="date-container">
              {this.handleConvertDay(experienceInfo.created_at)}
            </div>
            <div style={{ color: 'white', fontSize: '12px', }} className="time-container">
              {this.handleConvertTime(experienceInfo.created_at)}
            </div>
          </DateTimeContainer>
          {/* description container start*/}
          <div style={{ color: 'white', zIndex: '100', }} active={experienceInfo && experienceInfo.text === ""}>
            {experienceInfo && (
              <div style={{ margin: "0 8px" }}>
              </div>
            )}

            <div className="description" style={{ marginLeft: '8px', color: 'white', zIndex: '100', fontSize: '12px' }}>

              <div>
                {experienceInfo &&
                  experienceInfo.text &&
                  experienceInfo.text.length > 50
                  ? experienceInfo.text.slice(0, 50)
                  : experienceInfo.text}
              </div>
            </div>
          </div>
          {/* description container end */}
        </div>
        <FlexEnd>
        {Permission.getPermissionMulti(permission, ["change_readingexperience","change_activityexperience","change_storyexperience"])?
          <IconContainer
            size="20px"
            onClick={e => this.handlePublishExperience(e, experienceInfo)}
          >
            <i
              className="fa fa-check-circle"
              style={{
                color:
                  experienceInfo && experienceInfo.is_published
                    ? "#61bd4f"
                    : "#fff"
              }}
            />
          </IconContainer>
          :null}
          {experienceInfo &&
            experienceInfo.media &&
            experienceInfo.media.length > 0 ? (
              <IconContainer size="20px">
                {/*<a download="abcd.jpeg" href={experienceInfo.media[0].file}> hello</a>*/}

                <a
                  id="link"
                  href={experienceInfo && experienceInfo.media[0].media_url}
                  download
                  target="_blank"
                  onClick={evt => this.handleDownloadExperience(evt)}
                >
                  <i className="fa fa-cloud-download" style={{ color: "#fff" }} />
                </a>
              </IconContainer>
            ) : (
              <IconContainer size="20px">
                <i
                  className="fa fa-cloud-download"
                  style={{ color: "#fff" }}
                  onClick={evt => this.handleDownLoadError(evt)}
                />
              </IconContainer>
            )}
          {Permission.getPermissionMulti(permission, ["delete_readingexperience","delete_activityexperience","delete_storyexperience"])?
          <IconContainer
            size="20px"
            onClick={e => this.handleDeleteExperience(e, experienceInfo.id)}
          >
            <i className="fa fa-trash" style={{ color: "#fff" }} />
          </IconContainer>:null}
        </FlexEnd>
        {experienceInfo && experienceInfo.content_type === "video" ? (
          <FlexCenter>
            <i
              className="fa fa-play-circle"
              style={{ color: "#fff", fontSize: "28px" }}
            />
          </FlexCenter>
        ) : null}
      </FlatCard>
    );
  }
}
export default connectStore({
  experience,onboard
 })(AllExperienceCard)