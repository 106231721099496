import React, { Component } from "react";
import { StorySectionContainer } from "@c/story-view/story-creator/StoryCreator.styled";
import {
  FlexStart,
  SubHeading,
  ImagesListContainer,
  MediaThemedButton
} from "static/styled/custom.styled";
import { connectStore } from "redux-box";
import { module as story } from "store/story";
import {
  DeleteItemContainer,
  PhotoImage
} from "@c/activity-view/activity-creator/media-editor/MediaEditor.styled";
import uploadFile from "services/api/file";
import swal from "sweetalert2";
import { SelectFileIn } from "@c/activity-view/activity-creation-form/ActivityCreationForm.styled";
import { PhotoAlbumItemContainer } from "./ImagesStory.styled";
import { module as onboard } from "@st/onboard";
import Permission from "@s/permission";

class ImagesStory extends Component {
  constructor(props) {
    super();
    this.state = {
      media: [],
      enableLoad: false
    };
  }

  componentDidMount() {
    let { section } = this.props;
    if (section !== null && section.content_type === "image") {
      this.setState({
        media: section.media
      });
    }
  }

  handleUploadImages = e => {
    let i = 0;
    let { story } = this.props;

    if (e.target.files) {
      story.setFetchImagesLoading(true);
      story.setFetchMediaLoading(true);
      let media = [];
      let value = false;
      if (
        story.active_story &&
        story.active_story.section_data &&
        story.active_story.section_data.length === 0
      ) {
        let newSection = {
          content_type: "image",
          media: [],
          name: "image",
          description: "",
          story: story.active_story.id
        };
        this.props.story.requestCreateStorySection(newSection);
      }

      while (i < e.target.files.length) {
        media = this.state.media;
        value = e.target.files && i === e.target.files.length - 1;
        uploadFile(e.target.files[i]).then(res => {
          media.push({ thumbnail_url: "", media_url: res.file });
          if (
            story.active_story &&
            story.active_story.section_data &&
            story.active_story.section_data.length > 0
          ) {
            this.setImageSection(media, value);
          }
        });
        i++;
      }
    }
  };

  setImageSection = (media, value) => {
    let { story } = this.props;

    if (story.active_story) {
      let newSection = {
        content_type: "image",
        media: media,
        name: "image",
        description: "",
        story: story.active_story.id
      };
      story.requestUpdateStorySection(newSection);
    }
    if (value) {
      story.setFetchImagesLoading(false);
      story.setFetchMediaLoading(false);
    }
  };

  handleImageRemoval = selectedMediaFile => {
    let media = this.state.media;
    swal({
      text: "Are you sure you weant to remove this image!",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "red",
      cancelButtonColor: "skyblue",
      confirmButtonText: "Yes, delete it!"
    }).then(result => {
      if (result.value) {
        media = media.filter(media => {
          return selectedMediaFile.media_url !== media.media_url;
        });
        this.setImageSection(media, false);
        this.setState({
          media: media
        });
      }
    });
  };

  render() {
    const {permission} = this.props.onboard
    return (
      <StorySectionContainer>
        <FlexStart>
          {Permission.getPermission(permission, "change_story") ?
          <MediaThemedButton primary>
            {" "}
            Upload Images
            <SelectFileIn
              type="file"
              accept=".jpg, .jpeg, .png, .gif"
              onChange={this.handleUploadImages}
              multiple
            />
          </MediaThemedButton>:null}
          {this.props.story.fetch_images_loading ? (
            <SubHeading style={{ margin: "0px", marginLeft: "12px" }}>
              Uploading Images{" "}
              <img
                alt="loader"
                src={require("static/img/Theme-loader.gif")}
                width="24"
                height="24"
              />
            </SubHeading>
          ) : null}
        </FlexStart>

        {this.state.media && this.state.media.length > 0 ? (
          <ImagesListContainer active={this.state.media.length > 0}>
            <SubHeading>
              {this.state.media.length}
              &nbsp;Images Added
            </SubHeading>
            <div
              style={
                this.state.media.length > 0
                  ? { display: "flex", flexFlow: "wrap" }
                  : {}
              }
            >
              {this.state.media.map((media, i) => (
                <PhotoAlbumItemContainer key={media.media_url}>
                  <PhotoImage src={media.media_url} alt="Photo item" />
                  {Permission.getPermission(permission, "change_story") ?
                  <DeleteItemContainer
                    src={require("static/img/photo-cancel-icon.png")}
                    onClick={() => this.handleImageRemoval(media)}
                  />:null}
                </PhotoAlbumItemContainer>
              ))}
            </div>
          </ImagesListContainer>
        ) : null}
      </StorySectionContainer>
    );
  }
}
export default connectStore({
  story,onboard
})(ImagesStory)