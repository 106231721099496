import React, { Component } from "react";
import { AutoContainer,EmptyContainer,AlignContentHorizontal, LoadingContainer, InfiniteScrollParentContainer, InfiniteScrollLoader} from "static/styled/custom.styled";
import ActivityPackCard from "./ActivityPackCard/ActivityPackCard";
import { connectStore } from "redux-box";
import { module as activity } from "@st/activity";
import { Link } from "react-router-dom";
import { NavigationHeaderContainer,NavigationContainer } from "static/styled/NavigationBar.styled";
import swal from "sweetalert2";
import InfiniteScroll from 'react-infinite-scroller';
import { module as onboard } from "@st/activity";

// export default connectStore({
//   activity
// })
class ActivityPackList extends Component {
  componentDidMount() {

      this.props.activity.setActiveGradeLevel(this.props.match.params.gradeLevel);
      this.props.activity.requestActivityPacks();
  }

    handleDeleteClick=(activityPack,e)=>{
        e.preventDefault();
        swal({
            text: "Are you sure that you want to delete this activity pack ?",
            type: "warning",
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No'

        }).then((result) => {
            if (result.value) {
                this.props.activity.updateActivityPack({...activityPack,is_deleted:true});
            }

        });

    }


    fetchMoreActivityPacks= () => {


        if (this.props.activity.activity_packs_result !== null && this.props.activity.activity_packs_result.has_next) {

            setTimeout(() => {
                if(this.props.activity.activity_packs_result!==null && this.props.activity.activity_packs_result.next_page>0)
                    this.props.activity.requestConcatActivityPacks(this.props.activity.activity_packs_result.next_page)

            }, 300);
        }
    };


    handlePublishClick=(activityPack,e)=>{
        e.preventDefault();
        let value=null;
        if(activityPack.is_published)
            value="unpublish";
        else
            value="publish";

        swal({
            text: "Are you sure that you want to "+ value +" this activity pack ?",
            type: "warning",
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No'

        }).then((result) => {
            if (result.value) {
                this.props.activity.publishActivityPack({...activityPack,is_published:!activityPack.is_published});

            }

        });
        e.stopPropagation();
    }

    handleLevelChange (subLevel) {
        let {activity}=this.props;
        activity.setActiveLevel(subLevel);
        activity.requestActivityPacks();}

  render() {
    const { activity } = this.props;
    const {permission} = this.props.onboard
    const levels=['Easy','Hard'];
    let activityPacks=this.props.activity.activityPacks;
      let classList = levels.map((level, i) => (
              <Link to={"/activities/packs/"+this.props.match.params.gradeLevel+"/"+level}
                    key={i}
                    className={level ===this.props.match.params.level? "active" : ""}
                    onClick={()=>this.handleLevelChange(level) }>
              {level}
              </Link>
      ));
    return (
        <AutoContainer>
            <NavigationHeaderContainer shadow>
                <NavigationContainer>
                    {classList}
                </NavigationContainer>
            </NavigationHeaderContainer>
            {this.props.activity.fetch_activity_packs_by_level?
                <LoadingContainer style={{zIndex:'100'}}>
                    <img alt="loading" width="60" height="60" src={require("static/img/Theme-loader.gif")}  />
                </LoadingContainer>  :(<InfiniteScrollParentContainer>
                    <InfiniteScroll
                        pageStart={0}
                        loadMore={this.fetchMoreActivityPacks}
                        style={{position:'relative'}}
                        hasMore={this.props.activity.activity_packs_result && this.props.activity.activity_packs_result.has_next}
                        loader={<InfiniteScrollLoader className="loader" key={0} > <img alt="loading" width="60" height="60" src={require("static/img/Theme-loader.gif")}/> </InfiniteScrollLoader>}
                        useWindow={false}>
      <AlignContentHorizontal marginBottom="70px">
          { activity.activityPacks && activityPacks.length>0? activityPacks.map((activityPack, index) => {
          return (
            <div className="col-sm-6 col-md-4 col-lg-3" key={index}>
              <Link to={"/activities/packs/"+ this.props.match.params.gradeLevel+"/"
                +this.props.activity.active_level+"/edit/" + activityPack.id}
                onClick={() => this.props.activity.requestActivityPackById(activityPack.id)
                }
              >
                <ActivityPackCard activityPack={activityPack}
                                    permission={permission}
                                  handleDeleteClick={(e)=> this.handleDeleteClick(activityPack,e)}
                                  handlePublishClick={(e) => this.handlePublishClick(activityPack,e) }  />
              </Link>
            </div>
          );
        }):<EmptyContainer >
            <h4 >NO ACTIVITY PACKS CREATED FOR THIS LEVEL</h4>
                     </EmptyContainer>}
                </AlignContentHorizontal>
                    </InfiniteScroll>
                </InfiniteScrollParentContainer>)}
        </AutoContainer>
    );
  }
}

// export default ActivityPackList;
export default connectStore({
    activity,onboard
   })(ActivityPackList)
