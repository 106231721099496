import styled from "styled-components";
import { FlexBetween, PopUpContainer } from "./custom.styled";
import { ParentFormField } from "@c/users-view/parent-profile/ParentProfile.styled";

export const ActivityModalContainer = styled.div`
  width: 100%;
  height: 100%;
  padding-top: 55px;
`;

export const ActivityPopUpContainer = PopUpContainer.extend`
  padding-top: 0px;
`;

export const ActivityModalHeader = FlexBetween.extend`
  border-bottom: 1px solid var(--border);
  margin: 0px;
  position: absolute;
  width: 28%;
  background: white;
  padding: 10px 4px;
  margin-bottom: 8px;

  span {
    font-size: var(--lg-font-size);
    line-height: 1.33;
    text-align: left;
    color: #000000;
    margin-top: 6px;
    font-weight: 600;
  }
`;

export const ActivityPopupGenre = styled.div`
  font-size: var(--md-font-size);
  font-weight: 500;
  text-align: left;
  margin: 2px 0px 0px;
  color: rgba(52, 23, 68, 0.7);
`;

export const ActivityPopupActivityName = styled.div`
  font-size: 20px;
  line-height: 1.33;
  text-align: left;
  color: #000000;
  margin: 6px 0px;
  font-weight: 600;
`;

export const ActivityPopupPoints = styled.div`
  font-size: var(--sm-font-size);
  font-weight: 600;
  text-align: left;
  display: inline-block;
  color: #fd990a;
`;

export const BulletIcon = styled.div`
  width: 5px;
  display: inline-block;
  height: 5px;
  margin-right: 4px;
  border-radius: 100%;
  background-color: #fd990a;
`;

export const ActivityPopupDescription = styled.div`
  font-size: var(--md-font-size);
  margin: 8px 0;
  line-height: 1.44;
  text-align: left;
  color: #000000;
`;

export const ActivityAddHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: 14px;
  z-index: 100;
  align-items: center;
  width: 100%;
  span {
    font-size: var(--lg-font-size);
    line-height: 1.33;
    text-align: left;
    color: #000000;
    margin-top: 6px;
    font-weight: 600;
  }
`;
// ACTIVITY DROP-DOWN MENU

export const FreadomPointsInputField = ParentFormField.extend`
  width: 158px;
  padding: 2px 6px;

  margin-left: ${(props) => (props.primary ? "8px" : "24px")};

  ::placeholder {
    font-size: ${(props) => props.width};
  }
`;

export const ActivityCreatorNavContainer = styled.div`
  width: 100%;
  // display:flex;
  // flex-flow:row nowrap;
  // justify-content-flex-start
`;

export const ActivityDropdownLabel = styled.div`
  font-size: var(--sm-font-size);
  letter-spacing: 0.1px;
  text-align: left;
  color: #000000;
  margin-left: 14px;
`;

export const ActivityDropdownLabelContainer = styled.div`
  position: relative;
  img {
    position: absolute;
    top: 4px;
    left: ${(props) => (props.left ? props.left : "105px")};
    width: 8px;
    height: 8px;
  }
`;

export const ActivityGradeDropdown = styled.div`
  display: inline-block;
  margin-bottom: 0;
  width: ${(props) => (props.width ? props.width : "130px")};
  height: 30px;
  padding: 5px 0px;
  color: #b9b9b9;
  font-size: var(--lg-font-size);
  outline: none;
  font-weight: 400;
  line-height: 1.42857143;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  border-radius: 25.5px;
  border: solid 1px #d8d8d8;
`;

export const ActivityGradeDropdownContainer = styled.div`
  position: relative;
  width: ${(props) => (props.width ? props.width : "130px")};
  margin: 0px;
  outline: none;
  padding-bottom: 0px;
`;

export const ActivityGradeDropdownMenu = styled.div`
  position: absolute;
  top: 100%;
  width: ${(props) => (props.width ? props.width : "120px")};

  scroll-snap-type: inline;
  left: 0;
  z-index: 1;
  margin-bottom: 0;
  display: block;
  float: left;
  min-width: 130px;
  font-size: var(--sm-font-size);
  text-align: left;
  list-style: none;
  background-color: #fff;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  border: 1px solid #ccc;

  overflow-y: scroll;
  float: left;
  min-height: 32px;
  max-height: ${(props) => (props.height ? props.height : "80px")};
  border-radius: 8px;
  -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
`;

export const ActivityGradeSelectOption = styled.div`
  height: 26px;
  padding: 4px 14px;
  font-size: var(--sm-font-size);
  transition: all 0.3s;
  &:hover {
    background-color: rgba(255, 138, 35);
    color: white;
    cursor: pointer;
    font-weight: 500;
  }
`;

export const ActivityImageContainer = styled.div`
  height: 150px;
  width: 120px;
  border-radius: 5px;
  img {
    height: 150px;
    border-radius: 5px;
  }
`;

export const ActivityDescription = styled.div`
  padding-left: 20px;
  max-height: ${(props) => (props.height ? props.height : "151px")};
  width: 100%;
`;

export const ActivityDescriptionInfoContainer = styled.div`
  display: flex;
  padding: ${(props) => (props.primary ? "20px" : "0px")};
`;

export const ActivityDescriptionTextArea = styled.textarea`
  outline: none;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.6);
  border-radius: 10px;
  background-color: white;
  resize: none;
  overflow-y: ${(props) => (props.primary ? "auto" : "none")};
  width: ${(props) => (props.primary ? "75%" : "100%")};
  border: ${(props) => (props.primary ? "solid 1px rgba(40,50,112,0.1)" : "0")};
  padding: ${(props) => (props.primary ? "4px 8px" : "0px")};
  margin: ${(props) => (props.primary ? "5px 0px 0px 0px" : "0px")};
  height: ${(props) =>
    props.primary
      ? props.height
        ? props.height
        : "25px"
      : props.max
      ? props.max
      : "25px"};

  &::placeholder {
    font-size: var(--sm-font-size);
  }
`;

export const GradeSelectionContainer = styled.div`
  padding: 18px 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const AssessmentLabels = styled.span`
  font-size: var(--lg-font-size);
  font-weight: bold;
`;
export const AssessmentFooter = styled.div`
  position: absolute;
  bottom: 0;
  padding: 0 24px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  left: 0;
  background: white;
  z-index: 1;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  box-shadow: 0px 2px 4px 4px rgba(0, 0, 0, 0.3);
`;
