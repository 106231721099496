import styled from "styled-components";

export const YoutubeLink = styled.div`
  width: 120px;
  margin-left: 10px;

  a {
    text-decoration: none;
    width: 100%;
    height: 100%;
  }
`;

export const YoutubeListContainer = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  padding-bottom: 5px;
`;
