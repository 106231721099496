import React from "react";
import { Route, Switch, withRouter } from "react-router-dom";
import SentNotificationsList from "@c/notifications-view/sent-notifications-list/SentNotificationsList";
import ScheduledNotificationsList from "@c/notifications-view/scheduled-notifications-list/ScheduledNotificationsList";

export const Routes = function({ location }) {
  return (
    <Switch location={location}>
      <Route component={SentNotificationsList} path="/notifications/failed" />
      <Route component={SentNotificationsList} path="/notifications/sent" />
      <Route
        component={SentNotificationsList}
        path="/notifications/in-progress"
      />
      <Route
        component={ScheduledNotificationsList}
        path="/notifications/scheduled"
      />
    </Switch>
  );
};

export default withRouter(Routes);
