import React, { Component } from "react";
import {
  ActivityContinueButtonContainer,
  ActivityMainFormContainer,
  CreateActivityContainer
} from "@c/activity-view/activity-creation-form/ActivityCreationForm.styled";
import { InputBox, InputArea } from "@sc/InputField.styled";
import { ThemeButton } from "@sc/custom.styled";
import { module as ui } from "@st/ui";
import { module as tags } from "@st/tags";
import { connectStore } from "redux-box";
import swal from "sweetalert2";


class TagCreationForm extends Component {
  constructor(props) {
    super();
    this.state = {
      name: "",
      ideal_value: "",
      description: ""
    };
  }

  handleContinueBtn = () => {
    if (this.state.name && this.state.ideal_value) {
      this.props.tags.createParentTag({
        name: this.state.name,
        ideal_value: this.state.ideal_value,
        parent_tag: null,
        description: this.state.description
      });
      this.props.ui.hideModal();
    } else {
      swal("Oops!", "You must enter name and accuracy value", "error");
    }
  };

  handleTagNameChange = evt => {
    this.setState({
      name: evt.target.value
    });
  };
  handleValueChange = evt => {
    this.setState({
      ideal_value: evt.target.value
    });
  };
  handleDescriptionChange = e => {
    this.setState({
      description: e.target.value
    });
  };

  render() {
    return (
      <CreateActivityContainer>
        <ActivityMainFormContainer>
          <form>
            <InputBox
              type="text"
              autoFocus={true}
              placeholder="Enter Skill Name"
              value={this.state.name}
              onChange={this.handleTagNameChange}
            />
            <InputBox
              type="number"
              placeholder="Enter Accuracy Value"
              value={this.state.ideal_value}
              onChange={this.handleValueChange}
            />
            <InputArea
              placeholder="Enter description"
              value={this.state.description}
              onChange={this.handleDescriptionChange}
            />
            <ActivityContinueButtonContainer>
              <ThemeButton
                primary
                type="button"
                onClick={this.handleContinueBtn}
              >
                Continue
              </ThemeButton>
            </ActivityContinueButtonContainer>
          </form>
        </ActivityMainFormContainer>
      </CreateActivityContainer>
    );
  }
}
export default connectStore({
  ui,
  tags
})(TagCreationForm);
