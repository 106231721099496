import React, { Component } from 'react';
//import {FlexBetween} from "static/styled/custom.styled";
import {
    SearchBarContainer
} from "@c/users-view/MainContent.styled";
import { NavigationContainer, NavigationHeaderContainer, NavigationToolbar, RefreshBtn } from "static/styled/NavigationBar.styled";
import { Link } from "react-router-dom";
import { connectStore } from "redux-box";
import { module as notification } from "store/notification";



class NotificationsHeader extends Component {
    constructor(props) {
        super();
        this.state = ({
            navigationSelector: 0,
            selectedData: 'sent',
            searchBarEnable: false,
            refreshBtn: false,
            displayMenu: false,
            filter_type: 'all',
            message_type: 'all',

        });
    }
    componentDidMount() {
        // let types = ['sent', 'scheduled', 'in-progress']

        let currentPath = window.location.pathname;

        if (currentPath === 'sent') {
            this.setState({
                navigationSelector: 2,
                selectedData: 'sent',

            })
        }
        else if (currentPath === 'failed') {
            this.setState({
                navigationSelector: 3,
                selectedData: 'failed',

            })
        }
        else if (currentPath === 'scheduled') {
            this.setState({
                navigationSelector: 0,
                selectedData: 'scheduled',

            })

        }
        else if (currentPath.includes("in-progress")) {
            this.setState({
                navigationSelector: 1,
                selectedData: 'in-progress',

            })
        }
    }

    handleRefreshBtn = () => {
        console.log('hello')
        this.setState({
            refreshBtn: !this.state.refreshBtn,
        })
        let { notification } = this.props;
        notification.requestAllNotifications({
            offset: 0,
            type: this.state.selectedData,
        });
        setTimeout(() => {
            this.setState({
                refreshBtn: !this.state.refreshBtn,
            })
        }, 500);
    }
    handleNavigationSelector = (data, value) => {
        let { notification } = this.props;
        if (!notification.unset_data)
            notification.unsetNotificationData(true);

        this.setState({

            navigationSelector: value,
            selectedData: data,
        })

        notification.requestAllNotifications({ offset: 0, type: data });

        if (notification.active_notification) {
            notification.unsetDropdownData();
        }
        this.props.notification.setSelectedNotType(data);
        notification.setActiveNotification(null);
    };

    enableSearchBar = actionType => {
        if (actionType === "focus" && this.state.searchBarEnable === false)
            this.setState({
                searchBarEnable: true
            });
        else if (actionType === "blur" && this.state.searchBarEnable === true)
            this.setState({
                searchBarEnable: false
            });
    };

    displayMenu = () => {
        this.setState({
            displayMenu: !this.state.displayMenu,
        })
    };
    handleClearSearch = () => {
        let { notification } = this.props;
        notification.setSearchNotification("");
        notification.requestAllNotifications();
    };
    handleSearchNotification = (evt) => {
        let { notification } = this.props;
        notification.setSearchNotification(evt.target.value);
        setTimeout(() => {
            notification.requestAllNotifications({
                offset: 0,
                type: notification.selected_not_type,
            });
        }, 1500);
    }
    render() {
        return (
            <NavigationHeaderContainer>
                <NavigationContainer >
                    <Link to="/notifications/scheduled" className={this.state.navigationSelector === 0 ? "active" : ""}
                        onClick={() => this.handleNavigationSelector("scheduled", 0)}>
                        Scheduled </Link>

                    <Link to="/notifications/in-progress" className={this.state.navigationSelector === 1 ? "active" : ""}
                        onClick={() => this.handleNavigationSelector("in-progress", 1)}>
                        In Progress </Link>

                    <Link to="/notifications/sent"
                        className={this.state.navigationSelector === 2 ? "active" : ""}
                        onClick={() => this.handleNavigationSelector("sent", 2)}>
                        Sent
                                </Link>

                    <Link to="/notifications/failed"
                        className={this.state.navigationSelector === 3 ? "active" : ""}
                        onClick={() => this.handleNavigationSelector("failed", 3)}>
                        Failed
                                </Link>

                </NavigationContainer>
                <NavigationToolbar>
                    <RefreshBtn onClick={this.handleRefreshBtn} active={this.state.refreshBtn}>
                        <i className="fa fa-refresh" />
                    </RefreshBtn>
                    <SearchBarContainer width={this.state.searchBarEnable ? "300px" : "200px"}>
                        <i className="fa fa-search" />
                        <input type="text" placeholder="Search"
                            onChange={this.handleSearchNotification}
                            value={this.props.notification.search_notification}
                            onFocus={() => this.enableSearchBar("focus")}
                            onBlur={() => this.enableSearchBar("blur")} />
                        {/* {this.props.notification.search_notification.length > 0 && (
                            <i
                                className="fa fa-times cross"
                                onClick={() => this.handleClearSearch()}
                            />
                        )} */}
                    </SearchBarContainer>

                </NavigationToolbar>
            </NavigationHeaderContainer>
        )
    }
}
export default connectStore({
    notification
})(NotificationsHeader)
