import React, {Component} from 'react';
import {Container} from './HtmlEditor.styled';
import {PanelHeader, ThemeButton} from "static/styled/custom.styled";
import {module as story} from "@st/story";
import {connectStore} from "redux-box";
import { uploadFile } from "@s/api/file";
import { EditorState, convertToRaw, convertFromHTML,ContentState} from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import {stateToHTML} from 'draft-js-export-html';
import swal from "sweetalert2";


class HtmlEditor extends Component {
    constructor(){
        super();
        this.state = {
            editorState: EditorState.createEmpty(),
            editEnabled:false,
        }
    }

    componentDidMount()
    {
        let {section}=this.props;
        if(section!==null && section.content_type==='html'){
            if(section.description!=='')
            {const blocksFromHTML = convertFromHTML(section.description);
            if(blocksFromHTML!==null && blocksFromHTML.contentBlocks!==null)
            {const state = ContentState.createFromBlockArray(
                blocksFromHTML.contentBlocks,
                blocksFromHTML.entityMap
            );
            this.setState({
                editorState:EditorState.createWithContent(state),
            });
            }}
            else
            {
                this.setState({
                    editEnabled:false,
                })
            }
        }
    }

    componentWillUnmount()
    {
        let {story}=this.props;
        story.setStoryEditorType('');

    }

    onEditorStateChange = (editorState) => {
        this.setState({
            editorState:editorState,
            editEnabled:true,
        });
    };

    uploadImageCallBack = (file) => {
        return new Promise(
            (resolve, reject) => {
                uploadFile(file).then(res => {
                    resolve({data:{link:res.file}})
                })
                    .catch(err => {
                    reject(err)
                });
            }
        );
}
    setHTMLSection=()=>{
        let {story}=this.props;
        if(story.active_story)
        {
            let editorBlock=convertToRaw(this.state.editorState.getCurrentContent());
            //console.log(editorBlock);
            if((editorBlock.blocks && editorBlock.blocks.some((block)=>{ return block.text!==''})) || editorBlock.entityMap.length>0)
            {

            let newSection={
                content_type:'html',
                name:'html',
                media:[],
                description:stateToHTML(this.state.editorState.getCurrentContent()),
                story:story.active_story.id,
            };

            if(this.props.section===null)
            {
                story.requestCreateStorySection(newSection);
            }
            else
            {
                story.requestUpdateStorySection(newSection);
            }
                story.setStoryEditorType('')
            }
            else
            {
                swal('Oops!','Please add text to the HTML Editor','warning')
                this.setState({
                    editEnabled:false,
                })

            }

        }



    };

    
    handleDoneBtn=()=> {

        this.setHTMLSection();
    }

    render(){

        const EditorImage = (
            <Editor
                wrapperClassName="demo-wrapper"
                editorClassName="demo-editor"
                editorState={this.state.editorState}
                onEditorStateChange={this.onEditorStateChange}
                toolbar={{
                    options:['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign','image'],
                    inline: { inDropdown: true },
                    list: { inDropdown: true },
                    textAlign: { inDropdown: true },
                    image: { uploadCallback: this.uploadImageCallBack,popupClassName: "image-popup",
                        alt: { present: false},
                        defaultSize: {
                            height: 'auto',
                            width: '100%',
                        },
                        previewImage:true,
                    },

                }}
            />
        );
        return(
          <Container >
              <PanelHeader primary style={{borderRadius:'10px 10px 0 0',zIndex:'20'}}>
                  <span> HTML Text Editor</span>
                  {this.state.editEnabled ?
                      <ThemeButton primary onClick={this.handleDoneBtn}>Done</ThemeButton>
                      :<ThemeButton primary onClick={()=>this.props.story.setStoryEditorType('')}> Close </ThemeButton>}
              </PanelHeader>
              <div style={{padding:'65px 5px',overflow:'auto'}}>
                  {EditorImage}
              </div>
          </Container>
        )
    }
}
export default connectStore({
    story
})(HtmlEditor)