import styled from "styled-components";

export const Container = styled.div``;

export const TagsText = styled.div`
  color: var(--primary-color);
  font-size: var(--xsm-font-size);
  opacity: 0.7;
`;

export const SourcesText = styled.div`
  color: var(--primary-color);
  font-size: 14px;
  opacity: 1;
`;

export const LevelType = styled.div`
  width: 48px;
  height: 20px;
  border-radius: 12px;
  position: absolute;
  top: 10px;
  right: 10px;
  display: flex;
  align-items: center;
  font-size: var(--xsm-font-size);
  color: #000;
  font-weight: 600;
  justify-content: center;
  background-color: #fff;
`;

export const TopStoryIcon = styled.div`
  width: 16px;
  height: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${props => (props.color ? props.color : "white")};
  border: solid 1px #432666;
  border-radius: 4px;
  margin: 5px;
  transition: all 0.3s;
  color: white;
`;

export const TopStoryContainer = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  bottom: 8px;
  left: 6px;
  font-weight: 500;
  font-size: var(--sm-font-size);
  color: #fcae32;
`;
