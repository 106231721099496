import styled from "styled-components";

export const TaskListCardContainer = styled.div`
    background: #FFFFFF;
    width : 400px;
    box-shadow: 2px 0px 34px rgba(0, 0, 0, 0.06);
    border-radius: 8px;   
    display : flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom : 20px;
    padding-right : 10px;
    position : relative;
`;

export const TaskListLeftContent = styled.div`
    display : flex;
    align-items: center;
`;
export const TaskListRightContent = styled.div`
    display : flex;
    flex-direction : column;
    align-items: center;
    margin-top : 20px;
`;

export const CardDeleteBlock = styled.div`
    position : absolute;
    top : 0;
    right : 0;
`;

export const TaskListCardImageContainer = styled.div`
`;

export const TaskListCardImage = styled.div`
    height : 86px;
    width : 150px;
    border-radius: 8px 0 0 8px;
    background-image: url(${props => props.url});
    background-size: cover;
    background-position: center;
`

export const TaskListCardContent = styled.div`
    display: flex;
    flex-direction: column;
    padding 0px 10px;
`

export const TaskListCardTitle = styled.div`
    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #000000;
    margin-bottom: 10px;
`

export const TaskListCardDescription = styled.div`
        font-family: Rubik;
        font-style: italic;
        font-weight: normal;
        font-size: 14px;
        line-height: 20px;
        color: #75717A;
`
export const TaskListCardType = styled.div`
    mix-blend-mode: normal;
    border-radius: 4px;
    padding 0 5px;
    height: 23px;
    background: rgb(244, 113, 43,0.1);
    display: flex;
    justify-content: center;
    align-items: center;
`

export const TaskListCardTypeContent = styled.div`
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: -0.514286px;
    text-transform: uppercase;

    color: #F4712B;
`