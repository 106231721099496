export default {
  SET_SCHOOL_LIST: (state, { schoolList }) => (state.schoolList = schoolList),
  SET_PROJECT_LIST: (state, { projectList }) => {
    state.projectList = projectList;
  },
  SET_SLOT_LIST: (state, { slotList }) => (state.slotList = slotList),
  SET_CLASS_LIST: (state, { classList }) => (state.classList = classList),
  SET_SECTION_LIST: (state, { sectionList }) =>
    (state.sectionList = sectionList),
  SET_INVITE_CODE_CSV_DETAIL: (state, { data }) =>
    (state.inviteCodeDetail = data),
  SET_SCHOOL_CODES_OFFSET: (state, { offset }) => {
    state.schoolCodesOffset = offset;
  },
  SET_SCHOOL_CODE_LIST: (state, { data }) => {
    state.schoolCodes = [...state.schoolCodes, ...data];
  },
  SET_SCHOOL_CODE_LIST_AFTER_UPDATE: (state, { data }) => {
    state.schoolCodes = data;
  },
  SET_SCHOOL_CODE_LIST_LENGTH: (state, { count }) => {
    state.schoolCodesLength = count;
  },
  UPDATE_SCHOOL_CODE_LIST: (state, { data }) => {
    state.schoolCodes.unshift(data);
  },
  UNSET_INVITE_CODE_CSV_DETAIL: (state, { data }) =>
    (state.inviteCodeDetail = data),
  REQUEST_SCHOOL_SEARCH: (state, { string }) => {
    state.schoolSearchText = string;
  },
  SET_SEARCH_RESULT: (state, { result }) => {
    state.searchSchoolResult = result;
  },
  SET_SCHOOL_TO_EDIT: (state, { school }) => {
    state.selectedSchool = school;
  },
  SAVE_SCHOOL_LIST: (state, { school }) => {
    state.adminSchoolList = [...state.adminSchoolList,...school];
  },
  SAVE_SCHOOL_LIST_RESULT: (state, { school }) => {
    state.schoolListResult = school;
  },
  FETCH_SCHOOL_LIST: (state, { value }) => {
    state.fetch_school_list = value;
  },
  SET_SCHOOL_EDIT: (state, { value }) => {
    state.schoolEdit = value;
  },
  SET_SCHOOL_EDIT_INFO: (state, { value }) => {
    state.schoolEditInfo = value;
  },
  GET_CLASS_RESULT: (state, { result }) => {
    state.classResultList = result;
  },
  SET_SCHOOL_INFO: (state, { value }) => {
    state.schoolEditInfo = value;
    state.adminSchoolList =[];
  },
  SET_SEARCH_STRING: (state, { value }) => {
    state.schoolSearch=value.string,
    state.selectedSearchOption=value.option,
    state.adminSchoolList =[];
  },

  SET_REPORT_DATA: (state, { value }) => {
    console.log("SET_DOWNLOAD_REPORT====",value);
    
    state.reportData = value;
    // state.reportData = [...state.reportData,...value]
  },

  SET_SCHOOL_GRADE: (state, { value }) => {    
    state.schoolGrade = value;
  },

  SET_SCHOOL_GUIDED_PATH_FORM_DATA: (state, { value }) => {    
    console.log("SET_SCHOOL_GUIDED_PATH_FORM_DATA====",value);
    state.schoolGuidedPathFormData = {...state.schoolGuidedPathFormData , ...value};
  },

  CLEAR_SCHOOL_GUIDED_PATH: (state ) => {    
    state.schoolGuidedPathFormData = null;
  },

  SET_ALL_GRADES: (state, { value }) => {    
    state.allGrades = value;
  },

  SET_SCHOOL_IRO : (state, { data }) => {    
    state.schoolIro  = data;
  },

  SET_SCHOOL_IRO_FORM_DATA : (state, { value }) => {    
    state.schoolIroFormData  = value;
  },

};
