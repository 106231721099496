export default {

    //MISC
    SET_FETCH_NEWS_FREEDS_LIST:(state,{value})=>(state.fetch_news_freeds_list=value),
    SET_FETCH_ACTIVE_NEWS_FREED:(state,{value})=>(state.fetch_active_news_freed=value),
    SET_ACTIVE_NEWS_GRADE_LEVEL:(state,{gradeLevel})=>(state.active_news_grade_level=gradeLevel),
    SET_ACTIVE_NEWS_LEVEL:(state,{level})=>(state.active_news_level=level),
    SET_NEWS_EDITOR_TYPE:(state,{editorType})=>(state.current_news_editor_type=editorType),
    SET_ACTIVE_NEWS_DATE:(state,{date})=>(state.active_news_date=date),
    SET_SEND_PUSH_NOTIFICATION:(state,{value})=>(state.send_push_notification=value),
    SET_ENABLE_NEWS_EDIT:(state,{value})=>(state.active_news_edit=value),
    SET_SEARCH_FREED:(state,{search})=>(state.search_freed=search),
    //CRUD FOR NEWS FREEDS
    SET_NEWS_FREEDS_RESULT:(state,{result})=>(state.news_freeds_result=result),
    SET_NEWS_FREEDS_LIST:(state,{newsFreeds})=>(state.news_freeds_list=newsFreeds),
    SET_NEWS_FREEDS_LIST_CONCAT:(state,{newsFreeds})=>(state.news_freeds_list=state.news_freeds_list.concat(newsFreeds)),
     SET_ACTIVE_NEWS_FREED:(state,{activeFreed})=>(state.active_news_freed=activeFreed),
     SET_ACTIVE_NEWS_FREED_DESCRIPTION:(state,{description})=>(state.active_news_description=description),

    //crud for mcq creation

    UPDATE_NEW_NEWS_QUESTION: (state, {question}) => (state.new_question = question),
    ADD_NEW_NEWS_OPTION:(state,{option}) => (state.new_options.push({name:option,__id:Math.random()}) ),
    REMOVE_NEW_NEWS_OPTION: (state, {optionId}) => {
        state.new_options = state.new_options.filter(option => option.__id !== optionId)},
    SET_ACTIVE_NEWS_FREED_QUESTION: (state, {question}) => (state.active_mcq = question),
    UNSET_ACTIVE_NEWS_QUESTION:(state) => (state.active_mcq = null),
    SET_ALL_NEWS_QUESTION:(state, {question}) => (state.questions = question),
    UNSET_ALL_ACTIVE_NEWS_QUESTION:(state) => (state.questions = []),


}
