import { createActions, using } from "redux-box";

export default createActions({
  //....................PRODUCT ..................
  fetchProductList: using(""),
  setSelectedProduct: using("product"),
  setEditProduct: using("product"),
  updateProductList: using("updateProduct"),
  createProduct: using("updateProduct"),
  fetchSchoolGuidedPathProduct: using(""),
});
