export default {
  SHOW_MODAL: (state, action) => {
    state.show_modal = action.componentId;
  },
  SHOW_NOTIFICATION_MODAL: (state, action) => {
    state.show_notification_modal = action.componentId;
  },
  HIDE_MODAL: (state, action) => (state.show_modal = false),
  HIDE_NOTIFICATION_MODAL: (state, action) =>
    (state.show_notification_modal = false)
};
