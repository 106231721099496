import React, { useEffect, useState } from "react";
import { withRouter } from "react-router";
import { connectStore } from "redux-box";
import { module as inviteCode } from "@st/inviteCode";
import { module as product } from "@st/product";
import { module as onboard } from "@st/onboard";
import Select from "react-select";
import CheckBox from "./CheckBox";
import {
  DropDownContainer,
  DropDownTitle,
  DatePickerContainer,
} from "./SchoolIroEnable.styled";
import {
  Th,
  Td,
  Tr,
  Table,
  Grade
} from "./SchoolIroEnable.styled";
import DatePicker from "react-datepicker";
import moment from "moment";
import {
  DateSelector,
  DateSelectorIcon,
} from "../../flive-code-view/renewalold-school/RenewalOldSchool.styled";

function SchoolIroEnable(props) {
  const [gradeOption, setGradeOption] = useState([]);
  const [dropdownProduct, setProduct] = useState("");
  const [serverGradeOption, setServerGradeOption] = useState([]);
  const [schoolIroEnableData, setSchoolIroEnableData] = useState({
    isEnableSchoolIro: false,
    school: null,
    selectedGrades: [],
    // selectedDate : moment().format("YYYY-MM-DD")
    examDate: "",
    examDatesTable: {},
  });

  useEffect(() => {
    let { schoolEditInfo } = props.inviteCode;
    if (schoolEditInfo) {
      
      setSchoolIroEnableData({
        ...schoolIroEnableData,
        school: schoolEditInfo.id,
      });
      props.inviteCode.getSchoolIro(schoolEditInfo.id);

    }


    props.inviteCode.getAllGrades();
    
    
  }, []);

  useEffect(() => {
    let gradesList = props.inviteCode.allGrades.map((grade) => {
      return {
        value: grade.id,
        label: grade.name,
      };
    })
    setGradeOption(gradesList)
  }, [props.inviteCode.allGrades])

  function getMaxGradeIdsExamDate(data) {

      if (!data || !data.exam_dates) {
          return { date: null, gradeIds: [] };
      }
      let maxDate = null;
      let maxGradeIds = [];
      console.log("data:", data);
      for (const date in data.exam_dates) {
          if (data.exam_dates.hasOwnProperty(date)) {
              const gradeIds = data.exam_dates[date];
              if (gradeIds.length > maxGradeIds.length) {
                  maxDate = date;
                  maxGradeIds = gradeIds;
              }
          }
      }

      return { date: maxDate, gradeIds: maxGradeIds };
  }

  function getExamDates(data) {
    if (!data || !data.exam_dates) {
      return [];
    }
    let exam_dates_table = {};
    for (const date in data.exam_dates) {
      if (data.exam_dates.hasOwnProperty(date)) {
        const gradeIds = data.exam_dates[date];
        // get the names of each grade
        const gradeNames = gradeIds.map(gradeId => {
          const grade = props.inviteCode.allGrades.find(grade => grade.id === gradeId);
          return grade ? grade.name : "";
        });

        console.log("date:", date, "gradeNames:", gradeNames);

        exam_dates_table[date] = gradeNames;
      }
    }
    return exam_dates_table;
  }

  useEffect( () => {
    
    let  schoolIro  = props.inviteCode.schoolIro;
    console.log("schoolIro", schoolIro);

    let exam_dates = getExamDates(schoolIro[0]);

    let {date, gradeIds} = getMaxGradeIdsExamDate(schoolIro[0]);
    console.log("date", date);
    console.log("gradeIds", gradeIds);
    let grades = gradeIds.map((gradeId) => {
      // from the gradeOption find the grade with the gradeId
      return gradeOption.find((grade) => grade.value === gradeId);
    })
    console.log("grades:",grades);
    if(date){
      setSchoolIroEnableData({
        ...schoolIroEnableData,
        isEnableSchoolIro: true,
        examDate: date,
        selectedGrades: grades,
        examDatesTable: exam_dates
      });
    }

  }, [props.inviteCode.schoolIro])

  const handleCheckboxChange = (event) => {
    console.log("checked", event.target.checked);
    setSchoolIroEnableData({
      ...schoolIroEnableData,
      isEnableSchoolIro: event.target.checked,
    });

  };
  const handleGradeChange = (event) => {
    // console.log("value selected:",event.target.value);
    // add the grade to schoolIroEnableData.selectedGrades
    setSchoolIroEnableData({
      ...schoolIroEnableData,
      selectedGrades: [ ...event],
    });

    console.log("selectedGrades:", event);

    // for every object in event get the id of the object and make a list 
    
    updateSchoolIroFormData({
      ...schoolIroEnableData,
      selectedGrades: event,
    });
  };

  const updateSchoolIroFormData = (data) => {
    props.inviteCode.setSchoolIroFormData(data);
  };

  const handleEnableDateChange = (date) => {

    setSchoolIroEnableData({
      ...schoolIroEnableData,
      examDate: moment(date).format("YYYY-MM-DD"),
    });

    updateSchoolIroFormData({
      ...schoolIroEnableData,
      examDate: moment(date).format("YYYY-MM-DD"),
    });

  };
  const onDatepickerRef = (el) => {
    if (el && el.input) {
      el.input.readOnly = true;
    }
  };

  return (
    props.inviteCode.schoolEditInfo && (
      <div className="mt-4">
        <div>
          <label>
            <CheckBox
              checked={schoolIroEnableData.isEnableSchoolIro}
              onChange={handleCheckboxChange}
              disabled={
                props.inviteCode.schoolEdit
                  ? !props.inviteCode.schoolEdit
                  : true
              }
            />
            <span style={{ marginLeft: 8 }}>Enable IRO</span>
          </label>
        </div>

        {
          schoolIroEnableData.isEnableSchoolIro && (
            // for every date in schoolIroEnableData.examDatesTable, create a table row with the date and the grades
            // <table>
            //   <tbody>
            //     {
            //       Object.keys(schoolIroEnableData.examDatesTable).map(date => (
            //         <tr key={date}>
            //           <td>{date}</td>
            //           <td>
            //             {schoolIroEnableData.examDatesTable[date].map(grade => (
            //               <span key={grade}>{grade} </span>
            //             ))}
            //           </td>
            //         </tr>
            //       ))
            //     }
            //   </tbody>
            // </table>
            <Table
              >
              <thead>
                <Tr className="text-center"> 
                  <Th>Exam Date</Th>
                  <Th>Grades</Th>
                </Tr>
              </thead>
              <tbody>
                {
                  Object.keys(schoolIroEnableData.examDatesTable).map(date => (
                    <Tr key={date}>
                      <Td className="text-center">{date}</Td>
                      <Td className="text-center">
                          {schoolIroEnableData.examDatesTable[date].map((grade, index) => (
                            <span key={grade}>{grade}{index !== schoolIroEnableData.examDatesTable[date].length - 1 ? ", " : ""}</span>
                          ))}
                      </Td>
                    </Tr>
                  ))
                }
              </tbody>
            </Table>

              
          )
        }
        {schoolIroEnableData.isEnableSchoolIro && (
          <div>
            <DropDownContainer>
              <DropDownTitle>SCHOOL GRADES</DropDownTitle>
              <Select
                styles={{
                  placeholder: (provided, state) => ({
                    ...provided,
                    color: "rgba(0, 0, 0, 0.2)",
                  }),
                }}
                placeholder={"select school grades"}
                // value={schoolIroEnableData.selectedGrades}
                isDisabled={
                  props.inviteCode.schoolEdit
                    ? !props.inviteCode.schoolEdit
                    : true
                }
                onChange={handleGradeChange}
                options={gradeOption}
                isMulti={true}
              />
            </DropDownContainer>
            <DatePickerContainer>
              <h6>Exam Date</h6>
              <DateSelector
                style={{ overflow: "hidden", border: "1px solid #a349c9" }}
              >
                <DateSelectorIcon className="fa fa-calendar" />
                <DatePicker
                  selected={schoolIroEnableData.examDate}
                  selectsStart
                  startDate={schoolIroEnableData.examDate}
                  onChange={handleEnableDateChange}
                  minDate={moment().toDate()}
                  placeholderText="Select Enable Date"
                  disabled={
                    props.inviteCode.schoolEdit
                      ? !props.inviteCode.schoolEdit
                      : true
                  }
                  ref={(el) => onDatepickerRef(el)}
                />
              </DateSelector>
            </DatePickerContainer>
          </div>
        )}
      </div>
    )
  );
}

export default withRouter(
  connectStore({
    inviteCode,
    onboard,
    product,
  })(SchoolIroEnable)
);
