import styled from "styled-components";
import {LoadingContainer} from "static/styled/custom.styled";
import {ActivityDescription,ActivityInputField,} from "@c/activity-view/activity-creation-form/ActivityCreationForm.styled";
export const SchoolName=styled.input`
    font-size:var(--md-font-size);
    color:#000;
    outline:none;
   background:white;
    border:${props=>props.border};
    border-radius:${props=>props.radius};
     font-size:${props=>props.fontSize};
    padding:${props => props.padding};
    width:100%;
    ::placeholder
    {
        color:rgba(0,0,0,0.4);
        font-size:(--sm-font-size);
        
    }`;

export const LoadingSchoolContainer=LoadingContainer.extend`
position:relative;`;

export const SchoolNameDesc=ActivityDescription.extend`
    background:white;
    border:${props=>props.border};
    border-radius:${props=>props.radius};
    height:${props=>props.height};
    font-size:${props=>props.fontSize};
    padding:3px 6px;`;

export const SchoolInputField=ActivityInputField.extend`
   
    border:${props=>props.border};
     font-size:${props=>props.fontSize};
    border-radius:${props=>props.radius};
   margin-bottom:${props=>props.margin};
   
    `;

    export const AddButtonContainer = styled.div`

    button {
      border: none;
      border-radius: 100%;
      width: 30px;
      height: 30px;
      text-align: center;
      margin-left: 10px;
      font-size: var(--md-font-size);
      color: white;
      outline: none;
      font-weight: bold;
      background-color: #ff8a23;
      cursor: pointer;
    }
  `

export const CheckBoxContainer = styled.div`
    margin-top :10px;
`