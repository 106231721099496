import styled from "styled-components";
import {TagChips} from "@sc/custom.styled";

export const FilterChips = TagChips.extend`
margin:0 5px;
`;


export const OptionContainer=styled.div`
 width:100%;
 display:flex;
 flex-flow:row wrap;
 justify-content:space-between;
 padding:0px 10px;
 align-items:center;
 color:black;
 
 
    `;




