import {createActions,using} from 'redux-box'

export default createActions({
    // API REQUEST FOR PLANINFO POP UP
    requestPlanInfo:using(),

    // CRUD ACTIONS FOR PAYMENTS
    requestPaymentList:using("offset"),
    setSearchPayment:using("search"),
    setPaymentMode:using("mode"),
    setPaymentType:using("paymentType"),
    setActivePaymentDate:using("date"),
    

    // MISC ACTIONS FOR PAYMENTS

    //ACTIONS FOR SUBSCRIPTION LIST GET API
    requestSubscriptionList:using("subscription"),
    setActiveSubscription:using("subscription"),

    //ACTIONS FOR THE PAYMENT LIST
    requestSubPaymentList:using("payment"),
    setSubPaymentList:using("result"),

    // ACTIONS FOR GENERATING PAYMENT LINK 
    requestChildList: using("phoneNumber"),
    requestWebPlansList: using(),
    setSelectedChild: using("child"),
    setSelectedPlan: using("plan"),
    validatePromoCode: using("promoCode"),
    generatePaymentLink: using()
})