import React, { Component } from "react";

import { FileListContainer, FileLink } from "./FileSection.styled";

export default class FileSection extends Component {
  render() {
    let { dataType } = this.props;
    return (
      <FileListContainer>
        {this.props.media.map((file, i) => (
          <FileLink key={`fl${i}`}>
            <a href={file.media_url} target="__blank">
              <i
                className={
                  dataType !== "epub" ? "fa fa-file-pdf-o" : "fa fa-file"
                }
              />
            </a>
          </FileLink>
        ))}
      </FileListContainer>
    );
  }
}
