import { createSagas } from "redux-box";
import LevelApi from "@s/api/grade";
import { call, put } from "redux-saga/effects";

export default createSagas({
  //FREAD-1157
  REQUEST_LEVELS: function*() {
    try {
      const res = yield call(LevelApi.getAllLevels);
      yield put({
        type: "SET_LEVELS",
        levels: res
      });
    } catch (err) {
      console.log(err);
    }
  }
});
