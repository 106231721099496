import React, { Component } from "react";
import { connectStore } from "redux-box";
import { module as inviteCode } from "store/inviteCode";
import { module as activity } from "store/activity";
import { generateScheduleFile, validateScheduleCsvFile } from "@s/api/file";
import swal from "sweetalert2";
import { InputLabel, UploadButton } from "./Scheduled .styled";
import moment from "moment";
import { Link } from "react-router-dom";


class Scheduled extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedProject: null,
      selectedDate: null,
      fileValidated: false,
      fileData: "",
      rejectedList: [],
      generatedInviteCodes: false,
    };
  }

  componentDidMount() {
    let { inviteCode } = this.props;
    const projectList = inviteCode.requestProjectList();

    // console.log("schoolList", inviteCode.requestProjectList());
  }

  reInitializeState = (stateNames) => {
    stateNames.forEach((s) => {
      this.setState({ [s]: null });
    });
  };

  clearAllInput = () => {
    this.reInitializeState([
      "selectedSchool",
      "selectedDate",
      // "selectedClass",
      // "selectedFreadomGrade",
    ]);
    this.setState({
      fileValidated: false,
      fileData: "",
      rejectedList: [],
      generatedInviteCodes: false,
    });
  };

  handleSchoolChange = (e) => {
    this.reInitializeState([
      "selectedProject",
      "selectedDate",
      // "selectedClass",
      // "selectedFreadomGrade",
    ]);
    this.setState({
      fileValidated: false,
      fileData: "",
      generatedInviteCodes: false,
    });

    let { inviteCode } = this.props;

    const projectId = e.target.value;
    // console.log("Selected project id", projectId);

    this.setState({
      selectedProject:
        inviteCode.projectList &&
        inviteCode.projectList.find((p) => p.id === projectId),
    });
    // console.log("State value", this.state);
  };

  handleUploadClick = () => {
    this.inputElement.click();
  };

  handleClearBtn = () => {
    this.reInitializeState(["selectedDate"]);
    this.setState({
      fileValidated: false,
      fileData: "",
      generatedInviteCodes: false,
    });
  };

  onDatepickerRef(el) {
    if (el && el.input) {
      el.input.readOnly = true;
    }
  }

  handleDateChange = (date) => {
    this.setState({
      fileValidated: false,
      fileData: "",
      generatedInviteCodes: false,
    });
    this.setState({
      selectedDate: moment(date).format("YYYY-MM-DD"),
    });
  };

  handleFileChange = (file) => {
    let fileData = file.files[0];
    const { selectedProject } = this.state;
    const curr = new Date();
    var diff = new Date(this.state.selectedDate).getTime() - curr.getTime();

    validateScheduleCsvFile(fileData, selectedProject.id).then((res) => {
      this.setState({
        err: res,
      });
      if (res.success) {
        this.setState({
          fileValidated: true,
          fileData: file.files[0],
          rejectedList: [],
        });

        fileData = "";
        swal("Done", "Verified successfully", "success");
      } else {
        if (res.length > 0) {
          let rejectedList = res.map((c) => {
            let obj = {};
            obj.childName = c[0];
            obj.contactNo = c[1];
            obj.reason = c[2];
            return obj;
          });
          this.setState({ rejectedList });
        }
        this.setState({ fileValidated: false, fileData: "" });
        // unValidatedData: some_array
        fileData = "";
        file.value = "";
        swal.fire({
          icon: "error",
          title: "Not Validated!",
          html: res.error.error_message,
        });
      }
    });
  };
  generateBulkInviteCode = () => {
    const fileData = this.state.fileData;
    const reqBody = {
      project_id: this.state.selectedProject.id,
    };

    generateScheduleFile(fileData, reqBody).then((res) => {
      if (res.success) {
        swal("Done", "Uploaded Successfully", "success");
        this.setState({ generatedInviteCodes: true });
      } else {
        swal("Oops!", res.error.error_message);
      }
    });
  };
  render() {
    let { inviteCode } = this.props;
    let { selectedProject, rejectedList, fileValidated } = this.state;
    let projectListOptions =
      inviteCode.projectList.length &&
      inviteCode.projectList.map((project, i) => (
        <option value={project.id} key={project.id}>
          {project.name ? project.name : ""}
        </option>
      ));
    // console.log("schoollistoptions", projectListOptions);

    return (
      <div className="p-4">
        <div className="alert alert-warning" role="alert">
          <h5>Bulk Generate Booking Slots</h5>
        </div>
        <div className="row">
          <div className="col-sm-4">
            <div className="card" style={{ height: "420px" }}>
              <div className="card-body">
                <div className="mb-4 text-right">
                  <button
                    className="btn btn-warning btn-sm"
                    onClick={this.clearAllInput}
                  >
                    Clear all
                    <i className="fa fa-times ml-2" />
                  </button>
                </div>
                <div className="input-group mb-3">
                  <div className="input-group-prepend">
                    <InputLabel
                      className="input-group-text"
                      htmlFor="inputGroupSelect01"
                    >
                      Project
                    </InputLabel>
                  </div>
                  <select
                    className="custom-select"
                    id="inputGroupSelect01"
                    onChange={(e) => this.handleSchoolChange(e)}
                  >
                    <option defaultValue>Choose...</option>
                    {projectListOptions}
                  </select>
                </div>

                {selectedProject !== null && (
                  <div>
                    {selectedProject != null && (
                      <div className="mb-4">
                        <input
                          style={{ display: "none" }}
                          ref={(input) => (this.inputElement = input)}
                          type="file"
                          accept=".csv"
                          onChange={(e) => this.handleFileChange(e.target)}
                        />
                        <UploadButton
                          className="btn btn-simple mt-4"
                          primary
                          onClick={this.handleUploadClick}
                          disabled={fileValidated}
                        >
                          Upload And Validate
                        </UploadButton>
                      </div>
                    )}
                  </div>
                  // </div>
                )}
              </div>
            </div>
          </div>
          <div className="col-sm-8">
            <div className="card">
              <div className="card-body">
                Uploading Projects
                <h5>
                  <b>Project:</b>{" "}
                  {selectedProject != null && selectedProject.name}
                </h5>
                {rejectedList.length > 0 && (
                  <div className="alert alert-danger" role="alert">
                    <table className="table table-bordered">
                      <thead className="thead-dark">
                        <tr>
                          <th scope="col">#</th>
                          <th scope="col">Error Message</th>
                        </tr>
                      </thead>
                      <tbody>
                        {rejectedList.map((k, index) => {
                          return (
                            <tr>
                              <th scope="row">{index + 1}</th>
                              <td>{this.state.err[index]}</td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                    <p>
                      <b> Please fix these changes and upload again.</b>
                    </p>
                  </div>
                )}
                <div>
                  {this.state.fileValidated &&
                    !this.state.generatedInviteCodes && (
                      <UploadButton
                        className="btn btn-warning"
                        onClick={this.generateBulkInviteCode}
                      >
                        Create Projects
                      </UploadButton>
                    )}
                </div>
                {this.state.generatedInviteCodes && (
                  <div className="text-center">
                    <Link
                      className="btn btn-outline-primary"
                      to={"/flive-code/" + this.state.selectedProject.id}
                      target="_blank"
                    >
                      Check Available Slots List
                    </Link>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default connectStore({
  inviteCode,
  activity,
})(Scheduled)