import { Api } from "./base";

const _headers = () => {
  return {
    Authorization:
      "JWT " + require("store").default.getState().onboard.user.auth_token,
    "Content-Type": "application/json",
  };
};

export default {
  //.................end points for collection CRUD..........
  getAllCategory() {
    return Api.get(`/quiz/v1/admin_category/`, _headers());
  },

  createCategory(data) {
    return Api.post(`/quiz/v1/admin_category/`, data, _headers());
  },
};
