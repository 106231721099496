import React, {Component} from "react";
import {
    ActivityComponentInfoContainer,
    TopNav
} from "@c/activity-view/activity-creator/ActivityCreator.styled";
//import { NavigationBar } from "static/styled/NavigationBar.styled";
import {
    SaveChangesButton,
    ThemeButton,
    LoadingContainer
} from "static/styled/custom.styled";
import {ComponentContainer, SubContainer,CreatorComponent} from '@sc/Creator.styled';
import {module as activity} from "@st/activity";
import {module as readingChallenge} from "@st/readingChallenge";
import {connectStore} from "redux-box";
import ReadingChallengeInfo from "./reading-challenge-info/ReadingChallengeInfo";
import TextEditor from "./text-editor/TextEditor";
import MediaEditor from "./media-editor/MediaEditor";
import McqEditor from "./mcq-editor/McqEditor";
import TagEditor from "./tag-editor/TagEditor";
import McqSection from "./mcq-section/McqSection";
import TagsSection from "./tags-section/TagsSection";
//import DateSection from "./date-section/DateSection";
import NewSection from "./new-section/NewSection";
import Section from "./section/Section";
import swal from "sweetalert2";
import ExperienceEditor from "./experience-editor/ExperienceEditor";
import { module as onboard } from "@st/onboard";
import Permission from "@s/permission";


 class ReadingChallengeCreator extends Component
{
    constructor(props) {
        super();
        this.state = {
            levelSelector:false,
            showSubContainer: false,
            activityDescription: "",
            sectionList: [],
            translate:'100%',
            sectionTitle: null,

        };
    }
    componentWillMount() {
        const {readingChallenge} = this.props;
        readingChallenge.requestReadingChallengeById(this.props.match.params.id);
        readingChallenge.requestAllReadingQuestions(this.props.match.params.id);

    }
    componentDidMount(){


        setTimeout(()=>{
            this.setState({
                opacity: 1,
                translate: '0'
            })
        },30)

        let history=this.props.history;
        //let location=this.props.location;
        document.addEventListener('keyup', function(e) {

            if (e.keyCode === 27 && window.location.pathname.includes("/reading-challenge")) {
                history.push("/reading-challenge");
            }
        });

    }
    componentWillUnmount(){
        this.props.readingChallenge.resetNewReadingSections();
        this.props.activity.setEditorType('');
        this.props.readingChallenge.setActiveReadingChallenge(null);
        this.props.readingChallenge.unsetActiveReadingQuestion();
    }

    handleShowSubContainer = (type, title = null) => {
        this.setState({ showSubContainer: type, sectionTitle: title });
    };

    hideSubContainer = () => {
        this.setState({ showSubContainer: false });
    };
    AddSection = () => {
        this.props.readingChallenge.addNewReadingSection();
    };

    getAllSections = () => {
        const activity = this.props.activity;
        if (activity.active_activity)
            return [
                ...activity.active_activity.sectiondata_set,
                activity.new_section
            ];
    };


    renderSections = () => {
        const readingChallenge = this.props.readingChallenge && this.props.readingChallenge.active_reading_challenge;

        return readingChallenge && readingChallenge.section_data && readingChallenge.section_data.map((section, i) => section && (
                <Section
                    key={i}
                    handleShowSubContainer={this.handleShowSubContainer}
                    section={section}
                />
            )
        )
    };
    renderNewSections = () => {
        const {readingChallenge} = this.props;
        return readingChallenge.new_sections.map((section, i) => <NewSection key={i} section={section}/>)
    };




    addSection=() =>{

        if(this.props.readingChallenge.active_reading_challenge && this.props.readingChallenge.active_reading_challenge.section_data.length<10)
            this.props.readingChallenge.addNewReadingSection({
                name: ""
            })

        else
        {
            swal({
                text: "Sorry! you cannot add more than 10 sections to "+ this.props.readingChallenge.active_reading_challenge.name,
                type: "warning"});
            //errorHandler("Sorry ! you cannot add more than 5 sections to activity");
        }


    };
    handleLevelSelector=()=>{
        this.setState({
            levelSelector:!this.state.levelSelector
        })
    };

    saveChangesBtn=()=>{
        let {readingChallenge}=this.props;
        if(readingChallenge.active_reading_challenge && readingChallenge.active_reading_challenge.experience &&
        readingChallenge.active_reading_challenge.experience.length>0)
        this.props.activity.setEditorType('experience');
        else
        {
            swal ("Oops!","No experience added to this reading challenge");
        }
        // let loc = this.props.location;
        // if (loc.pathname.includes("/reading-challenge/edit")) {
        //     this.props.history.push("/reading-challenge");}
            };

    render(){
        let {readingChallenge,activity}=this.props;
        let {permission}=this.props.onboard;
        return( <CreatorComponent style={{opacity: this.state.opacity}}>
                <ComponentContainer style={readingChallenge.active_reading_challenge ?{}:{ transform: `translateX(${this.state.translate})`}}>

                    {this.props.readingChallenge.fetchingReadingChallenge ? (
                        <LoadingContainer style={readingChallenge.active_reading_challenge?{height:'50px'}:{top:0}}>
                            <div>
                                <img alt="loading" width="60" height="60" src={require("static/img/Theme-loader.gif")} />
                            </div>
                        </LoadingContainer>
                    ) : null}

                    {this.props.readingChallenge.active_reading_challenge && <TopNav primary style={{justifyContent:'flex-end'}}>
                        {/*<NavigationBar primary>*/}
                            {/*<ul>*/}
                                {/*<li>*/}
                    {/*<span className={*/}
                        {/*!this.setState.levelSelector*/}
                            {/*? "navbar-active" : "navbar-inactive"}*/}
                          {/*onClick={this.handleLevelSelector}>Easy*/}
                    {/*</span>*/}
                                {/*</li>*/}
                                {/*<li>*/}
                    {/*<span className={*/}
                        {/*this.state.levelSelector*/}
                            {/*? "navbar-active" : "navbar-inactive"}*/}
                          {/*onClick={this.handleLevelSelector}>Hard*/}
                    {/*</span>*/}
                                {/*</li>*/}
                            {/*</ul>*/}
                        {/*</NavigationBar>*/}


                        <div>
                        { Permission.getPermission(permission, "change_reading")? 
                            <ThemeButton onClick={this.addSection} style={{marginRight: '20px'}}> Add
                                Section</ThemeButton>:null}
                            {readingChallenge && readingChallenge.active_reading_challenge?<SaveChangesButton primary marginRight={"16px"} onClick={this.saveChangesBtn}>
                                View Experiences
                            </SaveChangesButton>:null }
                        </div>
                    </TopNav>}
                    <ActivityComponentInfoContainer>

                        {readingChallenge.active_reading_challenge && (
                            <ReadingChallengeInfo active_reading={readingChallenge.active_reading_challenge}/>
                        )}

                        {readingChallenge.active_reading_challenge && this.renderSections()}
                        {this.renderNewSections()}




                        {readingChallenge.active_reading_challenge && (
                            <McqSection
                                question_data={readingChallenge.questions}
                                handleShowSubContainer={this.handleShowSubContainer}
                            />
                        )}

                        {readingChallenge.active_reading_challenge && (
                            <TagsSection
                                tags={readingChallenge.active_reading_challenge.tag}
                                handleShowSubContainer={this.handleShowSubContainer}
                            />
                        )}

                    </ActivityComponentInfoContainer>
                </ComponentContainer>


            {activity.current_editor_type && <SubContainer active={activity.current_editor_type}>


                    {/*when title is passed it creates a new one instead of updating active_section  */}
                    {activity.current_editor_type === "text" && (
                        <TextEditor
                            new={this.state.sectionTitle}
                            onDone={this.hideSubContainer}
                        />
                    )}
                    {/*when title is passed it creates a new one instead of updating active_section*/}
                    {["image", "audio", "video", "document","file", "youtube", "media","pdf","epub"].includes(
                        activity.current_editor_type
                    ) && (
                        <MediaEditor
                            new={this.state.sectionTitle}
                            type={this.state.showSubContainer}
                            onDone={this.hideSubContainer}
                        />
                    )}
                    {activity.current_editor_type === "mcq" && (
                        <McqEditor
                            title={this.state.sectionTitle}
                            onDone={this.hideSubContainer}
                        />
                    )}
                    {activity.current_editor_type === "tag" && (
                        <TagEditor

                            title={this.state.sectionTitle}
                            onDone={this.hideSubContainer}
                        />
                    )}
                    {activity.current_editor_type === "experience" && (
                        <ExperienceEditor
                            onDone={this.hideSubContainer}
                        />
                    )}
                </SubContainer>}
            </CreatorComponent>


        )
    }
}
export default connectStore({
    activity,readingChallenge,onboard
})(ReadingChallengeCreator)