import {createActions, using} from 'redux-box'

export default createActions({
    requestCreateGradeLevelTest: using("grade"),
    requestGetAllAssessmentQuestions:using("levelId"),
    requestGetLevelTests:using(),
    requestCreateAssessmentQuestion:using("question"),
    requestGetLevelTestById:using("assessmentId"),
    requestUpdateAssessmentQuestion:using("mcq"),
    setActiveAssessmentMcq:using("mcq"),
    requestDeleteAssessmentQuestion:using("question"),
    setActiveAssessment:using("activeAssessment"),
    requestUpdateAssessment:using("data"),
    unsetActiveAssessment:using(),
    unsetAllActiveAssessmentQuestion:using(),

})