import React from 'react';
import { connectStore } from 'redux-box';
import { module as activity } from 'store/activity';
import { module as onboard } from "@st/onboard";
import { module as freadomFuture } from "@st/freadomFuture";
import store from 'store';
import action from '@st/activity/actions';


import { TaskListCardContainer, TaskListCardImageContainer, TaskListCardContent, TaskListCardTitle, TaskListCardDescription, TaskListCardType } from './TaskListCard.styled'

const TaskListCardCard = (props) => {
    return (
        <TaskListCardContainer  
            onClick={() => {
                props.freadomFuture.setActiveFreadomFutureTask(props.taskData);
                store.dispatch(action.setEditorType('task'))
            }} 
        >
            <TaskListCardImageContainer url={props.taskData.thumbnail_url} >
            </TaskListCardImageContainer>
            <TaskListCardContent>
                <TaskListCardTitle>
                  {props.taskData.name}
                </TaskListCardTitle>
                <TaskListCardDescription>
                    {props.taskData.description}
                </TaskListCardDescription>
            </TaskListCardContent>
            <TaskListCardType>
                <span>
                    {props.taskData.content_type}
                </span>
            </TaskListCardType>
        </TaskListCardContainer>
    )
}
export default connectStore({
    activity, onboard, freadomFuture
})(TaskListCardCard)