import styled from "styled-components";
import { AlignContentHorizontal } from "@sc/custom.styled";
export const ActivitiesListContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
  width: 100%;
  padding: 8px 14px ;
  padding-top:240px;
`;

export const ActivitiesListHeader = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;
  padding: 14px 0px;
  margin:0px 10px;
  border-top: 2px solid rgba(0, 0, 0, 0.2);
  border-bottom: 2px solid rgba(0, 0, 0, 0.2);

  span {
    font-size: 20px;
    color: #000;
    font-weight: bold;
  }
`;

export const ActivitiesListMainContainer = AlignContentHorizontal.extend`
  padding: 20px 0px;
  max-height: calc(100vh - 420px);
    overflow-y: auto;
`;
