import styled from "styled-components";
import { FlexBetween, FlexCenter } from "@sc/custom.styled";

export const CreateMCQQuestionContainer = styled.div`
  margin-top: 15px;
  margin-bottom: 15px;
  display: flex;
  flex:1;
  justify-content: center;
  overflow-y: auto;
  max-height: 100%;
  border-radius: 10px;
  background-color: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.15);
  textarea {
    width: 100%;
    height: 80px;
    padding: 10px;
    text-align: left;
    font-size: var(--sm-font-size);
    outline: none;
    border: none;
    resize: none;
    font-weight: 500;
    color: #000000;
  }
`;

export const MCQOptionsAnswerContainer = FlexBetween.extend`
  margin-bottom: 15px;
  width: 100%;
  flex-flow: row wrap;
  input,
  textarea {
    width: 72%;
    outline: none;
    padding-top: 6px;
    height: 35px;
    font-size: var(--sm-font-size);
    text-align: left;
    padding-left: 15px;
    padding-right: 15px;
    color: #000;
    /*border-radius: 100px;*/
    /*FREAD-1205*/
    border-radius: 25px;
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, 0.15);

    &::placeholder {
      font-size: var(--sm-font-size);
    }
  }
`;

export const MCQDeleteButtonContainer = styled.div`
  // width: 18%;
  display: flex;
  justify-content: space-between;
  flex-flow: row nowrap;
  align-items: center;
  cursor: pointer;
  img {
    width: 12px;
    height: 16px;
    text-align: center;
  }
  span {
    font-size: var(--xsm-font-size);
    text-align: left;
    margin-left: 0px;
    color: #ff7373;
  }
`;

export const MCQCheckBoxContainer = FlexCenter.extend`
  border-radius: 100%;
  display:flex;
  padding:1px;
  align-items:center;
  justify-content:center;
  border: 1px solid rgba(0, 0, 0, 0.2);
  cursor: pointer;
  background: ${props => (props.active ? '#26de81;' : "")};
  color: ${props => (props.active) ? 'white' : ""};
  width: ${props => (props.active) ? '25px' : '25px'};
  height: ${props => (props.active) ? '25px' : '25px'};
  transition: all 0.3s;
 `;
export const MCQCheckBoxInnerContainer = styled.div`
  border-radius: 100%;
  display: flex;
  align-items:center;
  justify-content:center;
  width: 100%;
  height: 100%;
  background: #26de81;
`;

export const QuestionHeader = styled.div`
  display: flex;
  flex-flow: column nowrap;
`;
export const TagBox = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 10px 5px 30px 5px;
  border-top: 1px solid #f2f2f2;
`;
