export default {
  //MISC
  SET_CREATE_SUCCESS: (state, { value }) => (state.create_success = value),
  SET_CRITERIA_TYPE: (state, { data }) => (state.criteriaType = data),
  SET_FETCH_ACTIVE_NOTIFICATION: (state, { value }) =>
    (state.fetch_active_notification = value),
  SET_NOTIFICATION_EDITOR_TYPE: (state, { value }) =>
    (state.editor_type = value),
  SET_FETCH_NOTIFICATION_LIST: (state, { value }) =>
    (state.fetch_notification_list = value),
  SET_FETCH_USER_CHILD_LIST: (state, { value }) =>
    (state.fetch_user_child_list = value),
  SET_USER_CHILDREN_LIST: (state, { result }) => {
    state.children_list = result;
  },
  UNSET_NOTIFICATION_DATA: (state, { value }) => {
    state.unset_data = value;
  },
  // notification description for email type notification
  SET_NOTIFICATION_DESCRIPTION: (state, { value }) =>
    (state.email_description = value),
  SET_NOTIFICATION_TYPE: (state, { type }) => (state.notification_type = type),

  SET_ENABLE_SCROLL: (state, { value }) => (state.enableScroll = value),
  SET_USER_CONCAT_CHILDREN_LIST: (state, { result }) => {
    state.children_list = state.children_list.concat(result);
  },
  SET_USER_CHILDREN_LIST_RESULT: (state, { result }) => {
    state.children_list_result = result;
  },
  SET_ENABLE_DONE_BTN: (state, { value }) => {
    state.enableDoneBtn = value;
  },
  // SETGrades
  SET_GRADE_LIST: (state, { result }) => (state.grade_list = result),
  UNSET_DROPDOWN_DATA: state => {
    state.selected_levels = [];
    state.notification_type = "sms";
    state.email_description = "";
    state.selected_schools = null;
    state.selected_sections = [];
    state.selected_grades = [];
    state.selected_users = [];
    state.selected_all_data = "";
    state.selected_freadom_data = "";
    state.search_child = "";
    state.selected_wow_data = "";
    state.criteriaType = "People/Users";
    if (state.active_notification) state.active_notification = null;
  },

  SET_DROPDOWN_DATA: (state, { data }) => {
    let type = data.filter_type;
    switch (type) {
      case "all": {
        state.criteriaType = "People/Users";
        if (data.custom_user_ids.length === 0) {
          state.selected_users = ["All"];
          state.selected_all_data = "All Children";
        } else {
          state.selected_users = data.custom_user_ids;
          state.selected_all_data = data.custom_user_ids.length + " Children";
        }
        break;
      }
      case "wow-grade":
      case "wow-school": {
        state.criteriaType = "WOW";
        let selected_users = data.custom_user_ids;
        let child_label =
          selected_users.length === 0 ? "All" : selected_users.length;
        if (selected_users.length === 0) state.selected_users = ["All"];
        else state.selected_users = selected_users;

        if (data.filter_type === "wow-grade")
          state.selected_sections = data.filter_type_ids;
        else state.selected_sections = ["All"];
        if (data.school_name && data.school_name.length > 0) {
          state.selected_schools = data.school_name[0];
          state.selected_wow_data =
            data.school_name[0].name +
            ", " +
            data.filter_type_ids.length +
            "Sections, " +
            child_label +
            " Children";
        }
        break;

        // state.selected_schools=data.school_name
      }

      case "freadom-grade": {
        state.criteriaType = "Freadom";
        state.selected_filter = "Grade";

        state.selected_grades = data.filter_type_ids;
        state.selected_users = data.custom_user_ids;
        if (data.custom_user_ids.length === 0) {
          state.selected_users = ["All"];
          state.selected_freadom_data =
            "All Children, " + data.filter_type_ids.length + " Grades";
        } else
          state.selected_freadom_data =
            data.custom_user_ids.length +
            " Children, " +
            data.filter_type_ids.length +
            " Grades";
        break;
      }
      case "freadom-level-sublevel": {
        state.selected_filter = "Level";
        state.selected_users = data.custom_user_ids;
        state.selected_levels = data.filter_type_ids;
        if (data.custom_user_ids.length === 0) {
          state.selected_users = ["All"];
          state.selected_freadom_data =
            "All Children, " + data.filter_type_ids.length + " Levels";
        } else {
          state.selected_freadom_data =
            data.custom_user_ids.length +
            " Children, " +
            data.filter_type_ids.length +
            " Levels";
        }
        break;
      }

      default: {
        break;
      }
    }
  },
  UNSET_CHILDREN_LIST_RESULT: state => {
    state.children_list_result = [];
  },
  SET_CHILDREN_LIST_SEARCH: (state, { search }) => {
    state.search_child = search;
  },
  UNSET_CHILDREN_LIST: state => {
    state.children_list = [];
  },
  SET_SELECTED_FIRST_CHILD: (state, { child }) =>
    (state.selected_first_child = child),
  SET_WOW_SCHOOL_LIST: (state, { result }) => (state.wow_school_list = result),
  SET_WOW_SECTIONS_LIST: (state, { data }) => (state.wow_section_list = data),
  SET_SELECTED_LEVELS_LIST: (state, { data }) => (state.selected_levels = data),
  SET_SELECTED_SCHOOLS_LIST: (state, { data }) =>
    (state.selected_schools = data),
  SET_SELECTED_SECTIONS_LIST: (state, { data }) =>
    (state.selected_sections = data),
  SET_SELECTED_GRADE_LIST: (state, { data }) => (state.selected_grades = data),
  SET_SELECTED_USERS_LIST: (state, { data }) => (state.selected_users = data),
  SET_SELECTED_ALL_DATA: (state, { data }) => (state.selected_all_data = data),
  SET_SELECTED_FREADOM_DATA: (state, { data }) =>
    (state.selected_freadom_data = data),
  SET_SELECTED_WOW_DATA: (state, { data }) => (state.selected_wow_data = data),
  SET_SELECTED_GRADE_COUNT: (state, { value }) =>
    (state.selected_grade_count = value),
  SET_SELECTED_SECTION_COUNT: (state, { value }) =>
    (state.selected_section_count = value),
  SET_SELECTED_SCHOOL_COUNT: (state, { value }) =>
    (state.selected_school_count = value),
  SET_SELECTED_USER_COUNT: (state, { value }) =>
    (state.selected_user_count = value),

  // CRUD NOTIFICATIONS
  SET_NOTIFICATION_RESULT: (state, { result }) =>
    (state.notification_result = result),
  SET_NOTIFICATION_LIST: (state, { data }) => (state.notification_list = data),
  SET_CONCAT_NOTIFICATION_LIST: (state, { data }) =>
    (state.notification_list = state.notification_list.concat(data)),
  SET_SELECTED_FILTER: (state, { filter }) => (state.selected_filter = filter),
  SET_ACTIVE_NOTIFICATION: (state, { result }) =>
    (state.active_notification = result),

  // FOR FILTERING THE DATA
  RESET_FILTER_DATA: state => {
    state.search_notification = "";
    state.filter_type = "all";
    state.filter_date = "";
    state.filter_not_type = "all";
  },
  SET_SEARCH_NOTIFICATION: (state, { search }) => {
    state.search_notification = search;
  },
  SET_FILTER_USER_TYPE: (state, { userType }) => {
    state.filter_user_type = userType;
  },
  SET_FILTER_DATE: (state, { date }) => {
    state.filter_date = date;
  },
  SET_SELECTED_NOT_TYPE: (state, { value }) => {
    state.selected_not_type = value;
  },
  SET_FILTER_MESSAGE_TYPE: (state, { msgType }) => {
    state.filter_msg_type = msgType;
  },
  SET_MULTIPLE_SCHOOL_LIST: (state, { data }) => {
    state.multiple_school_list = data;
  }
};
