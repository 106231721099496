import { Api } from "./base";

const _headers = () => {
  return {
    Authorization:
      "JWT " + require("store").default.getState().onboard.user.auth_token,
    "Content-Type": "application/json"
  };
};

export default {
  getAnalyticsStory() {
    return Api.getRequest(`/analytics/v1/story_news_activity_csv/`, _headers());
  },
  getProjects() {
    return Api.getRequest(`/live-classes/v1/timekit-projects/`, _headers());
  }
};


