export default {
  SET_LEADER_BOARD_LIST: (state, { data }) => (state.leaderBoardList = data),
  SET_FREADOM_POINT_LEADER_BOARD_LIST: (state, { data }) =>
    (state.freadomPointLeaderBoardList = data),
  SET_LOADER: (state, { data }) => (state.loader = data),
  SET_SEARCH_LEADER_BOARD: (state, { search }) => (state.search_leaderBoard = search),
  SET_SEARCH_FREADOM_LEADER_BOARD: (state, { search }) => { state.search_leaderBoard = search },


};
