import styled from "styled-components";
import { border} from "@sc/custom.styled";
import {ActivityInputField} from "@c/activity-view/activity-creation-form/ActivityCreationForm.styled";


export const Heading = styled.span`
font-weight:500;
font-size:18px;
`;

export const NotificationDetailContainer = styled.div`
padding:15px;
overflow-y:auto;
padding-top:48px;
height:100%;
`;

export const NotificationDetailEditor = styled.div`

`;

export const PeopleSelectorContainer = styled.div`
border-bottom:1px solid ${border};
padding:10px 0;
     
`;

export const NotificationBasicInfoContainer = PeopleSelectorContainer.extend`
border-bottom:1px solid ${border};
padding:10px 0px;

     
`;

export const UploadImageContainer=styled.div`
 width:60px;
 height:60px;
 position:relative;
 border-radius: 6px;
 img
 {
    height: auto;
    width: 100%;
    max-width: 100%;
    object-fit: cover;
    object-position: center;
    cursor: pointer;
    border-radius: 10px;
 }
 
 `;
export const NotificationDescriptionContainer=styled.div`
    min-height:40px;
    position:relative;
    
    max-height:80px;
    textarea
    {
        font-size:var(--sm-font-size);
        color:#000;
        outline:none;
        max-height:80px;
        background-color:white;
        border:none;
        height:100%;
        width:100%;
       ::placeholder {
       color: #b2b2b2;
       font-size:var(--md-font-size);
    }
    `;

export const CounterContainer=styled.div`
  display:flex;
  justify-content:center;
  align-items:center;
  width:28px;
  height:28px;
  position:absolute;
  bottom:0;
  right:0;
  border-radius:100%;
  font-size:var(--xsm-font-size);
  color:white;
  background-color:#ff8a23`;
export const NotificationInputField=ActivityInputField.extend`
padding:0px;
::placeholder {
font-weight: 900;
color:rgba(0,0,0,0.3);
}`;

export const AddButtonContainer=styled.div`
 width:100%;
 height:100%;
 cursor:pointer;
  border-radius: 6px;
  display:flex;
  justify-content:center;
  align-items:center;
  font-size:32px;
  color:white;
  font-weight:500;
  background-color: #d8d8d8;`;

export const SelectImageButton=styled.input`
   position:absolute;
   opacity:0;
  top:0;
  left:0;
   width:60px;
   height:60px`;

export const MessageTypeSelector = PeopleSelectorContainer.extend`

`;

export const SchedulingContainer = PeopleSelectorContainer.extend`

`;
export const CriteriaOptionContainer=styled.div`
display:flex;
margin-top:10px;
justify-content:space-between;
flex-flow:column nowrap;

`;
export const CriteriaOptionItem=styled.div`
width:100%;
margin-bottom: 12px;
`;

export const SelectBoxContainer=styled.div`
display:flex;
justify-content:flex-start;
align-items:center;
img
{
width:18px;
height:18px;
margin-left:10px;
padding:4px;
cursor:pointer;
}`;
export const CriteriaSelectedData=styled.span`
font-size:12px;
color:rgba(0,0,0,0.4);
margin-left:20px;`;