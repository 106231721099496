import React, { Component } from "react";
import { connectStore } from "redux-box";
import { module as ui } from "@st/ui";
import { module as notification } from "@st/notification";
import actions from "@st/ui/actions";
import store from "store";
import {
  Heading,
  SelectBox,
  SelectorButton
} from "../../../static/styled/custom.styled";
import UsersDropdownContainer from "./users-dropdown-container/UsersDropdownContainer";
import WOWDropdownContainer from "./w-o-w-dropdown-container/WOWDropdownContainer";
import FreadomDropdownContainer from "./freadom-dropdown-container/FreadomDropdownContainer";
import {
  CloseIconBar,
  ModalBackDrop,
  ModalContainer,
  ModalView,
  ModalViewParentContainer,
  ModalViewContainer
} from "./NotificationSelectorModal.styled";

export const id = {
  WOWGRADE: "WOWGRADE",
  FREADOMGRADE: "FREADOMGRADE",
  ALLUSERS: "ALLUSERS"
};

class NotificationSelectorModal extends Component {
  componentDidMount() {
    // Handle ESC key (key code 27)
    document.addEventListener("keyup", function (e) {
      if (e.keyCode === 27) {
        store.dispatch(actions.hideNotificationModal());
      }
    });
  }

  handleModalClose = () => {
    this.props.ui.hideNotificationModal();
    this.props.notification.setMultipleSchoolList([]);
  };

  render() {
    let comp_id = this.props.ui.show_notification_modal;
    let { notification } = this.props;
    return (
      <div>
        <ModalBackDrop active={comp_id} />
        <ModalContainer active={comp_id} id="modalContainer">
          <ModalView id="modalDisplay">
            <ModalViewParentContainer>
              <ModalViewContainer>
                <CloseIconBar onClick={() => this.handleModalClose()}>
                  <img
                    src={require("static/img/grey-cross.png")}
                    alt="close icon"
                  />
                </CloseIconBar>
                <Heading styled={{ fontSize: "20px" }}>Select Criteria</Heading>
                <SelectBox active>
                  <SelectorButton active>
                    <div />
                  </SelectorButton>
                  <span>{notification.criteriaType}</span>
                </SelectBox>
                {comp_id === id.ALLUSERS && <UsersDropdownContainer />}
                {comp_id === id.WOWGRADE && <WOWDropdownContainer />}
                {comp_id === id.FREADOMGRADE && <FreadomDropdownContainer />}
              </ModalViewContainer>
            </ModalViewParentContainer>
          </ModalView>
        </ModalContainer>
      </div>
    );
  }
}
export default connectStore({
  ui,
  notification
})(NotificationSelectorModal)