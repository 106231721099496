import React, { Component } from "react";

import { connectStore } from "redux-box";
import { module as inviteCode } from "store/inviteCode";
import { module as activity } from "store/activity";
import { module as ui } from "store/ui";
import { ActivityContinueButtonContainer, CreateActivityContainer } from "../../activity-view/activity-creation-form/ActivityCreationForm.styled";
import { StoryImagesContainer } from "../../story-view/story-creation-form/StoryCreationForm.styled";
import moment from "moment";
import DatePicker from "react-datepicker";
import { DateSelector, DateSelectorIcon } from "../renewalold-school/RenewalOldSchool.styled";
import { ModalContainer } from "../school-code/SchoolCode.styled";
import { ThemeButton } from "../../../static/styled/custom.styled";


class SchoolView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchText: "",
      schoolData:{}
    };
    
  }

    componentDidMount(){
        let {selectedSchool} = this.props.inviteCode

        this.setState({schoolData:{
            ...selectedSchool,
            name:selectedSchool.name,
            trial_expiry_date: selectedSchool.trial_expiry_date? moment( selectedSchool.trial_expiry_date).format("YYYY-MM-DD"): moment().format("YYYY-MM-DD"),
        }})
    }
 
    handleSchoolNameChange=(e)=>{
        let {schoolData} = this.state

        this.setState({
            schoolData: {
                trial_expiry_date: schoolData.trial_expiry_date? moment( schoolData.trial_expiry_date).format("YYYY-MM-DD"): moment().format("YYYY-MM-DD"), 
                name:e.target.value}
          })
    }

    onDatepickerRef(el) {
        if (el && el.input) {
          el.input.readOnly = true;
        }
      }
    
    handleDateChange = (date) => {
        this.setState({ schoolData:
            {...this.state.selectedSchool, trial_expiry_date:moment(date).format("YYYY-MM-DD")}});
    };
    handleContinueBtn= () => {
        this.props.ui.hideModal();
        this.props.inviteCode.requestUpdateSchool(this.state.schoolData)
    }
  render() {
    let {schoolData} = this.state
    return (
        <ModalContainer>
            
            <input type="text" className="form-control" 
                placeholder="School Name" aria-label="schoolName" 
                aria-describedby="basic-addon1"
                value={schoolData.name}
                name="name"
                onChange={(val)=>this.handleSchoolNameChange(val)}/>
                <br/>
                <div>
                    <DateSelector style={{ overflow: "hidden" }}>
                    <DateSelectorIcon className="fa fa-calendar" />
                    <DatePicker
                        selected={schoolData.trial_expiry_date}
                        selectsStart
                        startDate={schoolData.trial_expiry_date}
                        onChange={this.handleDateChange}
                        minDate={moment().toDate()}
                        placeholderText="Select Expiry date"
                        ref={(el) => this.onDatepickerRef(el)}
                    />
                    {/* {this.state.date ? (
                        <i
                        className="fa fa-times"
                        onClick={() => this.handleClearBtn()}
                        style={{ padding: "8px", cursor: "pointer" }}
                        />
                    ) : null} */}
                    </DateSelector>
                </div>
                <ActivityContinueButtonContainer>
                    <ThemeButton
                        primary
                        type="button"
                        onClick={() => this.handleContinueBtn()}
                    >
                        Continue
                    </ThemeButton>
                    </ActivityContinueButtonContainer>    
        </ModalContainer>
    );
  }
}
export default connectStore({
  ui,
  inviteCode,
  activity
})(SchoolView)