import {createActions,using} from 'redux-box'

export default createActions({
    //..............Reading CRUD........................
    setActiveReadingGrade:using("grade"),
    requestReadingChallenge:using(),
    requestReadingChallengeByGradeId:using("gradeId"),
    requestCreateReadingChallenge:using("reading"),
    requestUpdateReadingChallenge:using("reading"),
    requestReadingChallengeById:using("readingId"),
    setActiveReadingChallenge:using("activeReading"),
     setReadingChallengeList:using("readingList"),
    setFetchReadingChallengeList:using("value"),
    requestConcatReadingChallenge:using("offset"),
    //.................Section............................
    addNewReadingSection: using(),
    setActiveReadingSection: using("section"),
    removeSection: using(),
    startCreatingReadingSection: using("sectionType, data"),
    startUpdatingReadingSection: using("sectionType, data"),
    removeNewReadingSection: using("section"),
    updateNewReadingSection: using("section"),
    resetNewReadingSections: using(),
    requestReadingSectionById:using("readingId"),
    requestCreateReadingSection:using("section"),
    requestDeleteReadingSection:using("sectionId"),
    requestUpdateReadingSection:using("section"),
    requestActiveGrade:using(),
    deleteReadingSection: using("section"),

    //..................tags......................
    requestCreateReadingTag:using("tag"),
    deleteReadingTag:using("tag"),

    //...............Questions.....................
    requestAllReadingQuestions: using("readingId"),
    requestCreateReadingQuestion: using("question"),
    requestUpdateReadingQuestion: using('question'),
    requestDeleteReadingQuestion: using('question'),
    updateNewReadingQuestion: using("question"),
    unsetActiveReadingQuestion:using(),
    createReadingQuestion: using("readingId", "question"),
    newReadingQuestion: using("question"),
    addNewReadingOption:using("option"),
    removeNewReadingOption:using("optionId"),
    setActiveReadingMcq:using("mcq"),

    //.................Experience.................
    requestPublishReadingExperience:using("experience"),
    requestDeleteReadingExperience:using("experienceId"),
    setActiveReadingExperience:using("experience"),

    //...............MISC.................
    setSearchReading:using("search"),

})