import React, { Component } from "react";
import { McqFreadContainer } from "./NewsFreedsMcqList.styled";
import store from "store";
import action from "@st/newsFreeds/actions";
import { connectStore } from "redux-box";
import { module as newsFreeds } from "@st/newsFreeds";
import {
  McqDescription,
  McqItemContainer,
  McqToolbar,
  McqSectionHeader,
  ThemeButton
} from "static/styled/custom.styled";
import { module as onboard } from "@st/onboard";
import Permission from "@s/permission";
import swal from "sweetalert2";

 class NewsFreedsMcqList extends Component {
  
  handleEditClick = data => {
    const { permission } = this.props.onboard;
    if(Permission.getPermission(permission, "change_newsfread")){
      let { newsFreeds } = this.props;
      newsFreeds.setActiveNewsFreedQuestion(data);
      newsFreeds.setNewsEditorType("mcq");
    }else{
      swal({
        text: "You do not have permission.",
        type: "warning",
      })
    }
   
  };

  render() {
    let { newsFreeds } = this.props;
    const { permission } = this.props.onboard;
    return (
      <McqFreadContainer>
        <McqSectionHeader>
          Assign MCQ
          {newsFreeds.questions && newsFreeds.questions.length > 0 ? null : (
            Permission.getPermission(permission, "change_newsfread")?
            <ThemeButton
              onClick={() => {
                newsFreeds.setActiveNewsFreedQuestion(null);
                store.dispatch(action.setNewsEditorType("mcq"));
              }}
            >
              + Create MCQ
            </ThemeButton>:null
          )}
        </McqSectionHeader>

        {newsFreeds.questions &&
          newsFreeds.questions.map((data, idx) => (
            <McqItemContainer
              key={idx}
              style={{ cursor: "pointer" }}
              onClick={() => this.handleEditClick(data)}
            >
              <McqDescription>{data.name}</McqDescription>
              <McqToolbar
                style={{
                  height: "100%",
                  display: "flex",
                  alignItems: "center"
                }}
              >
                <img
                  src={require("static/img/edit-button-blue.png")}
                  onClick={() => this.handleEditClick(data)}
                  className="image-fluid"
                  alt="Edit icon"
                />
              </McqToolbar>
            </McqItemContainer>
          ))}
      </McqFreadContainer>
    );
  }
}
export default connectStore({
  newsFreeds,onboard
})(NewsFreedsMcqList)