import React, { Component } from 'react';
import { StorySectionContainer } from "@c/story-view/story-creator/StoryCreator.styled";
import { MediaThemedButton, FlexStart, SubHeading } from "static/styled/custom.styled";
import { FileListContainer } from "@c/activity-view/activity-creator/file-section/FileSection.styled";
import { connectStore } from "redux-box";
import { module as story } from 'store/story';
import { uploadFile } from "services/api/file";
import swal from "sweetalert2";
import { module as onboard } from "@st/onboard";
import Permission from "@s/permission";

class PdfStory extends Component {
    constructor(props) {
        super();
        this.state = ({
            pdfName: '',
            enableLoad: false,
            media: [],
        })
    }

    componentDidMount() {
        let { section } = this.props;
        if (section !== null && section.content_type === 'pdf') {
            this.setState({
                pdfName: section.name !== 'pdf' ? section.name : '',
                media: section.media,
            })
        }

    }
    handleUpload = e => {
        if (e.target.files !== null) {
            let { story } = this.props;
            story.setFetchMediaLoading(true);

            this.setState({
                enableLoad: true,
            });

            let fileSize = e.target.files[0].size / 1024 / 1024;
            if (fileSize > 4) {
                swal('Oops!', 'File size greater than 4MB ', 'warning')
            }
            else {
                let pdfName = e.target.files.length > 0 ? e.target.files[0].name : 'pdf';
                uploadFile(e.target.files[0]).then(res => {
                    this.setState({
                        // pdfName:e.target.files && e.target.files[0].name,
                        media: [{ thumbnail_url: '', media_url: res.file }],
                        //videoFileName:e.target.files[0].name
                    });
                    if (story.active_story) {
                        let newSection = {
                            content_type: 'pdf',
                            media: this.state.media,
                            name: pdfName,
                            description: '',
                            story: story.active_story.id
                        };

                        if (this.props.section === null) {
                            story.requestCreateStorySection(newSection);
                        }
                        else {
                            story.requestUpdateStorySection(newSection);
                        }
                    }
                    this.setState({
                        enableLoad: false,
                        pdfName: pdfName,
                    });
                    story.setFetchMediaLoading(false);

                });
            }

        }

    };
    render() {
        let {permission}=this.props.onboard;
        return (
            <StorySectionContainer>
                <FlexStart>
                    {Permission.getPermission(permission, "change_story") ?
                    <MediaThemedButton primary> {this.state.media.length > 0 ? 'Change PDF' : 'Upload PDF'}
                        <input
                            type="file"
                            accept=".pdf"
                            onChange={this.handleUpload}
                        />
                    </MediaThemedButton>:null}
                    {this.state.enableLoad ? <SubHeading style={{ margin: '0px', marginLeft: '12px' }}>
                        Uploading Pdf <img alt="loader" src={require("static/img/Theme-loader.gif")} width="32" height="32" /></SubHeading> :
                        <SubHeading style={{ margin: '0px', marginLeft: '12px' }}>
                            {this.state.pdfName} </SubHeading>}

                </FlexStart>
                {!this.state.enableLoad ?
                    <FileListContainer style={{ marginTop: '20px' }}>
                        {this.state.media.map((mediaFile, i) => (
                            <a target="_blank" href={mediaFile.media_url} key={i}>
                                <i className="fa fa-file-pdf-o" style={{ fontSize: '48px', color: 'red', width: '100%', height: '100%' }} />
                            </a>
                        ))}
                    </FileListContainer> : null}
            </StorySectionContainer>
        )
    }
}
export default connectStore({
    story,onboard
})(PdfStory)