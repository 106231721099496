import React, { Component } from "react";
import {
  HeaderLabel,WaveContainer
} from "./Welcome.styled";
import {connectStore} from "redux-box";
import {module as onboard} from "@st/onboard";

class Welcome extends Component {
    render() {
        let { user } = this.props.onboard;
        return (
            <div>
                <HeaderLabel>Welcome back, <span style={{textTransform: 'capitalize'}}>&nbsp;{user.name}</span></HeaderLabel>
                <WaveContainer>
                    <div className="wave">👋</div>
                </WaveContainer>
                
            </div>
            )
    }
}
export default connectStore({
    onboard
})(Welcome)