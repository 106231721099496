import React, { Component } from "react";
import {
  MainBodyContentContainer,
  MainBodyContainer,
} from "@/static/styled/custom.styled";
import ViewHeader from "./view-header";
import LeaderBoardTabRouter from "@r/leaderBoardTabRouter";
import { connectStore } from "redux-box";
import { module as leaderBoard } from "store/leaderBoard";


class LeaderBoardView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchBarEnable: false,
      navigationSelector: false
    };
  }
  enableSearchBar = actionType => {
    if (actionType === "focus" && this.state.searchBarEnable === false)
      this.setState({
        searchBarEnable: true
      });
    else if (actionType === "blur" && this.state.searchBarEnable === true)
      this.setState({
        searchBarEnable: false
      });
  };

  render() {
    return (
      <MainBodyContainer>
        <ViewHeader {...this.props} />
        <MainBodyContentContainer>
          <LeaderBoardTabRouter />
        </MainBodyContentContainer>
      </MainBodyContainer>
    );
  }
}
export default connectStore({
  leaderBoard
})(LeaderBoardView)