import React, { Component } from "react";
import {
  ThemeButton,
  ErrorField,
  PanelHeader,
  TagChipsContainer,
  AudioThemeButton2,
  AudioThemeUploadIcon,
  TagChips
} from "@sc/custom.styled";
import {
  ActivityModalContainer,
  ActivityPopUpContainer
} from "@sc/ActivityModal.styled";
import {
  CreateMCQQuestionContainer,
  MCQCheckBoxContainer,
  MCQCheckBoxInnerContainer,
  MCQDeleteButtonContainer,
  MCQOptionsAnswerContainer,
  QuestionHeader
} from "@sc/McqEditor.styled";
import { connectStore } from "redux-box";
import { module as activity } from "@st/activity";
import { module as tags } from "@st/tags";
import { module as ui } from "@st/ui";
import TextArea from "react-autosize-textarea";
import swal from "sweetalert2";
import { UploadImageContainer } from "../../../story-view/story-creator/mcq-editor/McqEditor.styled";
import { UploadCollectionImageButton } from "../../../story-view/story-collection-creator/StoryCollectionCreator.styled";
import { ChangeImageButton, ImageContainer, SelectFileIn } from "../../activity-creation-form/ActivityCreationForm.styled";
import { uploadFile } from "@s/api/file";

const errorHandler = err => {
  swal({
    text: err,
    type: "warning"
  });
};
//FREAD-1325
let QuestionAnswerType =[
  "text",'image','audio'
]
// let QuestionAnswerType =[
//   "text"
// ]
const QASubCategoryType= [
  'normal','reorder'
]
//FREAD-1325

class ActivityCreateMCQ extends Component {
  constructor() {
    super();
    this.state = {
      question: "",
      options: [],
      valid_option: null,
      width: "0",
      error: "",
      tags: [],
      tagPill: [],
      isMcqCreator: true,
      quesMedia:null,
      qimageChange:false,
      questionAnswerType:QuestionAnswerType,
      quesType:QuestionAnswerType[0],
      optionType:QuestionAnswerType[0],
      catType:QASubCategoryType[0]
    };
    this.MAX_OPTIONS_COUNT = 4;
    this.MIN_OPTIONS_COUNT = 4;
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.activity && nextProps.activity.active_mcq) {
      if (this.state.question === "") {
        this.setState({
          tagPill: nextProps.activity.active_mcq.tag,
          isMcqCreator: false
        });
      } else {
        this.setState({
          tagPill: nextProps.tags.selectedTagList,
          isMcqCreator: false
        });
      }
      this.normalizeForState(nextProps.activity.active_mcq);
    } else {
      if (!this.state.isMcqCreator) {
        this.setState({
          question: "",
          options: [],
          quesMedia:null,
          valid_option: null,
          tagPill: [],
          isMcqCreator: true,
          questionAnswerType:QuestionAnswerType,
          quesType:QuestionAnswerType[0],
          optionType:QuestionAnswerType[0],
          catType:QASubCategoryType[0]
        });
      }
    }
  }
  componentDidMount() {
    // hack to stabilize the textarea
    this.setState({
      question: " "
    });
    setTimeout(() => {
      if (this.props.activity && this.props.activity.active_mcq) {
        this.normalizeForState(this.props.activity.active_mcq);
        this.props.tags.setSelectedTagList(this.props.activity.active_mcq.tag);
      } else {
        this.setState({
          question: ""
        });
      }
    }, 200);
  }
  componentWillMount() {
    this.props.tags.requestTagList();
  }
  componentWillUnmount() {
    this.props.activity.setActiveMcq(null);
    this.props.tags.setSelectedTagList([]);
  }

  updateQuestion = e => {
    if (e.target.value !== "" && this.state.question.length === 1) {
      this.setError(null);
    }
    this.setState({
      question: e.target.value
    });
  };

  setError = value => {
    this.setState({
      error: value
    });
  };

  updateOption = (option, e) => {
    const index = this.state.options.findIndex(op => op.id === option.id);
    let options1 = this.state.options.slice(0, index);
    let options2 =
      this.state.options.length > 1
        ? this.state.options.slice(index + 1, this.state.options.length)
        : [];
    this.setState({
      options: [
        ...options1,
        { id: option.id, name: e.target.value,order: option.order ? option.order :null },
        ...options2
      ]
    });
  };

  setAsValidOption = option => this.setState({ valid_option: option });

  removeOption = option => {
    if (this.state.valid_option && this.state.valid_option.id === option.id)
      this.setState({
        valid_option: null
      });
    const index = this.state.options.findIndex(op => op.id === option.id);
    let options1 = this.state.options.slice(0, index);
    let options2 =
      this.state.options.length > 1
        ? this.state.options.slice(index + 1, this.state.options.length)
        : [];
    this.setState({
      options: [...options1, ...options2]
    });
  };

  addNewOption = () => {
    if (this.state.question === "") {
      this.setError("* Enter question ");
      return;
    }
    if ((this.state.quesType === "image" || this.state.quesType === "audio" || this.state.quesType === "video" )&& !this.state.quesMedia ) {
      this.setError("* Upload question ");
      return;
    }
    this.setState({
      options: [...this.state.options, {id: Math.random(), name: "" ,imageChange:false, media: [],order:null}]
    });
  };

  createMcq = () => {
    const mcq = this.normalizeForApi();
    if (this.state.question.length > 0) {
      if (this.state.options.length === 0)
        errorHandler("Please add options to add the mcq?");
      else if (
        this.state.optionType == 'text' &&((this.state.options[0] && this.state.options[0].name === "") ||
        (this.state.options[1] && this.state.options[1].name === ""))
      ){
        errorHandler("Option field is empty!");
      }else if (this.state.catType == 'reorder'){
        let passed = true;
        for (const key in this.state.options) {
          let cnt =Number(key)+1
          if( this.state.optionType != 'text' && this.state.options[key].media &&  this.state.options[key].media.length == 0){
            passed = false
            errorHandler(`Option ${cnt} is empty!`);
          }else if( this.state.optionType == 'text' && this.state.options[key].name == ""){
            passed = false
            errorHandler(`Option ${cnt} is empty!`);
          }else{
            if(!this.state.options[key].order){
              passed = false
              errorHandler(`Order for option ${cnt} is required!`);
            }
          }
        }
        if(passed){
          this.props.activity.requestCreateQuestion(mcq);
          this.props.activity.closeEditor();
        }
       
      }
       
      else if (this.state.options.length < 2)
        errorHandler("Please select at least two options to add the mcq?");
      else if ((this.state.catType == 'normal'|| this.state.catType == 'dragdrop' ) && this.state.valid_option == null)
        errorHandler("Please mark the correct answer to add the mcq?");
      else {
        this.props.activity.requestCreateQuestion(mcq);
        this.props.activity.closeEditor();
      }
    } else if (
      this.state.question.length === 0 &&
      this.state.options.length > 0
    )
      errorHandler("Question field is empty!");
    else if (
      this.state.question.length === 0 &&
      this.state.options.length === 0
    ) {
      this.props.activity.closeEditor();
    }
  };

  updateMcq = () => {
    const mcq = this.normalizeForApi();
    if (this.state.options.length === 0 && this.state.question.length > 0)
      errorHandler("Please add options to add the mcq?");
    else  if (this.state.quesType != 'text' && !this.state.quesMedia){
      this.setError("* Upload question ");
      return;
    }else if (this.state.question.length > 0 && this.state.options.length > 0) {
      if ((this.state.catType == 'normal' || this.state.catType == 'dragdrop') && this.state.valid_option === null)
        errorHandler("Please mark the correct answer to add the mcq?");
      else if (this.state.catType == 'reorder'){
        let passed = true;
        for (const key in this.state.options) {
          let cnt =Number(key)+1
          if( this.state.optionType != 'text' && this.state.options[key].media &&  this.state.options[key].media.length == 0){
            passed = false
            errorHandler(`Option ${cnt} is empty!`);
          }else if( this.state.optionType == 'text' && this.state.options[key].name == ""){
            passed = false
            errorHandler(`Option ${cnt} is empty!`);
          }else{
            if(!this.state.options[key].order){
              passed = false
              errorHandler(`Order for option ${cnt} is required!`);
            }
          }
        }
        if(passed){
          this.props.activity.requestUpdateQuestion(mcq);
          this.props.activity.closeEditor();
          this.props.activity.setActiveMcq(null);
        }
       
      }else{
        this.props.activity.requestUpdateQuestion(mcq);
        this.props.activity.closeEditor();
        this.props.activity.setActiveMcq(null);
      }
    } else if (
      this.state.question.length === 0 &&
      this.state.options.length > 0
    ) {
      errorHandler("Question Field is Empty! ");
    } else if (
      this.state.question.length === 0 &&
      this.state.options.length === 0
    ) {
      this.props.activity.closeEditor();
    }
  };

  normalizeForApi = () => {
    let tagList = this.props.tags.selectedTagIdList;
    const res = { name: this.state.question };
    res.activity = this.props.activity.active_activity.id;
    res.question_category = this.state.quesType;
    res.option_category = this.state.optionType;
    res.sub_category= this.state.catType;
    if(this.state.quesMedia){
      res.media= [this.state.quesMedia];
    }
    if (this.props.activity.active_mcq) {
      res.options = this.state.options //.map(op => ({ id: op.id, name: op.text }));
      if(this.state.catType == "normal" || this.state.catType == "dragdrop"){
        // res.valid_option = this.state.valid_option.id;
      }else{
        res.valid_option = null;
      }
      
      res.tag = tagList;
    } else {
      res.options = this.state.options //.map(op => op.text);
      res.tag = tagList;
      if (this.state.valid_option != null && this.state.options)
        res.valid_option =
          this.state.options.findIndex(
            op => op.id === this.state.valid_option.id
          ) + 1;
    }
    return res;
  };

  normalizeForState = mcq => {
    console.log("mcq1",mcq)
    const options = mcq.option.map(op => ({ id: op.id, 
      name: op.name,
      imageChange:false, 
      media: op.media? op.media : [] ,
      order:op.order? op.order:null}));
    let valid_option_index = mcq.option.findIndex(
      op => op.id === mcq.valid_option
    );
    let valid_option = options[valid_option_index];
    this.setState({
      question: mcq.name,
      catType:mcq.sub_category,
      quesType:mcq.question_category,
      optionType:mcq.option_category,
      quesMedia: mcq.media? mcq.media[0]:null,
      options,
      valid_option
    });
  };

  handleTagRemoveClick = data => {
    if (data.parent_tag !== null) {
      this.props.tags.removeTagFromSelectedTagList(data);
      this.setState({
        tagPill: this.state.tagPill.filter(tag => tag.id !== data.id)
      });
    } else {
      this.props.tags.removeTagFromSelectedTagList(data);
      this.props.tags.removeChildTagFromSelectedTagList(data);
      this.setState({
        tagPill: this.state.tagPill.filter(
          tag => tag.id !== data.id || tag.parent_tag === data.id
        )
      });
    }
  };

  handleAddTagClick = () => {
    if (this.props.activity.active_mcq === null) {
      this.props.tags.setSelectedTagList([]);
    }
    this.setState({
      isMcqCreator: true
    });
    this.props.ui.showModal("TAGSELECTORFORM");
  };

  handleImageChange=(i,e)=>{
      // e.preventDefault();
      let reader = new FileReader();
      let file = e.target.files[0];
      let options = this.state.options;
     
      let fileSize = e.target.files[0].size / 1024 / 1024;

      if (fileSize > 2) {
        swal("Oops!", "File size greater than 2Mb ", "warning");
      } else {
        reader.onloadend = () => {
          options[i].media = [reader.result]
          options[i].imageChange= false;
          this.setState({
            enableLoad: true,
            options: options,
          });
        };
        reader.readAsDataURL(file);
        uploadFile(file).then((res) => {
          options[i].media = [res.file]
          this.setState({
            options: options,
          });
        });
      }
  }
  handleOptionUpload=(i,e)=>{
   // e.preventDefault();
    let options = this.state.options;
   console.log(options)
    let fileSize = e.target.files[0].size / 1024 / 1024;

    if (fileSize > 20) {
      swal("Oops!", "File size greater than 20Mb ", "warning");
    } else {
      uploadFile(e.target.files[0]).then((res) => {
        options[i].media = [res.file]
        console.log("options",options)
        this.setState({
          options: options,
        });
      });
    }
}
  handleAudioUploadBtn = () => {
    this.setState({
      quesMedia:null
    });
  };

  handleFileUpload = e => {
    if (e.target.files) {
      let fileSize = e.target.files[0].size / 1024 / 1024;
      if (fileSize > 20) {
        swal("Oops!", "File size greater than 20MB ", "warning");
      } else {
        console.log(e.target.files[0])
        uploadFile(e.target.files[0]).then(res => {
          this.setState({
            quesMedia: res.file
          });
        });
      }
    }
  }
  handleQuesImageChange=(e)=>{
    // e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];
    let options = this.state.options;
   
    let fileSize = e.target.files[0].size / 1024 / 1024;

    if (fileSize > 2) {
      swal("Oops!", "File size greater than 2Mb ", "warning");
    } else {
      reader.onloadend = () => {
        this.setState({
          options: options,
          quesMedia:reader.result
        });
      };
      reader.readAsDataURL(file);
      uploadFile(file).then((res) => {
        this.setState({
          quesMedia:res.file,
        });
      });
    }
}
  handleQuesChange=(e)=>{
    this.setState({quesType:e.target.value,quesMedia:null})
  }
  handleOptionChange=(e)=>{
    this.setState({optionType:e.target.value,options:[]})
  }

  handleCatChange=(e)=>{
    if(e.target.value == 'reorder'){
      this.setState({questionAnswerType:['text'],quesType:'text',optionType:'text'})
    }else{
      this.setState({questionAnswerType:QuestionAnswerType})
    }
    this.setState({catType:e.target.value,valid_option:null})
  }

  changeOrder=(e,index)=>{
    let option = this.state.options;
    let a=  option.map((v,i)=>{
      if(v.order == e.target.value){
        v.order = null;
      }else if(index == i){
        v.order = Number(e.target.value);
      }
      return v
    })
    this.setState({options: a})
  }
  handleQuesImageFocus = (actionType) => {
    if (actionType === "over" && this.state.qimageChange === false){
      this.setState({
        qimageChange: true,
      });
    }else if (actionType === "out" && this.state.qimageChange === true){
      this.setState({
        qimageChange: false,
      });
    }
      
  };

  handleImageFocus = (actionType,i) => {
    if (actionType === "over" && this.state.options[i].imageChange === false){
      let options = this.state.options
      options[i].imageChange = true
      this.setState({
        options: options,
      });
    }else if (actionType === "out" && this.state.options[i].imageChange === true){
      let options = this.state.options
      options[i].imageChange = false
      this.setState({
        options: options,
      });
    }
      
  };
  getFileTypes = (type) => {
    switch (type) {
      case "video":
        return ".mp4, .avi, .mpeg, .wmv, .mov";
      case "audio":
        return ".mp3, .ogg, .mpeg";
      default:
        return ".jpg, .jpeg, .png, .gif";
    }
  };
  render() {
    let { activity } = this.props;
    let questionTypeOption= this.state.questionAnswerType.map((ques, i)=> (
      <option selected={ques == this.state.quesType} value={ques} key={ques}>
        {ques}
      </option>
    ));
    let answerTypeOption= this.state.questionAnswerType.map((ans, i)=> (
      <option selected={ans == this.state.optionType}  value={ans} key={ans}>
       {ans}
      </option>
    ));
    let subCat = QASubCategoryType.map((type, i)=> (
      <option selected={type == this.state.catType} value={type} key={type}>
        {type}
      </option>
    ));
    let orderOption =
    this.state.options &&
    this.state.options.map((v, i) => (
      <option  value={i+1} key={"key"+i}>
        {i+1}
      </option>
    ));
    return (
      <ActivityPopUpContainer>
        <PanelHeader primary>
          <span> Create MCQ </span>
          {activity.active_mcq ? (
            <ThemeButton primary onClick={this.updateMcq}>
              Done
            </ThemeButton>
          ) : (
            <ThemeButton primary onClick={this.createMcq}>
              Done
            </ThemeButton>
          )}
        </PanelHeader>
        <ActivityModalContainer>
          <QuestionHeader>
            
            <div className="input-group my-1" style={{width: 'fit-content'}}>
              <div className="input-group-prepend">
                <label className="input-group-text" htmlFor="inputQuestion01">Question Type</label>
              </div>
              <select style={{textTransform:"capitalize"}} className="custom-select" id="inputQuestion01"  onChange={(e) => this.handleQuesChange(e)}>
                {questionTypeOption}
              </select>
            </div>
            <div className="input-group my-1" style={{width: 'fit-content'}}>
              <div className="input-group-prepend">
                <label className="input-group-text" htmlFor="inputAnswer01">Option Type&nbsp;&nbsp;</label>
              </div>
              <select disabled={activity.active_mcq != null} style={{textTransform:"capitalize"}} className="custom-select" id="inputAnswer01"  onChange={(e) => this.handleOptionChange(e)}>
                {answerTypeOption}
              </select>
            </div>  
            <div className="input-group my-1" style={{width: 'fit-content'}}>
              <div className="input-group-prepend">
                <label className="input-group-text" htmlFor="inputQA01">Category Type</label>
              </div>
              <select disabled={activity.active_mcq != null} style={{textTransform:"capitalize"}} className="custom-select" id="inputQA01"  onChange={(e) => this.handleCatChange(e)} >
                {subCat}
              </select>
            </div>
            <ErrorField width={this.state.error ? "auto" : "0"}>
              {this.state.error}
            </ErrorField>
            <div style={{display:"flex"}}>
            {this.state.quesType == 'image'?
              !this.state.quesMedia?
              <UploadImageContainer
                style={{
                  backgroundColor: "rgba(67, 38, 102,0.1)",
                  borderRadius: "4px",
                  height: "90px",
                  width: '90px',
                  marginTop: '15px',
                  marginBottom: '15px',
                  marginRight: '15px'
                }}
              >
                <UploadCollectionImageButton
                  type="button"
                  style={{ backgroundColor: "transparent" }}
                >
                  <i className="fa fa-cloud-upload" />
                  <div>Upload Image </div>
                </UploadCollectionImageButton>

                <SelectFileIn
                  style={{ height: "90px" }}
                  type="file"
                  onChange={(e) => this.handleQuesImageChange(e)}
                />
              </UploadImageContainer>
              :
              <UploadImageContainer
                style={{
                  backgroundColor: "rgba(67, 38, 102,0.1)",
                  borderRadius: "4px",
                  height: "90px",
                  width: '90px',
                  marginTop: '15px',
                  marginBottom: '15px',
                  marginRight: '15px'
                }}
                onMouseOver={() => this.handleQuesImageFocus("over")}
                onMouseOut={() => this.handleQuesImageFocus("out")}
              >
                <ImageContainer src={this.state.quesMedia} />
                <ChangeImageButton
                  top={this.state.qimageChange ? "83%" : "100%"}
                  opacity={this.state.qimageChange ? 1 : 0}
                >
                  Change Image
                </ChangeImageButton>
                <SelectFileIn
                  style={{ height: "90px" }}
                  type="file"
                  onChange={(e) => this.handleQuesImageChange(e)}
                />
              </UploadImageContainer>:null}
              {this.state.quesType == 'audio' || this.state.quesType == 'video'?
                 <AudioThemeButton2>
                   <input id="file"
                    type="file"
                    accept={this.getFileTypes(this.state.quesType)}
                    onChange={this.handleFileUpload}
                    onClick={this.handleAudioUploadBtn}/>
                    <label for="file" class="btn-2">
                      <span>Upload</span>
                    </label>
               </AudioThemeButton2>:null
              }
              <CreateMCQQuestionContainer>
                <TextArea
                  placeholder="Enter question"
                  autoFocus={true}
                  value={this.state.question}
                  onChange={this.updateQuestion}
                />
              </CreateMCQQuestionContainer>
            </div>
            {this.state.quesType == 'audio' && this.state.quesMedia?
              <audio controls>
                <source src={this.state.quesMedia} type="audio/ogg"/>
                <source src={this.state.quesMedia} type="audio/mp3"/>
                <source src={this.state.quesMedia} type="audio/mpeg"/>
              </audio>:null
            }
            {this.state.quesType == 'video' && this.state.quesMedia?
                <video width="250" height="150" controls>
                  <source src={this.state.quesMedia} type="video/mp4"/>
                  <source src={this.state.quesMedia} type="video/avi"/>
                  <source src={this.state.quesMedia} type="video/mpeg"/>
                  <source src={this.state.quesMedia} type="video/wmv"/>
                  <source src={this.state.quesMedia} type="video/mov"/>
                </video>:null
            }
            
          </QuestionHeader>
            <div>
                <hr></hr>
              {this.state.options.map((option,i) => (
                <MCQOptionsAnswerContainer key={option.id}>
                  {this.state.optionType == "video" || this.state.optionType == "audio"? 
                  <AudioThemeUploadIcon>
                    <input id={"file"+i}
                      type="file"
                      accept={this.getFileTypes(this.state.optionType)}
                      onChange={(e)=>this.handleOptionUpload(i,e)}/>
                    <label for={"file"+i} class="btn-2">
                    </label>
                  </AudioThemeUploadIcon>
                  : 
                  <span style={{margin:'10px 0',fontSize:10}}>&#9658;</span>}
                  {this.state.optionType == "image" ?
                  option.media.length == 0 ?
                  <UploadImageContainer
                    style={{
                      backgroundColor: "rgba(67, 38, 102,0.1)",
                      borderRadius: "4px",
                      height: "100px",
                    }}
                  >
                    <UploadCollectionImageButton
                      type="button"
                      style={{ backgroundColor: "transparent" }}
                    >
                      <i className="fa fa-cloud-upload" />
                      <div>Upload Image </div>
                    </UploadCollectionImageButton>

                    <SelectFileIn
                      style={{ height: "100px" }}
                      type="file"
                      onChange={(e) => this.handleImageChange(i,e)}
                    />
                  </UploadImageContainer>
                  :
                  <UploadImageContainer
                    height="100px"
                    marginBottom="14px"
                    onMouseOver={() => this.handleImageFocus("over",i)}
                    onMouseOut={() => this.handleImageFocus("out",i)}
                  >
                    <ImageContainer src={option.media[0]} />
                    <ChangeImageButton
                      top={option.imageChange ? "83%" : "100%"}
                      opacity={option.imageChange ? 1 : 0}
                    >
                      Change Image
                    </ChangeImageButton>
                    <SelectFileIn
                      style={{ height: "100px" }}
                      type="file"
                      onChange={(e) => this.handleImageChange(i,e)}
                    />
                  </UploadImageContainer> :
                  this.state.optionType == "text" ?
                  
                   <TextArea
                      type="text"
                      autoFocus={true}
                      placeholder="Enter option"
                      value={option.name}
                      onChange={e => this.updateOption(option, e)}
                    />:this.state.optionType =="audio" && option.media[0]?
                      <audio  style={{width: 180}} controls>
                        <source src={option.media[0]} type="audio/ogg"/>
                        <source src={option.media[0]} type="audio/mp3"/>
                        <source src={option.media[0]} type="audio/mpeg"/>
                      </audio>:
                      this.state.optionType =="video" && option.media[0]?
                      <video width="180" height="100" controls>
                        <source src={option.media[0]} type="video/mp4"/>
                        <source src={option.media[0]} type="video/avi"/>
                        <source src={option.media[0]} type="video/mpeg"/>
                        <source src={option.media[0]} type="video/wmv"/>
                        <source src={option.media[0]} type="video/mov"/>
                      </video>:null
                  }
                  
                  {this.state.catType == "reorder"?
                    <select
                      style={{width:'auto'}}
                      className="custom-select"
                      id="inputGroupSelect01"
                      value={option.order}
                      onChange={(e) => this.changeOrder(e,i)}
                    >
                      <option selected={!option.order} defaultValue>Choose...</option>
                      {orderOption}
                    </select> : 
                    this.state.catType == "normal" || this.state.catType == "dragdrop" ?
                    <MCQCheckBoxContainer
                    onClick={() => this.setAsValidOption(option)}
                  >
                    {this.state.valid_option &&
                    this.state.valid_option.id === option.id ? (
                      <MCQCheckBoxInnerContainer />
                    ) : null}
                  </MCQCheckBoxContainer>
                  :null
                  }
                  {activity.active_mcq === null && (
                  <MCQDeleteButtonContainer
                    onClick={() => this.removeOption(option)}
                  >
                    <img
                      src={require("static/img/trash.png")}
                      className="image-fluid"
                      alt="Trash Icon"
                    />
                    {/* <span> Delete </span> */}
                  </MCQDeleteButtonContainer>
                )}
                </MCQOptionsAnswerContainer>
              ))}
              {activity.active_mcq === null &&
                this.state.options.length < 4 && (
                  <ThemeButton onClick={this.addNewOption}>
                    + Add Answer
                  </ThemeButton>
              )} 
              
            </div> 
          <div style={{ marginTop: "20px" }}>
            {this.state.options.length !== 0 && (
              <ThemeButton primary onClick={this.handleAddTagClick}>
                Add Skill Tag
              </ThemeButton>
            )}
          </div>

          {this.state.tagPill.length > 0 && (
            <TagChipsContainer>
              {this.state.tagPill.map((data, id) => (
                <TagChips
                  key={id}
                  onClick={() => this.handleTagRemoveClick(data)}
                >
                  {data.name}
                  <img
                    className="image-fluid"
                    src={require("static/img/tagCloseIcon.png")}
                    alt="Edit icon"
                  />
                </TagChips>
              ))}
            </TagChipsContainer>
          )}
        </ActivityModalContainer>
      </ActivityPopUpContainer>
    );
  }
}

// export default ActivityCreateMCQ;

export default connectStore({
  activity,
 tags,
  ui,
 })(ActivityCreateMCQ)
