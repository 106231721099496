import React, { Component } from "react";

import {
  ExploreButton,
  SearchBarContainer,
  ToolbarContainer,
} from "@c/users-view/MainContent.styled";
import { connectStore } from "redux-box";
import { module as inviteCode } from "store/inviteCode";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
// import { module as ui } from "store/ui";
import { module as ui } from "@st/ui";
import { module as activity } from "store/activity";
import {
  NavigationHeaderContainer,
  NavigationToolbar,
} from "@sc/NavigationBar.styled";
import { module as onboard } from "@st/onboard";
import {
  CheckBox,
  PublishContainer,
  ThemeButton,
} from "../../../static/styled/custom.styled";

class SchoolListHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      navigationSelector: false,
      searchBarEnable: false,
      displayMenu: false,
      searchOption: "name",
    };
  }

  componentWillReceiveProps(nextProps) {}

  componentDidMount() {
    // document.addEventListener('click', this.handleFilterClick);
  }
  componentWillUnmount() {
    // document.removeEventListener('click', this.handleFilterClick);
  }
  enableSearchBar = (actionType) => {
    if (actionType === "focus" && this.state.searchBarEnable === false)
      this.setState({
        searchBarEnable: true,
      });
    else if (actionType === "blur" && this.state.searchBarEnable === true)
      this.setState({
        searchBarEnable: false,
      });
  };

  handleNavigationSelector = () => {
    this.setState({
      navigationSelector: !this.state.navigationSelector,
      displayMenu: false,
    });
  };

  // methods handling search for parents and shild start
  handleChildSearch = (evt) => {
    let data = {
      string: evt.target.value,
      option: this.state.searchOption,
    };
    this.props.inviteCode.setSearchStringFun(data);
    // setTimeout(() => {
    //     this.props.user.requestChildrenByOffset(0);
    // }, 1500);
  };
  handleToggle = (value) => {
    this.setState({ searchOption: value });
    if (this.props.inviteCode.schoolSearch) {
      let data = {
        string: this.props.inviteCode.schoolSearch,
        option: value,
      };
      this.props.inviteCode.setSearchStringFun(data);
    }
  };

  handleClearChildSearch = () => {
    let { user } = this.props;
    user.setChildSearch("");
    user.requestChildrenByOffset();
  };

  downloadReport = (evt) => {
    const input = document.getElementById("divDownload");
    html2canvas(input).then((canvas) => {
      var imgData = canvas.toDataURL("image/png");
      var pdf = new jsPDF("p", "pt", [canvas.width, canvas.height]);
      var pdfWidth = pdf.internal.pageSize.getWidth();
      var pdfHeight = pdf.internal.pageSize.getHeight();
      pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);
      pdf.save("Report.pdf");
    });
    evt.stopPropagation();
  };
  //    cross button methods handling start

  // handleChangeStart = startDate => this.handleChange({ startDate });

  // handleChangeEnd = endDate => this.handleChange({ endDate });
  // export excel with date parameters methods end

  render() {
    const { open } = this.state;
    let { user } = this.props;
    let currentPath = window.location.pathname;
    const { permission } = this.props.onboard;
    return (
      <NavigationHeaderContainer>
        <NavigationToolbar>
          {!this.props.showDownloadReport && (
            <SearchBarContainer
              right
              width={this.state.searchBarEnable ? "300px" : "200px"}
            >
              <i className="fa fa-search" />
              <input
                type="text"
                placeholder="Search"
                onChange={this.handleChildSearch}
                value={this.props.inviteCode.schoolSearch}
                onFocus={() => this.enableSearchBar("focus")}
                onBlur={() => this.enableSearchBar("blur")}
              ></input>
            </SearchBarContainer>
          )}
          {!this.props.showDownloadReport && (
            <PublishContainer>
              <CheckBox
                active={this.state.searchOption == "name"}
                onClick={() => this.handleToggle("name")}
              >
                <i className="fa fa-check" />
              </CheckBox>
              <span style={{ color: "black" }}> School Name </span>
              &nbsp; &nbsp;
              <CheckBox
                active={this.state.searchOption == "code"}
                onClick={() => this.handleToggle("code")}
              >
                <i className="fa fa-check" />
              </CheckBox>
              <span style={{ color: "black" }}> School Code </span>
            </PublishContainer>
          )}
          {this.props.showDownloadReport && (
            <ThemeButton primary type="button" onClick={this.downloadReport}>
              {" "}
              Download Report{" "}
            </ThemeButton>
          )}
        </NavigationToolbar>
      </NavigationHeaderContainer>
    );
  }
}
// export default UsersListHeader;
export default connectStore({
  ui,
  inviteCode,
  activity,
  onboard,
})(SchoolListHeader);
