import React, { useEffect } from 'react'
import { useState } from 'react'
import { module as user } from "@st/user";
import { connectStore } from "redux-box";
import { module as onboard } from "@st/onboard";
import { module as ui } from "@st/ui";
import useDebouncing from '../../../../utils/useDebouncing'

import './LinkSchoolCode.css'
import { ThemeButton } from '../../../../static/styled/custom.styled';
import Select from 'react-select'
import { UserInputField } from '../ChildProfile.styled';

const LinkSchoolCode = (props) => {

  const [schoolCode, setSchoolCode] = useState('')
  const [section,setSection] = useState([])
  const debouncedSchoolCode = useDebouncing(schoolCode, 1000);

  const customStyles = {
    menu: (provided, state) => ({
      ...provided,
      position: "relative"
    }),
  };

  useEffect(() => {
    if (debouncedSchoolCode && debouncedSchoolCode.length == 6) {
      props.user.validateLinkSchool(debouncedSchoolCode);
    }
  }, [debouncedSchoolCode]);

  useEffect(()=>{
    if(props.user.link_school){
      props.user.getSections({
        school: props.user.link_school.id,
        grade: props.user.active_child.grade.id,
})
    }

  },[props.user.link_school])


  const handleLinkschoolCode = async() => {
    const data = {
      school: props.user.link_school.id,
      child: props.user.active_child.id,
      // school_class: props.user.active_child.section.id
      school_class: props.user.active_child.is_teacher? section.map((sec)=>sec.value):[section.value]
    }
    await props.user.linkSchoolCode(data)
    props.ui.hideModal()
  }
  return (
    <div className='link_school_code_form'>
      <UserInputField
        type="text"
        placeholder="Enter School Code"
        border={"2px solid #004ea3"}
        radius={"10px"}
        fontSize={"14px"}
        margin={"3px"}
        value={schoolCode}
        onChange={(evt) =>{if(evt.target.value.length <=6) {setSchoolCode(evt.target.value)}}}
      />
      {props.user.link_school && schoolCode.length ==6 && (
        <React.Fragment>
          <UserInputField
            type="text"
            disabled={true}
            border={"2px solid #004ea3"}
            radius={"10px"}
            fontSize={"14px"}
            margin={"3px"}
            value={props.user.link_school && props.user.link_school.name}
          />
          <Select
            className="dropdown-container"
            isMulti = {props.user.active_child.is_teacher ? true:false}
            value={section}
            onChange={(selectedSection) =>
              setSection(selectedSection)
            }
            options={props.user.sectionsList && props.user.sectionsList}
            placeholder="Select Section"
            styles={customStyles}
          />

          <ThemeButton primary onClick={() => handleLinkschoolCode()}
            type="button" className="link_school_code_form_btn">Link school code</ThemeButton>
        </React.Fragment>
      )}
    </div>
  )
}

export default connectStore({
  user,
  onboard,
  ui
})(LinkSchoolCode);