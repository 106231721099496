import styled from "styled-components";
import { DropDown, DropDownMenu } from "@sc/custom.styled";

export const Container = styled.div``;
export const secondary = "#ff8a23";

export const FreadomPointsInputField = styled.div`
  margin-left: 0.5rem;
`;

export const RCHeaderDropDown = styled(DropDown)`
  display: block;
  width: 70%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
`;

export const CategoryDropDown = styled(DropDown)`
  display: block;
  margin-right: 5%;
  width: 70%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
`;

export const RCHeaderDropDownMenu = styled(DropDownMenu)`
  border-radius: 0;
`;

export const CreateRCButton = styled.button`
  color: white;
  width: 100%;
  background: #ea872b;
`;

export const RCNameInput = styled.input`
  margin: 0.5rem 0;
`;

export const CategorySection = styled.div`
  display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    font-size: var(--lg-font-size);
    font-weight: 500;
`;

export const CategoryName = styled.div`
  // width: 76%;
`;

export const CategoryButton = styled.button`
  border: none;
  border-radius: 100px;
  border: ${(props) => (props.primary ? "0" : "1px solid")};
  border-color: ${(props) => (props.primary ? "white" : `${secondary}`)};
  padding: 5px 20px;
  background: ${(props) => (props.primary ? `${secondary}` : "white")};
  color: ${(props) => (props.primary ? "white" : `${secondary}`)};
  font-size: 15px;
  cursor: pointer;
  outline: none;
  transition: all 0.3s;
  margin-right:2%;
  height:40px;
  white-space:nowrap;
  &:hover {
    transform: ${(props) => props.primary && "translateY(-2px)"};
    box-shadow: ${(props) =>
      props.primary && "0px 3px 10px -2px rgba(0,0,0,0.5)"};
  }
  // margin-left: 66%;
`;

export const SubmitButton = styled.button`
  border: none;
  border-radius: 100px;
  border: ${(props) => (props.primary ? "0" : "1px solid")};
  border-color: ${(props) => (props.primary ? "white" : `${secondary}`)};
  padding: 5px 20px;
  background: ${(props) => (props.primary ? `${secondary}` : "white")};
  color: ${(props) => (props.primary ? "white" : `${secondary}`)};
  font-size: 15px;
  cursor: pointer;
  outline: none;
  transition: all 0.3s;
  &:hover {
    transform: ${(props) => props.primary && "translateY(-2px)"};
    box-shadow: ${(props) =>
      props.primary && "0px 3px 10px -2px rgba(0,0,0,0.5)"};
  }
  margin-top: 10%;
  margin-left: 40%;
`;

export const PopupBox = styled.div`
  position: absolute;
  // background: #00000050;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
`;

export const Box = styled.div`
     position: relative;
    width: 60%;
    margin: 0 auto;
    height: auto;
    max-height: 70vh;
    background: #fff;
    border-radius: 4px;
    padding: 20px;
    border: 1px solid #999;
    overflow: auto;
    margin-left: 21%;
    margin-top: 1%;
    border-radius: 8px;
    overflow:hidden;

`;

export const Closeicon = styled.span`
 content: "x";
    cursor: pointer;
    position: absolute;
    background: #ff8a23;
    color: white;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    line-height: 20px;
    text-align: center;
    border: 1px solid #999;
    font-size: 20px;
    margin-top: -17px;
    margin-left: 91.0%;
`;
