import React, { Component } from "react";
import { MainBodyContainer } from "static/styled/custom.styled";
import UsersListHeader from "./users-list-header/UsersListHeader";
import UsersTabRouter from "@r/usersTabRouter";
import { connectStore } from "redux-box";
import { module as activity } from "@st/activity";
// export default connectStore({
//   activity
// })

// export default 
class UsersContainer extends Component {
  componentDidMount() {
    this.props.activity.requestGrades();
  }
  render() {
    return (
      <MainBodyContainer>
        <UsersListHeader />
        <UsersTabRouter />
      </MainBodyContainer>
    );
  }
}

export default connectStore({
  activity
 })(UsersContainer)