
export default {
    SET_CASE_STUDIES: (state, {caseStudies}) => (state.caseStudies =caseStudies),
    SET_ACTIVE_CASE_STUDY:(state,{caseStudy})=>(state.active_case_study=caseStudy),
    FETCH_CASE_STUDY:(state,{value})=>(state.fetching_case_study=value),
    SET_CASE_STUDY_ID:(state,{caseStudyId})=>(state.caseStudyId=caseStudyId),
    ENABLE_CASE_STUDY_EDIT:(state,{value})=>(state.editCaseStudy=value),
    SET_FETCH_CASE_STUDIES_LIST:(state,{value})=>(state.fetch_case_studies_list=value)
    
};
