export default {
    // STATE VARIABLES FOR PAYMENTS MODULE
    plan_info: null,
    payment_list_result:[],
    search_payment: '',
    payment_type: 'success',
    payment_mode: '',
    payment_date: '',
    fetch_payment_list: false,
    payment_offset:0,

    // STATE VARIABLES FOR THE SUBSCRIPTION
    active_subscription:null,
    subscription_list_result:[],
    subscription_list:[],
    fetch_subscription_list:false,

    // STATE VARIABLES FOR THE PAYMENTS LIST FOR INDIVIDUAL SUBSCRIPTION PER CHILD
     // sub denotes sunscription
     sub_payment_list_result:[],
     fetch_sub_payment_list:false,
     sub_payment_list:[],

    //  STATE VARIABLES FOR GENERATING SCHOOL CODES 
    webPlans: [],
    phoneNumber: "",
    childList: [],
    selectedChild: {},
    selectedPlan: {},
    validatedPromoCode: null,
    paymentLink: ""  
}
