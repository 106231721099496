import styled from "styled-components";

export const TaskListCardContainer = styled.div`
    background: #FFFFFF;
    box-shadow: 2px 0px 34px rgba(0, 0, 0, 0.06);
    border-radius: 8px;   
    display : flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom : 20px;
`;

export const TaskListCardImageContainer = styled.div`
    height : 86px;
    width : 162px;
    background-image: url(${props => props.url});
`

export const TaskListCardContent = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
`

export const TaskListCardTitle = styled.div`
    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 19px;
    color: #000000;
    margin-bottom: 10px;
`

export const TaskListCardDescription = styled.div`
        font-family: Rubik;
        font-style: italic;
        font-weight: normal;
        font-size: 14px;
        line-height: 20px;
        color: #75717A;
`
export const TaskListCardType = styled.div`
    mix-blend-mode: normal;
    // opacity: 0.1;
    border-radius: 4px;
    width: 45px;
    height: 23px;
`