import styled from "styled-components";
import { DateSelectionField, ThemeButton } from "@sc/custom.styled";

export const InputLabel = styled.label`
  width: 140px !important;
`;

export const DateSelector = styled(DateSelectionField)`
  padding: 0.5rem 0;
  width: initial;
  height: initial;
  border-radius: 5px;
  margin: 0;

  input {
    border: none;
    font-size: 15px;
    margin-left: 4px;
    width: 100%;
    outline: none;
    ::placeholder {
      color: rgba(0, 0, 0, 0.2);
      font-size: 18px;
    }
  }
`;

export const DateSelectorIcon = styled.i`
  padding: 0rem 0.8rem;
  color: #eb8a2b;
  font-size: 20px;
`;

export const UploadButton = styled(ThemeButton)`
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  color: white;
  background: #ff8a23;
`;
