import { Api } from "./base";

const _headers = () => {
  return {
    Authorization:
      "JWT " + require("store").default.getState().onboard.user.auth_token,
    "Content-Type": "application/json",
  };
};

export default {
  //..................................UNIT CRUD.................

  getUnit(gradeId, offset, search) {
    const urlParams = new URLSearchParams(window.location.search);

    const unit_type = urlParams.get("type");

    let path = `/guided-path/v1/admin-guided-path-unit/?grade_id=${gradeId}&unit_type=${unit_type}`;

    if (search !== "")
      // add search param
      return Api.getCsv(path, _headers());

    return Api.getCsv(`${path}&offset=${offset}`, _headers());
  },
  getUnitBasedonSection(sectionId, offset, search) {
    const urlParams = new URLSearchParams(window.location.search);

    const id = urlParams.get("id");
    const unit_type = urlParams.get("type");

    let path = `/guided-path/v1/admin-guided-path-unit/?section_id=${sectionId}&guided_path=${id}`;

    if (search !== "")
      // add search param
      return Api.getCsv(path, _headers());

    return Api.getCsv(`${path}&offset=${offset}`, _headers());
  },

  createUnit(unitData) {
    const urlParams = new URLSearchParams(window.location.search);

    unitData["unit_type"] = urlParams.get("type");

    return Api.post(
      `/guided-path/v1/admin-guided-path-unit/`,
      unitData,
      _headers()
    );
  },

  updateUnit(unitUpdateData) {
    const urlParams = new URLSearchParams(window.location.search);

    unitUpdateData["unit_type"] = urlParams.get("type");

    return Api.put(
      `/guided-path/v1/admin-guided-path-unit/${unitUpdateData.unitId}/`,
      unitUpdateData,
      _headers()
    );
  },

  //.........................TASK CRUD......................
  requestTaskList(unitId) {
    return Api.get(
      `/guided-path/v1/admin-guided-path-task/?unit_id=${unitId}`,
      _headers()
    );
  },

  createTask(taskData) {
    return Api.post(
      `/guided-path/v1/admin-guided-path-task/`,
      taskData,
      _headers()
    );
  },

  updateTask(updateTaskData) {
    return Api.put(
      `/guided-path/v1/admin-guided-path-task/${updateTaskData.task_id}/`,
      updateTaskData,
      _headers()
    );
  },

  deleteTask(taskId) {
    return Api.deleteCall(
      `/guided-path/v1/admin-guided-path-task/${taskId}/`,
      _headers()
    );
  },

  getContentSearchList(searchValue, searchIndex) {
    return Api.get(
      `/global-search/v1/?query=${searchValue}&index_type=${searchIndex}`,
      _headers()
    );
  },

  getSectionsForGuidedPathProduct(product_id){
      return Api.get(`/guided-path/v1/admin-guided-path-school/?guided_path=${product_id}`,_headers())
  }
};
