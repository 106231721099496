import React, { Component } from "react";
import {
  ComponentContainer,
  CreatorComponent,
  SubContainer
} from "@sc/Creator.styled";
import NewsFreedsInfo from "./news-freeds-info/NewsFreedsInfo";
import {
  CheckBox,
  ComponentFooter,
  PublishContainer
} from "static/styled/custom.styled";
import TextArea from "react-autosize-textarea";
import {
  NewsFreedsDescriptionContainer,
  NewsFreedsSectionContainer
} from "./NewsFreedsCreator.styled";
import { connectStore } from "redux-box";
import { module as newsFreeds } from "store/newsFreeds";
import NewsFreedsMcqList from "./news-freeds-mcq-list/NewsFreedsMcqList";
import NewsFreadLink from "./news_freads_link/NewsFreadLink";
import NewsFreedsMcqEditor from "./news-freeds-mcq-editor/NewsFreedsMcqEditor";
import swal from "sweetalert2";
import { LoadingContainer } from "../../../static/styled/custom.styled";
import { module as onboard } from "@st/onboard";
import Permission from "@s/permission";

class NewsFreedsCreator extends Component {
  constructor(props) {
    super();
    this.state = {
      description: "",
      send_push_now: false,
      translate: "100%"
    };
  }

  componentWillMount() {
    let { newsFreeds } = this.props;
    let currentPath = window.location.pathname;
    if (
      newsFreeds.active_news_freed === null &&
      !currentPath.includes("/create")
    ) {
      newsFreeds.requestNewsFreedById(this.props.match.params.id);
      newsFreeds.requestGetAllNewsFreedQuestions(this.props.match.params.id);
    } else {
      newsFreeds.setEnableNewsEdit(true);
    }
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps &&
      nextProps.newsFreeds &&
      nextProps.newsFreeds.active_news_freed
    ) {
      this.normalizeForState(nextProps.newsFreeds.active_news_freed);
    } else if (nextProps && nextProps.newsFreeds) {
      this.setState({
        send_push_now: nextProps.newsFreeds.send_push_notification
      });
    } else {
      this.setState({
        description: "",
        send_push_now: false
      });
    }
  }

  normalizeForState(activeNewsFreed) {
    this.setState({
      send_push_now: activeNewsFreed.send_push_now
    });
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({
        translate: "0"
      });
    }, 300);
    let history = this.props.history;
    let { newsFreeds } = this.props;
    //let location=this.props.location;
    document.addEventListener("keyup", function(e) {
      if (
        e.keyCode === 27 &&
        window.location.pathname.includes("/news-freads")
      ) {
        history.push(
          "/news-freads/" +
            newsFreeds.active_news_grade_level +
            "/" +
            newsFreeds.active_news_level
        );
      }
    });
  }
  componentWillUnmount() {
    let { newsFreeds } = this.props;
    if (newsFreeds.active_news_edit) newsFreeds.setEnableNewsEdit(false);
    newsFreeds.setActiveNewsFreed(null);
    newsFreeds.setAllNewsQuestion([]);
    newsFreeds.setSendPushNotification(false);
    newsFreeds.setNewsEditorType("");
  }

  handlePushNotifications = () => {
    const {permission} = this.props.onboard;
    if(Permission.getPermission(permission, "change_newsfread")){
      let { newsFreeds } = this.props;
      let currentPath = window.location.pathname;
      if (currentPath.includes("create")) {
        if (newsFreeds.questions && newsFreeds.questions.length === 0)
          swal(
            "Oops!",
            "Please add mcq to push news fread immediately",
            "warning"
          );
        else
          newsFreeds.setSendPushNotification(!newsFreeds.send_push_notification);
      }
    }
  };

  handleDescriptionChange = evt => {
    let { newsFreeds } = this.props;

    this.setState({
      description: evt.target.value
    });
    console.log(newsFreeds.active_news_edit);
    if (newsFreeds.active_news_edit)
      newsFreeds.setActiveNewsFreedDescription(evt.target.value);
  };

  render() {
    let { newsFreeds } = this.props;
    return (
      <CreatorComponent key={this.props.location.key}>
        <ComponentContainer
          style={{
            transform: `translateX(${this.state.translate})`,
            position: "relative"
          }}
        >
          {this.props.newsFreeds.fetch_active_news_freed ? (
            <LoadingContainer
              style={
                newsFreeds.active_news_freed ? { height: "50px" } : { top: 0 }
              }
            >
              <div>
                <img
                  alt="loading"
                  width="60"
                  height="60"
                  src={require("static/img/Theme-loader.gif")}
                />
              </div>
            </LoadingContainer>
          ) : null}
          {!this.props.newsFreeds.fetch_active_news_freed ? (
            <NewsFreedsInfo
              history={this.props.history}
              newsInfo={
                newsFreeds.active_news_freed
                  ? newsFreeds.active_news_freed
                  : null
              }
            />
          ) : null}
          {!this.props.newsFreeds.fetch_active_news_freed ? (
            <NewsFreedsSectionContainer>
              <NewsFreedsDescriptionContainer>
                <TextArea
                  placeholder="Add description here"
                  value={this.props.newsFreeds.active_news_description}
                  onChange={this.handleDescriptionChange}
                />
              </NewsFreedsDescriptionContainer>
              <NewsFreedsMcqList />
              {this.props.match.params.id && <NewsFreadLink id={this.props.match.params.id}/>}
            </NewsFreedsSectionContainer>
          ) : null}
          {!this.props.newsFreeds.fetch_active_news_freed ? (
            <ComponentFooter>
              <PublishContainer onClick={this.handlePushNotifications}>
                <CheckBox active={this.props.newsFreeds.send_push_notification}>
                  <i className="fa fa-check" />
                </CheckBox>
                <span> Send Push Notifications Immediately</span>
              </PublishContainer>
            </ComponentFooter>
          ) : null}
        </ComponentContainer>
        {newsFreeds.current_news_editor_type && (
          <SubContainer active={newsFreeds.current_news_editor_type}>
            {newsFreeds.current_news_editor_type === "mcq" && (
              <NewsFreedsMcqEditor />
            )}
          </SubContainer>
        )}
      </CreatorComponent>
    );
  }
}
export default connectStore({
  newsFreeds,onboard
})(NewsFreedsCreator)