import styled from "styled-components";
export const UploadContainer = styled.div`
  padding: 14px;
  width: 100%;
  background-color: #fafafa;
  border-radius: 10px;
  margin: 14px 0px;
`;

export const SectionToolbar = styled.div`
  width: 10%;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
`;

export const ToolbarItem = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  font-size: var(--lg-font-size);
  .icon-container {
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100px;
    background: rgba(67, 38, 102, 0.1);
    transition: all 0.3s;
    i {
      color: ${props => (props.primary ? "#0acade" : "#ff7373")};
      transition: all 0.3s;
    }
    &:hover {
      background: ${props => (props.primary ? "#0acade" : "#ff7373")};
      i {
        color: white;
      }
    }
  }
`;
