import { createSagas } from "redux-box";
import swal from "sweetalert2";
import { call, put } from "redux-saga/effects";
import NewsFreedsApi from "@s/api/newsFreeds";

const errorHandler = err => {
  console.log(err);
  swal("Oops!", err.error_message, "error");
};

export default createSagas({
  REQUEST_NEWS_FREEDS: function* () {
    try {
      yield put({
        type: "SET_FETCH_NEWS_FREEDS_LIST",
        value: true
      });
      let globalState = require("store").default.getState();
      let activeLevel =
        globalState.newsFreeds.active_news_grade_level +
        ":" +
        globalState.newsFreeds.active_news_level;
      let date = globalState.newsFreeds.active_news_date;
      let search = globalState.newsFreeds.search_freed;
      if (date === "") {
        let selectedDate = new Date();
        let month =
          selectedDate.getMonth() >= 9
            ? selectedDate.getMonth() + 1
            : "0" + (selectedDate.getMonth() + 1);
        let day =
          selectedDate.getDate() < 10
            ? "0" + selectedDate.getDate()
            : selectedDate.getDate();
        date = selectedDate.getFullYear() + "-" + month + "-" + day;
      }

      const res = yield call(
        NewsFreedsApi.requestNewsFreeds,
        0,
        date,
        activeLevel,
        search
      );

      yield put({
        type: "SET_NEWS_FREEDS_RESULT",
        result: res
      });
      if (res && res.result) {
        yield put({
          type: "SET_NEWS_FREEDS_LIST",
          newsFreeds: res.result
        });
      }
    } catch (err) {
      errorHandler(err);
    } finally {
      yield put({
        type: "SET_FETCH_NEWS_FREEDS_LIST",
        value: false
      });
    }
  },
  REQUEST_NEWS_FREEDS_BY_OFFSET: function* ({ offset }) {
    try {
      let globalState = require("store").default.getState();
      let search = globalState.newsFreeds.search_freed;
      let activeLevel =
        globalState.newsFreeds.active_news_grade_level +
        ":" +
        globalState.newsFreeds.active_news_level;
      let date = globalState.newsFreeds.active_news_date;
      if (date === "") {
        let selectedDate = new Date();
        let month =
          selectedDate.getMonth() >= 9
            ? selectedDate.getMonth() + 1
            : "0" + (selectedDate.getMonth() + 1);
        let day =
          selectedDate.getDate() < 10
            ? "0" + selectedDate.getDate()
            : selectedDate.getDate();
        date = selectedDate.getFullYear() + "-" + month + "-" + day;
      }

      const res = yield call(
        NewsFreedsApi.requestNewsFreeds,
        offset,
        date,
        activeLevel,
        search
      );

      yield put({
        type: "SET_NEWS_FREEDS_RESULT",
        result: res
      });
      if (res && res.result) {
        yield put({
          type: "SET_NEWS_FREEDS_LIST_CONCAT",
          newsFreeds: res.result
        });
      }
    } catch (err) {
      errorHandler(err);
    }
  },
  REQUEST_NEWS_FREEDS_BY_LEVEL: function* ({ levelData }) {
    try {
      yield put({
        type: "SET_ACTIVE_NEWS_GRADE_LEVEL",
        gradeLevel: levelData.level
      });

      yield put({
        type: "SET_ACTIVE_NEWS_LEVEL",
        level: levelData.subLevel
      });
      yield put({
        type: "REQUEST_NEWS_FREEDS"
      });
    } catch (err) {
      errorHandler(err);
    }
  },
  REQUEST_CREATE_NEWS_FREED: function* ({ data }) {
    try {
      let globalState = require("store").default.getState();
      let description =
        globalState.newsFreeds.active_news_description &&
          globalState.newsFreeds.active_news_description.length > 0
          ? globalState.newsFreeds.active_news_description
          : "";
      let send_push_now = globalState.newsFreeds.send_push_notification;
      const res = yield call(NewsFreedsApi.createNewsFreed, {
        ...data,
        description: description,
        send_push_now: send_push_now
      });

      let active_question =
        globalState.newsFreeds.questions.length > 0
          ? globalState.newsFreeds.questions[0]
          : null;
      if (active_question !== null && res !== null) {
        yield call(NewsFreedsApi.createQuestion, {
          ...active_question,
          news_fread: res.id
        });
      }
      swal("Done", "News Fread created successfully", "success");
      yield put({
        type: "REQUEST_NEWS_FREEDS"
      });
    } catch (err) {
      errorHandler(err);
    } finally {
      yield put({
        type: "SET_ACTIVE_NEWS_FREED_QUESTION",
        question: null
      });
    }
  },

  REQUEST_UPDATE_NEWS_FREED: function* ({ data }) {
    try {
      let globalState = require("store").default.getState();
      let description =
        globalState.newsFreeds.active_news_description.length > 0
          ? globalState.newsFreeds.active_news_description
          : "";
      let send_push_now = globalState.newsFreeds.send_push_notification;
      let news_freed_id = globalState.newsFreeds.active_news_freed.id;
      const res = yield call(NewsFreedsApi.updateNewsFreedById, news_freed_id, {
        ...data,
        description: description,
        send_push_now: send_push_now
      });
      yield put({
        type: "REQUEST_NEWS_FREED_BY_ID",
        newsFreedId: res.id
      });

      yield put({
        type: "REQUEST_NEWS_FREEDS"
      });
    } catch (err) {
      errorHandler(err);
    }
  },

  REQUEST_NEWS_FREED_BY_ID: function* ({ newsFreedId }) {
    try {
      yield put({
        type: "SET_FETCH_ACTIVE_NEWS_FREED",
        value: true
      });
      const res = yield call(NewsFreedsApi.requestNewsFreedById, newsFreedId);
      yield put({
        type: "SET_ACTIVE_NEWS_FREED_DESCRIPTION",
        description: res.description
      });

      yield put({
        type: "SET_SEND_PUSH_NOTIFICATION",
        value: res.send_push_now
      });

      yield put({
        type: "SET_ACTIVE_NEWS_FREED",
        activeFreed: res
      });
    } catch (err) {
      errorHandler(err);
    } finally {
      yield put({
        type: "SET_FETCH_ACTIVE_NEWS_FREED",
        value: false
      });
    }
  },

  // SAGAS FOR MCQ CRUD

  REQUEST_GET_ALL_NEWS_FREED_QUESTIONS: function* ({ newsFreedId }) {
    try {
      const res = yield call(NewsFreedsApi.getAllQuestion, newsFreedId);

      yield put({
        type: "SET_ALL_NEWS_QUESTION",
        question: res
      });
    } catch (err) {
      errorHandler(err);
    }
  },
  REQUEST_CREATE_NEWS_FREED_QUESTION: function* ({ question }) {
    try {
      const res = yield call(NewsFreedsApi.createQuestion, question);
      swal("Done!", "Created question!", "success");

      yield put({
        type: "REQUEST_GET_ALL_NEWS_FREED_QUESTIONS",
        newsFreedId: res.news_fread
      });
    } catch (err) {
      errorHandler(err);
    }
  },

  REQUEST_UPDATE_NEWS_FREED_QUESTION: function* ({ mcq }) {
    try {
      const gState = require("store").default.getState();
      const questionId = gState.newsFreeds.active_mcq.id;
      
      for (let i = 0; i < mcq.options.length; i++) {
        let option = mcq.options[i];
        yield NewsFreedsApi.updateOption(option.id, { name: option.name });
      }
      delete mcq.options;
      const res = yield call(NewsFreedsApi.updateQuestion, questionId, mcq);

      swal("Done!", "Updated question!", "success");
      yield put({
        type: "REQUEST_GET_ALL_NEWS_FREED_QUESTIONS",
        newsFreedId: res.news_fread
      });
    } catch (err) {
      errorHandler(err);
    }
  },
  REQUEST_DELETE_NEWS_FREED_QUESTION: function* ({ question }) {
    try {
      yield call(NewsFreedsApi.deleteQuestion, question.id);
      swal("Done!", "The selected question was deleted!", "success");
      yield put({
        type: "REQUEST_GET_ALL_NEWS_FREED_QUESTIONS",
        newsFreedId: question.news_fread
      });
    } catch (err) {
      errorHandler(err);
    }
  }
});
