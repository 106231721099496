import styled from "styled-components";
import {HoverAnimation} from "@sc/custom.styled";

export const NotificationCardMainContainer=HoverAnimation.extend`
display:flex;
justify-content:space-between;
flex-flow:row wrap;
overflow:hidden;
width:100%;
height:120px;
background-color:white;
cursor:pointer;
border-radius:10px;
box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.08);
margin-bottom:14px;

`;

export const NotificationBorder=styled.span`
margin:12px 0;
 width:2%;
 border-right:solid 1px rgba(151,151,151,0.2);`;

export const NotificationItemCard=styled.div`
 width:${props=>props.width};
 display:flex;
 position:relative;
 flex-flow:${props=>props.flexFlow};
 padding:${props=>props.padding};
 `;

export const NotificationImageContainer=styled.div`
 position:relative;
 width:120px;
 img
 {
 height: 120px;
    width: 100px;
    object-fit: cover;
    object-position: center;
    cursor: pointer;
   
 }
 `;

export const NotificationType=styled.div`
 width: 48px;
  height: 20px;
  border-radius: 12px;
  position:absolute;
  top:10px;
  left:10px;
  display:flex;
  align-items:center;
  font-size: var(--xsm-font-size);
  color:#000;
  font-weight: 600;
  justify-content:center;
  background-color: #fff;`;

export const NotificationDetailsContainer=styled.div`
display:flex;
flex-flow:column nowrap;
margin:12px 0px;
 padding:0px 12px;
 
 
`;

export const NotificationContent=styled.div`
font-size: ${props=>props.fontSize};
  font-weight:${props=>props.fontWeight};
 opacity:${props=>props.opacity?props.opacity:"1.0"};
   color: ${props=>props.color?props.color:'#000000'};
   word-break:break-all;
   overflow:hidden;
`;

export const ScheduleDetailsContainer=NotificationContent.extend`
 display:flex;
  justify-content:flex-end;
  align-items:center;
  opacity:0.6;`;

export const ItemContainer=styled.div`
  //width:110px;
  display:flex;
  justify-content:center;
  flex-flow:row nowrap;
  align-items:center;
  margin-left:20px;
  `;

export const NotificationToolbar=styled.div`
display:flex;
justify-content:flex-end;
margin-top:8px;
height:22px;
img {
    height: 22px;
    width:22px;
    padding:5px;
    background-repeat: no-repeat;
    cursor: pointer;
}`;