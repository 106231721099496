import React, { Fragment } from "react";
import { Route, Switch, withRouter } from "react-router-dom";
import { ModalBackDrop } from "static/styled/ModalBackDrop.styled";
import AllExperiencesEditor from "@c/experiences-view/all-experiences-editor/AllExperiencesEditor";

export const Routes = function({ location }) {
  return (
    <Switch location={location}>
      <Route
        path={"/experiences/:category/edit/:id"}
        render={props => (
          <Fragment>
            <AllExperiencesEditor {...props} />
            <ModalBackDrop active second />
          </Fragment>
        )}
      />
    </Switch>
  );
};

export default withRouter(Routes);
