import { createSagas } from "redux-box";
import swal from "sweetalert2";
import LeaderBoardApi from "@s/api/leaderBoard";
import { call, put } from "redux-saga/effects";


const errorHandler = err => {
  console.log(err);
  swal("Oops!", err.error_message, "error");
};
export default createSagas({
  REQUEST_LEADER_BOARD_LIST: function* ({ offset }) {
    try {
      yield put({
        type: "SET_LOADER",
        data: true
      });
      // searching in leaderboard
      const globalState = require("store").default.getState();
      let search = globalState.leaderBoard.search_leaderBoard;
      const res = yield call(LeaderBoardApi.getLeaderBoardList, search, offset);
      yield put({
        type: "SET_LEADER_BOARD_LIST",
        data: res
      });
      yield put({
        type: "SET_LOADER",
        data: false
      });
    } catch (err) {
      errorHandler(err);
    } finally {
      yield put({
        type: "SET_LOADER",
        data: false
      });
    }
  },
  REQUEST_FREADOM_POINT_LEADER_BOARD_LIST: function* ({ offset }) {
    try {
      yield put({
        type: "SET_LOADER",
        data: true
      });
      const globalState = require("store").default.getState();
      let search = globalState.leaderBoard.search_leaderBoard;
      const res = yield call(
        LeaderBoardApi.getFreadomPointLeaderBoardList, search,
        offset
      );
      yield put({
        type: "SET_FREADOM_POINT_LEADER_BOARD_LIST",
        data: res
      });
    } catch (err) {
      errorHandler(err);
    } finally {
      yield put({
        type: "SET_LOADER",
        data: false
      });
    }
  }
});

