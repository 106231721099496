export default {
  activities_result:null,
    activity_packs_result:null,
  activities: [],
  activityPacks: [],
  grades: [],
    activity_offset:0,
  active_grade: null,
    enableOrder:false,
  search_text: "",
  search_pack_text:'',
  new_sections: [],
  current_section_action: "create",
  current_editor_type: null,
    active_experience:null,
  active_section: {},
    active_grade_level:'Level 1',
    active_level:'Easy',
    fetch_activity_by_grade:false,
    fetch_activity_packs_by_level:false,
  new_section: null,
  active_activity: null,
  active_activity_pack: null,
  fetchingActivity: false,
  show_activity_pack:false,
  fetchingActivityPack: false,
  questions: [],
    active_pack_grade:{},
  active_question: {},
  active_options: [],
  new_question: {
    name: "",
    answer: "",
    level: "easy"
  },
  gradeList:[],
  new_options: [],
  active_mcq: null,
    activity_level:"easy",
    order_no:'',
};
