import styled from "styled-components";
import {StoryInputField} from "../story-creation-form/StoryCreationForm.styled";

export const InputBox=StoryInputField.extend`
width: 160px;
padding:0 10px;
font-size:14px;
text-align:center;
margin:0;
margin-left:20px;
`;

export const StorySectionContainer=styled.div`
    position:relative;
    padding:14px 0px;
`;

export const StorySectionsInfoContainer=styled.div`
overflow-y:auto;
height:100%;
padding:25px 30px;
margin-bottom:100px;
padding-top:205px;
`;

export const StoryFooter=styled.div`
position:absolute;
bottom:0;
padding:0 24px;
left:0;
background:white;
z-index: 1;
//height:40px;
display:flex;
justify-content:flex-start;
align-items:center;
width:100%;
box-shadow:0px 2px 4px 4px rgba(0,0,0,0.3);
`;


export const StorySectionInputField=styled.input`
    outline:none;
    width:240px;
    padding:4px 10px;
    height:32px;
    margin-right:20px;
    border-radius:100px;
    color:black;
    font-size:var(--md-font-size);
    border:1px solid  rgba(0,0,0,0.3);
    
    ::placeholder
    {
    font-size:var(--sm-font-size);
    }
    `;

export const StoryTypeContainer=styled.div`
    display:flex;
    flex-flow:row wrap;
    padding-top:18px;
    justify-content:flex-start`;

export const StoryTypeItem=styled.div`
    display:flex;
    flex-flow:row no-wrap;
    margin-right:16px;
    cursor:pointer;
    transition:all 0.3s;
    align-items:center;
    span{
     margin-left:8px;
     }`;

export const SectionsContainer=styled.div`
    padding:14px 0px;
    border-top:${props=>props.primary?'none':'1px solid rgba(0,0,0,0.3)'};
    `;