import { createSagas } from "redux-box";
import userApi from "@s/api/user";
import { call, put } from "redux-saga/effects";
//import {push} from "react-router-redux";
import swal from "sweetalert2";


const errorHandler = err => {
    //console.log(err);
    swal("Oops!", err.error_message, "error");

};

export default createSagas({
    //SAGA TO GET PARENT LIST
    REQUEST_PARENTS: function* () {
        try {
            let search = '';
            const globalState = require("store").default.getState();
            let offset = globalState.user.parents_offset;
            let filterParent = globalState.user.filterParentSearch;
            let parent_search = globalState.user.search_parent;
            let val = 0;
            let count = 0;
            for (let i in filterParent) {
                if (filterParent[i] !== '')
                    count++;
            }

            for (let i in filterParent) {
                if (filterParent[i] !== '') {
                    if (count === 1) {
                        search += (i + "=" + filterParent[i]);
                        break;
                    }
                    else {
                        search += (i + "=" + filterParent[i]);
                        if (val < count - 1)
                            search += "&";
                        val++;
                    }

                }
            }
            if (parent_search !== '') {
                if (count === 0)
                    search += "key=" + parent_search;
                else
                    search += "&key=" + parent_search;

            }
            yield put({
                type: "SET_FETCH_PARENT",
                value: true,
            });
            if (offset >= 0) {
                if (count > 0 || parent_search !== '')
                    search += "&offset=" + offset;
                else
                    search += "offset=" + offset;
            }

            const res = yield call(userApi.getAllParents, search);
            yield put({
                type: "SET_PARENTS",
                parents: res
            });


        } catch (err) {
            errorHandler(err);
        }
        finally {
            yield put({
                type: "SET_FETCH_PARENT",
                value: false,
            });
        }

    },
    REQUEST_PARENTS_BY_OFFSET: function* ({ offset }) {
        try {
            yield put({
                type: "SET_PARENTS_OFFSET",
                offset: offset
            });
            yield put({
                type: "REQUEST_PARENTS",
            })
        } catch (err) {
            errorHandler(err);
        }
    },
    REQUEST_PARENT_BY_ID: function* ({ parentId }) {
        try {
            const res = yield call(userApi.getParentById, parentId);
            yield put({
                type: "SET_ACTIVE_PARENT",
                parent: res
            });
        } catch (err) {
            errorHandler(err);
        }
    },

    REQUEST_UPDATE_PARENT_PROFILE: function* ({ parent }) {

        try {
            const res = yield call(userApi.updateParentProfile, parent.id, parent);
            if (res) {
                swal('Done!', res.name + ' profile updated successfully!', 'success');
            }
            yield put({
                type: "REQUEST_PARENT_BY_ID",
                parentId: res.id
            });
            yield put({
                type: "REQUEST_PARENTS",
            });
        } catch (err) {
            errorHandler(err);
        }
    },
    REQUEST_CHILDREN_BY_OFFSET: function* ({ offset }) {

        try {
            yield put({
                type: "SET_CHILDREN_OFFSET",
                offset: offset
            });
            yield put({
                type: "REQUEST_CHILDREN",
            })
        } catch (err) {
            errorHandler(err);
        }
    },
    REQUEST_CHILDREN: function* () {
        try {
            let search = '';
            const globalState = require("store").default.getState();
            let offset = globalState.user.children_offset;
            let filterChild = globalState.user.filterChildSearch;
            let child_search = globalState.user.search_child;
            let val = 0;
            let count = 0;
            for (let i in filterChild) {
                if (filterChild[i] !== '')
                    count++;
            }

            for (let i in filterChild) {
                if (filterChild[i] !== '') {
                    if (count === 1) {
                        search += (i + "=" + filterChild[i]);
                        break;
                    }
                    else {
                        search += (i + "=" + filterChild[i]);
                        if (val < count - 1)
                            search += "&";
                        val++;
                    }

                }
            }
            if (child_search !== '') {
                if (count === 0)
                    search += "key=" + child_search;
                else
                    search += "&key=" + child_search;

            }
            if (offset >= 0) {
                if (count > 0 || child_search !== '')
                    search += "&offset=" + offset;
                else
                    search += "offset=" + offset;
            }
            yield put({
                type: "SET_FETCH_CHILD",
                value: true,
            });
            const res = yield call(userApi.getAllChildren, search);
            yield put({
                type: "SET_CHILDREN",
                children: res
            });

        } catch (err) {
            errorHandler(err);
        }
        finally {
            yield put({
                type: "SET_FETCH_CHILD",
                value: false,
            });
        }

    },
    REQUEST_CHILDREN_BY_PARENT_ID: function* ({ parentId }) {

        try {
            const res = yield call(userApi.getChildrenByParentId, parentId);
            yield put({
                type: "SET_CHILDREN",
                children: res
            });

        } catch (err) {
            errorHandler(err);
        }

    },
    REQUEST_CHILD_BY_ID: function* ({ childId }) {
        try {
            const res = yield call(userApi.getChildById, childId);
            yield put({
                type: "SET_ACTIVE_CHILD",
                child: res
            });
        } catch (err) {
            errorHandler(err);
        }
    },
    REQUEST_UPDATE_CHILD_PROFILE: function* ({ child }) {
        try {
            const res = yield call(userApi.updateChildProfile, child.id, child);
             yield put({
        type: "SET_ACTIVE_CHILD",
        child: res,
      });
            yield put({
                type: "REQUEST_CHILD_BY_ID",
                childId: res.id
            });
            yield put({
                type: "REQUEST_CHILDREN",
            });
        } catch (err) {
            errorHandler(err);
        }
    },

    REQUEST_UPDATE_CHILD_SUB_LEVEL: function* ({ child }) {
        try {
            const res = yield call(userApi.updateChildProfile, child.id, child);
            swal('Done!', res.name + "'s level updated successfully to hard!", 'success');
            yield put({
                type: "REQUEST_CHILD_BY_ID",
                childId: res.id
            });
            yield put({
                type: "REQUEST_CHILDREN",
            });
        } catch (err) {
            errorHandler(err);
        }
    },
    EXPORT_PARENT_DATA: function* ({ data }) {
        try {
            let search = '';
            const globalState = require("store").default.getState();
            //let offset = globalState.user.parents_offset;
            let filterParent = globalState.user.filterParentSearch;
            let parent_search = globalState.user.search_parent;
            let val = 0;
            let count = 0;
            for (let i in filterParent) {
                if (filterParent[i] !== '')
                    count++;
            }

            for (let i in filterParent) {
                if (filterParent[i] !== '') {
                    if (count === 1) {
                        search += (i + "=" + filterParent[i]);
                        break;
                    }
                    else {
                        search += (i + "=" + filterParent[i]);
                        if (val < count - 1)
                            search += "&";
                        val++;
                    }

                }
            }
            if (parent_search !== '') {
                if (count === 0)
                    search += "key=" + parent_search;
                else
                    search += "&key=" + parent_search;
            }
            const res = yield call(userApi.exportData, data, search);
            if (res.success) {
                swal('Done!', 'Parents data exported successfully!', 'success');
                yield put({
                    type: "HIDE_MODAL"
                });
            }

        } catch (err) {
            errorHandler(err);
        }
    },
    EXPORT_CHILD_DATA: function* ({ data }) {
        try {
            let search = '';
            const globalState = require("store").default.getState();
            let filterChild = globalState.user.filterChildSearch;
            let child_search = globalState.user.search_child;
            let val = 0;
            let count = 0;
            for (let i in filterChild) {
                if (filterChild[i] !== '')
                    count++;
            }

            for (let i in filterChild) {
                if (filterChild[i] !== '') {
                    if (count === 1) {
                        search += (i + "=" + filterChild[i]);
                        break;
                    }
                    else {
                        search += (i + "=" + filterChild[i]);
                        if (val < count - 1)
                            search += "&";
                        val++;
                    }

                }
            }
            if (child_search !== '') {
                if (count === 0)
                    search += "key=" + child_search;
                else
                    search += "&key=" + child_search;

            }

            const res = yield call(userApi.exportData, data, search);
            if (res.success) {
                swal('Done!', 'Children data exported successfully!', 'success')
                yield put({
                    type: "HIDE_MODAL"
                });
            }
        } catch (err) {
            errorHandler(err);
        }
    },
    REQUEST_UPDATE_FREADOS_BY_CHILD_ID: function* ({ data }) {
        try {
            const res = yield call(userApi.updateFreadosByChildId, data);
            if (res.success) {
                swal('Done!', 'Freados Updated Successfully!', 'success');
            }
            yield put({
                type: "REQUEST_CHILD_BY_ID",
                childId: data.child_id
            });

        } catch (err) {
            errorHandler(err);
        }
    },
    REQUEST_UPDATE_STREAK_BY_CHILD_ID: function* ({ data }) {
        try {
            const res = yield call(userApi.updateStreakByChildId, data);
            if (res.success) {
                swal('Done!', 'Streak Updated Successfully!', 'success');
            }
            yield put({
                type: "REQUEST_CHILD_BY_ID",
                childId: data.child_id
            });

        } catch (err) {
            errorHandler(err);
        }
    },
    VALIDATE_LINK_SCHOOL: function*({ code }) {
    try {
      const res = yield call(userApi.validateSchool, code);
      // console.log(res)
      yield put({
        type: "SET_LINK_SCHOOL",
        school: res,
      });
    } catch (err) {}
  },
  GET_SECTIONS: function*({ data }) {
    // console.log(data)
    try {
      const res = yield call(userApi.getSections, data);
      // console.log(res)
      yield put({
        type: "SET_SECTIONS",
        data: res,
      });
    } catch (err) {
      errorHandler(err);
    }
  },
  LINK_SCHOOL_CODE: function*({ data }) {
    const child_id = data.child;
    try {
      const res = yield call(userApi.linkSchoolCode, data);
      // console.log(res)
      if (res) {
        swal("Done!", "School linked successfully.", "success");
        yield put({
          type: "REQUEST_CHILD_BY_ID",
          childId: child_id,
        });

        yield put({
          type: "SET_LINK_SCHOOL",
          school: null,
        });
      }
    } catch (err) {
      errorHandler(err);
    }
  },
  UNLINK_SCHOOL_CODE: function*({ data }) {
    // console.log(data)
    let child_id = data.child;
    try {
      const res = yield call(userApi.unlinkSchoolCode, data);
      if (res) {
        swal("Done!", "School unlinked successfully.", "success");

        yield put({
          type: "REQUEST_CHILD_BY_ID",
          childId: child_id,
        });

      }
      // console.log(res)
    } catch (err) {
      errorHandler(err);
    }
  },
})