import React, { PureComponent } from "react";
import {
  AlignContentHorizontal,
  ThemeButton,
  EmptyContainer,
  AutoContainer,
  DraggableContainer,
  DraggableItem,
  InfiniteScrollParentContainer,
  InfiniteScrollLoader,
} from "static/styled/custom.styled";
import {
  NavigationHeaderContainer,
  NavigationContainer,
} from "static/styled/NavigationBar.styled";
import ActivityCard from "./ActivityCard/ActivityCard";
import { connectStore } from "redux-box";
import { module as activity } from "@st/activity";
import { Link } from "react-router-dom";
import swal from "sweetalert2";
import { Transition } from "react-spring";
import {
  SortableContainer,
  SortableElement,
  arrayMove,
} from "react-sortable-hoc";
import { LoadingContainer } from "static/styled/custom.styled";
import InfiniteScroll from "react-infinite-scroller";

const SortableItem = SortableElement(({ activityInfo }) => (
  <DraggableItem
    className="col-sm-6 col-md-6 col-lg-4 col-xl-3"
    draggable={true}
  >
    <ActivityCard
      activityInfo={activityInfo}
      style={{ width: "200px", margin: "14px" }}
    />
  </DraggableItem>
));

const SortableList = SortableContainer(({ activities }) => {
  return (
    <DraggableContainer>
      {activities.map((activity, index) => (
        <SortableItem
          key={`item-${index}`}
          index={index}
          activityInfo={activity}
        />
      ))}
    </DraggableContainer>
  );
});

// export default connectStore({
//   activity
// })

class ActivitiesList extends PureComponent {
  constructor(props) {
    super();
    this.state = {
      classList: [],
      enableReorder: false,
      activitiesList: [],
      has_next: false,
    };
  }

  componentDidMount() {
    //FREAD-1185
    !this.props.activity.activities.length > 0 &&
      this.props.activity.setFetchActivityByGrade(true);
    // this.props.activity.requestActivities();
    if (this.props.activity.active_grade)
      this.setState({
        activities: this.props.activity.activities,
      });
    if (this.props.activity.activities_result) {
      this.setState({
        has_next: this.props.activity.activities_result.has_next,
      });
    }
  }
  componentWillReceiveProps(nextProps) {
    if (
      nextProps.activity &&
      nextProps.activity.activities &&
      nextProps.activity.activities_result
    ) {
      this.setActivityList(
        nextProps.activity.activities,
        nextProps.activity.activities_result
      );
    }
  }

  setActivityList = (activityList, activityResult) => {
    //console.log(activityList);
    this.setState({
      activitiesList: activityList,
      has_next: activityResult.hasNext,
    });
    //this.props.activity.setFetchActivityByGrade(false);
  };

  handleGradeChange = (gradeId) => {
    let { activity } = this.props;
    if (activity.enableOrder) {
      activity.enableReorder(false);
    }
    activity.requestActivitiesByGrade(gradeId);
  };

  handleDeleteClick = (activity, e) => {
    e.preventDefault();
    swal({
      text: "Are you sure that you want to delete this activity?",
      type: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
      activities: [],
    }).then((result) => {
      if (result.value) {
        this.props.activity.deleteActivity(activity.id);
      }
    });
  };

  handlePublishClick = (activity, e) => {
    e.preventDefault();
    let value = null;
    if (activity.is_published) value = "unpublish";
    else value = "publish";

    swal({
      text: "Are you sure that you want to " + value + " this activity?",
      type: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
    }).then((result) => {
      if (result.value) {
        this.props.activity.updateActivity({
          ...activity,
          is_published: !activity.is_published,
        });
      }
    });
    e.stopPropagation();
  };

  handleReorderBtn = () => {
    let { activity } = this.props;

    if (activity.active_grade) {
      this.props.activity.setActivitySearch("");
      if (activity.search !== "")
        activity.requestActivitiesByGrade(activity.active_grade);

      this.props.activity.enableReorder(true);
    }
  };

  // filterActivities = () => {
  //   return this.props.activity.active_grade!==null && this.props.activity.activities ? this.props.activity.activities.filter(activity => {
  //     return (
  //         activity.name.toLowerCase().includes(this.props.activity.search_text.toLowerCase())
  //
  //     )
  //   }): [];

  onSortEnd = ({ oldIndex, newIndex }) => {
    this.props.activity.setActivities(
      arrayMove(this.state.activitiesList, oldIndex, newIndex)
    );
    this.props.activity.reorderActivity({
      id: this.state.activitiesList[newIndex].id,
      new_order: newIndex + 1,
    });
  };

  fetchMoreActivities = () => {
    if (
      this.props.activity.activities_result !== null &&
      this.props.activity.activities_result.has_next
    ) {
      setTimeout(() => {
        if (
          this.props.activity.activities_result !== null &&
          this.props.activity.activities_result.next_page > 0
        )
          this.props.activity.requestActivitiesConcat(
            this.props.activity.activities_result.next_page
          );
        // this.setState({
        //     activitiesList: this.state.activitiesList.concat(Array.from({ length: 20 }))
        // });
      }, 300);
    }
  };

  render() {
    const { activity } = this.props;
    let activities = this.props.activity.activities;
    // let activities = activity.activities;

    let classList =
      this.props.activity.grades &&
      this.props.activity.grades.map((grade, i) => (
        <a
          key={i}
          className={
            activity.active_grade && grade === activity.active_grade
              ? "active"
              : ""
          }
          onClick={() => this.handleGradeChange(grade)}
        >
          <span> {grade.name}</span>
        </a>
      ));

    let activityList = (
      <AlignContentHorizontal marginBottom="70px">
        {activities && activities.length > 0 ? (
          <Transition
            from={{ opacity: 0 }}
            enter={{ opacity: 1 }}
            leave={{ opacity: 0 }}
            keys={activities.map((activity) => activity.id)}
          >
            {activities.map((activity) => (styles) => (
              <div
                style={styles}
                className="col-sm-6 col-md-6 col-lg-4 col-xl-3"
              >
                <Link to={"/activities/edit/" + activity.id}>
                  <ActivityCard
                    activityId={activity.id}
                    activityInfo={activity}
                    handleDeleteClick={(e) =>
                      this.handleDeleteClick(activity, e)
                    }
                    handlePublishClick={(e) =>
                      this.handlePublishClick(activity, e)
                    }
                    activityLength={activities.length}
                  />
                </Link>
              </div>
            ))}
          </Transition>
        ) : (
          <EmptyContainer>
            <h4>NO ACTIVITIES FOR THIS GRADE</h4>
          </EmptyContainer>
        )}

        {/*{activities && activities.length > 0 ? activities.map((activity, index) => {*/}
        {/*return  (*/}
        {/*<div className="col-sm-6 col-md-4 col-lg-3" key={index}>*/}
        {/*<Link*/}
        {/*to={"/activities/edit/" + activity.id}>*/}
        {/*<ActivityCard activityId={activity.id} activityInfo={activity}*/}
        {/*handleDeleteClick={(e) => this.handleDeleteClick(activity, e)}*/}
        {/*handlePublishClick={(e) => this.handlePublishClick(activity, e)}*/}
        {/*activityLength={activities.length}/>*/}
        {/*</Link>*/}
        {/*</div>*/}
        {/*)*/}
        {/*}) :*/}
        {/*<EmptyContainer>*/}
        {/*<h4>NO ACTIVITIES FOR THIS GRADE</h4>*/}
        {/*</EmptyContainer>*/}
        {/*}*/}
      </AlignContentHorizontal>
    );

    return (
      <AutoContainer>
        <NavigationHeaderContainer shadow>
          <NavigationContainer>{classList}</NavigationContainer>
          {!this.props.activity.enableOrder ? (
            <ThemeButton primary onClick={this.handleReorderBtn}>
              {" "}
              Reorder{" "}
            </ThemeButton>
          ) : (
            <ThemeButton
              primary
              onClick={() => this.props.activity.enableReorder(false)}
            >
              {" "}
              Done{" "}
            </ThemeButton>
          )}
        </NavigationHeaderContainer>
        {this.props.activity.fetch_activity_by_grade ? (
          <LoadingContainer style={{ zIndex: 100 }}>
            <img
              alt="loading"
              width="60"
              height="60"
              src={require("static/img/Theme-loader.gif")}
            />
          </LoadingContainer>
        ) : !this.props.activity.enableOrder ? (
          <InfiniteScrollParentContainer>
            <InfiniteScroll
              pageStart={0}
              loadMore={this.fetchMoreActivities}
              style={{ position: "relative" }}
              hasMore={
                this.props.activity.activities_result &&
                this.props.activity.activities_result.has_next
              }
              loader={
                <InfiniteScrollLoader className="loader" key={0}>
                  {" "}
                  <img
                    alt="loader"
                    width="50"
                    height="50"
                    src={require("static/img/Theme-loader.gif")}
                  />{" "}
                </InfiniteScrollLoader>
              }
              useWindow={false}
            >
              {activityList}{" "}
            </InfiniteScroll>
          </InfiniteScrollParentContainer>
        ) : (
          <div
            style={{
              position: "relative",
              overflowY: "auto",
              marginTop: "10px",
              left: "0",
            }}
          >
            <InfiniteScroll
              pageStart={0}
              style={{ height: "inherit", position: "relative" }}
              loadMore={this.fetchMoreActivities}
              hasMore={this.props.activity.activities_result.has_next}
              loader={
                <InfiniteScrollLoader className="loader" key={0}>
                  {" "}
                  <img
                    alt="loader"
                    width="50"
                    height="50"
                    src={require("static/img/Theme-loader.gif")}
                  />{" "}
                </InfiniteScrollLoader>
              }
              useWindow={false}
            >
              <SortableList
                activities={this.state.activitiesList}
                onSortEnd={this.onSortEnd}
                axis="xy"
                lockToContainerEdges={true}
                lockOffset="10%"
              />
            </InfiniteScroll>{" "}
          </div>
        )}
      </AutoContainer>
    );
  }
}
export default connectStore({
  activity,
})(ActivitiesList);
