import React, { Component } from "react";
import {
  MainBodyContainer,
  MainBodyContentContainer,
  PlusButton,
  PlusIcon
} from "static/styled/custom.styled";
import StoryViewHeader from "./story-view-header/StoryViewHeader";
import StoryOverlayRouter from "@r/storyOverlayRouter";
import { connectStore } from "redux-box";
import { module as ui } from "@st/ui";
import { module as story } from "@st/story";
import StoryViewList from "./story-view-list/StoryViewList";
import swal from "sweetalert2";
import { module as onboard } from "@st/onboard";
import Permission from "@s/permission";

class StoryView extends Component {
  componentDidMount() {
    this.props.story.setActiveStoryLevel(this.props.match.params.level);
    this.props.story.setActiveStoryGradeLevel(
      this.props.match.params.gradeLevel
    );
    this.props.story.requestStories();
  }

  handleAddClickButton = e => {
    let { story } = this.props;
    let gradeLevel = story.active_story_grade_level;
    let subLevel = story.active_story_level;
    let loc = this.props.location;
    const { permission } = this.props.onboard;
    if (loc.pathname === "/stories/" + gradeLevel + "/" + subLevel) {
      if(Permission.getPermission(permission, "add_story")){
        this.props.ui.showModal("STORYFORM");
      }else{
        swal({
          text: "You do not have permission.",
          type: "warning"
        });
      }
      
    } else {
      if (story.fetch_media_loading || story.fetch_images_loading) {
        swal({
          title: "Media file is being uploaded !",
          text: "Are you sure you want to close the story",
          type: "warning",
          showCancelButton: true,
          confirmButtonColor: "red",
          cancelButtonColor: "skyblue",
          confirmButtonText: "Yes, close it!"
        }).then(result => {
          if (result.value) {
            story.setFetchMediaLoading(false);
            if (story.current_story_type === "image")
              story.setFetchImagesLoading(false);
            this.props.story.setActiveStory(null);
            this.props.history.push("/stories/" + gradeLevel + "/" + subLevel);
          }
        });
      } else {
        this.props.history.push("/stories/" + gradeLevel + "/" + subLevel);
      }
    }
  };

  render() {
    const isCreatorOpen = this.props.location.pathname.includes(
      "/stories/" +
      this.props.story.active_story_grade_level +
      "/" +
      this.props.story.active_story_level +
      "/edit"
    );
    
    return (
      <MainBodyContainer>
        <StoryOverlayRouter />
        <StoryViewHeader history={this.props.history} />
        <MainBodyContentContainer>
          <StoryViewList />
        </MainBodyContentContainer>
       
          <PlusButton
            hide={this.props.ui.show_modal}
            onClick={this.handleAddClickButton}
          >
            <PlusIcon
              primary
              alt="Plus Icon"
              cross={isCreatorOpen}
              src={require("static/img/plusicon.png")}
            />
          </PlusButton>
      </MainBodyContainer>
    );
  }
}
export default connectStore({
  ui,
  story,
  onboard
})(StoryView)