import styled from "styled-components";

export const SectionHeading = styled.input`
  font-weight: 600;
  font-size: var(--lg-font-size);
  margin-bottom: 10px;
  background-color: white;
  padding: 2px 4px;
  border-radius: 10px;
  margin-right: 8px;
  border: solid 1px rgba(40, 50, 112, 0.1);
  width: ${props => props.width};
  outline: none;
  ::placeholder {
    color: rgba(40, 50, 112, 0.5);
    font-size: var(--sm-font-size);
  }
`;

export const UploadHeaderContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  justify-content: space-between;
  input[disabled] {
    color: #000000;
  }
`;

export const RemoveSection = styled.div`
  font-size: var(--sm-font-size);
  color: rgba(38, 54, 81, 0.4);

  cursor: pointer;

  &:hover {
    color: rgba(38, 54, 81, 0.8);
  }
`;

export const SectionNameEditable = styled.div`
  width: 60%;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  img {
    text-align: center;
    width: 22px;
    height: 18px;
    padding: 3px 5px;
    cursor:pointer;
  }
`;

export const EditIcon=styled.img`
    width: 22px;
    cursor:pointer;
    height: 18px;
    padding: 3px 5px;`;

export const ActivityImageContainer = styled.div`
width: ${props=>props.width?props.width:'150px'};
margin-left:${props=>props.marginLeft?props.marginLeft:'0px'};
  height: 150px;
  border-radius: 10px;
  border: dashed 1px rgba(151, 151, 151, 0.44);
  background-color: #ffffff;
  cursor: pointer;
  outline: none;
  display: flex;
  justify-content: space-around;
  position: relative;
  
`;
