import React, { Fragment } from "react";
import { Route, Switch, withRouter } from "react-router-dom";
import ActivityCreator from "../components/activity-view/activity-creator/ActivityCreator";
import ActivityPackCreator from "../components/activity-view/activity-pack-creator/ActivityPackCreator";
import { ModalBackDrop } from "static/styled/ModalBackDrop.styled";
export const Routes = function({ location }) {
  return (
    <Switch location={location}>
      <Route
        path={"/activities/edit/:id"}
        render={props => (
          <Fragment>
            <ActivityCreator {...props} />
            <ModalBackDrop active second />
          </Fragment>
        )}
      />
      <Route
        path={"/activities/packs/:gradeLevel/:level/edit/:id"}
        render={props => (
          <Fragment>
            <ActivityPackCreator {...props} />
            <ModalBackDrop active second />
          </Fragment>
        )}
      />
      <Route
        path="/activities/packs/:gradeLevel/:level/:activitypackid/activity/edit/:id"
        render={props => (
          <Fragment>
            <ActivityCreator {...props} />
            <ModalBackDrop active second />
          </Fragment>
        )}
      />
    </Switch>
  );
};

export default withRouter(Routes);
