import React, { Component } from 'react';
import {
    UserDetailsContainer, UserDetailValueLabels, UserField2, UserFieldName, UserListLoadingContainer, Pagination, PaginationButton
} from "@c/users-view/MainContent.styled";
import { module as payment } from '@st/payment';
import { connectStore } from "redux-box";
import {SectionCard} from "./PaymentsLink.styled"

import FetchChild from "./Sections/FetchChild.js"
import ChildCards from './Sections/ChildCards.js';
import PlanCards from './Sections/PlanCards.js';
import PromoCode from './Sections/PromoCode.js';
import GeneratePaymentLink from './Sections/GeneratePaymentLink.js';



class PaymentsLink extends Component {
    constructor(props) {
      super();
      this.state = {
        fetchedChild: false,
        fetchedPlan: false,
        showPromo: false,
        canGenerate: false,
        err: null,
        isLoading: false
      };
    }

    componentDidMount() {
        let { payment } = this.props;
        payment.requestWebPlansList();
    }

    hasErr=(errMssg)=> { // show error message only for 3 seconds
        this.setState({err: {message: errMssg}});
        setTimeout(()=>this.setState({err: null}), 3000)
    }

    fetchChild=(phoneNumber)=> {
        this.props.payment.setSelectedChild({});
        this.props.payment.setSelectedPlan({});
        this.props.payment.validatePromoCode("");
        this.setState({
            fetchedChild: false,
            fetchedPlan: false,
            showPromo: false,
            canGenerate: false
        });

        let { payment } = this.props;
        payment.requestChildList(phoneNumber)
        this.setState({fetchedChild: true});
    }

    fetchPlans= (isChildSelected) => {
        this.props.payment.setSelectedPlan({});
        this.props.payment.validatePromoCode("");
        if(isChildSelected) {
            this.setState({fetchedPlan: true});
        }
        else {
            this.setState({fetchedPlan: false});
        }
        // reset promo and generate component.
        this.setState({
            showPromo: false,
            canGenerate: false
        });
    }

    showPromo= (isPlanSelected) => {
        if(isPlanSelected) {
            this.setState({showPromo: true});
            this.setState({canGenerate: true});
        }
        else {
            this.setState({showPromo: false})
            this.setState({canGenerate: false})
        }
    }

    render() {
        return (
            <UserDetailsContainer>
                {this.state.err!==null && 
                <div className="alert alert-danger container m-0" role="alert">
                    {this.state.err.message}
                </div>}
                
                <div className="container my-4 px-4">
                    <FetchChild className="phone-num-ber-input" hasErr={this.hasErr} fetchChild={this.fetchChild} />
                    
                    {
                        this.state.fetchedChild &&
                        <ChildCards className="child-card-groups" fetchPlans={this.fetchPlans}/>
                    }

                    {
                        this.state.fetchedPlan && 
                        <PlanCards className="plan-card-groups" showPromo={this.showPromo} />
                    }
                    
                    {
                        this.state.showPromo && 
                        <PromoCode className="promo-code-input"/>
                    }

                    {
                        this.state.canGenerate &&
                        <GeneratePaymentLink />
                    }
                </div>
                {
                    this.state.isLoading && 
                    <div className="text-center">Loading...</div>
                }
            </UserDetailsContainer>
        )
    }
}
export default connectStore({
    payment
})(PaymentsLink)