import styled from "styled-components"
import {AlignContentHorizontal, ThemeButton,MainBodyContentContainer} from "static/styled/custom.styled";


export const AlignRow=AlignContentHorizontal.extend`
    margin:${props=>props.margin?props.margin:'0px 0px 28px 0px'};
    
    @media (max-width:767px)
        {
                margin-bottom:0px;
        }
        `;

export const ParentLabelMainContainer=styled.div`
        
        margin-left: 10px;`;


export const ParentFormField=styled.div`
        
        padding:${props=>props.padding?props.padding:'6px'};
        border-radius: 100px;
        margin:0px;
        background-color:white;
        outline: none;
        border: solid 1px rgba(40,50,112,0.1);
        input
        {
                 border: none;
    outline: none;
     background-color:white;
    width:80%;
    padding-top:0px;
    padding-left:10px;
    ::placeholder {
    color: ${props=>props.primary?'rgba(38,54,81,0.8)':'rgba(40,50,112,0.5)'};
    opacity: 1;
    font-size: 14px;
    font-weight: 500;

}
        }
        
        @media (max-width:767px)
        {
                margin-bottom:20px;
        }
        
        `;
export const LastLoginLabel=styled.div`
    font-size:var(--sm-font-size);
    font-weight:500;`;
export const ParentFormContainer=styled.div`
        padding:20px;
    object-fit: contain;
    border-radius: 6px;
    background-color: #ffffff;
    min-height:440px;
    margin:20px 0px 20px 0px;
    `;

export const ParentDetailsLabel=styled.div`
        font-size: 22px;
        font-weight: 500;
        text-align: left;
        color: #000000;
        @media (max-width:767px)
        {
                margin-bottom:20px;
        }
    `;
export const GenderFormField=ParentFormField.extend`
         padding:5px 10px;
         position: relative;
        display: flex;
        justify-content: space-between;
       
        `;
export const GenderFieldOptions=styled.button`
        border-radius: 100%;
    width:26px;

    margin-left:4px;
    height:26px;
    outline: none;
    border: none;
    cursor: pointer;
    left:20px;
    background-color: ${props=>props.primary?'var(--secondary-color)':'#d8d8d8'};
    font-size: 12px;
    font-weight: 500;
    text-align: center;
    color: #ffffff;`;


export const GenderValue=styled.span`
             color: ${props=>props.primary?'rgba(40,50,112,0.5)':'rgba(40,50,112,1.0)'};
             opacity: 1;
             font-size: ${props=>props.primary? 'var(--sm-font-size)' : 'var(--md-font-size)'};
             font-weight: 500;
             margin-top:${props=>props.primary?'5px' :'2px'};
             padding-left:8px;`;
export const ParentProfileHeaderLeft=styled.div`

    width:50%;
    display: flex;
    flex-flow: row nowrap;
    align-items:center;
    justify-content: flex-start;
    
        
    `;

export const SaveButton=ThemeButton.extend`
       margin-left:${props=>props.left?props.left:'0px'}
        padding:8px 20px;`;


export const CameraIconContainer=styled.div`

    border-radius: 100%;
    outline: none;
    background-color: #e0e0e0;
    width:44px;
    text-align: center;
    height:44px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    padding: 8px;
    
    img{
            padding:0px;
            height:12px;
            width:12px;
            
    }`;

export const ToggleButtonMainContainer=styled.div`

        width:180px;
        display: flex;
        flex-flow: row nowrap;
        align-items:center;
        justify-content:space-between;`;





export const ArrowLeft=styled.img`
     width: 34px;
             height: 14px;
             cursor:pointer;
             padding:0px 10px;
             `;


export const ParentNameContainer=styled.div`

    display:flex;
    flex-flow:row nowrap;
    
    
        
    `;

export const UserName=styled.input`font-size: 20px;
            font-weight: 500;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: 0.1px;
            text-align: left;
            border:none;
            outline:none;
            color: #263651;
            display:block;
            font-size:16px;
            background-color:white;
            
            min-width:120px;
            
            width:${props=>props.width};
            
            ::placeholder
            {
                font-size:14px;
            }`;

export const EditButton=styled.img`
            width:14px;
            height:14px;
            display:flex;
            margin-top:6px;
            align-items:center;
            outline:none;
            border:none;
            cursor: pointer;`;

export const ParentBodyContentContainer=MainBodyContentContainer.extend`
        padding:20px 40px 0px 40px`;

export const EditIcon=styled.div`
    display:flex;
    justify-content:center;
    align-items:center;
    flex-flow:row nowrap;
    margin-left:10px;
    cursor:pointer;
    transition:all 0.3s;
   
    img
    {
    width:26px;
    padding:0px 6px;
    height:14px;
    text-align:center;
    }`;
