import React, { Component } from "react";

import MusicImg from "../../../../static/img/album-music.svg";
import ReadStory from "../../../../static/img/read-story.png";
import WatchStory from "../../../../static/img/video-story.png";
import "./bookSummary.css";
import MediaViewer from "../../../activity-view/activity-creator/media-viewer/MediaViewer";
import Modal from "../../../activity-view/activity-creator/modal";

const READ = 'read';
const LISTEN = 'listen';
const WATCH = 'watch';

class BookSummary extends Component {
  constructor(props){
    super(props);
    this.state = {
      storyData: {},
      openModalViewer:false
    }
  } 

  componentDidMount() {
    const { data } = this.props
    this.setState({isLiked: data.is_liked, storyData: data})
  }
  hideMediaModal=()=>{
    this.setState({openModalViewer:false})
  }
  handleStoryReading = async () => {
    if(this.props.data.section_data && this.props.data.section_data.length > 0) {
      this.setState({openModalViewer:true})
      // this.props.close();
    } else {
      alert('Invalid media...')
    }
  }

  getStoryReadingText = () => {
      return 'Start Reading';
  }


  render() {
    const { data  } = this.props;
    const { quizOpened, isLiked, storyData } = this.state;
    let contentType = '';
    let mediaData = null;
    if(data.section_data && data.section_data.length > 0){
      contentType =data.section_data[0].content_type;
      if(contentType == 'image'){
        mediaData = data.section_data[0].media
      }else{
        if(data.section_data[0].media && data.section_data[0].media.length > 0){
          mediaData = data.section_data[0].media[0]
        }
      }
      
      
    }
    
    return (
      <div className="component-summary" >
        {/* <Icon className="close-icon" type="close" /> */}
        {/* <img src={CloseBlackIcon} className="close-icon" alt="close" onClick={this.props.close} /> */}
        <div className="flex left">
          <img
            src={data.background_image}
            alt="book-coverImg"
            className="bg-image"
          />
          <div className="mobile-back" onClick={this.props.close}>
            {/* <Icon type="left" /> */}
            <span style={{textShadow: '0px 2px 3px #333'}}>Back</span>
          </div>
          <div className="story-card">
            <div className="name">{data.name}</div>
            <div className="author-name">Written by <b>{data.author_name}</b></div>
          </div>
        </div>
        <div className="division" />
        <div className="flex description-card">
        {/* {quizOpened === false && <React.Fragment> */}
          <div>
            <div className="flex summary-card">
              <span className="title">DESCRIPTION</span>
              <div className="summary">{data.description}</div>
            </div>
            {data.story_type === LISTEN && <div className="audioBook">
              <img src={MusicImg} alt="album-music" style={{ marginRight: "5px" }} />
              <span className="text">Audiobook</span>
            </div>}
            {data.story_type === READ && <div className="audioBook">
              <img src={ReadStory} alt="audio" style={{ marginRight: "5px" }} />
              <span className="text">Read</span>
            </div>}
            {data.story_type === WATCH && <div className="audioBook">
              <img src={WatchStory} alt="video" style={{ marginRight: "5px" }} />
              <span className="text">Read</span>
            </div>}
          </div>
          <div style={{ padding: "15px", display: 'flex', justifyContent: 'flex-end' }}>
          <button type="button" className="btn btn-warning text-white" style={{background: '#ea882b'}}
            onClick={this.handleStoryReading} >
            { this.getStoryReadingText() }
          </button>
          </div>
          {/* </React.Fragment>} */}
        </div>
        {this.state.openModalViewer?<Modal
            id="media-modal"
            size='lg'
            isOpen={this.state.openModalViewer}
            onClose={this.hideMediaModal}
           >
              <MediaViewer closeMedia={() => this.setState({openModalViewer: false})} content_type={contentType} data={mediaData}/>
           </Modal>:null}
      </div>
    );
  }
}

export default BookSummary

