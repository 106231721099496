import React, { Component } from "react";

import swal from "sweetalert2";
import { Chevron, EditorMainContainer } from "../../../static/styled/custom.styled";
import DatePicker from 'react-datepicker';
import { module as user } from "store/user";
import { connectStore } from "redux-box";
import { ExploreButton } from "@c/users-view/MainContent.styled";
import { DateSelectionField } from "@sc/custom.styled";
import moment from 'moment';
import { ModalHeader } from '../export-excel-modal/export-date-modal.styled.js';
import { SearchBarContainer } from "@c/users-view/MainContent.styled";




class ExportExcelModal extends Component {
  constructor(props) {
    super();
    this.state = {
      startDate: '',
      endDate: '',
      export_email: '',
      // searchBarEnable: false,
    }
  };

  handleExportDataBtn = (e) => {
    e.preventDefault();
    // if (!this.state.export_email) {
    // swal.fire({
    //   position: 'error',
    //   type: 'warning',
    //   title: 'Email can\'t be empty',
    //   showConfirmButton: false,
    //   timer: 1700
    // })
    // return
    // }
    let { user } = this.props;
    let currentPath = window.location.pathname;
    if (currentPath === '/users/children') {
      let checkChild = this.props.user.exportChildData({ userType: "child", startedAt: this.state.startDate ? moment(this.state.startDate).format('YYYY-MM-DD') : this.state.startDate, endedAt: this.state.endDate ? moment(this.state.endDate).format('YYYY-MM-DD') : this.state.endDate, email: this.state.export_email ? this.state.export_email : '' })
    }
    else {
      let checkParent = this.props.user.exportParentData({ userType: "parent", startedAt: this.state.startDate ? moment(this.state.startDate).format('YYYY-MM-DD') : this.state.startDate, endedAt: this.state.endDate ? moment(this.state.endDate).format('YYYY-MM-DD') : this.state.endDate, email: this.state.export_email ? this.state.export_email : '' })
    }
  }
  onDatepickerRef(el) {
    if (el && el.input) {
      el.input.readOnly = true;
    }
  }

  handleChangeStart = startDate => {
    let { user } = this.props;
    this.setState({
      startDate: startDate
    })
    if (startDate) {
      let selectedDate = new Date(startDate);
      let month = selectedDate.getMonth() >= 9 ?
        selectedDate.getMonth() + 1 :
        "0" + (selectedDate.getMonth() + 1);
      let day = selectedDate.getDate() < 10
        ? "0" + selectedDate.getDate()
        : selectedDate.getDate();
      let startDatedata = selectedDate.getFullYear() + "-" + month + "-" + day;
      return startDatedata;

    }
  }

  enableSearchBar = actionType => {
    if (actionType === "focus" && this.state.searchBarEnable === false)
      this.setState({
        searchBarEnable: true
      });
    else if (actionType === "blur" && this.state.searchBarEnable === true)
      this.setState({
        searchBarEnable: false
      });
  };

  handleEmail = (e) => {
    this.setState({
      export_email: e.target.value
    })
  }

  handleChangeEnd = endDate => {
    let { user } = this.props;
    this.setState({
      endDate: endDate
    })
    if (endDate) {
      let selectedDate = new Date(endDate);
      let month = selectedDate.getMonth() >= 9 ?
        selectedDate.getMonth() + 1 :
        "0" + (selectedDate.getMonth() + 1);
      let day = selectedDate.getDate() < 10
        ? "0" + selectedDate.getDate()
        : selectedDate.getDate();
      let endDatedata = selectedDate.getFullYear() + "-" + month + "-" + day;
      return endDatedata;
    }
  }
  // method for cross button while entering date start
  handleClearStartBtn = () => {
    let { user } = this.props;
    this.setState({
      startDate: "",
    });
  };
  handleClearEndBtn = () => {
    let { user } = this.props;
    this.setState({
      endDate: "",
    });
  };
  // method for cross button while entering date end 
  render() {
    return (
      <div>
        <ModalHeader>Export CSV Data</ModalHeader>
        <div style={{ display: 'flex', height: '230px', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', marginTop: '-12px' }}>
          {/* <div style={{ display: 'flex' }}> */}
          {/* <input type="email" placeholder="email">Email</input> */}

          <div id="payments-date" style={{ display: 'flex' }}>
            <DateSelectionField width="220px" style={{ overflow: "hidden" }}>
              <img src={require("static/img/date-icon.png")} alt="startDate" />
              <DatePicker
                selected={this.state.startDate}
                selectsStart
                startDate={this.state.startDate}
                endDate={this.state.endDate}
                onChange={this.handleChangeStart}
                placeholderText='Start date'
                ref={el => this.onDatepickerRef(el)}
              />
              {this.state.startDate ? (
                <i
                  className="fa fa-times"
                  onClick={() => this.handleClearStartBtn()}
                  style={{ padding: "8px", cursor: "pointer", }}
                />
              ) : null}
            </DateSelectionField>
            <DateSelectionField width="220px" style={{ overflow: "hidden" }}>
              <img src={require("static/img/date-icon.png")} alt="startDate" />
              <DatePicker
                selected={this.state.endDate}
                selectsEnd
                startDate={this.state.startDate}
                endDate={this.state.endDate}
                onChange={this.handleChangeEnd}
                placeholderText='End date'
                minDate={this.state.startDate}
                ref={el => this.onDatepickerRef(el)}
              />
              {this.state.endDate ? (
                <i
                  className="fa fa-times"
                  onClick={() => this.handleClearEndBtn()}
                  style={{ padding: "8px", cursor: "pointer" }}
                />
              ) : null}
            </DateSelectionField>
          </div>
          <form style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }} onSubmit={this.handleExportDataBtn}>
            <SearchBarContainer style={{ marginTop: '10px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
              width={this.state.searchBarEnable ? "300px" : "200px"}
            >
              <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                <div>
                  <i style={{ color: '#ff8a23' }} className="fa fa-envelope" aria-hidden="true"></i>
                </div>
                <input
                  type="email"
                  name="email"
                  onChange={this.handleEmail}
                  placeholder="Enter email"
                  // value={this.props.activity.search_pack_text}
                  value={this.state.export_email}
                  onFocus={() => this.enableSearchBar("focus")}
                  onBlur={() => this.enableSearchBar("blur")}
                  required />
              </div>
            </SearchBarContainer>
            {/* </div> */}
            <ExploreButton type="submit" style={{ marginTop: '10px', }} primary> Submit</ExploreButton>
          </form>
        </div>
      </div>

    );
  }
}
export default connectStore({
  user
})(ExportExcelModal)




