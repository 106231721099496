import { createSagas } from "redux-box";
import CategoryApi from "@s/api/category";
import { call, put } from "redux-saga/effects";
import swal from "sweetalert2";

const errorHandler = (err) => {
  swal("Oops!", err.error_message, "error");
};

export default createSagas({
  GET_CATEGORY_DATA: function*() {
    console.log("Hello>>>>>>>");
    try {
      const res = yield call(CategoryApi.getAllCategory);
      console.log("result>>>>>>>", res);
      yield put({
        type: "SET_CATEGORY_DATA",
        value: res,
      });
    } catch (err) {
      errorHandler(err);
    }
  },

  REQUEST_CREATE_CATEGORY: function*({ category }) {
    console.log("requst_category", category);
    try {
      yield call(CategoryApi.createCategory, { name: category });
      yield put({
        type: "GET_CATEGORY_DATA",
      });
      swal("Done", "Category created successfully", "success");
    } catch (err) {
      errorHandler(err);
    }
  },
});
