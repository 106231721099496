import { Api } from "./base";

const _headers = () => {
  return {
    Authorization:
      "JWT " + require("store").default.getState().onboard.user.auth_token,
    "Content-Type": "application/json",
  };
};

export default {
  //.......................................Listing Quiz ........................................
  getQuizList(gradeId, offset) {
    return Api.getCsv(
      `/quiz/v1/admin_quiz/?offset=${offset}&grade_id=${gradeId}`,
      _headers()
    );
  },
  getQuizById(quizId) {
    return Api.get(`/quiz/v1/admin_quiz/${quizId}/`, _headers());
  },

  //....................................Quiz..........................

  createQuiz(data) {
    return Api.post(`/quiz/v1/admin_quiz/`, data, _headers());
  },
  updateQuiz(quizId, data) {
    return Api.put(`/quiz/v1/admin_quiz/${quizId}/`, data, _headers());
  },
  deleteQuiz(id) {
    return Api.deleteCall(`/quiz/v1/admin_quiz/${id}/`, _headers());
  },
  //.....................................Question.......................
  createQuestion(data) {
    return Api.post(`/quiz/v1/admin_content/`, data, _headers());
  },
  updateQuestion(questionId, data) {
    return Api.put(`/quiz/v1/admin_content/${questionId}/`, data, _headers());
  },
  deleteQuestion(questionId) {
    return Api.deleteCall(`/quiz/v1/admin_content/${questionId}/`, _headers());
  },
  getAllQuestions(quizid) {
    return Api.get(`/quiz/v1/${quizid}/`, _headers());
  },
};
