import React from 'react'
import { connectStore } from 'redux-box'
import { ThemeButton } from '../../../../static/styled/custom.styled'
import './unlink.css'
import { module as ui } from "@st/ui";
import { module as user } from "@st/user";
import Select from 'react-select'
import { useState } from 'react';
import { useEffect } from 'react';

const UnlinkSchoolCode = (props) => {
  const [sections, setSections] = useState([])
  const [linkedSections, setLinkedSections] = useState([])

  const customStyles = {
    menu: (provided, state) => ({
      ...provided,
      position: "relative"
    }),
  };

  useEffect(() => {
    if (props.user.active_child) {
      let allSections = props.user.active_child.school_class.map((section) => {
        return (
          {
            value: section.id,
            label: section.name
          }
        )
      })

      setLinkedSections(allSections)
    }
  }, [props.user.active_child])

  const handleUnlinkSchool = () => {
    const { user } = props;
    let data = {}
    {
      props.user.active_child.is_teacher ?
        data = {
          school: user.active_child.school_code,
          child: user.active_child.id,
          school_class: sections.map((sec) => sec.value)
        }
        :
        data = {
          school: user.active_child.school_code,
          child: user.active_child.id,
        }

    }
    user.unlinkSchoolCode(data);
    props.ui.hideModal()
  }
  return (
    <div className='unlink_school_code_modal'>
      <p>Are you sure you want to unlink the school from this child profile?</p>
      {
        props.user.active_child.is_teacher && linkedSections.length > 0 &&
        <Select
          className="dropdown-container"
          isMulti={props.user.active_child.is_teacher ? true : false}
          value={sections}
          onChange={(selectedSection) =>
            setSections(selectedSection)
          }
          options={linkedSections && linkedSections}
          placeholder="Select Section"
          styles={customStyles}
        />
      }
      <div className='unlink_btn_container'>
        <ThemeButton onClick={() => handleUnlinkSchool()} primary style={{ width: '50%' }} >UnLink</ThemeButton>{
          props.user.active_child.is_teacher && linkedSections.length > 0 && sections.length === 0 &&
          <ThemeButton onClick={() => handleUnlinkSchool()} primary style={{ width: '50%' }} >UnLink All</ThemeButton>
        }
        <ThemeButton onClick={() => props.ui.hideModal()} style={{ width: '50%' }} >Cancel</ThemeButton>
      </div>

    </div>
  )
}

export default connectStore({
  ui, user
})(UnlinkSchoolCode)