import React, {Component} from 'react';
import {StorySectionContainer} from "@c/story-view/story-creator/StoryCreator.styled";
import {FlexStart,SubHeading,MediaThemedButton} from "static/styled/custom.styled";
import {module as story} from "store/story"
import uploadFile from "services/api/file";
import {connectStore} from "redux-box";
import AudioSection from "@c/activity-view/activity-creator/audio-section/AudioSection";
import {
    ActivityUploadImageButton, ChangeImageButton, ImageContainer, SelectFileIn,
    UploadActivityImageContainer
} from "@c/activity-view/activity-creation-form/ActivityCreationForm.styled";
import {ImageLoader} from "static/styled/custom.styled";
import swal from "sweetalert2";
import { module as onboard } from "@st/onboard";
import Permission from "@s/permission";

class AudioStory extends Component
{
    constructor(props)
    {
        super();
        this.state=({
            imageChange:false,
            enableLoad:false,
            audioFileName:'',
            enableAudioLoad:false,
            currentAudioFile:'',
            thumbnail_image:'',
            media:[]
        });
    }


    
    handleAudioUpload=(e)=>
    {
        let {story}=this.props;
        if(e.target.files && e.target.files.length>0)
        {
                 story.setFetchMediaLoading(!this.state.enableAudioLoad);
                this.setState({
                     enableAudioLoad:true,

                });
            let activeStory=this.props.story.active_story;
            let fileSize = e.target.files[0].size / 1024 / 1024;
            if (fileSize > 10) {
                swal('Oops!', 'File size greater than 10MB ', 'warning')
            }
            else {
            uploadFile(e.target.files[0]).then(res => {
                this.setState({

                    media: [{thumbnail_url:'',media_url:res.file}],
                    current_audio:res.file,
                    enableAudioLoad:false,
                });
                this.props.story.setFetchMediaLoading(false);

                if(activeStory.section_data.length===0)
                {
                    let section={
                        name:this.state.audioFileName,
                        content_type:'audio',
                        media:this.state.media,
                        story:activeStory.id,

                    };
                    this.props.story.requestCreateStorySection(section);
                }
                else
                {
                    this.props.story.requestUpdateStorySection({name:this.state.audioFileName,media:this.state.media,content_type:'audio'});
                }
            });
        

            this.setState({
                audioFileName:e.target.files[0].name || '',

            });}


        }

        this.setState({

            thumbnail_image:'',
            imageChange:false,
        })
    }

    handleThumbnailImageFocus = actionType => {
        if (actionType === "over" && this.state.imageChange === false)
            this.setState({
                imageChange: true
            });
        else if (actionType === "out" && this.state.imageChange === true)
            this.setState({
                imageChange: false
            });
    };

    _handleThumbnailImageChange = e => {
        // e.preventDefault();
        let reader = new FileReader();
        let file = e.target.files[0];
        let fileSize = e.target.files[0].size / 1024 / 1024;
        if (fileSize > 2) {
          swal('Oops!', 'File size greater than 2MB ', 'warning')
        }
        else {
        reader.onloadend = () => {
            this.setState({
                imageChange:false,
                enableLoad:true,
                thumbnail_image:reader.result,

            });

        };
        reader.readAsDataURL(file);
        uploadFile(file)
            .then(res => {
                let media=this.state.media;
                if(media.length===0)
                {
                    media=[{thumbnail_url:res.file,media_url:''}]
                }
                else
                {
                    media=[{...this.state.media[0],thumbnail_url:res.file}]
                }
                this.setState({
                    thumbnail_image:res.file,

                    enableLoad:false});

                if(this.state.media && this.state.media.length>0 && this.state.media[0].media_url!=='')
                this.props.story.requestUpdateStorySection({media:media,content_type:'audio'});
                });}

    };
    componentDidMount()
    {
        let {section}=this.props;
        if(section!==null)
        {
            this.setState({
                audioFileName:section.name,
                thumbnail_image:section.media.length>0 && section.media[0].thumbnail_url!==''?section.media[0].thumbnail_url:'',
                media:section.media,
            })
        }
    }

    render(){
        let {story}=this.props;
        let {permission}=this.props.onboard;
        return(
            <StorySectionContainer>
                <FlexStart>
                    {Permission.getPermission(permission, "change_story") ?
                        <MediaThemedButton primary>
                            {!this.props.story.active_section || (story.active_section.length===0)?
                                'Upload Audio':'Change Audio'}
                    <input
                        type="file"
                        accept=".mp3, .ogg"
                        onChange={this.handleAudioUpload}
                    />
                        </MediaThemedButton>:null}
                    {this.state.enableAudioLoad?
                    <SubHeading style={{margin:'0px',marginLeft:'12px'}}>
                        Uploading Audio  <img alt="loader" src={require("static/img/Theme-loader.gif")} width="32" height="32" /> </SubHeading>:
                        <SubHeading style={{margin:'0px',marginLeft:'12px'}}> {this.state.audioFileName} </SubHeading>}
                </FlexStart>
                <div style={{marginTop:'14px'}}>
                    {this.state.thumbnail_image===''? (
                        <UploadActivityImageContainer height="100px">
                            <ActivityUploadImageButton type="button">
                                <div> <img src={require("static/img/cloud-upload-icon.png")} style={{width:'18px',height:'18px'}} alt="cloud-upload"/>
                                </div>
                                <span>Upload Thumbnail  </span>
                            </ActivityUploadImageButton>

                            <SelectFileIn
                                type="file"
                                onChange={e => this._handleThumbnailImageChange(e)}
                            />
                        </UploadActivityImageContainer>
                    ) : this.state.enableLoad?
                        <UploadActivityImageContainer height="100px" marginBottom="14px">
                            <ImageLoader > <img alt="loading" width="30" height="30" src="http://photokonkurs.russia.travel/images/icons/preloader.gif" />
                            </ImageLoader>
                        </UploadActivityImageContainer>:
                        <UploadActivityImageContainer
                            height="100px"
                            onMouseOver={() => this.handleThumbnailImageFocus("over")}
                            onMouseOut={() => this.handleThumbnailImageFocus("out")}
                        >
                            <ImageContainer
                                src={this.state.thumbnail_image} />
                            <ChangeImageButton
                                top={this.state.imageChange ? "83%" : "100%"}
                                opacity={this.state.imageChange ? 1 : 0}
                            >
                                Change Thumbnail
                            </ChangeImageButton>
                            <SelectFileIn
                                type="file"
                                onChange={e => this._handleThumbnailImageChange(e)}
                            />
                        </UploadActivityImageContainer>}
                </div>

                {!this.state.enableAudioLoad &&  this.props.story.active_section &&
                    <div style={this.props.story.active_section.media.length>0?{marginTop:'20px'}:{}}>
                <AudioSection media={this.state.media}/>
                </div>}

            </StorySectionContainer>
        )
    }
}
export default connectStore({
    story,onboard
})(AudioStory)