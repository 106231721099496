import React, { Component } from 'react';
import { AlbumContainer, AlbumListContainer, AlbumItem, AlbumImage } from "@sc/custom.styled";
export default class ImageSection extends Component {
    render() {
        return (<AlbumContainer>
            <AlbumListContainer>
                {this.props.media.map((image, i) => (
                    <AlbumItem key={i}>
                        <AlbumImage
                            src={image.media_url}
                            alt="album icon"
                        />
                    </AlbumItem>
                ))}
            </AlbumListContainer>
        </AlbumContainer>

        )
    }
}
